import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-underlying-investment-view',
  templateUrl: './underlying-investment-view.component.html',
  styleUrls: ['./underlying-investment-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class UnderlyingInvestmentViewComponent extends ComponentBase implements OnInit, OnDestroy {

  title: string = '';
  constructor(public store: Store<AppState>, public translate: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  setTitle(event) {
    this.translate.get('VIEWS.UnderlyingInvestments.subtitle')
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        if (x) { this.title = x; }
        else {
          this.title = event;
        }

      })
  }

}
