
import { IAccountUnderlyingInvestmentSummaryViewState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResponseUnderlyingInvestmentSummaryAction, InvestmentOptionChangedAction, ShowInvestmentOptionsAction } from './actions';
import { KeyValueModel } from '@ifaa-components/ui-components/lib/model/keyValueModel';
import { consolidateSuper_List } from '../consolidate-super/selectors';

export const state: IAccountUnderlyingInvestmentSummaryViewState = {
  model: null,
  selectedInvestmentOption: null,
  investmentOptions: [],
  hasMultipleInvestments : false,
  noOfInvestments : 0,
  isConsolidatedView :false,
};


const underlyingInvestmentSummeryReducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state1, action) => {
    return state1;
  }),
  on(ResponseUnderlyingInvestmentSummaryAction, (state, { payload }) => {
    
    var hasMultipleInvestments = false;
    var isConsolidatedView = true;

    var investmentOptions = payload.investmentOptions;
    
    var consolidatedInvestment = payload.investmentSummaries.filter(o => o.investmentOptionId == "-1");

    var investmentOptionsList = payload.investmentSummaries.filter(o => o.investmentOptionId != "-1");
    //Sort list be invested amount desc
    investmentOptionsList.sort(function (a, b) {
      return a.amountInvested - b.amountInvested;
    });

    var defaultInvestmentOption = null; 
    var investmentCount = investmentOptions.length;

    if(investmentOptionsList.length > 1)
    {
      hasMultipleInvestments = true;
      defaultInvestmentOption = consolidatedInvestment[0];
      
      
    }
    else{
      defaultInvestmentOption = investmentOptionsList[0];
    }
    
      return {
      ...state,
      model: payload,
      selectedInvestmentOption: defaultInvestmentOption,
      investmentOptions : investmentOptions, 
      hasMultipleInvestments:  hasMultipleInvestments,
      noOfInvestments : investmentCount,
      isConsolidatedView: isConsolidatedView
    };
  }),
  on(InvestmentOptionChangedAction, (state, { investmentOptionId }) => {
    var list = state.model.investmentSummaries.filter(o => o.investmentOptionId == investmentOptionId);
    var selected = null;
    if (list.length > 0)
      selected = list[0];

      return {
      ...state,
      selectedInvestmentOption: selected,
      isConsolidatedView: investmentOptionId == '-1' ? true : false
    };
  }),
    on(ShowInvestmentOptionsAction, (state) => {

    var first = state.model.investmentSummaries[0];
    var selected = null;
    if (first)
      selected = first;

      return {
      ...state,
      selectedInvestmentOption: selected,
      isConsolidatedView : false
    };
  })
);

export function accountUnderlyingInvestmentSummaryViewReducer(state: any | undefined, action: Action) {
  return underlyingInvestmentSummeryReducer(state, action);
}