import { createAction, props } from "@ngrx/store";
import { AccessYourSuperKiwiSaverDetailSectionModel, AccessYourSuperPaymentDetailSectionModel, AccessYourSuperSfhDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { AccessYourSuperApplicationDataModel } from "src/app/model/access-your-super-form.models";

export const GetCustomDataAction = createAction("[Access your super update submitted component] get custom section data", props<{accountId:number, applicationId:number, applicationTypeId:number}>());
export const SaveCustomDataAction = createAction("[Access your super update submitted component] save custom section data", props<{payload: AccessYourSuperPaymentDetailSectionModel|AccessYourSuperKiwiSaverDetailSectionModel|AccessYourSuperSfhDetailSectionModel}>());

export const GetKiwiSaverDataAction = createAction("[Access your super update submitted component] get kiwi saver data", props<{accountId: number, applicationId:number}>());
export const GetSfhDataAction = createAction("[Access your super update submitted component] get severe financial hardship data", props<{accountId: number, applicationId:number}>());

export const ResetComponentStateAction = createAction('[Access your super update submitted component] reset component state')

export const SaveApplicationDataForUpdateSubmittedAction = createAction("[Access your super update submitted component] set application data", props<{model:AccessYourSuperApplicationDataModel, applicationId:number}>());