import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperSfhDependantModel, AccessYourSuperSfhDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { InvestmentItemModel } from "src/app/model/investment-item.model";

export interface IAccessYourSuperSevereFinancialHardshipComponentState {
    model: AccessYourSuperSfhDetailSectionModel;
    formOne: FormGroupState<SfhBenefitPaymentDetailsForm>;
    formTwo: FormGroupState<SfhDependantsForm>;
    formThreeValid:boolean;
    savedFormData: Date;
    fullAmountRadioOptions: KeyValueModel[];
    taxOptions: KeyValueModel[];
    sfhStep: number;
    formVersion: number | null;
}

export class SfhBenefitPaymentDetailsForm {
    paymentType: number = 0;
    fullAmountSelection: number = 0;
    partialAmountSpecified: number = 0;
    isPermanentlyRetired: boolean = true;
    isBeforeTax: boolean = true;
    sfhSummary: string = "";
    receivingQualifyingSupport: boolean = true;
    receivingQualifyingSupportErrorMsg: string = "";
    partialWithdrawalSelection: boolean = false;
    investmentOptionDrawdown: InvestmentItemModel[] = [];
    memberReachedPreservationAge: boolean = false;
}

export class SfhDependantsForm {
    dependants: AccessYourSuperSfhDependantModel[] = [];
}

export class FinancialDetailsOutput{
    financialDetails: {[key:string]:any}
    isValid:boolean;
}