<div id="rollin-container" *ngIf="form$ | async; let form">

  <form [ngrxFormState]="form" (ngSubmit)="onConfirmClick()" *ngIf="form.value.step == 'start'">
    <!-- <div class="mb-3">
      <app-greenid [accountid]="accountId" (onVerified)="onVerified($event)"></app-greenid>
    </div> -->

    <app-superannuation-provider-search (onSelected)="onSelectedOption($event)"></app-superannuation-provider-search>


    <app-entry title="Your Member Number in the Fund" [control]="form.controls.memberNumber"
      [icon]="('VIEWS.Global.displayEntryIcons' | translate)?'assignment_ind':''">
    </app-entry>

    <app-entry-dropdown [control]="form.controls.amountToTransferOption" title="Amount to Transfer"
    [icon]="('VIEWS.Global.displayEntryIcons' | translate)?'chrome_reader_mode':''"  list="amountToTransfer">
    </app-entry-dropdown>


    <app-entry-number title="Requested Transfer Amount" [control]="form.controls.amountToTransfer"
      [icon]="('VIEWS.Global.displayEntryIcons' | translate)?'attach_money':''" class="attach_money" appanimatecss
      *ngIf="form.value.amountToTransferOption == 1">
    </app-entry-number>


    <!-- <div class="components-actions">
      <app-button loading class=" mr-1 ml-1" title="Continue" icon="east" type="submit" [isDisabled]="form.isInvalid || !isMemberVerified"
        (onClick)="onConfirmClick()">
      </app-button>
    </div> -->

    <div class="components-actions">
      <app-button loading class=" mr-1 ml-1" title="Continue" icon="east" type="submit"
        [isDisabled]="form.isInvalid || invalidProvider(form.value.provider)" (onClick)="onConfirmClick()">
      </app-button>
    </div>
  </form>

  <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form.value.step == 'confirm'"
    appanimatecss>
    <h4>Please review and confirm</h4>

    <app-superannuation-provider-search hidesearch="true"></app-superannuation-provider-search>
    <app-readonly title="Transfer from Account Number" [value]="form.value.memberNumber"></app-readonly>
    <app-readonly title="Amount to Request"
      [value]="form.value.amountToTransferOption == 0 ? 'Full Amount': 'Partial Amount'"></app-readonly>
    <app-readonly title="Requested Transfer Amount" [value]="form.value.amountToTransfer | currency"
      *ngIf="form.value.amountToTransferOption == 1"></app-readonly>

    <!-- <div class="alert mt-3"
      [ngClass]="{'alert-warning': !form.value.confirm.insurance, 'alert-success': form.value.confirm.insurance}">
      <div [innerHTML]="'rollover_ConfirmedInsurance'| message | async"></div>
      <app-checkbox [control]="form.controls.confirm.controls.insurance" class="w-100  mr-1 ml-1"
        title="I understand that any insurance benefit I had in my previous fund may cease upon transfer.">
      </app-checkbox>
    </div> -->

    <div class="alert mt-3" title=""
      [ngClass]="{'alert-warning': !form.value.confirm.termsAndConditions, 'alert-success': form.value.confirm.termsAndConditions}">
      <div [innerHTML]="'rollover_ConfirmedTC'| message | async"></div>
      <app-checkbox [control]="form.controls.confirm.controls.termsAndConditions" class="w-100  mr-1 ml-1"
        title="I have read, understood and agree to the above">
      </app-checkbox>
    </div>
    <div class="components-actions d-flex flex-row">
      <app-button loading class="  " title="Confirm" icon="east" type="submit"
        [isDisabled]="!(form.value.confirm.termsAndConditions)" (onClick)="onSubmitClick(form.value)">
      </app-button>

      <app-button loading class="back  mr-1 " title="Cancel" icon="west" color="light" (onClick)="onConfirmBackClick()">
      </app-button>

    </div>
  </form>
  <div *ngIf="form.value.step == 'done'">
    <div class="alert alert-success mt-4 d-flex flex-row align-items-center" role="alert">
      <div class="mr-4">
        <mat-icon style="font-size: 40px;">check</mat-icon>
      </div>
      <div>
        <p>
          Your rollover request has been submitted and should be processed in the next 3 to 28 Days.
        </p>
        <p>
          You can view the status of your recent super rollover requests on the dashboard.
        </p>
      </div>
    </div>

    <div class="components-actions">
      <app-button id="btnGoToDashboard" routerLink="/dashboard" loading class=" " title="Go to Overview Screen"
        icon="undo">
      </app-button>
    </div>

  </div>
</div>
