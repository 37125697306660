
import { IStartupState, SMRTStartupValidationModel } from './state';

import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { CheckAvailabilityResponseAction, StartupAction, StartupCompletedAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'StartupForm';

export const StartupState: IStartupState = {
  form: createFormGroupState(formName, new SMRTStartupValidationModel()),
  url: '',
  isLoading: false,
  isUnavailable: false,
  isUnavailableMessage: ''
};
var helper = new Helper();

export const validateStartupForm = updateGroup<SMRTStartupValidationModel>({
  email: validate(required, email),
});

const reducer = createReducer(StartupState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(StartupAction, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(CheckAvailabilityResponseAction, (state, { payload }) => {
    return {
      ...state,
      isUnavailable: payload.data,
      isUnavailableMessage: payload.message
    };
  }),
  on(StartupCompletedAction, (state, { payload }) => {
    return {
      ...state,
      url: payload,
      // isLoading: false
    };
  }),
);

const startupReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateStartupForm,
);

export function startupReducer(state: any | undefined, action: Action) {
  return startupReducerFormState(state, action);
}
