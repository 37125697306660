import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { commonState_SystemConfig_SiteError } from '../store/common/common.selectors';
import { map, skipWhile } from 'rxjs/operators';

@Injectable()
export class ErrorPageGuardService implements CanActivate {

    siteError$ = this.store.pipe(select(commonState_SystemConfig_SiteError));

    constructor(public store: Store<AppState>,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.siteError$.pipe(
            skipWhile(val => val === null || val === undefined),
            map(siteError => {
                if (siteError){
                    return true;
                }
                else {
                    this.router.navigate(['/'])
                    return false;
                }
            })
        );
    }
}
