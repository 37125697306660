import * as Actions from './common.actions';
import { ICommonState } from './common.state';
import { Action, createReducer, on } from '@ngrx/store';
import { MenuModel } from 'src/app/model/menu.model';
import { SystemConfigModel } from 'src/app/model/system-config.model';
import { LoggedInModel } from 'src/app/model/logged-in.model';
// import { LoggedInModel } from 'src/app/model/loggedIn.model';

export const commonState: ICommonState = {
  isLoading: false,
  screenWidth: window.innerWidth,
  menuList: [],
  systemConfig: {} as SystemConfigModel,
  loggedInModel: {} as LoggedInModel,
  siteError: false,
  loginViewVideoUrl: '',
  member: null
};

const reducerList = createReducer(commonState,
  on(Actions.ResponseMemberDataAction, (state, { payload }) => {
    return {
      ...state,
      member: payload
    };
  }),
  on(Actions.IsLoadingAction, (state, { payload }) => {
    if (state.isLoading == payload)
      return state;
    return {
      ...state,
      isLoading: payload,
    };
  }),

  on(Actions.ChangeScreenWidthAction, (state, { payload }) => {
    return {
      ...state,
      screenWidth: payload,
    };
  }),
  on(Actions.SetLoginVideoUrlAction, (state, { url }) => {
    return {
      ...state,
      loginViewVideoUrl: url,
    };
  }),
  on(Actions.SetMenuAction, (state, { data }) => {
    return {
      ...state,
      menuList: data,
    };
  }),
  on(Actions.SystemConfigurationResponseAction, (state, { payload }) => {
    return {
      ...state,
      systemConfig: {
        ...payload,
        ...state.systemConfig
      },
    };
  }),
  on(Actions.IsLoggedInResponseAction, (state, { data }) => {
    return {
      ...state,
      loggedInModel: 
        {
          ...data,
          isAdviserElevated:state.loggedInModel.isAdviserElevated,
          isFundElevated: state.loggedInModel.isFundElevated
        }
    };
  }),
  on(Actions.SetIsFundElevatedAccessAction, (state, {isElevated}) => {
    return {
      ...state,
      loggedInModel:{...state.loggedInModel, isFundElevated:isElevated}
    }
  }),
  on(Actions.SetIsAdviserElevatedAccessAction, (state, {isElevated}) => {
    return {
      ...state,
      loggedInModel:{...state.loggedInModel, isAdviserElevated:isElevated}
    }
  }),
  on(Actions.AccountFeatureTogglesResponseAction, (state, { payload }) => {
    var limitedServiceToggles = payload?.data?.filter(o => o.message && o.startDate && o.endDate) ?? [];

    return {
      ...state,
      systemConfig: {
        ...state.systemConfig,
        accountIdFeatureToggles: payload.accountId,
        accountFeatureToggles: payload.data,
        limitedServiceToggles: limitedServiceToggles
      }
    };
  }),
  on(Actions.SetSiteError, (state, { error }) => {
    return {
      ...state,
      siteError: error
    }
  })
);

export function commonReducer(state: any | undefined, action: Action) {
  return reducerList(state, action);
}
