<div class="d-flex flex-wrap">
    <h5 class="w-100 flex-fill mb-0">Assets</h5>
    <app-readonly title="Savings/Bank account balance" [value]="sfhDataV1.assetsBankBalance | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Own home (Total value)" [value]="sfhDataV1.assetsOwnHome | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Investment properties" [value]="sfhDataV1.assetsInvestmentProperties | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Shares/Investments" [value]="sfhDataV1.assetsSharesInvestments | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Vehicle(s)" [value]="sfhDataV1.assetsVehicles | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="House contents" [value]="sfhDataV1.assetsHomeContents | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Other assets" [value]="sfhDataV1.assetsOtherAssets | currency" class="w-100 p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="sfhDataV1.assetsOtherAssets > 0" title="Other assets details" [value]="sfhDataV1.assetsOtherAssetsDetails" class="w-100 p-1 flex-fill"></app-readonly>
    <app-readonly title="Total assets" [value]="calculateAssetsTotal(sfhDataV1) | currency" class="w-100 p-1 flex-fill mt-2"></app-readonly>

    <h5 class="w-100 flex-fill mb-0">Liabilities</h5>
    <app-readonly title="Home loan repayments" [value]="sfhDataV1.liabilitiesHomeLoanRepayments | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Investment loan repayments" [value]="sfhDataV1.liabilitiesInvestmentLoanRepayments | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Personal loan repayments" [value]="sfhDataV1.liabilitiesPersonalLoanRepayments | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Credit card debt" [value]="sfhDataV1.liabilitiesCreditCardRepayments | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Vehicle/Car loan repayments" [value]="sfhDataV1.liabilitiesVehicleRepayments | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Other debts" [value]="sfhDataV1.liabilitiesOtherDebts | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="sfhDataV1.liabilitiesOtherDebts > 0" title="Other debts details" [value]="sfhDataV1.liabilitiesOtherDebtsDetails" class="w-100 p-1 flex-fill"></app-readonly>
    <app-readonly title="Total liabilities" [value]="calculateLiabilitiesTotal(sfhDataV1) | currency" class="w-100 p-1 flex-fill mt-2"></app-readonly>
    
    <h5 class="w-100 flex-fill mb-0">Weekly Income</h5>
    <app-readonly title="Your income" [value]="sfhDataV1.ownWeeklyIncome | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Your partner's income" [value]="sfhDataV1.partnersWeeklyIncome | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Your dependants income" [value]="sfhDataV1.dependantsWeeklyIncome | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Income support payments" [value]="sfhDataV1.weeklyIncomeSupportPayments | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Rental income" [value]="sfhDataV1.rentalWeeklyIncome | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Interest from investments" [value]="sfhDataV1.investmentInterestWeeklyIncome | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Other income" [value]="sfhDataV1.otherWeeklyIncome | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="sfhDataV1.otherWeeklyIncome > 0" title="Other income details" [value]="sfhDataV1.otherWeeklyIncomeDetails" class="w-100 p-1 flex-fill"></app-readonly>
    <app-readonly title="Total income" [value]="calculateIncomeTotal(sfhDataV1) | currency" class="w-100 p-1 flex-fill mt-2"></app-readonly>
    
    <h5 class="w-100 flex-fill mb-0">Weekly Expenses</h5>
    <app-readonly title="Rent/Board/Mortgage repayments" [value]="sfhDataV1.weeklyRentMortgageExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Minimum credit card and loan repayments" [value]="sfhDataV1.weeklyCreditCardExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Council Rates" [value]="sfhDataV1.weeklyCouncilRateExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Groceries" [value]="sfhDataV1.weeklyGroceriesExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Medical/Pharmaceutical" [value]="sfhDataV1.weeklyMedicalExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Vehicle/Transport costs" [value]="sfhDataV1.weeklyVehicleExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Utilities - Electricity" [value]="sfhDataV1.weeklyElectricityExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Ulitities - Gas" [value]="sfhDataV1.weeklyGasExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Utilities - Water" [value]="sfhDataV1.weeklyWaterExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Utilities - Phone" [value]="sfhDataV1.weeklyPhoneExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Utilities - Internet" [value]="sfhDataV1.weeklyInternetExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Insurance (House/Health/Car)" [value]="sfhDataV1.weeklyInsuranceExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Education/ Childcare" [value]="sfhDataV1.weeklyEducationExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly title="Other expenses" [value]="sfhDataV1.weeklyOtherExpenses | currency" class="w-50 p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="sfhDataV1.weeklyOtherExpenses > 0" title="Other expenses details" [value]="sfhDataV1.weeklyOtherExpensesDetails" class="w-100 p-1 flex-fill"></app-readonly>
    <app-readonly title="Total expenses" [value]="calculateExpensesTotal(sfhDataV1) | currency" class="w-100 p-1 flex-fill mt-2"></app-readonly>
    
    <app-readonly [title]="formLabels.financeDetailsRenegotiateQuestion" [value]="sfhDataV1.attemptedRenegotiateLoans ? 'Yes' : 'No'" class="w-100 p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="sfhDataV1.attemptedRenegotiateLoans" title="Provided details" [value]="sfhDataV1.attemptedRenegotiateLoansDetails" class="w-100 p-1 flex-fill"></app-readonly>

</div>