<app-component-wrapper class="w-50 flex-fill" compclass="flex-fill h-100" icon="list_alt" header="Account Summary">

  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="account-summary-view-container">
      <div>
        <div class="d-flex flex-wrap" *ngIf="accountSummary$ | async; let accountSummary">
          <app-readonly class="w-50" icon="attach_money" title="Preserved" [value]="accountSummary.preserved | currency" tooltip="Available when you retire after preservation age."></app-readonly>

          <!-- <div class="col-6 title d-flex flex-row align-content-center">
            <div class="align-self-center">Preserved:</div>
            <mat-icon class="ml-2 tooltip-readonly align-self-center" matTooltip="Available when you retire after preservation age.">help_outline</mat-icon>
          </div>
          <div class="col-6">
            <div>{{accountSummary.preserved | currency}}</div>
          </div> -->
          <app-readonly class="w-50" icon="attach_money" title="Restricted non preserved" [value]="accountSummary.restrictedNonPreserved | currency" tooltip="Available when paid employment is ceased."></app-readonly>

          <!-- <div class="col-6 title d-flex flex-row align-content-center">
            <div class="align-self-center">Restricted non preserved: </div>
            <mat-icon class="ml-2 tooltip-readonly align-self-center" matTooltip="Available when paid employment is ceased.">help_outline</mat-icon>
          </div>
          <div class="col-6">
            <div>{{accountSummary.restrictedNonPreserved | currency}}</div>
          </div> -->

          <app-readonly class="w-50" icon="attach_money" title="Unrestricted non preserved" [value]="accountSummary.unrestrictedNonPreserved | currency" tooltip="Available as cash now."></app-readonly>

          <!-- <div class="col-6 title d-flex flex-row align-content-center">
            <div class="align-self-center">Unrestricted non preserved:</div>
            <mat-icon class="ml-2 tooltip-readonly align-self-center" matTooltip="Available as cash now.">help_outline</mat-icon>
          </div>
          <div class="col-6">
            <div>{{accountSummary.unrestrictedNonPreserved | currency}}</div>
          </div> -->
          <app-readonly class="w-50" icon="attach_money" title="Tax free component" [value]="accountSummary.taxFreeComponent | currency"></app-readonly>

          <!-- <div class="col-6 title">
            <div>Tax free component:</div>
          </div>
          <div class="col-6">
            <div>{{accountSummary.taxFreeComponent | currency}}</div>
          </div> -->

          <app-readonly class="w-50" icon="attach_money" title="Taxed component" [value]="accountSummary.taxableComponentTaxed | currency"></app-readonly>

          <!-- <div class="col-6 title">
            <div>Taxed component:</div>
          </div>
          <div class="col-6">
            <div>{{accountSummary.taxableComponentTaxed | currency}}</div>
          </div> -->
          <app-readonly class="w-50" icon="event" title="As at" [value]="accountSummary.asAt | date:'dd MMM yyyy'"></app-readonly>

          <!-- <div class="col-6 title">
            <div>As at:</div>
          </div>
          <div class="col-6">
            <div>{{accountSummary.asAt | date:'dd MMM yyyy' }}</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</app-component-wrapper>
