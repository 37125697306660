<div id="cash-transactions-container">

  <mat-drawer-container class="drawer-container" hasBackdrop="true" style="height:630px">
    <mat-drawer #drawer [mode]="filterMode" [opened]="isFilterOpen" [openedChange]="openedChange()"
      style="max-width: 100%;">
      <b class="clearfix">Filter</b>
      <div class="p-2 float-right click" style="margin-top: -30px;" (click)="toggleFilter()">
        <mat-icon>close</mat-icon>
      </div>
      <div class="p-3">
        <h5 class="mt-1">Transaction type</h5>

        <ul class="list-group pl-0  mb-3">
          <li class="list-group-item click" [ngClass]="{'active': (filter$|async).value.transactionType == item.value}"
            *ngFor="let item of (transactionTypes$ | async)" (click)="selectTransactionType(item)">
            {{item.value}}
          </li>
        </ul>
        <h5 class="mt-3">Date</h5>

        <mat-accordion *ngIf="(filter$ | async)?.controls; let filter">
          <mat-expansion-panel hideToggle class="pl-0 ">
            <mat-expansion-panel-header (click)="customFinancialYear(filter)">
              <mat-panel-title class="d-flex align-items-center">
                <mat-icon class="mr-2">date_range</mat-icon>
                Show all
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>navigate_next</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <label class="mt-3">Show all dates</label>

          </mat-expansion-panel>

          <mat-expansion-panel hideToggle class="pl-0 ">
            <mat-expansion-panel-header (click)="currentFinancialYear(filter)">
              <mat-panel-title class="d-flex align-items-center">
                <mat-icon class="mr-2">date_range</mat-icon>
                Current financial year
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>navigate_next</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-4">From:</div>
              <div class="col-8">{{(filter$ | async)?.controls.from.value | date:"dd/MM/yyyy"}}</div>
            </div>
            <div class="row">
              <div class="col-4">To:</div>
              <div class="col-8">{{(filter$ | async)?.controls.to.value | date:"dd/MM/yyyy"}}</div>
            </div>


          </mat-expansion-panel>
          <mat-expansion-panel hideToggle class="pl-0  ">
            <mat-expansion-panel-header (click)="previousFinancialYear(filter)">
              <mat-panel-title class="d-flex align-items-center">
                <mat-icon class="mr-2">date_range</mat-icon>
                Previous financial year
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>navigate_next</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-4">From:</div>
              <div class="col-8">{{(filter$ | async)?.controls.from.value | date:"dd/MM/yyyy"}}</div>
            </div>
            <div class="row">
              <div class="col-4">To:</div>
              <div class="col-8">{{(filter$ | async)?.controls.to.value | date:"dd/MM/yyyy"}}</div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel hideToggle class="pl-0 ">
            <mat-expansion-panel-header (click)="customFinancialYear(filter)">
              <mat-panel-title class="d-flex align-items-center">
                <mat-icon class="mr-2">date_range</mat-icon>
                Custom date
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>navigate_next</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-check pl-0 mr-3">
              <app-entry-date title="From" [date]="(filter$ | async)?.controls.from"></app-entry-date>
            </div>
            <div class="form-check pl-0 mr-3">
              <app-entry-date title="To" [date]="(filter$ | async)?.controls.to"></app-entry-date>
            </div>

          </mat-expansion-panel>



        </mat-accordion>

        <app-button class="w-100  mt-4 " title="Done" icon="double_arrow" (onClick)="toggleFilter()">
        </app-button>

      </div>
    </mat-drawer>
    <mat-drawer-content #drawercontent>
      <nav class="navbar navbar-filter navbar-expand-lg navbar-light bg-light sticky-top pl-1">
        <div class="navbar-brand m-1" *ngIf="screenWidth>420">
          <div class="toggle">
            <a class="btn btn-link d-flex lign-items-center no-text-decoration">
              <mat-icon>filter_alt</mat-icon>
            </a>
          </div>
        </div>
        <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center" mat-button
          [matMenuTriggerFor]="menu">
          Type <span class="ml-2 badge badge-light ">{{selectedType$ | async}}</span>
          <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="mat-menu cash-transactions">
          <button mat-menu-item *ngFor="let item of (transactionTypes$ | async)"
            (click)="selectTransactionType(item.value)">
            <mat-icon>arrow_right</mat-icon>{{item.value}}
          </button>
        </mat-menu>

        <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center" mat-button
          [matMenuTriggerFor]="menuDesc">
          Description <span class="badge badge-light ml-2">{{selectedDescription$ | async}}</span>
          <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menuDesc="matMenu" class="mat-menu cash-transactions">
          <button mat-menu-item *ngFor="let item of (transactionDescription$ | async)"
            (click)="selectTransactionDescription(item.key)">
            <mat-icon>arrow_right</mat-icon>{{item.value}}
          </button>
        </mat-menu>

        <button class="date badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center" mat-button
          [matMenuTriggerFor]="menuFrom">
          <div class="d-flex flex-row">
            <div class="ml-1">From:<span class="badge badge-light ml-2 mr-2">{{(filter$ | async)?.value.from |
                date:"dd/MM/yyyy" |
                dash}}</span></div>
            <div class="ml-1">To:<span class="badge badge-light ml-2">{{(filter$ | async)?.value.to |
                date:"dd/MM/yyyy" | dash}}</span>
            </div>
          </div>
          <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #menuFrom="matMenu" class="mat-menu cash-transactions">

          <button *ngIf="(filter$ | async)?.controls; let filter" mat-menu-item class="d-flex align-items-center"
            (click)="customFinancialYear(filter)">
            <mat-icon>close</mat-icon>
            Clear dates
          </button>
          <button class="d-flex align-items-center" *ngIf="(filter$ | async)?.controls; let filter" mat-menu-item
            (click)="currentFinancialYear(filter)">
            <mat-icon>date_range</mat-icon>Current financial year
          </button>
          <button class="d-flex align-items-center" *ngIf="(filter$ | async)?.controls; let filter" mat-menu-item
            (click)="previousFinancialYear(filter)">
            <mat-icon>date_range</mat-icon>Previous financial year
          </button>
          <div class="custom-date-select" *ngIf="(filter$ | async)?.controls; let filter" (click)="$event.stopPropagation();">
            <div class="m-3">
              <div class="d-flex align-items-center mb-3 flex-row">
                <mat-icon class="mr-3">edit</mat-icon>Custom dates:
              </div>
              <div class="form-check pl-0 mr-3">
                <app-entry-date title="From" [date]="(filter$ | async)?.controls.from"></app-entry-date>
              </div>
              <div class="form-check pl-0 mr-3">
                <app-entry-date title="To" [date]="(filter$ | async)?.controls.to"></app-entry-date>
              </div>
            </div>
          </div>

        </mat-menu>
        <!-- <div class="badge badge-primary p-1 mr-1">
          Type <span class="badge badge-light ml-1">{{selectedType$ | async}}</span>
        </div> -->
        <!-- <div class="badge badge-primary p-1 mr-1" *ngIf="(filter$ | async)?.value.from">
          From <span class="badge badge-light ml-1">{{(filter$ | async)?.value.from | date:"dd/MM/yyyy"}}</span>
        </div>
        <div class="badge badge-primary p-1" *ngIf="(filter$ | async)?.value.to">
          To <span class="badge badge-light ml-1">{{(filter$ | async)?.value.to | date:"dd/MM/yyyy"}}</span>
        </div> -->

      </nav>

      <!-- <div class="d-flex flex-row align-items-center" (click)="toggleFilter()">
        <label class="mr-2 p-2"> Filter:</label>

      </div> -->
      <div class="flex-fill m-2">
        <app-no-items message="No transaction found" *ngIf="(list$ |async)?.length === 0"></app-no-items>

        <div class="table-responsive animate__animated" *ngIf="(list$ |async)?.length > 0"
          [ngClass]="{'animate__fadeIn': loading}">
          <table class="table {{'VIEWS.Global.table-default-style' | translate}} table-sm ">
            <thead class="">
              <tr>
                <th>Transaction date</th>
                <th>Transaction type</th>
                <th>Description</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of list$ | async">
                <td>{{item.transactionDate | date:'dd MMM yyyy'}}</td>
                <td>{{item.accountDetailType}}</td>
                <td>{{item.description}}</td>
                <td class="text-right">{{item.amount | currency}}</td>
              </tr>
              <tr class="list-total">
                <th></th>
                <td></td>
                <td></td>
                <td class="text-right">{{listTotal$ | async | currency}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
</div>
