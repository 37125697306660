import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MemberPortalSharedState } from "../../store/shared.states";
import { featureKey } from '../../store/shared.reducers';
import { IAccessYourSuperUpdateSubmittedComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperUpdateSubmittedState = createSelector(memberPortalState, (state) => state.accessYourSuperUpdateSubmittedComponentState);

export const accessYourSuperUpdateSubmitted_CustomData = createSelector(
    getAccessYourSuperUpdateSubmittedState,
    (state: IAccessYourSuperUpdateSubmittedComponentState) => state.customData
)

export const accessYourSuperUpdateSubmitted_ApplicationData = createSelector(
    getAccessYourSuperUpdateSubmittedState,
    (state: IAccessYourSuperUpdateSubmittedComponentState) => state.applicationData
)

export const accessYourSuperUpdateSubmitted_ApplicationId = createSelector(
    getAccessYourSuperUpdateSubmittedState,
    (state: IAccessYourSuperUpdateSubmittedComponentState) => state.applicationId
)