
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, required } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction, SetFullAmountRadioOptionsAction, SetReleaseConditionOptionsAction } from './actions';
import { AccessYourSuperTerminalMedicationConditionForm, IAccessYourSuperTerminalMedicalConditionComponentState } from './state';

export const state: IAccessYourSuperTerminalMedicalConditionComponentState = {
  model: null,
  form: createFormGroupState('AccessYourSuperTerminalMedicationConditionForm', new AccessYourSuperTerminalMedicationConditionForm()),
  releaseConditionOptions: null,
  fullAmountRadioOptions: null,
  savedFormData: null,
};

const maxDateValidator = (input: string | null | undefined) => {
  const errors = {};

  // Create a date set at midnight instead of 10am.
  var finishDate = new Date(new Date(input).setHours(0, 0, 0, 0));

  if (finishDate > new Date()) {
    errors['futureDateError'] = "Date cannot be in the future";
  }
  return errors;
}

export const validateTerminalMedicalConditionForm = updateGroup<AccessYourSuperTerminalMedicationConditionForm>({
  paymentType: validate(required),
  partialAmountSpecified: (state, parentState) => {
    if (parentState.value.paymentType === 1) {
      var totalBalance = parentState.value.investmentOptionDrawdown.reduce((a, b) => a + b.amount, 0);
      totalBalance = Math.round(totalBalance * 1000) / 1000;
      state = validate(state, required, greaterThan(0), lessThanOrEqualTo(totalBalance))
    }
    return state;
  },
  releaseCondition: validate(required),
  illHealthDate: (state, parentState) => {
    state = validate(state, required, maxDateValidator)
    return state;
  },
  medicalConditionSummary: (state, parentState) => {
    if (parentState.value.showMedicalConditionSummary){
      state = validate(state, required)
    }
    return state;
  }
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      model: null,
      form: createFormGroupState('AccessYourSuperTerminalMedicationConditionForm', new AccessYourSuperTerminalMedicationConditionForm()),
      savedFormData: null
    }
  }),
  on(SetReleaseConditionOptionsAction, (state, { payload }) => {
    return {
      ...state,
      releaseConditionOptions: payload
    }
  }),
  on(SetFullAmountRadioOptionsAction, (state, { payload }) => {
    return {
      ...state,
      fullAmountRadioOptions: payload
    }
  }),
  on(SavedFormDataAction, (state, { }) => {
    return {
      ...state,
      savedFormData: new Date()
    }
  })
);

const editTerminalMedicalCOnditionReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateTerminalMedicalConditionForm
)

export function accessYourSuperTerminalMedicalConditionComponentReducer(state: any | undefined, action: Action) {
  return editTerminalMedicalCOnditionReducerFormState(state, action);
}
