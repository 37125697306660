import { KeyValueModel } from '@ifaa-components/ui-components';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccessYourSuperApplicationDataModel } from 'src/app/model/access-your-super-form.models';

export const SetFirstDropdownValuesAction = createAction('[Access your super type selection component] set first dropdown', props<{payload:KeyValueModel[]}>())
export const SetSecondDropdownValuesAction = createAction('[Access your super type selection component] set second dropdown', props<{payload:KeyValueModel[]}>())

export const ResetSecondDropdownValueAction = createAction('[Access your super type selection component] reset second dropdown selection');
export const ResetComponentStateAction = createAction('[Access your super type selection component] reset state');

export const SelectApplicationTypeAction = createAction('[Access your super type selection component] select application type', props<{accountId: number, applicationType: number}>());
export const SaveApplicationIdAction = createAction('[Access your super type selection component] save application id', props<{applicationId:number}>());

export const GetApplicationDataAction = createAction('[Access your super type selection component] get application specific data', props<{accountId:number, applicationType:number}>());
export const SaveApplicationDataAction = createAction('[Access your super type selection component] save application specific data', props<{model:AccessYourSuperApplicationDataModel}>());
