import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperPaymentDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { InvestmentItemModel } from "src/app/model/investment-item.model";

export interface IAccessYourSuperUnrestrictedNonPreservedComponentState {
    model:AccessYourSuperPaymentDetailSectionModel;
    form:FormGroupState<AccessYourSuperUnrestrictedNonPreservedForm>;
    fullAmountRadioOptions:KeyValueModel[];
    savedFormData:Date
}

export class AccessYourSuperUnrestrictedNonPreservedForm {
    paymentType:number=0;
    fullAmountSelection:number=0;
    partialAmountSpecified:number=0;
    unrestrictedNonPreserved:number=0;
    partialWithdrawalSelection:boolean=false;
    investmentOptionDrawdown:InvestmentItemModel[] = [];
}