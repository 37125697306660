import { takeUntil } from 'rxjs/operators';
import { AppInjector } from './../app.module';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { Action, select, Store } from '@ngrx/store';
import { commomState_ScreenWidth } from '../store/common/common.selectors';
import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { FeatureToggleCheckService } from '../helper/featureToggleHelper';

@Injectable()
export class CommomService {

  constructor(public store: Store<AppState>) {

  }

}
export class ComponentBase {
  public screenWidth: number;
  public destroy$ = new Subject();
  commomService = AppInjector.get(CommomService);
  toggleCheck = AppInjector.get(FeatureToggleCheckService);

  public set sub(value: Subscription) {
    this.addSubscription(value);
  }
  private subscriptions: Subscription[] = [];

  constructor() {
    window.scrollTo(0, 0);

    this.commomService.store.pipe(
      takeUntil(this.destroy$),
      select(commomState_ScreenWidth)).subscribe(x => {
        this.screenWidth = x;
      });
  }

  dispatch(store: Store<any>, action: Action) {
    setTimeout(() => store.dispatch(action), 0);
  }

  checkAccountFeatureToggle(value: string, callback: any) {
    this.toggleCheck.checkToggle(this.destroy$, value, callback);
  }

  screenWidthChange(store: Store<AppState>, callback) {
    this.sub = store.pipe(select(commomState_ScreenWidth)).subscribe(x => {
      this.screenWidth = x;
      callback(x);
    });

  }
  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  ngOnInitBase() {
  }

  getRouteParam(route: ActivatedRoute, name: string, callback, callbackNotFound = null) {
    const sub = route
      .params
      .subscribe(params => {
        const val = params[name];
        if (val) {
          callback(val);
        }
        else {
          if (callbackNotFound)
            callbackNotFound()
        }
      });
    this.addSubscription(sub);
  }

  ngOnDestroyBase() {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.subscriptions.forEach(x => {
      if (x) {
        x.unsubscribe();
      }
    });
  }

  getUrlParameterByName(name: string, url?: any) {
    if (!url) { url = window.location.href; }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
