import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IAccessYourSuperKiwiSaverComponentState } from "./state";
import { MemberPortalSharedState } from "src/app/modules/shared/store/shared.states";
import { featureKey } from "../../../store/shared.reducers";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperKiwiSaverState = createSelector(memberPortalState, (state) => state.accessYourSuperKiwiSaverComponentState);

export const accessYourSuperKiwiSaver_Model = createSelector(
    getAccessYourSuperKiwiSaverState,
    (state: IAccessYourSuperKiwiSaverComponentState) => state.model
);

export const accessYourSuperKiwiSaver_FormOne = createSelector(
    getAccessYourSuperKiwiSaverState,
    (state: IAccessYourSuperKiwiSaverComponentState) => state.formOne
);
export const accessYourSuperKiwiSaver_FormTwo = createSelector(
    getAccessYourSuperKiwiSaverState,
    (state: IAccessYourSuperKiwiSaverComponentState) => state.formTwo
);
export const accessYourSuperKiwiSaver_FormThree = createSelector(
    getAccessYourSuperKiwiSaverState,
    (state: IAccessYourSuperKiwiSaverComponentState) => state.formThree
);

export const accessYourSuperKiwiSaver_SavedFormData = createSelector(
    getAccessYourSuperKiwiSaverState,
    (state: IAccessYourSuperKiwiSaverComponentState) => state.savedFormData
);

export const accessYourSuperKiwiSaver_KiwiSaverStep = createSelector(
    getAccessYourSuperKiwiSaverState,
    (state: IAccessYourSuperKiwiSaverComponentState) => state.kiwiSaverStep
);