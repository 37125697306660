
<div id="employment-history-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Employment History </div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper class="employment-history-component d-flex flex-fill" icon="" header="">
      <div class="d-flex flex-column" slot="start">
        <p>This is a listing of all of your employments where contributions have been received into CIRT.
          All amounts claimed are displayed are Gross amounts and do not include any tax that may have been deducted.
          A full listing of contributions made to CIRT on your behalf can be found on the <a routerLink="/transaction-listing">transaction listing page</a></p>
        <app-no-items message="No history found" *ngIf="(list$ |async)?.length === 0"></app-no-items>
        <div class="table-wrapper">
          <table class="table table-striped" *ngIf="(list$ |async)?.length > 0">
            <thead>
              <tr>
                <th scope="col">Employer Name</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">CIRT Severance Contribution Total</th>
                <th scope="col">Amount Claimed</th>
                <th scope="col">Available Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of list$ | async">
                <th>{{item.employerName}}</th>
                <th>{{item.startDate | date:'dd/MM/yyyy'}}</th>
                <th>{{item.endDate | date:'dd/MM/yyyy'}}</th>
                <th>{{item.employerSeverance | currency | dash}}</th>
                <th>{{item.severanceClaimed | currency | dash}}</th>
                <th>{{item.availableBalance | currency | dash}}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-component-wrapper>





  </div>
</div>









