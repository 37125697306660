import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { commomState_ElevatedAccess, commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { LogoutRequestAction } from 'src/app/store/common/common.actions';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';


@Component({
  selector: 'app-user-top-dropdown',
  templateUrl: './user-top-dropdown.component.html',
  styleUrls: ['./user-top-dropdown.component.scss']
})
export class UserTopDropdownComponent extends ComponentBase implements OnInit, OnDestroy {

  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));
  changePasswordFeatureToggle = FeatureToggleName.member.changePassword.view;

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private msalInstance: MsalService) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    // this.store.dispatch(RequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  sigout() {
    this.msalInstance.logout();
    // Redirect(
    //   {      postLogoutRedirectUri: environment.b2cLogoutUrl  }      );
    this.store.dispatch(LogoutRequestAction());
  }
}

