<div>
    <h2 class="mb-3">Select which benefit withdrawal request you want to complete</h2>

    <form [ngrxFormState]="form" *ngIf="form$|async; let form">
        <div class="d-flex flex-row flex-wrap">
            <div class="col-12 col-md-6">
                <app-entry-dropdown class="w-100" title="Application category" [customList]="firstDropdownValues$"
                    [control]="form.controls.firstDropdown">
                </app-entry-dropdown>
            </div>
            <div class="col-12 col-md-6" *ngIf="form.value.firstDropdown !== null">
                <app-entry-dropdown class="w-100" title="Condition type" [customList]="secondDropdownValues$"
                    [control]="form.controls.secondDropdown">
                </app-entry-dropdown>
            </div>
        </div>
        <div>
            <div class="description" [innerHtml]="selectedType?.conditionDescription"></div>
        </div>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex mt-4">
            <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1 flex-fill': screenWidth<768}"
                title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                color="{{'BTN.access-your-super.cancel.color' | translate}}"
                (onClick)="exitForm()"></app-button>
            <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1 flex-fill': screenWidth<768}" title="Next"
                [isDisabled]="form.isInvalid" [iscanceledit]="form"
                icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                color="{{'BTN.access-your-super.continue.color' | translate}}" [dialogText]="dialogText"
                (onClick)="goNextStep($event)"></app-button>
        </div>
    </form>
</div>
