// import { commomState_LoggedinInfo } from './../store/common/common.selectors';
import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { commonState_SystemConfig_AccountFeatureToggles } from '../store/common/common.selectors';
import { memberAccountDropdown_SelectedAccount } from '../modules/shared/components/member-account-dropdown/selectors';
import { debounceTime, distinctUntilChanged, map, skipWhile } from 'rxjs/operators';
import { FeatureToggleValue } from '../model/feature-toggle-value.model';
import { FeatureToggleLevel } from '../model/feature-toggle-level.model';

@Injectable()
export class AccountFeatureToggleGuardService implements CanActivate {

  featuretoggle$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  constructor(public store: Store<AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let featureFlag = route.data.flag as [any];
    return combineLatest([this.featuretoggle$, this.selectedAccount$])
      .pipe(
        debounceTime(200),
        distinctUntilChanged((x, y) => {
          if (JSON.stringify(x[1]) !== JSON.stringify(y[1]) ||
            JSON.stringify(x[0]) !== JSON.stringify(y[0]))
            return false;

          return true;
        }),
        skipWhile(x => !x[0] || !x[1]),
        map(x => {
          var globalFlag = x[0].find(flag => flag.level === FeatureToggleLevel.global &&
            featureFlag.indexOf(flag.name) > -1 &&
            flag.value === FeatureToggleValue.available);

          if (!globalFlag) {
            this.router.navigate(["/"])
            return false;
          }

          var accountFlag = x[0].find(flag => flag.level === FeatureToggleLevel.accountClassification &&
            featureFlag.indexOf(flag.name) > -1 &&
            flag.value === FeatureToggleValue.available &&
            Number(flag.levelReference) === x[1].accountClassificationId);

          if (!accountFlag) {
            this.router.navigate(["/"])
            return false;
          }
          else {
            // Check if its been disabled/hidden for that account type
            var accountTypeFlag = x[0].find(flag => flag.level === FeatureToggleLevel.accountType &&
              featureFlag.indexOf(flag.name) > -1 &&
              flag.value !== FeatureToggleValue.available &&
              Number(flag.levelReference) === x[1].accountTypeId);
            
              if (!accountTypeFlag){
                return true;
              }
              
            this.router.navigate(["/"])
            return false;
          }
        })
      );
  }
}
