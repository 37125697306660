import { Action, createReducer, on } from "@ngrx/store";
import { onNgrxForms, onNgrxFormsAction, SetValueAction } from "ngrx-forms";
import { IAccessYourSuperUpdateSubmittedComponentState } from "./state";
import { ResetComponentStateAction, SaveCustomDataAction, SaveApplicationDataForUpdateSubmittedAction } from "./actions";

export const state: IAccessYourSuperUpdateSubmittedComponentState = {
    customData:null,
    applicationData:null,
    applicationId:null
}

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(ResetComponentStateAction, (state) => {
        return {
            customData:null,
            applicationData:null,
            applicationId:null
        }
    }),
    on(SaveCustomDataAction, (state, {payload}) => {
        return {
            ...state,
            customData:payload
        }
    }),
    on(SaveApplicationDataForUpdateSubmittedAction, (state, {model, applicationId}) => {
        return {
            ...state,
            applicationData:model,
            applicationId:applicationId
        }
    })
);

export function AccessYourSuperUpdateSubmittedComponentReducer(state: any | undefined, action: Action) {
    return reducer(state, action);
}
