//import { IInterimStatementState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';
import { ResponseAction } from './actions';
import { ITakeMeWithYouState } from './state';

export const takeMeWithYouState: ITakeMeWithYouState = {

};

const reducer = createReducer(takeMeWithYouState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),

);

export function takeMeWithYouReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
