<div id="main-layout-container" class="d-flex flex-column min-h-100 smrt-theme" screen-width>

  <nav class="top navbar navbar-expand-lg " [ngClass]="{'animate__animated animate__fadeInDown': !(animated$ | async)}">
    <mat-progress-bar class="animate__animated animate__bounceIn" mode="indeterminate" *ngIf="isLoading$ | async">
    </mat-progress-bar>
    <div class="container">
      <div class="navbar-brand logo flex-fill">
        <img src="https://sharedassetstorage.blob.core.windows.net/web/SMRT/smartMonday_logo_white.png" class="logo"
          style="height: 50px;width: auto;">
      </div>


      <div class="{{screenWidth>=992?'form-inline float-right':'d-flex flex-row w-100 pl-3'}} ">
        <app-member-account-dropdown class="flex-fill"></app-member-account-dropdown>
        <app-user-top-dropdown></app-user-top-dropdown>
      </div>
    </div>

  </nav>
  <nav class="menu navbar navbar-expand-lg " [ngClass]="{'animate__animated animate__fadeInDown': !(animated$ | async)}">
    <div class="container" *ngIf="screenWidth>=992">
      <ng-container *ngTemplateOutlet="templateMenu">
      </ng-container>
    </div>
  </nav>

  <div class="main-content flex-fill d-flex flex-column w-100">
    <div class="navbar-global-wrapper">
      <nav class="navbar navbar-inverse navbar-global navbar-fixed-top">
        <div class="container">
          <div class="navbar-header d-flex flex-row w-100">
          </div>
        </div>
      </nav>
    </div>

    <main class="container d-flex flex-fill" (swiperight)="onSwipeToggle($event)">
      <app-scroll-up></app-scroll-up>
      <router-outlet name="master"></router-outlet>
    </main>

    <app-footer></app-footer>

    <nav class="navbar-secondary toolbar-bottom d-flex align-items-center flex-row" *ngIf="screenWidth<992"
      appanimatecss="animate__fadeInUp animate__delay-1s">
      <div class="d-flex flex-fill option flex-column align-items-center" (click)="openMenu()" matRipple >
        <mat-icon>menu_open</mat-icon>
        <label>Menu</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/">
        <mat-icon>house</mat-icon>
        <label>Overview</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/personal-details">
        <mat-icon>person</mat-icon>
        <label>Profile</label>
      </div>
    </nav>
  </div>

  <ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>

    <mat-accordion *ngIf="menuList$ | async; let menuList" class="mobile-menu">
      <mat-expansion-panel [hideToggle]="item.children.length == 0?true: false" *ngFor="let item of menuList"
        [disabled]="item.children.length == 0?true: false" [ngClass]="{'d-none': (item.featureFlag | featureToggle | async) === featureToggleValues?.hidden,
        'disabled': (item.featureFlag | featureToggle | async) === featureToggleValues?.disabled}">
        <mat-expansion-panel-header (click)="onItemSelectedClick(item)">
          <mat-panel-title class="d-flex align-items-center" >
            <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon><span class="nav-label"
              style="white-space: pre;">{{item.name}}</span>
          </mat-panel-title>

        </mat-expansion-panel-header>
        <ul class="pl-2 matmenu-list list-unstyled" appanimatecss="animate__fadeInDown">
          <li *ngFor="let child of item.children">
            <ng-container *ngIf="child.featureFlag | featureToggle | async; let childFlagValue;">
              <a *ngIf="child.link?.startsWith('/') && childFlagValue !== featureToggleValues?.hidden"
                [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center"
                [routerLink]="child.link" [fragment]="child.fragment">
                <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                <div class="nav-label">{{child.name}}</div>
              </a>
              <a *ngIf="child.link?.startsWith('http') && childFlagValue !== featureToggleValues?.hidden"
                [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center" [href]="child.link"
                [attr.target]="child.target ? child.target : null">
                <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                <div class="nav-label">{{child.name}}</div>
              </a>
            </ng-container>
          </li>
        </ul>
      </mat-expansion-panel>

    </mat-accordion>
  </ng-template>

  <ng-template #templateMenu>
    <ng-container *ngIf="menuList$ | async; let menuList">
      <div *ngFor="let item of menuList" class="menu-items" [ngClass]="{'d-none': (item.featureFlag | featureToggle | async) === featureToggleValues?.hidden,
        'disabled': (item.featureFlag | featureToggle | async) === featureToggleValues?.disabled}">

        <a class="btn d-flex align-items-center" [class.active]="isMenuExpanded(item)"
          *ngIf="(item.featureFlag | featureToggle | async) !== featureToggleValues?.hidden"
          [ngClass]="{'disabled': (item.featureFlag | featureToggle | async) === featureToggleValues?.disabled}"
          [routerLink]="item.link" routerLinkActive="active-link" [attr.target]="item.target ? item.target : null"
          (click)="onItemSelectedClick(item)" [matMenuTriggerFor]="item.children.length > 0?menu:placeholder"
          aria-expanded="false">
          <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon><span class="nav-label"
            style="white-space: pre;">{{item.name}}</span>
          <mat-icon *ngIf="item.children.length > 0" class="item-arrow">expand_more</mat-icon>
        </a>
        <mat-menu #placeholder="matMenu" class="placeholder"></mat-menu>
        <mat-menu #menu="matMenu">
          <ul class="pl-4 matmenu-list list-unstyled" appanimatecss="animate__fadeInDown">
            <li *ngFor="let child of item.children" style="background-color: #fafafa;">
              <ng-container *ngIf="child.featureFlag | featureToggle | async; let childFlagValue;">
                <a *ngIf="child.link?.startsWith('/') && childFlagValue !== featureToggleValues?.hidden"
                  [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                  [routerLinkActive]="['is-active']"
                  class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center"
                  [routerLink]="child.link" [fragment]="child.fragment">
                  <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                  <div class="nav-label">{{child.name}}</div>
                </a>
                <a *ngIf="child.link?.startsWith('http') && childFlagValue !== featureToggleValues?.hidden"
                  [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                  class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center" [href]="child.link"
                  [attr.target]="child.target ? child.target : null">
                  <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                  <div class="nav-label">{{child.name}}</div>
                </a>
              </ng-container>
            </li>
          </ul>
        </mat-menu>
      </div>
    </ng-container>
  </ng-template>

</div>
