import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperCompassionateGroundsComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperCompassionateGroundsState = createSelector(memberPortalState, (state) => state.accessYourSuperCompassionateGroundsComponentState);

export const accessYourSuperCompassionateGrounds_Model = createSelector(
    getAccessYourSuperCompassionateGroundsState,
    (state: IAccessYourSuperCompassionateGroundsComponentState) => state.model
);

export const accessYourSuperCompassionateGrounds_Form = createSelector(
    getAccessYourSuperCompassionateGroundsState,
    (state: IAccessYourSuperCompassionateGroundsComponentState) => state.form
)

export const accessYourSuperCompassionateGrounds_SavedFormData = createSelector(
    getAccessYourSuperCompassionateGroundsState,
    (state: IAccessYourSuperCompassionateGroundsComponentState) => state.savedFormData
)