import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-tenant-jetco-approved-training',
  templateUrl: './approved-training.component.html',
  styleUrls: ['./approved-training.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class TenantJetcoApprovedTrainingComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<UIComponentState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
