import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';


export const RequestAction = createAction('[take me with you] get',
  props<{ accountId: number }>());

export const ResponseAction = createAction('[take me with you] set choice of fund',
  props<{ payload: any }>());

