<div id="unit-prices-container">
  <div class="page-header">
    <app-toolbar-options icon="local_offer">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title"> {{'Unit Prices' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>


    <app-component-wrapper icon="add_task" header="View">
      <div class="d-flex flex-column" slot="start">
        <app-unit-prices-view></app-unit-prices-view>
      </div>
    </app-component-wrapper>


  </div>
</div>
