
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { CurrentStepUpdatedAction, GetApplicationTypesResponseAction, ResetComponentStateAction, SaveApplicationDataAction, SaveBankDetailsAction, SaveDocumentModelAction, SaveLastEmployerDetailsAction,
   SetCurrentStepAction, 
   UpdateDocumentRequiredAction} from './actions';
import { IAccessYourSuperFormComponentState } from './state';
import { Helper } from '@ifaa-components/ui-components';

export const state: IAccessYourSuperFormComponentState = {
    currentStep:null,
    currentStepUpdated:null,
    applicationId:null,
    applicationData:null,
    applicationTypes:null
};

var helper = new Helper();

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(GetApplicationTypesResponseAction, (state, { payload }) => {
    return {
      ...state,
      applicationTypes: payload,
      currentStep: payload.hasError ? -1 : 1
    };
  }),
  on(ResetComponentStateAction, (state, {}) => {
    return {
      ...state,
      currentStep:null,
      currentStepUpdated:null,
      applicationId:null,
      applicationData:null,
    }
  }),
  on(SetCurrentStepAction, (state, { nextStep }) => {
    return {
      ...state,
      currentStep: nextStep,
      currentStepUpdated: null
    };
  }),
  on(SaveApplicationDataAction, (state, {applicationId,model}) => {
    return {
      ...state,
      applicationId: applicationId,
      applicationData: model
    }
  }),
  on(CurrentStepUpdatedAction, (state, {}) => {
    return {
      ...state,
      currentStepUpdated: new Date()
    }
  }),
  on(SaveLastEmployerDetailsAction, (state, payload) => {
    return {
      ...state,
      applicationData:{
        ...state.applicationData,
        employerDetails:{
          ...state.applicationData.employerDetails,
          lastEmployerName: payload.lastEmployerName,
          stillWithEmployer:payload.stillWithEmployer,
          finishDate:payload.finishDate,
          waitingOnFinalContribution:payload.waitingOnFinalContribution
        }
      }
    }
  }),
  on(SaveBankDetailsAction, (state, payload) => {
    return {
      ...state,
      applicationData:{
        ...state.applicationData,
        bankDetails:{
          ...state.applicationData.bankDetails,
          bankName: payload.bankName,
          accountName: payload.accountName,
          accountNumber: payload.accountNumber,
          bsb: payload.bsb
        }
      }
    }
  }),
  on(SaveDocumentModelAction, (state, {payload}) => {
    return {
      ...state,
      applicationData:{
        ...state.applicationData,
        applicationDocuments: payload
      }
    }
  }),
  on(UpdateDocumentRequiredAction,(state, {documentTypeId, isRequired}) => {
    var clone = helper.clone(state.applicationData.applicationDocuments);
    var documentIndex = clone.findIndex(x => x.documentTypeId === documentTypeId);
    clone[documentIndex].isDocumentRequired = isRequired;

    return {
      ...state,
      applicationData:{
        ...state.applicationData,
        applicationDocuments:[...clone]
      }
    }
  })
);

export function accessYourSuperFormComponentReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
