import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';
import { IAccountSummaryState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const accountSumary = createSelector(memberPortalState, (state) => state.accountSummaryState);

export const accountSummary_Data = createSelector(
  accountSumary,
  (state: IAccountSummaryState) => state.accountSummary
);
