import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperUnrestrictedNonPreservedComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperUnrestrictedNonPreservedState = createSelector(memberPortalState, (state) => state.accessYourSuperUnrestrictedNonPreservedComponentState);

export const accessYourSuperUnrestrictedNonPreserved_Model = createSelector(
    getAccessYourSuperUnrestrictedNonPreservedState,
    (state: IAccessYourSuperUnrestrictedNonPreservedComponentState) => state.model
);

export const accessYourSuperUnrestrictedNonPreserved_Form = createSelector(
    getAccessYourSuperUnrestrictedNonPreservedState,
    (state: IAccessYourSuperUnrestrictedNonPreservedComponentState) => state.form
);

export const accessYourSuperUnrestrictedNonPreserved_FullAmountRadioOptions = createSelector(
    getAccessYourSuperUnrestrictedNonPreservedState,
    (state: IAccessYourSuperUnrestrictedNonPreservedComponentState) => state.fullAmountRadioOptions
);

export const accessYourSuperUnrestrictedNonPreserved_CurrentTotalPercent = createSelector(
    getAccessYourSuperUnrestrictedNonPreservedState,
    (state: IAccessYourSuperUnrestrictedNonPreservedComponentState) => state.form.value.investmentOptionDrawdown.reduce((a, b) => a + b.percentage, 0)
);

export const accessYourSuperUnrestrictedNonPreserved_SavedFormData = createSelector(
    getAccessYourSuperUnrestrictedNonPreservedState,
    (state: IAccessYourSuperUnrestrictedNonPreservedComponentState) => state.savedFormData
);