<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="local_atm">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Contributions' | textTransform}}</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper fragmentid="bpay"    icon="receipt_long" header="Make a Contribution">
      <div class="d-flex flex-column" slot="start">
        <p class="mb-3 description" [innerHTML]="'bpayContribution_Description'|message|async"></p>

      </div>
    </app-component-wrapper>

    <app-component-wrapper fragmentid="bpay" icon="receipt_long" header="<span class='bpay-font'>BPAY<sup>&reg;</sup> Details</span>">
      <div class="d-flex flex-column" slot="start">
        <app-bpay-details></app-bpay-details>
      </div>
    </app-component-wrapper>
  </div>
</div>
