
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { LoginModel } from 'src/app/model/login.model';
import { OkModel } from 'src/app/model/ok.model';
import { SMRTStartupValidationModel } from './state';

export const StartupAction = createAction('[Startup View] validate',
  props<{ payload: SMRTStartupValidationModel }>());

export const StartupCompletedAction = createAction('[Startup Logged In View]validate completed',
  props<{ payload: string }>());

export const LoggedInAction = createAction('[Startup Logged In View] user is loggedin',
  props<{ payload: string }>());

export const IsLoggedInAction = createAction('[Startup Is Logged in] check');

export const CheckAvailabilityRequestAction = createAction('[Startup View] check availbility');

export const CheckAvailabilityResponseAction = createAction('[Startup View] set check availbility',
  props<{ payload: OkModel<boolean> }>());
