import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IRecentEmploymentDetailsState } from './state';
import { featureKey } from '../../store/shared.reducers';
export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const recentEmployment = createSelector(memberPortalState, (state) => state.recentEmploymentDetailState);

export const recentEmployment_Employer = createSelector(
  recentEmployment,
  (state: IRecentEmploymentDetailsState) => state.employer
);
