// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AccessYourSuperKiwiSaverDetailSubmissionModel, AccessYourSuperPaymentDetailSectionModel, AccessYourSuperPaymentDetailSubmissionModel } from '../model/access-your-super-custom-sections.model';
import { AccessYourSuperBankDetailsSubmissionModel, AccessYourSuperDocumentSubmissionModel, AccessYourSuperEmployerDetailsSubmissionModel } from '../model/access-your-super-form.models';
import { AppFileModel } from '@ifaa-components/ui-components';


@Injectable()
export class AccessYourSuperService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getApplicationList(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/retrieveAllApplications`, { headers: this.headers() });
  }

  getApplicationTypes(accountType: number, accountId:number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/accountType/${accountType}/getApplicationTypes`, { headers: this.headers() });
  }

  submitApplicationTypeSelection(accountId: number, applicationType: number): Observable<any> {
    return this.http.post(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/createNewApplication/applicationType/${applicationType}`, { headers: this.headers() });
  }

  getApplicationSpecificData(accountId: number, applicationType: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/generateApplicationData/applicationType/${applicationType}`, { headers: this.headers() });
  }

  cancelApplication(accountId: number, applicationId: any): Observable<any> {
    return this.http.put(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/updateApplication/status/cancelled/applicationId/${applicationId}`, { headers: this.headers() });
  }

  continueApplication(accountId: number, applicationId: any, draftApplication:boolean): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/continueApplication/applicationId/${applicationId}?draftApplication=${draftApplication}`, { headers: this.headers() });
  }

  updateCurrentStep(accountId: number, currentStep: number, applicationId: number): Observable<any> {
    return this.http.put(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/updateApplication/currentStep/${currentStep}/applicationId/${applicationId}`,
      { headers: this.headers() });
  }

  saveLastEmployerData(accountId: number, applicationId: number, obj: AccessYourSuperEmployerDetailsSubmissionModel): Observable<any> {
    return this.http.put<AccessYourSuperEmployerDetailsSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/updateApplication/employerDetails/applicationId/${applicationId}`, obj,
      { headers: this.headers() });
  }

  searchBankName(bsb: any): Observable<any> {
    return this.http.get<string>(environment.apiUrl + `/member-portal/Bank/getFinancialInstitutionName/${bsb}`,
      { headers: this.headers() });
  }

  saveBankDetails(accountId: any, applicationId: any, payload: AccessYourSuperBankDetailsSubmissionModel): Observable<any> {
    return this.http.put<AccessYourSuperBankDetailsSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/updateApplication/bankDetails/applicationId/${applicationId}`, payload,
      { headers: this.headers() });
  }

  saveDocument(accountId:any, applicationId:any, documentTypeId:any, payload:AppFileModel, draftApplication:boolean): Observable<any>{
    return this.http.put<AppFileModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/updateApplication/saveApplicationDocument/applicationId/${applicationId}/documentTypeId/${documentTypeId}?draftApplication=${draftApplication}`, payload,
      { headers: this.headers() });
  }

  deleteDocument(accountId:any, applicationId:any, uploadedDocumentId:any, draftApplication:boolean): Observable<any>{
    return this.http.delete(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/updateApplication/deleteApplicationDocument/applicationId/${applicationId}/uploadedDocumentId/${uploadedDocumentId}?draftApplication=${draftApplication}`,
      { headers: this.headers() });
  }

  completeDocuments(accountId:any, applicationId: any, payload:AccessYourSuperDocumentSubmissionModel): Observable<any>{
    return this.http.put<AccessYourSuperDocumentSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/updateApplication/completeDocumentStep/applicationId/${applicationId}`, payload,
    { headers: this.headers() });
  }

  getCustomSectionData(accountId: number, applicationId: number, applicationTypeId: number): Observable<any> {
    return this.http.get<AccessYourSuperPaymentDetailSectionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/generateCustomSection/applicationId/${applicationId}/applicationTypeId/${applicationTypeId}`,
      { headers: this.headers() });
  }

  saveRetirementCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/retirement`, payload,
      { headers: this.headers() });
  }

  saveCeasedWorkSixtyCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/ceasedWorkAfterSixty`, payload,
      { headers: this.headers() });
  }

  savePermanentlyRetiredPreservationCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/permanentRetirementAfterPreservation`, payload,
      { headers: this.headers() });
  }

  saveCeasedPaidEmploymentCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/ceasedPaidEmployment`, payload,
      { headers: this.headers() });
  }

  savePensionCommutationsCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/pensionCommutations`, payload,
      { headers: this.headers() });
  }

  saveTerminalMedicalConditionCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/terminalMedicalCondition`, payload,
      { headers: this.headers() });
  }

  saveSevereFinancialHardshipCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/severeFinancialHardship`, payload,
      { headers: this.headers() });
  }

  saveCompassionateGroundsCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/compassionateGrounds`, payload,
      { headers: this.headers() });
  }

  saveUnrestrictedNonPreservedCustomSectionData(accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel): Observable<any> {
    return this.http.post<AccessYourSuperPaymentDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/unrestrictedNonPreserved`, payload,
      { headers: this.headers() });
  }

  submitApplication(accountId:number, applicationId:number):Observable<any>{
    return this.http.put(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/submitApplication/applicationId/${applicationId}`, { headers: this.headers() });
  }

  getKiwiSaverSectionData(accountId: number, applicationId:number):Observable<any>{
    return this.http.get(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/generateKiwiSaver/applicationId/${applicationId}`, { headers: this.headers() });
  }

  getSfhSectionData(accountId: number, applicationId:number):Observable<any>{
    return this.http.get(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/generateSfh/applicationId/${applicationId}`, { headers: this.headers() });
  }

  saveKiwiSaverSectionData(accountId:number, payload: AccessYourSuperKiwiSaverDetailSubmissionModel):Observable<any>{
    return this.http.post<AccessYourSuperKiwiSaverDetailSubmissionModel>(environment.apiUrl + `/member-portal/AccessYourSuper/account/${accountId}/saveCustomSection/kiwiSaver`, payload,
    { headers: this.headers() });
  }

}
