import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { InsuranceDetailsModel } from 'src/app/model/insurance-details.model';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';

@Component({
  selector: 'app-smrt-no-insurance',
  templateUrl: './smrt-no-insurance.component.html',
  styleUrls: ['./smrt-no-insurance.component.scss']
})
export class SMRTNoInsuranceComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() fund = '';
  @Input() data?: InsuranceDetailsModel = undefined;

  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  selectedAccount$ = this.appstore.pipe(select(memberAccountDropdown_SelectedAccount));

  constructor(
    public appstore: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }



}
