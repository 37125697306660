import { AppFileModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { AccessYourSuperDocumentSubmissionModel } from "src/app/model/access-your-super-form.models";

export const ResetComponentStateAction = createAction('[Access your super documents component] reset state');

export const GreenIdVerifiedAction = createAction('[Access your super documents component] green id verified', props<{verified:boolean}>());

export const SetupSupportingDocumentOverviewAction = createAction('[Access your super documents component] add app file model', props<{documentTypeId:number, documentAmount:number, referenceRequired:boolean, authorityGiven:boolean, referenceValue:string|null, previouslyProvided:boolean|null, isDocumentRequired:boolean}>());

export const AddNewAppFileAction = createAction('[Access your super documents component] add new document detail', props<{overviewIndex:number}>())
export const RemoveAppFileAction = createAction('[Access your super documents component] remove document detail', props<{overviewIndex:number, uploadIndex:number}>())

export const SaveDocumentAction = createAction('[Access your super documents component] save document', props<{accountId:number, applicationId:number, documentTypeId:number, controlId:any, documentIndex:number, fileName:string, payload:AppFileModel, draftApplication:boolean}>());
export const UpdateSavedDocumentAction = createAction('[Access your super documents component] update uploaded document state', props<{uploadedDocumentId:number, fileName:string, documentTypeId:number, documentIndex:number, controlId:any}>());

export const ReplaceDocumentAction = createAction('[Access your super documents component] replace uploaded document', props<{accountId:number, applicationId:number, uploadedDocumentId:number, overviewIndex:number, documentIndex:number, controlId:any, documentTypeId:number, fileName:string, payload:AppFileModel, originalFile:AppFileModel, draftApplication:boolean}>());
export const UpdateReplacedDocumentAction = createAction('[Access your super documents component] handle replaced document', props<{documentTypeId:number, controlId:any, documentIndex:number, fileName:string, payload:AppFileModel}>());

export const DeleteDocumentAction = createAction('[Access your super documents component] delete document', props<{accountId:number, applicationId:number, uploadedDocumentId:number, overviewIndex:number, uploadIndex:number, controlId:any, draftApplication:boolean}>());
export const UpdateDeletedDocumentAction = createAction('[Access your super documents component] update deleted document state', props<{uploadedDocumentId:number, overviewIndex:number, uploadIndex:number, controlId:any}>());

export const SetFileRequestFailedAction = createAction('[Access your super documents component] set reset file field', props<{controlId:any, file:AppFileModel}>());
export const ResetFileRequestFailedAction = createAction('[Access your super documents component] clear reset field field');

export const CompleteDocumentsAction = createAction('[Access your super documents component] complete documents component', props<{accountId: number, applicationId:number, submission:AccessYourSuperDocumentSubmissionModel}>())
export const DocumentsCompletedAction = createAction('[Access your super documents component] documents completed')