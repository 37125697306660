import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperDrawdownModel, AccessYourSuperPaymentDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { InvestmentItemModel } from "src/app/model/investment-item.model";

export interface IAccessYourSuperCeasedWorkSixtyComponentState {
    model:AccessYourSuperPaymentDetailSectionModel;
    form:FormGroupState<AccessYourSuperCeasedWorkSixtyForm>;
    fullAmountRadioOptions:KeyValueModel[];
    savedFormData:Date
}

export class AccessYourSuperCeasedWorkSixtyForm extends AccessYourSuperDrawdownModel{
    paymentType:number=0;
    fullAmountSelection:number=0;
    partialAmountSpecified:number=0;
    partialWithdrawalSelection:boolean=false;
    investmentOptionDrawdown:InvestmentItemModel[] = [];
}