import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.states";
import { environment } from "src/environments/environment";

export const toSentenceCase = str => {
  const s =
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .join(' ');
  return s.slice(0, 1).toUpperCase() + s.slice(1);
};

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'textTransform'
})
export class TextTransformPipe implements PipeTransform {
  /**
   * Pipe Constructor
   *
   * @param _sanitizer: DomSanitezer
   */
  // tslint:disable-next-line
  constructor(public store: Store<AppState>, protected _sanitizer: DomSanitizer) {
  }

  // Displays 0 instead of values disappearing from UI due to NaN
  transform(value: string): string {
    switch (environment.buttonTitleTextTransform) {
      case 'LowerCase':
        return value.toLowerCase();
      case 'UpperCase':
        return value.toUpperCase();
      case 'SentenceCase':
        var val = value.toLowerCase().trim();
        val = val.charAt(0).toUpperCase() + val.slice(1);
        return val;
    }

    return value;
  }
}
