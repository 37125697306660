import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { InvestmentService } from 'src/app/services/investment.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class InvestmentSwitchHistoryEffects {
  constructor(private actions$: Actions,
    public toastr: ToastrService,
    private service: InvestmentService) {
  }

  getHistory$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getHistory(action.accountId, action.page, action.filter).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
