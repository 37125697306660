import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperPaymentDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { InvestmentItemModel } from "src/app/model/investment-item.model";

export interface IAccessYourSuperCompassionateGroundsComponentState {
    model:AccessYourSuperPaymentDetailSectionModel;
    form:FormGroupState<AccessYourSuperCompassionateGroundsForm>;
    savedFormData:Date
}

export class AccessYourSuperCompassionateGroundsForm{
    isAtoApproved:boolean=true;
    partialAmountSpecified:number=0;
    investmentOptionDrawdown:InvestmentItemModel[] = [];
}