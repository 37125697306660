import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccountFeatureToggle, AccountFeatureToggleInfo } from 'src/app/model/account-feature-toggles.model';
import { LoggedInModel } from 'src/app/model/logged-in.model';
import { MemberModel } from 'src/app/model/member.model';
import { MenuModel } from 'src/app/model/menu.model';
import { SystemConfigModel } from 'src/app/model/system-config.model';
// import { ErrorModel } from '../../model/error.model';
// import { LoggedInModel } from 'src/app/model/loggedIn.model';

// export const ShowErrorAction = createAction('[Show error] error',
//   props<{ payload: ErrorModel }>());

export const ChangeScreenWidthAction = createAction('[Screen Width] set',
  props<{ payload: number }>());

export const IsLoadingAction = createAction('[Is app loading] loading',
  props<{ payload: boolean }>());

export const SystemConfigurationRequestAction = createAction('[System Config] get data');

export const SystemConfigurationResponseAction = createAction('[System Config] set data',
  props<{ payload: SystemConfigModel }>());

export const IsLoggedInRequestAction = createAction('[User check] is logged in request');

export const IsLoggedInResponseAction = createAction('[User check] is logged in response',
  props<{ data: LoggedInModel }>());


export const LogoutRequestAction = createAction('[Logout request]');

export const SetMenuAction = createAction('[App] set menu', props<{ data: MenuModel[] }>());

export const AccountFeatureTogglesRequestAction = createAction('[System Config] get account feature toggles', props<{ accountId: number }>())
export const AccountFeatureTogglesResponseAction = createAction('[System Config] set account feature toggles', props<{ payload: AccountFeatureToggleInfo }>())

export const SetSiteError = createAction('[System Config] set site error', props<{ error: boolean }>());

export const SetLoginVideoUrlAction = createAction('[System Config] set login view video', props<{ url: string }>());

export const SetIsAdviserElevatedAccessAction = createAction('[System Config] set adviser elevated access', props<{isElevated:boolean}>())
export const SetIsFundElevatedAccessAction = createAction('[System Config] set fund elevated access', props<{isElevated:boolean}>())


export const RequestMemberDataAction = createAction('[Member data] get data');
export const ResponseMemberDataAction = createAction('[Member data] set data',  props<{ payload: MemberModel }>());