<div id="severe-financial-hardship-form" *ngIf="model$ | async; let model">
    <div *ngIf="combinedFormData$ | async; let combinedForm;">
        <!-- form 1 -->
        <div appanimatecss="animate__fadeIn" *ngIf="(sfhStep$ | async) === 1">
            <h2>Benefit payment details</h2>
            <form [ngrxFormState]="combinedForm.formOne" class="ml-3">
                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div>
                        <div class="title mb-1">{{model.formLabels.paymentType}}</div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio list="accessYourSuperPaymentTypes"
                                [control]="combinedForm.formOne.controls.paymentType"></app-entry-radio>
                        </div>
                    </div>
                </div>
                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div>
                        <div class="title d-flex flex-row align-items-center">{{model.formLabels.benefitAmount}}
                            <mat-icon *ngIf="showBenefitAmountTooltip" class="ml-2 tooltip-readonly"
                                [matTooltip]="model.formLabels.benefitAmountTooltip">help_outline</mat-icon>
                        </div>
                        <div *ngIf="combinedForm.formOne.value.paymentType === 0"
                            class="custom-control custom-radio answer pb-4">
                            <app-entry-radio [customList]="fullAmountRadioOptions$"
                                [control]="combinedForm.formOne.controls.fullAmountSelection"></app-entry-radio>
                        </div>
                        <div *ngIf="combinedForm.formOne.value.paymentType === 1" class="d-flex flex-row flex-wrap">
                            <div class="custom-control custom-radio answer">
                                <app-entry-number title="Please enter an amount"
                                    [control]="combinedForm.formOne.controls.partialAmountSpecified" icon="attach_money"
                                    appanimatecss [decimalplaces]="totalBalance < 1000 ? 2 : 0"
                                    [showincremental]="false">
                                </app-entry-number>
                            </div>
                            <div class="custom-control custom-radio answer">
                                <app-entry-dropdown class="w-100" title="Please select an option"
                                    [customList]="taxOptions$" [control]="combinedForm.formOne.controls.isBeforeTax">
                                </app-entry-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="combinedForm.formOne.value.memberReachedPreservationAge"
                    class="question d-flex flex-row description pl-2 mb-4">
                    <div>
                        <div class="title mb-1 d-flex flex-row align-items-center">
                            {{model.formLabels.permanentlyRetired}}
                            <mat-icon class="ml-2 tooltip-readonly"
                                [matTooltip]="model.formLabels.permanentlyRetiredTooltip">help_outline</mat-icon>
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio list="yesNo"
                                [control]="combinedForm.formOne.controls.isPermanentlyRetired"></app-entry-radio>
                        </div>
                    </div>
                </div>
                <div class="question d-flex flex-row description pl-2 mb-2">
                    <div>
                        <div class="title mb-1 d-flex flex-row align-items-center">
                            {{model.formLabels.summaryQuestion}}
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-textarea
                                [control]="combinedForm.formOne.controls.sfhSummary"></app-entry-textarea>
                        </div>
                    </div>
                </div>
                <div class="question d-flex flex-row description pl-2 mb-2">
                    <div>
                        <div class="title mb-1 d-flex flex-row align-items-center">
                            {{model.reachedPreservationAgePlusThirtyNineWeeks ?
                            model.formLabels.receivingQualifyingSupport39Weeks :
                            model.formLabels.receivingQualifyingSupport26Weeks}}
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio list="yesNo"
                                [control]="combinedForm.formOne.controls.receivingQualifyingSupport"></app-entry-radio>
                        </div>
                    </div>
                </div>
                <div *ngIf="combinedForm.formOne.value.paymentType === 1"
                    class="question d-flex flex-row description mt-2 pl-2">
                    <div>
                        <div class="title mb-1 d-flex flex-row align-items-center">
                            {{model.formLabels.investmentOptionChoice}}
                            <mat-icon class="ml-2 tooltip-readonly"
                                [matTooltip]="model.formLabels.investmentOptionChoiceTooltip">help_outline</mat-icon>
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio list="yesNo"
                                [control]="combinedForm.formOne.controls.partialWithdrawalSelection"></app-entry-radio>
                        </div>
                    </div>
                </div>
                <div *ngIf="combinedForm.formOne.value.paymentType === 1 && combinedForm.formOne.value.partialWithdrawalSelection"
                    class="question d-flex flex-column description mt-4 pl-2">
                    <div class="title mb-1">
                        {{model.formLabels.provideInvestmentOption}}
                    </div>
                    <app-access-your-super-drawdown [form]="combinedForm.formOne"
                        [disclaimer]="model.formLabels.drawdownDisclaimer"
                        [totalCount]="currentTotalPercent$ | async"></app-access-your-super-drawdown>
                </div>
            </form>

            <p class="mb-5 mt-3"
                [ngClass]="{'mt-4': !combinedForm.formOne.value.partialWithdrawalSelection && combinedForm.formOne.value.paymentType === 1}"
                [innerHtml]="model.formLabels.conditionDisclaimer"></p>
        </div>

        <!-- form 2 -->
        <div appanimatecss="animate__fadeIn" *ngIf="(sfhStep$ | async) === 2">
            <h2>Dependants</h2>
            <div [innerHtml]="model.formLabels.dependantText" class="ml-3"></div>
            <div class="d-flex flex-row justify-content-end mb-4 mt-3">
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" title="Add new dependant"
                    color="{{'BTN.access-your-super.continue.color' | translate}}"
                    icon="{{'BTN.access-your-super.add.icon' | translate}}"
                    (onClick)="addDependant(combinedForm.formTwo)"></app-button>
            </div>
            <form [ngrxFormState]="combinedForm.formTwo" class="ml-3 mb-3">
                <ng-container
                    *ngFor="let dependant of combinedForm.formTwo.controls.dependants.controls;  let i = index; trackBy: trackByFn">
                    <div class="dependant-container">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <h3 class="m-0">Dependant {{i+1}}</h3>
                            <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" title="Remove"
                                icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                                color="{{'BTN.access-your-super.cancel.color' | translate}}"
                                (onClick)="removeDependant(i, combinedForm.formTwo)"></app-button>
                        </div>
                        <app-entry title="Name of dependant" [control]="dependant.controls.name">
                        </app-entry>

                        <app-entry title="Age of dependant" [control]="dependant.controls.age" entrytype="number">
                        </app-entry>

                        <app-entry title="Relationship to dependant" [control]="dependant.controls.relationship">
                        </app-entry>
                    </div>

                </ng-container>
            </form>
        </div>

        <!-- form 3 -->
        <div appanimatecss="animate__fadeIn" *ngIf="(sfhStep$ | async) === 3">
            <h2>Financial details</h2>
            <ng-container [ngSwitch]="(formVersion$|async)">
                <sfh-financial-details-v1 *ngSwitchCase="1" (onFormValid)="financialDetailsValid($event)"
                    [data]="model.sfhSubmissionData.dependantAndFinanceData.financialDetails"
                    [labels]="model.formLabels">
                </sfh-financial-details-v1>
            </ng-container>
        </div>

        <div class="w-75 flex-row m-auto" [ngClass]="{'d-flex ': screenWidth>991, 'd-none': screenWidth<992}">
            <app-access-your-super-stepper #stepper [stepTitles]="stepperData"
                [onSelectionChangeFn]="onStepperSelectionChange"></app-access-your-super-stepper>
        </div>

        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                    color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button *ngIf="showBackButton" [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.access-your-super.back.class' | translate}}" title="Back"
                    icon="{{'BTN.access-your-super.back.icon' | translate}}"
                    color="{{'BTN.access-your-super.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                    color="{{'BTN.access-your-super.continue.color' | translate}}" [isDisabled]="(combinedForm.formOne.isInvalid && (sfhStep$ | async) === 1) ||
                    (combinedForm.formTwo.isInvalid && (sfhStep$ | async) === 2) ||
                    (!(formThreeValid$|async) && (sfhStep$ | async) === 3)" (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>