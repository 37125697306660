<div id="lodge-claim-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Lodge a Claim </div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper class="lodge-claim-component" icon="" header="">
      <div class="d-flex flex-column" slot="start">

        <div class="header ">
          <h3 class="mt-2 mb-0">Training Program Details</h3>
        </div>

        <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">

          <app-entry title="Training Provider" [control]="form.controls.trainingProvider" icon="email">
          </app-entry>

          <app-entry title="Training Course" [control]="form.controls.trainingCourse" icon="email">
          </app-entry>

          <app-entry-date title="Start Date" [date]="form.controls.startDate"></app-entry-date>

          <app-entry-date title="Finish Date" [date]="form.controls.endDate"></app-entry-date>

          <app-checkbox [control]="form.controls.receiving" class="w-100  mr-1 ml-1"
            title="Are you receiving or going to receive any additional subsidies from your employer, any other individual or organisation for the training program detailed above?">
          </app-checkbox>

          <app-entry-number title="If yes, how much?" [control]="form.controls.receivingHowMuch" icon="attach_money"
            appanimatecss>
          </app-entry-number>

          <app-entry-number title="Cost of Training" [control]="form.controls.costOfTraining" icon="email">
          </app-entry-number>

          <!-- <app-readonly title="Available Claim Amount" icon="attach_money" [value]="claimAmount$ | async"></app-readonly> -->

        </form>
      </div>

    </app-component-wrapper>

    <app-component-wrapper class="lodge-claim-component" icon="" header="">
      <div class="d-flex flex-column" slot="start">

        <div class="header">
          <h3 class="mt-2 mb-0">Payment Details</h3>
        </div>
        <div class="subheader ">
          <h5 class="mt-0">
            Please complete all bank details and provide a copy of the top of your latest bank statement (which includes
            your full name, account number and BSB), so we can verify your account details.
          </h5>
        </div>

        <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">


          <div class="d-flex flex-row">
            <div class="flex-fill">
              <app-entry title="Account Name" [control]="form.controls.bankDetails.controls.accountName" icon="email">
              </app-entry>
            </div>

          </div>
          <div class="row">
            <div class="col col-md-6">
              <app-entry title="BSB" [control]="form.controls.bankDetails.controls.bsb" icon="email">
              </app-entry>
            </div>
            <div class="col col-md-6">
              <app-entry title="Account Number" [control]="form.controls.bankDetails.controls.accountNumber"
                icon="email">
              </app-entry>
            </div>
          </div>
        </form>
      </div>

    </app-component-wrapper>

    <app-component-wrapper class="lodge-claim-component" icon="" header="">
      <div class="d-flex flex-column" slot="start">

        <div class="header">
          <h3 class="mt-2 mb-0">Attachments Required</h3>
        </div>
        <div class="subheader ">
          <h5 class="mt-0 mb-3">Please upload the required documents: </h5>
        </div>

        <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">



          <div class="upload-files d-flex flex-row align-items-start  mt-4 flex-wrap">
            <div class="d-flex align-items-start flex-column mr-2">
              <h5 class="mb-1">Invoice and Receipt</h5>
              <app-entry-file [file]="form.controls.invoiceAndReceipt" class="d-flex flex-fill  "
                [extension]="extensions" [maxSize]="maxfilesize">
              </app-entry-file>

            </div>
            <div class="d-flex align-items-start flex-column ml-2 mr-2 pb-3">
              <h5 class="mb-1">Completion Certificate or Ticket</h5>
              <app-entry-file [file]="form.controls.completionCertificate" class="d-flex flex-fill "
                [extension]="extensions" [maxSize]="maxfilesize">
              </app-entry-file>

            </div>
            <div class="d-flex align-items-start flex-column ml-2 pb-3">
              <h5 class="mb-1">Bank Statement</h5>
              <app-entry-file [file]="form.controls.bankStatement" class="d-flex flex-fill " [extension]="extensions"
                [maxSize]="maxfilesize">
              </app-entry-file>

            </div>
            <div class="d-flex align-items-start flex-column ml-2 mr-2 pb-3">
              <h5 class="mb-1">Certified ID</h5>
              <app-entry-file [file]="form.controls.certifiedId" class="d-flex flex-fill " [extension]="extensions"
                [maxSize]="maxfilesize">
              </app-entry-file>

            </div>
            <div class="d-flex align-items-start flex-column ml-2 pb-3">
              <h5 class="mb-1">Completed Employee Training Subsidy Application Form</h5>
              <app-entry-file [file]="form.controls.completedEmployeeTraining" class="d-flex flex-fill " [extension]="extensions"
                [maxSize]="maxfilesize">
              </app-entry-file>

            </div>
          </div>
        </form>
      </div>

    </app-component-wrapper>

    <app-component-wrapper class="lodge-claim-component" icon="" header="">
      <div class="d-flex flex-column" slot="start">

        <div class="header">
          <h3 class="mt-2 mb-0">Declaration</h3>
        </div>

        <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">

          <app-checkbox [control]="form.controls.declaration" class="w-100  mr-1 ml-1"
            title="I hereby certify that all details are true and correct, and give authority for CIRT to provide balance and employer contribution data to the subsidy approving body to enable analysis against eligibility criteria.">
          </app-checkbox>
          <p>Please note: Eligibility rules are available on the CIRT website <a href="https://www.cirt.com.au/jetco"
              target="_blank">www.cirt.com.au/jetco</a></p>


        </form>
      </div>
      <div class="d-flex flex-row components-actions" slot="actions" *ngIf="form$ | async; let form">
        <app-button class=" mr-1 ml-1" title="Submit" icon="east" (onClick)="onSubmitClick(form.value)" [form]="form"
          [isDisabled]="form.isInvalid">
        </app-button>
      </div>
    </app-component-wrapper>


  </div>
  <ng-template #myTemplate>
    <div class="p-4">
      <h3 class="mb-3">Thank you for your JETCO Claim submission
      </h3>
      <p>
        The JETCO Team will work on this payment and will contact if any additional details are required
      </p>
      <p>
        Reimbursements are usually paid on the last working day of the week
      </p>

      <app-button loading class="float-right  mb-4 mt-4" title="Return to Dashboard" icon="keyboard_return"
        type="submit" (onClick)="onGoToDashboardClick()">
      </app-button>
    </div>
  </ng-template>
</div>
