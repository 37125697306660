import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction, setValue, SetValueAction,
} from 'ngrx-forms';
import { IInvestmentSwitchHistoryState, InvestmentSwitchHistoryFilter } from './state';
import { ResetAction, ResetFilterAction, ResponseAction, SwitchHistoryChangeSwitchStatusFilterAction, SwitchHistoryChangeSwitchTypeFilterAction, SwitchHistoryIncrementPageAction, SwitchHistorySetFromToDateAction } from './actions';
import { Helper, KeyValueModel } from '@ifaa-components/ui-components';

export const switchHistoryFilter = 'switchHistoryFilter';
var helper = new Helper();

export const defaultState: IInvestmentSwitchHistoryState = {
  data: null,
  filter: createFormGroupState(switchHistoryFilter, new InvestmentSwitchHistoryFilter()),
  isLoading: false,
  switchTypes: [],
  switchStatuses: []
};

const reducer = createReducer(defaultState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (action.controlId == 'switchHistoryFilter.from'
      || action.controlId == 'switchHistoryFilter.to') {
      var newFilter = {
        ...state.filter.value,
        currentPage: 0
      };
      return {
        ...state,
        filter: setValue(newFilter)(state.filter)
      };
    }

    return state;
  }),
  on(ResetAction, (_state) => defaultState),
  on(ResponseAction, (state, { data }) => {
    var clone = helper.clone(state.filter.value);
    var dataClone = { ...data };

    if (clone.currentPage !== 0) {
      dataClone.switchHistory = [...state.data.switchHistory, ...data.switchHistory];
    }

    if (data.switchHistory.length < 10) {
      clone.currentPage = -1;
    }

    if (!state.switchStatuses || state.switchTypes) {
      var switchStatuses = [...data.switchStatusFilters];
      switchStatuses.unshift({ key: 0, value: "All" })

      var switchTypes = [...data.switchTypeFilters];
      switchTypes.unshift({ key: 0, value: "All" });

      return {
        ...state,
        filter: setValue(clone)(state.filter),
        data: dataClone,
        isLoading: false,
        switchStatuses,
        switchTypes
      }
    }

    return {
      ...state,
      filter: setValue(clone)(state.filter),
      data: dataClone,
      isLoading: false
    }
  }),
  on(SwitchHistorySetFromToDateAction, (state, { from, to }) => {
    var clone = helper.clone(state.filter.value);
    clone.from = from;
    clone.to = to;
    clone.currentPage = 0;
    return {
      ...state,
      filter: setValue(clone)(state.filter),
      isLoading: true,
    }
  }),
  on(ResetFilterAction, (state, { }) => {
    return {
      ...state,
      filter: createFormGroupState(switchHistoryFilter, new InvestmentSwitchHistoryFilter()),
      isLoading: true,
    }
  }),
  on(SwitchHistoryIncrementPageAction, (state) => {
    if (state.filter.value.currentPage === -1 || state.isLoading)
      return state;

    var clone = helper.clone(state.filter.value);

    clone.currentPage = state.filter.value.currentPage + 1;

    return {
      ...state,
      isLoading: true,
      filter: setValue(clone)(state.filter)
    };
  }),
  on(SwitchHistoryChangeSwitchTypeFilterAction, (state, { filter }) => {
    var clone = helper.clone(state.filter.value);
    clone.switchType = filter;
    clone.currentPage = 0;

    return {
      ...state,
      filter: setValue(clone)(state.filter),
      isLoading: true,
    }
  }),
  on(SwitchHistoryChangeSwitchStatusFilterAction, (state, { filter }) => {
    var clone = helper.clone(state.filter.value);
    clone.switchStatus = filter;
    clone.currentPage = 0;

    return {
      ...state,
      filter: setValue(clone)(state.filter),
      isLoading: true,
    }
  })
);


export function investmentSwitchHistoryReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
