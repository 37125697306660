import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ChangePasswordModel } from '../model/change-password.model';
import { LoginModel } from '../model/login.model';

@Injectable()
export class WebUserService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  authenticate(obj: LoginModel): Observable<any> {
    return this.http.post<LoginModel>(environment.apiUrl + '/web-user/authenticate', obj);
  }

  isLoggedIn(): Observable<any> {
    return this.http.get(environment.apiUrl + '/web-user/isloggedin', { headers: this.headers() });
  }

  isMaintenanceMode(): Observable<any> {
    return this.http.get(environment.apiUrl + `/web-user/isMaintenanceMode`, { headers: this.headers() });
  }

  changepassword(obj: ChangePasswordModel): Observable<any> {
    return this.http.post<ChangePasswordModel>(environment.apiUrl + `/web-user/change-password`, obj, { headers: this.headers() });
  }

}
