<div id="beneficiaries-view-container">

  <p class="mb-3 description" [innerHTML]="'beneficiaries_Description'|message|async"></p>

  <app-no-items message="No beneficiary found" *ngIf="(list$ |async)?.length === 0"></app-no-items>

  <div class="d-flex flex-column " *ngIf="(list$ |async)?.length > 0" appanimatecss="fadeIn delay-1s">
    <div class="card beneficiary d-flex flex-fill" *ngFor="let item of list$ | async; let i = index">
      <div class="d-flex align-items-center flex-row beneficiaryinfo">
        <div class="beneficiaries-data-1 d-flex flex-fill">
          <div class="badge badge-info">{{i+1}}</div>

          <div class="icon mr-1">
            <!-- <mat-icon>account_circle</mat-icon> -->
            <!-- <div role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{item.percentage}}"></div> -->
            <circle-progress [percent]="item.percentage" [radius]="45" [outerStrokeWidth]="4" [innerStrokeWidth]="2"
              [showSubtitle]="false" [outerStrokeColor]="'CIRCLE_PROGRESS.OUTERSTROKECOLOR' | translate" [toFixed]="2"
              [innerStrokeColor]="'CIRCLE_PROGRESS.INNERSTROKECOLOR' | translate"
              [animationDuration]="2000"></circle-progress>
          </div>
          <div class="d-flex flex-fill flex-column ml-2">
            <h5 class="name">{{item.name}}</h5>
            <div class="relationship d-flex align-items-center">
              <mat-icon>group</mat-icon>
              {{item.relationship}}
            </div>
          </div>
        </div>
        <div class="beneficiaries-data-2 d-flex flex-fill">
          <div class="d-flex flex-fill ml-2 beneficiary-info">
            <app-readonly class="w-100" icon="bookmark_border" title="Type"
              [value]="item.beneficiaryTypeName"></app-readonly>
          </div>
          <div class="d-flex flex-fill ml-2 beneficiary-info">
            <app-readonly class="w-100" icon="date_range" title="Expiration date"
              [value]="item?.expirationDate | date:'dd/MM/yyyy'"></app-readonly>
          </div>
        </div>
      </div>


      <!-- <div class="percentage d-flex align-items-center flex-column">
        <mat-progress-bar mode="determinate" [value]="item.percentage"></mat-progress-bar>

        <div class="value">{{item.percentage + '%'}}</div>
      </div> -->
    </div>
  </div>

  <!-- <div class="table-wrapper">
    <table class="table {{'VIEWS.Global.table-default-style' | translate}}" *ngIf="(list$ |async)?.length > 0">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Percent</th>
          <th scope="col">Relationship to You</th>
          <th scope="col">Expiration Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list$ | async">
          <th>{{item.name}}</th>
          <th>{{item.beneficiaryTypeName}}</th>
          <th>{{item.percentage + '%'}}</th>
          <th>{{item.relationship}}</th>
          <th>{{item.expirationDate }}</th>
        </tr>

      </tbody>
    </table>
  </div> -->

  <div class="components-actions" *ngIf="canEdit$ | async">
    <app-button *featuretoggle="updateBeneficiariesFeatureToggle" loading class="floar-right" title="Edit Beneficiary"
      icon="{{'BTN.beneficiary-view.edit.icon' | translate}}" (onClick)="onEditClick()">
    </app-button>
  </div>

</div>
