import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperPermanentRetirementPreservationComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperPermanentRetirementPreservationState = createSelector(memberPortalState, (state) => state.accessYourSuperPermanentRetirementPreservationComponentState);

export const accessYourSuperPermanentRetirementPreservation_Model = createSelector(
    getAccessYourSuperPermanentRetirementPreservationState,
    (state: IAccessYourSuperPermanentRetirementPreservationComponentState) => state.model
);

export const accessYourSuperPermanentRetirementPreservation_Form = createSelector(
    getAccessYourSuperPermanentRetirementPreservationState,
    (state: IAccessYourSuperPermanentRetirementPreservationComponentState) => state.form
)

export const accessYourSuperPermanentRetirementPreservation_FullAmountRadioOptions = createSelector(
    getAccessYourSuperPermanentRetirementPreservationState,
    (state: IAccessYourSuperPermanentRetirementPreservationComponentState) => state.fullAmountRadioOptions
)

export const accessYourSuperPermanentRetirementPreservation_CurrentTotalPercent = createSelector(
    getAccessYourSuperPermanentRetirementPreservationState,
    (state: IAccessYourSuperPermanentRetirementPreservationComponentState) => state.form.value.investmentOptionDrawdown.reduce((a, b) => a + b.percentage, 0)
);

export const accessYourSuperPermanentRetirementPreservation_SavedFormData = createSelector(
    getAccessYourSuperPermanentRetirementPreservationState,
    (state: IAccessYourSuperPermanentRetirementPreservationComponentState) => state.savedFormData
)