import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccessYourSuperApplicationsListModel } from 'src/app/model/access-your-super-applications.model';
import { AccessYourSuperApplicationDataModel } from 'src/app/model/access-your-super-form.models';

export const RequestAction = createAction('[Access your super start component] get data', props<{ accountId: number }>());
export const ResponseAction = createAction('[Access your super start component] set data', props<{ payload: AccessYourSuperApplicationsListModel }>());

export const CancelApplicationAction = createAction('[Access your super start component] cancel application', props<{accountId:number, applicationId:number}>());
export const MoveApplicationToCancelledAction = createAction('[Access your super start component] move application to cancelled list', props<{applicationId:number}>());
export const SetApplicationToCancelledAction = createAction('[Access your super start component] set applicationToCancelled');

export const RequestContinueApplicationAction = createAction('[Access your super start component] request continue application', props<{accountId:number, applicationId:number, draftApplication:boolean}>())
export const ContinueApplicationAction = createAction('[Access your super start component] continue application', props<{model:AccessYourSuperApplicationDataModel}>());

export const ResetComponentStateAction = createAction('[Access your super start component] reset component state')

export const UpdateSubmittedApplicationAction = createAction('[Access your super start component] update submitted application', props<{model:AccessYourSuperApplicationDataModel}>())