<div id="maintenance-container" class="d-flex ">
  <div class="d-flex w-100 content-wrapper" appanimatecss="{{'LOGIN_VIEW.ANIMATE_CONTENT_WRAPPER' | translate}}">
    <div class="left-side">
      <div class="left-side-content">
        <video *ngIf="videoUrl" class="video" autoplay="" loop="" playsinline="" muted="true">
          <source [src]="videoUrl" type="video/mp4">
        </video>
      </div>

    </div>
    <div class="right-side flex-fill d-flex align-items-center">
      <div class="backdrop"></div>

      <div appanimatecss="{{'LOGIN_VIEW.ANIMATE_WRAPPER' | translate}}" class="wrapper">
        <div class="p-3 ">
          <a target="_blank" href="{{'website' | tenantconfig | async }}">
            <div class="logo"></div>
          </a>

          <div class="page-title">

          </div>
          <div class=" d-flex flex-row w-100 ">

            <div class="flex-fill d-flex flex-column w-100">
              <h1 class="card-title mb-1 ">There seems to be an issue with your account.</h1>
              <p>Please contact us at <a href="tel:{{'phoneNumber' | tenantconfig | async }}">{{'phoneNumber' |
                  tenantconfig | async }}</a> or <a href="mailto:{{'email' | tenantconfig | async }}">{{'email' |
                  tenantconfig | async }}</a>. </p>
              <app-button class="invert-btn mr-3" title="Return to Login" icon="west"
                (onClick)="onSubmitClick()"></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
