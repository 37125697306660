import { AppState } from 'src/app/store/app.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAccountFeesState } from './state';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const accountFees = createSelector(memberPortalState, (state) => state.accountFeesState);

export const accountFees_Model = createSelector(
  accountFees,
  (state: IAccountFeesState) => state.model
);
