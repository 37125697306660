
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, required, requiredTrue } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction, SaveFormDataAction, SetFullAmountRadioOptionsAction, SetTaxOptionsAction } from './actions';
import { AccessYourSuperPensionCommutationsForm, IAccessYourSuperPensionCommutationsComponentState } from './state';

export const state: IAccessYourSuperPensionCommutationsComponentState = {
  model: null,
  form: createFormGroupState('AccessYourSuperPensionCommutationForm', new AccessYourSuperPensionCommutationsForm()),
  fullAmountRadioOptions: null,
  taxOptions: null,
  savedFormData: null,
};



export const validatePensionCommutationsForm = updateGroup<AccessYourSuperPensionCommutationsForm>({
  paymentType: (state, parentState) => {
    if (parentState.value.paymentType === 0 && parentState.value.isTtr && !parentState.value.isPermanentlyRetired) {
      const ttrIsPermRetiredValidator = () => {
        const errors = {};

        var totalBalance = parentState.value.investmentOptionDrawdown.reduce((a, b) => a + b.amount, 0);
        totalBalance = Math.round(totalBalance * 1000) / 1000;
        var fullAmountPossible = parentState.value.unrestrictedNonPreservedAmount === totalBalance;

        if (!fullAmountPossible) {
          errors['customError'] = "You must select a partial amount thats less than or equal to your unrestricted non-preserved if you are not currently permanently retired.";
        }

        return errors;
      }
      //TODO: Uncomment this validation in PD-5188 and remove validation below once Acurity fixes account components
      // state = validate(state, required, ttrIsPermRetiredValidator)
      state = validate(state, required)
    }
    else {
      state = validate(state, required);
    }

    return state;
  },
  fullAmountSelection: (state, parentState) => {
    if (parentState.value.paymentType === 0) {
      state = validate(state, required)
    }
    return state;
  },
  partialAmountSpecified: (state, parentState) => {
    if (parentState.value.paymentType === 1) {
      // if account is not ttr, or if it is ttr and they are perm retired, amount needs to be less than total balance
      if (!parentState.value.isTtr || (parentState.value.isTtr && parentState.value.isPermanentlyRetired)) {
        var totalBalance = parentState.value.investmentOptionDrawdown.reduce((a, b) => a + b.amount, 0);
        state = validate(state, required, greaterThan(0), lessThanOrEqualTo(totalBalance))
      }
      // otherwise amount needs to be less than unrestricted non preserved amount
      else {

        //TODO: Uncomment this validation in PD-5188 and remove validation below once Acurity fixes account components
        // if (parentState.value.unrestrictedNonPreservedAmount > 0){
        //   // state = validate(state, required, greaterThan(0), lessThanOrEqualTo(parentState.value.unrestrictedNonPreservedAmount))
        // }
        // else{
        //   const setCustomUnrpError = () => {
        //     const errors = {};
        //     errors['customError'] = 'You are currently not eligible to commute your pension since you have not permanently retired and have no unrestricted non preserved (URNP) balance.'
        //     return errors;
        //   }
        //   state = validate(state, required, greaterThan(0), setCustomUnrpError)
        // }

        state = validate(state, required, greaterThan(0))
      }
    }
    return state;
  },
  isPermanentlyRetired: (state, parentState) => {
    if (parentState.value.isTtr) {
      state = validate(state, required);
    }
    return state;
  },
  partialWithdrawalSelection: (state, parentState) => {
    if (parentState.value.paymentType === 1) {
      state = validate(state, required)
    }
    return state;
  },
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      model: null,
      form: createFormGroupState('AccessYourSuperPensionCommutationsForm', new AccessYourSuperPensionCommutationsForm()),
      savedFormData: null
    }
  }),
  on(SetFullAmountRadioOptionsAction, (state, { payload }) => {
    return {
      ...state,
      fullAmountRadioOptions: payload
    }
  }),
  on(SetTaxOptionsAction, (state, { payload }) => {
    return {
      ...state,
      taxOptions: payload
    }
  }),
  on(SavedFormDataAction, (state, { }) => {
    return {
      ...state,
      savedFormData: new Date()
    }
  })
);

const editPensionCommutationsReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validatePensionCommutationsForm
)

export function accessYourSuperPensionCommutationsComponentReducer(state: any | undefined, action: Action) {
  return editPensionCommutationsReducerFormState(state, action);
}
