import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "@ifaa-components/ui-components";
import { select, Store } from "@ngrx/store";
import { SetErrorsAction, SetValueAction } from "ngrx-forms";
import { pairwise } from "rxjs/operators";
import { AccessYourSuperEmployerDetailsModel, AccessYourSuperEmployerDetailsSubmissionModel } from "src/app/model/access-your-super-form.models";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { SetCurrentStepAction } from "../access-your-super-form/actions";
import { ResetComponentStateAction, SaveLastEmployerDetailsAction } from "./actions";
import { accessYourSuperLastEmployer_Form, accessYourSuperLastEmployer_LastEmployerDetailsSaved } from "./selector";

@Component({
    selector: 'app-access-your-super-last-employer',
    templateUrl: './access-your-super-last-employer.component.html',
    styleUrls: ['./access-your-super-last-employer.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class AccessYourSuperLastEmployerComponent extends ComponentBase implements OnInit, OnDestroy {
    @Output() onLastEmployerDetailsSaved: EventEmitter<any> = new EventEmitter();

    @Input() set data (value:AccessYourSuperEmployerDetailsModel){
        this.model=value;
    }
    @Input() set nextStep (value:number){
        this.step = value;
    }
    @Input() set applicationId (value:number){
        this.id = value;
    }
    @Input() set account (value:number){
        this.accountId = value;
    }
    @Input() set canGoBack (value:boolean){
        this.showBackButton = value;
    }
    @Input() set wording (value:{[key:string]:string}){
        this.finalContTooltip = value?.lastEmployerContributionTooltip;
    }

    form$ = this.store.pipe(select(accessYourSuperLastEmployer_Form));
    lastEmployerDetailsSaved$ = this.store.pipe(select(accessYourSuperLastEmployer_LastEmployerDetailsSaved));

    model:AccessYourSuperEmployerDetailsModel= null;
    step:number= null;
    id:number= null;
    accountId:number= null;
    payload:AccessYourSuperEmployerDetailsSubmissionModel = null;
    maxDate:Date = new Date();
    showBackButton:boolean = false;

    finalContTooltip:string = null;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        // update form values with data from state if it exists when the component loads
        if (this.model){
            var helper = new Helper();
            var form = await helper.getValue(this.form$);

            this.store.dispatch(new SetValueAction(form.controls.lastEmployerName.id, this.model.lastEmployerName))

            // if the values are actually set, assign them to the form values
            if (this.model.stillWithEmployer || this.model.stillWithEmployer === false){
                this.store.dispatch(new SetValueAction(form.controls.stillWithEmployer.id, this.model.stillWithEmployer));
            }
            if (this.model.finishDate){
                var date = new Date(this.model.finishDate);
                var dateFormatted = date.toISOString().split('T')[0]
                this.store.dispatch(new SetValueAction(form.controls.finishDate.id, dateFormatted));
            }
            if (this.model.waitingOnFinalContribution || this.model.waitingOnFinalContribution === false){
                this.store.dispatch(new SetValueAction(form.controls.waitingOnFinalContribution.id, this.model.waitingOnFinalContribution));
            }
        }

        // subscription used to reset the form validation when required
        this.sub = this.form$.pipe(pairwise()).subscribe(form => {
            if (form){
                // If the member has swapped their answer to first question from no back to yes, reset the form validation
                if (!form[0].value.stillWithEmployer && form[1].value.stillWithEmployer){
                    this.store.dispatch(new SetErrorsAction(form[1].controls.finishDate.id, {}));
                    this.store.dispatch(new SetErrorsAction(form[1].controls.waitingOnFinalContribution.id, {}));
                }
            }
        })


        // if x has a value, the details have been saved in the backend therefore we can emit saved data to parent component
        // and go next step
        this.sub = this.lastEmployerDetailsSaved$.subscribe(x => {
            if (x){
                this.onLastEmployerDetailsSaved.emit(this.payload);
                this.dispatch(this.store, SetCurrentStepAction({nextStep:this.step}))
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction())
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }

    async goNextStep(){
        var helper = new Helper();
        var form = await helper.getValue(this.form$);

        this.payload = {
            lastEmployerName:form.value.lastEmployerName,
            stillWithEmployer:form.value.stillWithEmployer,
            finishDate: !form.value.stillWithEmployer && form.value.finishDate !== "" ? new Date(form.value.finishDate) : null,
            waitingOnFinalContribution: !form.value.stillWithEmployer ? form.value.waitingOnFinalContribution : null,
            currentStep: this.step
        };

        this.dispatch(this.store, SaveLastEmployerDetailsAction({accountId:this.accountId, applicationId:this.id, lastEmployerDetails:this.payload}))
    }

    // The back button on the last employer details will always go to available balance component
    goBack(){
        this.dispatch(this.store, SetCurrentStepAction({nextStep:3}))
    }
}
