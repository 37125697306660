import { FormGroupState } from "ngrx-forms";

export interface ISevereFinancialHardshipFinancialDetailsV1ComponentState {
    form: FormGroupState<SfhFinancialDetailsFormV1>;    
}

export class SfhFinancialDetailsFormV1 {
    // Assets
    assetsBankBalance: number = 0;
    assetsOwnHome: number = 0;
    assetsInvestmentProperties: number = 0;
    assetsSharesInvestments: number = 0;
    assetsVehicles: number = 0;
    assetsHomeContents: number = 0;
    assetsOtherAssets: number = 0;
    assetsOtherAssetsDetails: string = "";

    // Liabilities
    liabilitiesHomeLoanRepayments: number = 0;
    liabilitiesInvestmentLoanRepayments: number = 0;
    liabilitiesPersonalLoanRepayments: number = 0;
    liabilitiesCreditCardRepayments: number = 0;
    liabilitiesVehicleRepayments: number = 0;
    liabilitiesOtherDebts: number = 0;
    liabilitiesOtherDebtsDetails: string = "";

    // Weekly Income
    ownWeeklyIncome: number = 0;
    partnersWeeklyIncome: number = 0;
    dependantsWeeklyIncome: number = 0;
    weeklyIncomeSupportPayments: number = 0;
    rentalWeeklyIncome: number = 0;
    investmentInterestWeeklyIncome: number = 0;
    otherWeeklyIncome: number = 0;
    otherWeeklyIncomeDetails: string = "";

    // Weekly Expenses
    weeklyRentMortgageExpenses: number = 0;
    weeklyCreditCardExpenses: number = 0;
    weeklyCouncilRateExpenses: number = 0;
    weeklyGroceriesExpenses: number = 0;
    weeklyMedicalExpenses: number = 0;
    weeklyVehicleExpenses: number = 0;
    weeklyElectricityExpenses: number = 0;
    weeklyGasExpenses: number = 0;
    weeklyWaterExpenses: number = 0;
    weeklyPhoneExpenses: number = 0;
    weeklyInternetExpenses: number = 0;
    weeklyInsuranceExpenses: number = 0;
    weeklyEducationExpenses: number = 0;
    weeklyOtherExpenses: number = 0;
    weeklyOtherExpensesDetails: string = "";

    // Renegotiated loans question
    attemptedRenegotiateLoans: boolean = false;
    attemptedRenegotiateLoansDetails: string = "";
}