import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, of } from 'rxjs';
import { MemberPensionService } from 'src/app/services/member-pension.service';

@Injectable()
export class PensionChangePaymentScheduleEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private service: MemberPensionService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.GetPaymentScheduleRequestAction),
    switchMap((action: any) => this.service.getPaymentScheduleData(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.GetPaymentScheduleResponseAction({ scheduleData: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitPaymentScheduleRequestAction),
    switchMap((action: any) => this.service.savePaymentScheduleData(action.accountId, action.submissionData).pipe(
      map((data: any) => {
        return ActionsList.SubmitPaymentScheduleResponseAction();
      }),
      catchError(err => {
        return of(ActionsList.SubmitPaymentScheduleErrorResponseAction());
      })
    )),
  ));
}
