import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.states';
import { commonReducer } from './common/common.reducer'
import { changePasswordReducer } from '../views/change-password/reducer'
import { takeMeWithYouReducer } from '../views/take-me-with-you-view/reducer';

export const reducers: ActionReducerMap<AppState> = {
  commonState: commonReducer,
  changePasswordState: changePasswordReducer,
  takeMeWithYouState: takeMeWithYouReducer,
};
