import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { CIRTState } from '../../store/cirt.states';
import { jetcoClaimAmount_Amount } from '../../components/jetco-claim-amount/selectors';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';

@Component({
  selector: 'app-tenant-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class TenantDashboardComponent extends ComponentBase implements OnInit, OnDestroy {

  showQuestionnaire$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  jetco_amount$ = this.cirtstore.pipe(select(jetcoClaimAmount_Amount));


  memberProfileSummaryFeatureToggle = FeatureToggleName.member.memberProfileSummary.view;
  currentEmployerSummaryFeatureToggle = FeatureToggleName.member.account.currentEmployer.view;
  jetcoClaimSummaryFeatureToggle = FeatureToggleName.member.account.JetcoClaim.view;
  accountBalWithGraphFeatureToggle = FeatureToggleName.member.account.accountBalWithGraph.view;
  employerBalancesSummaryFeatureToggle = FeatureToggleName.member.account.employerBalances.view;


  constructor(public store: Store<AppState>,
    public cirtstore: Store<CIRTState>,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
