import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { Helper } from "@ifaa-components/ui-components";
import { SfhFinancialDetailsFormV1 } from "../../../access-your-super-custom-sections/access-your-super-severe-financial-hardship/financialDataFormVersions/v1/state";

@Component({
    selector: 'sfh-financial-details-review-v1',
    templateUrl: './severe-financial-hardship-financial-details-review-V1.component.html',
    styleUrls: ['./severe-financial-hardship-financial-details-review-V1.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class SfhFinancialDetailsReviewV1Component extends ComponentBase implements OnInit, OnDestroy {

    @Input() set data(value: SfhFinancialDetailsFormV1) {
        this.sfhDataV1 = value;    
    }

    @Input() set labels(value: { [key: string]: string }) {
        this.formLabels = value;
    }

    formLabels: { [key: string]: string } = {};
    sfhDataV1: SfhFinancialDetailsFormV1 = null;
    helper: Helper = new Helper();

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }

    calculateAssetsTotal(values: SfhFinancialDetailsFormV1) {
        return values.assetsBankBalance + values.assetsHomeContents + values.assetsInvestmentProperties + values.assetsOtherAssets + values.assetsOwnHome + values.assetsSharesInvestments + values.assetsVehicles;
    }

    calculateLiabilitiesTotal(values: SfhFinancialDetailsFormV1) {
        return values.liabilitiesCreditCardRepayments + values.liabilitiesHomeLoanRepayments + values.liabilitiesInvestmentLoanRepayments + values.liabilitiesOtherDebts + values.liabilitiesPersonalLoanRepayments + values.liabilitiesVehicleRepayments;
    }

    calculateIncomeTotal(values: SfhFinancialDetailsFormV1) {
        return values.weeklyIncomeSupportPayments + values.ownWeeklyIncome + values.otherWeeklyIncome + values.rentalWeeklyIncome + values.partnersWeeklyIncome + values.dependantsWeeklyIncome + values.investmentInterestWeeklyIncome;
    }

    calculateExpensesTotal(values: SfhFinancialDetailsFormV1) {
        return values.weeklyRentMortgageExpenses + values.weeklyCreditCardExpenses + values.weeklyCouncilRateExpenses + values.weeklyGroceriesExpenses + values.weeklyMedicalExpenses + values.weeklyVehicleExpenses + values.weeklyElectricityExpenses + values.weeklyGasExpenses + values.weeklyWaterExpenses + values.weeklyPhoneExpenses + values.weeklyInternetExpenses + values.weeklyInsuranceExpenses + values.weeklyEducationExpenses + values.weeklyOtherExpenses;
    }
}
