import { FormGroupState } from 'ngrx-forms';
import { AccessYourSuperApplicationsListModel } from 'src/app/model/access-your-super-applications.model';
import { AccessYourSuperApplicationDataModel } from 'src/app/model/access-your-super-form.models';

export interface IAccessYourSuperStartComponentState {
  model: AccessYourSuperApplicationsListModel;
  filter: FormGroupState<AccessYourSuperFilterModel>;
  applicationAccountId: number;
  applicationToCancelled?: Date;
  applicationData:AccessYourSuperApplicationDataModel;
  updatingSubmittedDocuments:boolean;
}

export class AccessYourSuperFilterModel {
  activeOptionButton: string = "Draft";
}