import { Component, Input, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
    selector: 'noi-pending-withdrawal-dialog',
    templateUrl: './noi-pending-withdrawal-dialog.component.html',
    styleUrls: ['./noi-pending-withdrawal-dialog.component.scss']
})

export class NoiPendingWithdrawalDialog extends ComponentBase implements OnInit, OnDestroy {

    tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));
    tenantCode: string = null;
    popoverText: string = "This notice of intent may be considered invalid if the proportion of the tax-free component of your balance that you are claiming a tax deduction on has already been withdrawn or transferred out of your {fund} account. Please contact us for further details on determining the correct amount to claim as a tax deduction to ensure your notice is valid.";

    constructor(
        public store: Store<AppState>,
        public dialogRef: MatDialogRef<NoiPendingWithdrawalDialog>) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();

        this.sub = this.tenantConfig$.subscribe(x => {
            this.tenantCode = x.fundCode.toLowerCase();
            this.popoverText = this.popoverText.replace("{fund}", x.name)
        })
    }


    ngOnDestroy() {
        super.ngOnDestroyBase();
    }

    close(){
        this.dialogRef.close();
    }
}

