import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { RequestAction } from './actions';
import { recentEmployment_Employer } from './selectors';
import { AppState } from 'src/app/store/app.states';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';


@Component({
  selector: 'app-recent-employment-details',
  templateUrl: './recent-employment-details.component.html',
  styleUrls: ['./recent-employment-details.component.scss']
})
export class RecentEmploymentDetailsComponent extends ComponentBase implements OnInit, OnDestroy {
  employer$ = this.store.pipe(select(recentEmployment_Employer));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));  
  recentEmployerDetailDatesFeatureToggle = FeatureToggleName.member.account.recentEmployerDetails.recentEmployerDetailDates.view;

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.screenWidthChange(this.appstore, (width: number) => { });

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}

