import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { noticeOfIntentTaxDeductionForm_ViewTitle } from 'src/app/modules/shared/components/notice-of-intent-tax-deduction-container/selectors';

@Component({
  selector: 'app-notice-of-intent-tax-deduction-view',
  templateUrl: './notifice-of-intent-tax-deduction-view.component.html',
  styleUrls: ['./notifice-of-intent-tax-deduction-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class NoticeOfIntentToClaimTaxDeductionViewComponent extends ComponentBase implements OnInit, OnDestroy {

  viewContainerTitle$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_ViewTitle));

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
