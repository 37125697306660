import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccountComponentsModel } from 'src/app/model/account-component.model';

export const AccountSummaryRequestAction = createAction('[Account Summary] get',
  props<{ accountId: number }>());

export const AccountSummaryResponseAction = createAction('[Account Summary] set deatils',
  props<{ payload: AccountComponentsModel }>());

