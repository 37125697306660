import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Router } from '@angular/router';
import { AccountFeatureToggle } from 'src/app/model/account-feature-toggles.model';
import { commonState_SystemConfig_LimitedServiceToggles } from 'src/app/store/common/common.selectors';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent extends ComponentBase implements OnInit, OnDestroy {

  limitedServiceToggles$ = this.store.pipe(select(commonState_SystemConfig_LimitedServiceToggles));
  toggle: AccountFeatureToggle = null;

  constructor(public store: Store<AppState>,
    private recaptchaV3Service: ReCaptchaV3Service, 
    private router: Router) {
    super();

    this.sub = this.limitedServiceToggles$.subscribe(limitedServiceToggles => {
      // Should always have the blackout toggle when we get to this page due to account guard
      var siteBlackoutToggle = limitedServiceToggles.find(o => o.name === FeatureToggleName.member.maintenance.view)
      if (siteBlackoutToggle){
        this.toggle = siteBlackoutToggle;
      }
      else{
        // Redirect to dashboard as we dont want users going to this page manually
        this.router.navigate(["/"])
      }
    })
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
