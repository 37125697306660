<ng-container *ngIf="form$|async; let form">
    <form [ngrxFormState]="form" class="ml-3 mb-3 d-flex flex-row flex-wrap">
        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h4>Assets</h4>

            <app-entry-number icon="attach_money" title="Savings/Bank account balance"
                [control]="form.controls.assetsBankBalance" [showincremental]="false">
            </app-entry-number>
            <app-entry-number icon="attach_money" title="Own home (Total value)" [control]="form.controls.assetsOwnHome"
                [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Investment properties"
                [control]="form.controls.assetsInvestmentProperties" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Shares/Investments"
                [control]="form.controls.assetsSharesInvestments" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Vehicle(s)" [control]="form.controls.assetsVehicles"
                [tooltip]="formLabels.assetVehicleTooltip" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="House contents" [control]="form.controls.assetsHomeContents"
                [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Other assets" [control]="form.controls.assetsOtherAssets"
                [tooltip]="formLabels.assetOtherAssetTooltip" [showincremental]="false"></app-entry-number>
            <app-entry-textarea *ngIf="form.value.assetsOtherAssets > 0"
                [title]="formLabels.assetProvideDetailsTooltip"
                [control]="form.controls.assetsOtherAssetsDetails"></app-entry-textarea>

            <app-readonly title="TOTAL ASSETS"
                [value]="calculateAssetsTotal(form.value) | currency"></app-readonly>
        </div>

        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h4 class="d-flex flex-row align-items-center">
                Liabilities
                <mat-icon class="ml-2 tooltip-readonly"
                    [matTooltip]="formLabels.liabilitiesTooltip">help_outline</mat-icon>
            </h4>

            <app-entry-number icon="attach_money" title="Home loan repayments"
                [control]="form.controls.liabilitiesHomeLoanRepayments" [showincremental]="false">
            </app-entry-number>
            <app-entry-number icon="attach_money" title="Investment loan repayments"
                [control]="form.controls.liabilitiesInvestmentLoanRepayments" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Personal loan repayments"
                [control]="form.controls.liabilitiesPersonalLoanRepayments" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Credit card debt" [tooltip]="formLabels.liabilitiesCreditCardTooltip"
                [control]="form.controls.liabilitiesCreditCardRepayments" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Vehicle/Car loan repayments"
                [control]="form.controls.liabilitiesVehicleRepayments" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Other debts" [control]="form.controls.liabilitiesOtherDebts"
                [tooltip]="formLabels.liabilitiesOtherDebtsTooltip" [showincremental]="false"></app-entry-number>
            <app-entry-textarea *ngIf="form.value.liabilitiesOtherDebts > 0"
                [title]="formLabels.liabilitiesProvideDetailsTooltip"
                [control]="form.controls.liabilitiesOtherDebtsDetails"></app-entry-textarea>

            <app-readonly title="TOTAL LIABILITIES"
                [value]="calculateLiabilitiesTotal(form.value) | currency"></app-readonly>
        </div>

        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h4 class="d-flex flex-row align-items-center">
                Weekly income
                <mat-icon class="ml-2 tooltip-readonly"
                    [matTooltip]="formLabels.weeklyIncomeTooltip">help_outline</mat-icon>
            </h4>

            <app-entry-number icon="attach_money" title="Your income" [control]="form.controls.ownWeeklyIncome"
                [showincremental]="false">
            </app-entry-number>
            <app-entry-number icon="attach_money" title="Your partner's income"
                [control]="form.controls.partnersWeeklyIncome" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Your dependants income"
                [control]="form.controls.dependantsWeeklyIncome" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Income support payments"
                [control]="form.controls.weeklyIncomeSupportPayments" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Rental income" [control]="form.controls.rentalWeeklyIncome"
                [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Interest from investments"
                [control]="form.controls.investmentInterestWeeklyIncome" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Other income" [control]="form.controls.otherWeeklyIncome"
                [tooltip]="formLabels.weeklyIncomeOtherIncomeTooltip" [showincremental]="false"></app-entry-number>
            <app-entry-textarea *ngIf="form.value.otherWeeklyIncome > 0"
                [title]="formLabels.weeklyIncomeProvideDetailsTooltip"
                [control]="form.controls.otherWeeklyIncomeDetails"></app-entry-textarea>

            <app-readonly title="TOTAL WEEKLY INCOME"
                [value]="calculateIncomeTotal(form.value) | currency"></app-readonly>
        </div>

        <div class="col-12 col-md-6 d-flex flex-column mb-4">
            <h4 class="d-flex flex-row align-items-center">
                Weekly expenses
                <mat-icon class="ml-2 tooltip-readonly"
                    [matTooltip]="formLabels.weeklyExpensesTooltip">help_outline</mat-icon>
            </h4>

            <app-entry-number icon="attach_money" title="Rent/Board/Mortgage repayments"
                [control]="form.controls.weeklyRentMortgageExpenses" [showincremental]="false">
            </app-entry-number>
            <app-entry-number icon="attach_money" title="Minimum credit card and loan repayments"
                [control]="form.controls.weeklyCreditCardExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Council Rates"
                [control]="form.controls.weeklyCouncilRateExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Groceries" [control]="form.controls.weeklyGroceriesExpenses"
                [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Medical/Pharmaceutical"
                [control]="form.controls.weeklyMedicalExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Vehicle/Transport costs"
                [tooltip]="formLabels.weeklyExpensesVehicleCostTooltip"
                [control]="form.controls.weeklyVehicleExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Utilities - Electricity"
                [control]="form.controls.weeklyElectricityExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Utilities - Gas" [control]="form.controls.weeklyGasExpenses"
                [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Utilities - Water"
                [control]="form.controls.weeklyWaterExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Utilities - Phone"
                [control]="form.controls.weeklyPhoneExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Utilities - Internet"
                [control]="form.controls.weeklyInternetExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Insurance (House/Health/Car)"
                [control]="form.controls.weeklyInsuranceExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Education/Childcare"
                [control]="form.controls.weeklyEducationExpenses" [showincremental]="false"></app-entry-number>
            <app-entry-number icon="attach_money" title="Other expenses" [control]="form.controls.weeklyOtherExpenses"
                [tooltip]="formLabels.weeklyExpensesOtherExpensesTooltip" [showincremental]="false"></app-entry-number>
            <app-entry-textarea *ngIf="form.value.weeklyOtherExpenses > 0"
                [title]="formLabels.weeklyExpensesProvideDetailsTooltip"
                [control]="form.controls.weeklyOtherExpensesDetails"></app-entry-textarea>
            <app-readonly title="TOTAL WEEKLY EXPENSES"
                [value]="calculateExpensesTotal(form.value) | currency"></app-readonly>
        </div>

        <div class="question d-flex flex-row description mt-2 pl-2">
            <div>
                <div class="title mb-1 d-flex flex-row align-items-center">
                    {{formLabels.financeDetailsRenegotiateQuestion}}
                </div>
                <div class="custom-control custom-radio answer">
                    <app-entry-radio list="yesNo"
                        [control]="form.controls.attemptedRenegotiateLoans"></app-entry-radio>
                </div>
                <div class="custom-control custom-radio answer">
                    <app-entry-textarea *ngIf="form.value.attemptedRenegotiateLoans" title="Please provide more details regarding reducing your repayments."
                        [control]="form.controls.attemptedRenegotiateLoansDetails"></app-entry-textarea>
                </div>
            </div>
        </div>

    </form>
</ng-container>