import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { mainLayoutReducer } from '../layout/main/reducer'
import { FUTRState } from './futr.states';

export const featureKey = 'futr';

export const reducers = new InjectionToken<ActionReducerMap<FUTRState>>(featureKey, {
  factory: () => ({
    mainLayout: mainLayoutReducer
  })
});
