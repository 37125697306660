import { AddressModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperKiwiSaverDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";

export interface IAccessYourSuperKiwiSaverComponentState {
    model: AccessYourSuperKiwiSaverDetailSectionModel;
    formOne: FormGroupState<AccessYourSuperKiwiSaverFormOne>;
    formTwo: FormGroupState<AccessYourSuperKiwiSaverFormTwo>;
    formThree: FormGroupState<AccessYourSuperKiwiSaverFormThree>;
    savedFormData: Date;
    kiwiSaverStep: number;
}

export class AccessYourSuperKiwiSaverFormOne {
    currentEmployerName: string = '';
    isCurrentEmployerLastAusEmployer:boolean = false;
    lastAusEmployerName: string = '';
    lastAusEmploymentDate: string = '';
    isEmployerConfirmationRequested: boolean = false;
    isEmployerConfirmationRequired: boolean = false;
}

export class AccessYourSuperKiwiSaverFormTwo {
    lastAusResidentialAddress: AddressModel = new AddressModel();
    nzResidentialAddress: AddressModel = {
        lineOne: '',
        lineTwo: '',
        suburb: '',
        postCode: '',
        state: '',
        country: 'New Zealand',
        isRequired: true
      } as AddressModel;
}

export class AccessYourSuperKiwiSaverFormThree {
    nzIrdNumber: string = '';
    kiwiSaverSchemeName: string = '';
    kiwiSaverSchemeRegistrationNumber: string = '';
    kiwiSaverSchemeAccountNumber: string = '';
    schemePostalAddress: AddressModel = {
        lineOne: '',
        lineTwo: '',
        suburb: '',
        postCode: '',
        state: '',
        country: 'New Zealand',
        isRequired: true
      } as AddressModel;
}