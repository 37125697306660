
import { ValidationErrors } from '@angular/forms';
import { createReducer, Action, on } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, ValidationFn, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { BankDetailsModel } from 'src/app/model/bank-details.model';
import { ResetComponentStateAction, SetBankDetailsSavedAction, SetBankNameAction, SetFieldValuesAction } from './actions';
import { AccessYourSuperBankDetailsForm, IAccessYourSuperBankDetailsComponentState } from './state';

export const state: IAccessYourSuperBankDetailsComponentState = {
  form: createFormGroupState('AccessYourSuperRetirementForm', new AccessYourSuperBankDetailsForm()),
  bankDetailsSaved: null
};



export const validateAccessYourSuperBankDetailForm = updateGroup<AccessYourSuperBankDetailsForm>({
  financialInstitutionName: validate(required),
  bankAccountBsb: (state, parentState) => {
    const invalidBsb = () => {
      const errors = {};
      if (!parentState.value.financialInstitutionName || parentState.value.financialInstitutionName === ""){
        errors['invalidBsb'] = 'The BSB entered did not match anything in our system.'
      }
      return errors;
    }
    state = validate(state, required, invalidBsb)
    return state;
  },
  bankAccountName: validate(required),
  bankAccountNumber: (state, parentState) => {
    const validateAccountNumber = () => {
      const errors = {};
      const regex = /^(?!0+$)\d{6,10}$/;
      let isValidAccountNumber = regex.test(parentState.value.bankAccountNumber);
      if(!isValidAccountNumber)
      {
        errors['customError'] = 'Your bank account number must be between 6 - 10 digits.'
        return errors;
      }
    }
    state = validate(state,required, validateAccountNumber)
    return state;
  }
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(SetFieldValuesAction, (state, {bankName, accountName, accountNumber, bsb}) =>{
    return {
      ...state,
      form: setValue(Object.assign(new AccessYourSuperBankDetailsForm(),
        {
          ...state.form.value,
          financialInstitutionName: bankName,
          bankAccountName: accountName,
          bankAccountNumber: accountNumber,
          bankAccountBsb: bsb
        }
      ))(state.form)
    }
  }),
  on(SetBankNameAction, (state, { payload }) => {
    return {
      ...state,
      form: setValue(Object.assign(new AccessYourSuperBankDetailsForm(),
        {
          ...state.form.value,
          financialInstitutionName: payload
        }
      ))(state.form)
    }
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      form: createFormGroupState('AccessYourSuperRetirementForm', new AccessYourSuperBankDetailsForm()),
      bankDetailsSaved: null
    }
  }),
  on(SetBankDetailsSavedAction, (state, {}) => {
    return {
      ...state,
      bankDetailsSaved: new Date()
    }
  })
);

const editAccessYourSuperBankDetailsReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateAccessYourSuperBankDetailForm
)

export function accessYourSuperBankDetailsComponentReducer(state: any | undefined, action: Action) {
  return editAccessYourSuperBankDetailsReducerFormState(state, action);
}
