<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="verified_user">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Insurance' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>

  <div class="page-content" appanimatecss *ngIf="(model$ | async); let data">
    <ng-container *ngIf="!(showQuestionnaire$ | async)">
      <app-component-wrapper icon="admin_panel_settings"
        header="Your insurance cover {{getCoverText(data)}}"
        *featuretoggle="showInsuranceFeatureToggle">
        <div class="d-flex flex-column" slot="start">
          <app-insurance-details></app-insurance-details>
        </div>
      </app-component-wrapper>
    </ng-container>


    <ng-container *ngIf="(showQuestionnaire$ | async)">
      <app-component-wrapper *featuretoggle="showQuestionnaireFeatureToggle" icon="add_task" header="Insurance">
        <div class="d-flex flex-column" slot="start" >
          <app-insurance-pre-questionnaire *ngIf="(showPreQuestionnaire$ | async)"></app-insurance-pre-questionnaire>

          <app-insurance-questionnaire *ngIf="(showStartQuestion$ | async)"></app-insurance-questionnaire>
        </div>

      </app-component-wrapper>
    </ng-container>
  </div>
</div>
