import { TransactionService } from './../../../../services/transaction.service';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { EMPTY } from 'rxjs';

@Injectable()
export class ContributionsLimitsEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private transactionervice: TransactionService) {
  }

  getContributions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.transactionervice.getContributions(action.accountId).pipe(
      map((response: any) => {
        return ActionsList.ResponseAction({ payload: response.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
