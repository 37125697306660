import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState, SetErrorsAction } from "ngrx-forms";
import { Observable } from "rxjs";
import { distinctUntilChanged, pairwise } from "rxjs/operators";
import { AccessYourSuperDrawdownModel } from "src/app/model/access-your-super-custom-sections.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";

@Component({
    selector: 'app-access-your-super-drawdown',
    templateUrl: './access-your-super-drawdown.component.html',
    styleUrls: ['./access-your-super-drawdown.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class AccessYourSuperDrawdownComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set form(value: FormGroupState<AccessYourSuperDrawdownModel>) {
        this.formControl = value;
    }
    @Input() set totalCount(value:number){
        this.total = value;
    }
    @Input() set disclaimer(value:string){
        this.drawdownDisclaimer = value;
    }

    formControl: FormGroupState<AccessYourSuperDrawdownModel> = null;
    total:number = null;
    drawdownDisclaimer:string=null;

    constructor(
        public store: Store<AppState>) {
        super();
    }
    ngOnDestroy(): void {
        super.ngOnDestroyBase();
    }

    async ngOnInit() {
        super.ngOnInitBase();
    }

    trackByFn(index, item) {
        return index;
    }
}
