<div id="bpay-details-container">

  <h4 class=" mb-2 d-flex flex-row align-items-center">Personal contribution BPAY details
    <mat-icon class="ml-2 tooltip-readonly"
      matTooltip="Personal contributions are contributions that you make to your super account from your after-tax income.">help_outline</mat-icon>

  </h4>

  <p class="mb-3 description">
    Below are your unique <span class="bpay-font normal">BPAY</span> details to make personal contributions through your
    bank.
  </p>

  <app-no-items message="No BPAY Details found" *ngIf="!(bpayDetail$ | async)?.billerCode"></app-no-items>

  <div class="d-flex flex-column ">
    <div class="bpay-container fit-content" *ngIf="(bpayDetail$ | async) as bpayDetail">
      <div class="d-flex flex-row">
        <img mat-card-image src="https://sharedassetstorage.blob.core.windows.net/web/bpay_logo.svg"
          class="bpay-image" />
        <div class="bpay-details-container flex-fill">
          <p class="bpay-detail-text">Biller Code: {{bpayDetail.billerCode}}</p>
          <p class="bpay-detail-text">Ref: {{bpayDetail.reference}}</p>
        </div>
      </div>
      <div class="d-flex flex-column bpay-info-text">
        <p class="bpay-detail-text small">Mobile & Internet Banking - BPAY<sup>&reg;</sup></p>
        <p class="bpay-detail-text smaller">Make this payment from your preferred bank account.</p>
      </div>
    </div>


    <div *featuretoggle="spouseBPAYViewFeatureToggle">
      <div class="mt-4" *ngIf="(bpayDetail$ | async) as bpayDetail">

        <h4 class="mt-4 mb-2 d-flex flex-row align-items-center">Spouse contribution BPAY details
          <mat-icon class="ml-2 tooltip-readonly"
            matTooltip="Spouse contributions are contributions that your spouse can make to your super account from their after-tax income. They do not have to be a member of {{(tenantConfig$|async)?.name}} to make contributions to your super account. Share the BPAY details below with your spouse to allow them to make contributions to your {{(tenantConfig$|async)?.name}} account">help_outline</mat-icon>
        </h4>

        <p class="mb-3 description">Your spouse can use below BPAY details to make contributions to your smartMonday account via their bank.</p>

        <div class="bpay-container fit-content">

          <div class="d-flex flex-row">
            <img mat-card-image src="https://sharedassetstorage.blob.core.windows.net/web/bpay_logo.svg"
              class="bpay-image" />
            <div class="bpay-details-container flex-fill">
              <p class="bpay-detail-text">Biller Code: {{bpayDetail.spouseBillerCode}}</p>
              <p class="bpay-detail-text">Ref: {{bpayDetail.reference}}</p>
            </div>
          </div>
          <div class="d-flex flex-column bpay-info-text">
            <p class="bpay-detail-text small">Mobile & Internet Banking - BPAY<sup>&reg;</sup></p>
            <p class="bpay-detail-text smaller">Make this payment from your preferred bank account.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-info mt-4">
    <p class="p-1 info">Please keep in mind your financial institution's processing time-frames if making payment
      via
      BPAY. We
      recommend you allow at least three business days for BPAY.</p>
  </div>
</div>
