import { HelperService } from './../../services/helper.service';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { AccountFeatureTogglesRequestAction, AccountFeatureTogglesResponseAction, IsLoggedInRequestAction, IsLoggedInResponseAction, RequestMemberDataAction, ResponseMemberDataAction, SystemConfigurationRequestAction, SystemConfigurationResponseAction } from './common.actions';
import { WebUserService } from 'src/app/services/webuser.service';
import { EMPTY } from 'rxjs';
import { FeatureToggleService } from 'src/app/services/feature-toggle.service';
import { MembersService } from 'src/app/services/members.service';

@Injectable()
export class CommonEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private userService: WebUserService,
    private service: HelperService,
    private memberService: MembersService,
    private featureToggleService: FeatureToggleService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(RequestMemberDataAction),
    switchMap((action: any) => this.memberService.getView().pipe(
      map((data: any) => {
        return ResponseMemberDataAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getSystemConfig$ = createEffect(() => this.actions$.pipe(
    ofType(SystemConfigurationRequestAction),
    switchMap((action: any) => this.service.systemConfiguration().pipe(
      map((data: any) => {
        return SystemConfigurationResponseAction({ payload: data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  isLoggedIn$ = createEffect(() => this.actions$.pipe(
    ofType(IsLoggedInRequestAction),
    switchMap((action: any) => this.userService.isLoggedIn().pipe(
      map((data: any) => {
        return IsLoggedInResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getAccountFeatureToggles$ = createEffect(() => this.actions$.pipe(
    ofType(AccountFeatureTogglesRequestAction),
    switchMap((action:any) => this.featureToggleService.getAccountFeatureToggles(action.accountId).pipe(
      map((data:any) => {
        return AccountFeatureTogglesResponseAction({payload:data.data});
      }),
      catchError(err => {
        return EMPTY;
      })
    ))
  ))

}
