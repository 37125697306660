import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import * as ActionsList from './actions';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccessYourSuperFormComponentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: AccessYourSuperService) {
  }

  getApplicationTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.GetApplicationTypesRequestAction),
    switchMap((action: any) => this.service.getApplicationTypes(action.accountType, action.accountId).pipe(
      map((data: any) => {
        return ActionsList.GetApplicationTypesResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  updateCurrentStep$ = createEffect(() =>this.actions$.pipe(
    ofType(ActionsList.UpdateCurrentStepAction),
    switchMap((action: any) => this.service.updateCurrentStep(action.accountId, action.currentStep, action.applicationId).pipe(
      map(() => {
        return ActionsList.CurrentStepUpdatedAction();
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
