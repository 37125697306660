import { KeyValueModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { AccessYourSuperPaymentDetailSectionModel, AccessYourSuperPaymentDetailSubmissionModel } from "src/app/model/access-your-super-custom-sections.model";

export const ResetComponentStateAction = createAction('[Access your super ceased paid employment component] reset state');
export const RequestAction = createAction('[Access your super ceased paid employment component] get data', props<{ accountId: number, applicationId:number, applicationTypeId:number }>());
export const ResponseAction = createAction('[Access your super ceased paid employment component] set data', props<{ payload: AccessYourSuperPaymentDetailSectionModel }>());

export const SetTaxOptionsAction = createAction('[Access your super ceased paid employment component] set tax options', props<{payload:KeyValueModel[]}>());


export const SaveFormDataAction = createAction('[Access your super ceased paid employment component] save form data', props<{accountId:number, payload:AccessYourSuperPaymentDetailSubmissionModel}>());
export const SavedFormDataAction = createAction('[Access your super ceased paid employment component] saved form data');
