export const environment = {
  production: false,
  apiUrl: 'https://localhost:8001/api',
  code: '',
  dashboard: '/dashboard',
  bugsnagApiKey: '691cd6149de59c8693b21e6e9826e173',
  bugsnagEnv: 'Development',
  recaptchaApi: '6LfeW9saAAAAAPWtOiD0ly77pxy3mxibLWJd2-yH',
  ga: '',
  buttonTitleTextTransform:'UpperCase',
  titleTextTransform:'SentenceCase',
  greenIdEnv: 'test',
  greenIdCode: '9nU-Se3-XMt-fbU',
  greenIdVerifiedIcon: 'fingerprint',
  greenIdNotVerifiedIcon: 'fingerprint',
  entryFileAttachedIcon: '',
  entryFileDefaultIcon: '',
  underlyingInvestmentsChartColors: undefined,
  b2cClientId: '',
  b2cPolicySignIn: '',
  b2cSignInAuthority: '',
  b2cPolicyElevatedSignIn: '',
  b2cElevatedSignInAuthority: '',
  b2cPolicyAdviserElevatedSignIn: '',
  b2cAdviserElevatedSignInAuthority: '',
  b2cAuthorityDomain: '',
  b2cReadScope: '',
  b2cWriteScope: '',
  b2cApiEndpoint: 'https://localhost:8001/api/**',
  b2cLogoutUrl: '/',
  postLogoutRedirectUri: '/',
  takeMeWithYouUrl: 'take-me-with-you',
  underlyingInvestment: 'underlying-investment',
  showButtonIcon: true
};
