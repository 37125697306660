import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { InsuranceQuestionnaireService } from 'src/app/services/insurance-questionnaire.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class InsuranceQuestionnaireEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private service: InsuranceQuestionnaireService) {
  }

  showQuestionnaire$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.ShowQuestionnaireRequestAction),
    switchMap((action: any) => this.service.showQuestionnaire(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ShowQuestionnaireResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
