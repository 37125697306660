import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class TakeMeWithYouService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getChoiceOfFund(accountId: number): Observable<any> {
    const params = new HttpParams()
      .set('accountId', accountId.toString())
    return this.http.get(environment.apiUrl + `/member-portal/TakeMeWithYou/${accountId}/choice-of-fund`, { headers: this.headers(), params: params });
  }


}
