import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { PersonalContributionService } from 'src/app/services/personal-contrubution.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class PersonalContributionEditEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private service: PersonalContributionService) {
  }

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.submitData(action.accountId, action.payload).pipe(
      map((data: any) => {
        this.service.showOk(data.message);
        return ActionsList.SubmitResponseAction({ payload: data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  bankDetails$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.BankDetailsRequestAction),
    switchMap((action: any) => this.service.bankDetails().pipe(
      map((data: any) => {
        console.log(data)
        return ActionsList.BankDetailsResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
