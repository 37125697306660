import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { SfhFinancialDetailsFormV1 } from "./state";
import { distinctUntilChanged, } from "rxjs";
import { accessYourSuperForm_ApplicationModel } from "../../../../access-your-super-form/selector";
import { Helper } from "@ifaa-components/ui-components";
import { UpdateDocumentRequiredAction } from "../../../../access-your-super-form/actions";
import { AccessYourSuperDocumentTypeEnum } from "src/app/enums/access-your-super-document-type.enum";
import { severeFinancialHardshipFinancialDetailsV1_Form, severeFinancialHardshipFinancialDetailsV1_FormValid } from "./selector";
import { SetFormDataAction } from "./actions";

@Component({
    selector: 'sfh-financial-details-v1',
    templateUrl: './sfh-financial-details-V1.component.html',
    styleUrls: ['./sfh-financial-details-V1.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class SfhFinancialDetailsV1Component extends ComponentBase implements OnInit, OnDestroy {
    @Output() onFormValid: EventEmitter<any> = new EventEmitter();

    @Input() set data(value: SfhFinancialDetailsFormV1) {
        this.sfhDataV1 = value;
    }

    @Input() set labels(value: { [key: string]: string }) {
        this.formLabels = value;
    }

    applicationModel$ = this.store.pipe(select(accessYourSuperForm_ApplicationModel));
    form$ = this.store.pipe(select(severeFinancialHardshipFinancialDetailsV1_Form));
    formValid$ = this.store.pipe(select(severeFinancialHardshipFinancialDetailsV1_FormValid));

    formLabels: { [key: string]: string } = {};
    helper: Helper = new Helper();
    sfhDataV1: SfhFinancialDetailsFormV1 = null;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        this.dispatch(this.store, SetFormDataAction({ financialDetails: this.sfhDataV1 }))

        // Need to listen to the rental field to trigger living arrangement doc to mandatory or not
        this.sub = this.form$
            .pipe(distinctUntilChanged((x, y) => x.value.weeklyRentMortgageExpenses === y.value.weeklyRentMortgageExpenses))
            .subscribe(async x => {
                if (x.value?.weeklyRentMortgageExpenses >= 0) {
                    var applicationData = await this.helper.getValue(this.applicationModel$)
                    var livingArrangementDocIndex = applicationData.applicationDocuments.findIndex(x => x.documentTypeId === AccessYourSuperDocumentTypeEnum.LivingArrangement);
                    if (x.value.weeklyRentMortgageExpenses > 0 && livingArrangementDocIndex !== -1 && !applicationData.applicationDocuments[livingArrangementDocIndex].isDocumentRequired) {
                        this.dispatch(this.store, UpdateDocumentRequiredAction({ documentTypeId: AccessYourSuperDocumentTypeEnum.LivingArrangement, isRequired: true }))
                    }
                    else if (x.value.weeklyRentMortgageExpenses === 0 && livingArrangementDocIndex !== -1 && applicationData.applicationDocuments[livingArrangementDocIndex].isDocumentRequired) {
                        this.dispatch(this.store, UpdateDocumentRequiredAction({ documentTypeId: AccessYourSuperDocumentTypeEnum.LivingArrangement, isRequired: false }))
                    }
                }
            })

        // Emit if form is valid or not and form values to parent component
        this.sub = this.formValid$
            .pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)))
            .subscribe(x => {
                if (x) {
                    this.onFormValid.emit(x);
                }
            })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }

    calculateAssetsTotal(values: SfhFinancialDetailsFormV1) {
        return values.assetsBankBalance + values.assetsHomeContents + values.assetsInvestmentProperties + values.assetsOtherAssets + values.assetsOwnHome + values.assetsSharesInvestments + values.assetsVehicles;
    }

    calculateLiabilitiesTotal(values: SfhFinancialDetailsFormV1) {
        return values.liabilitiesCreditCardRepayments + values.liabilitiesHomeLoanRepayments + values.liabilitiesInvestmentLoanRepayments + values.liabilitiesOtherDebts + values.liabilitiesPersonalLoanRepayments + values.liabilitiesVehicleRepayments;
    }

    calculateIncomeTotal(values: SfhFinancialDetailsFormV1) {
        return values.weeklyIncomeSupportPayments + values.ownWeeklyIncome + values.otherWeeklyIncome + values.rentalWeeklyIncome + values.partnersWeeklyIncome + values.dependantsWeeklyIncome + values.investmentInterestWeeklyIncome;
    }

    calculateExpensesTotal(values: SfhFinancialDetailsFormV1) {
        return values.weeklyRentMortgageExpenses + values.weeklyCreditCardExpenses + values.weeklyCouncilRateExpenses + values.weeklyGroceriesExpenses + values.weeklyMedicalExpenses + values.weeklyVehicleExpenses + values.weeklyElectricityExpenses + values.weeklyGasExpenses + values.weeklyWaterExpenses + values.weeklyPhoneExpenses + values.weeklyInternetExpenses + values.weeklyInsuranceExpenses + values.weeklyEducationExpenses + values.weeklyOtherExpenses;
    }
}
