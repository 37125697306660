<ng-container *ngIf="relevantOptions.length > 0">
    <div class="hover-data">
      <table class="{{'VIEWS.Global.table-default-style' | translate}}">
        <thead>
          <tr>
            <th scope="col">Option</th>
            <th scope="col">%</th>
            <th *ngIf="showDollarCol" scope="col">$</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let hiddenItem of relevantOptions; index as j">
            <tr>
              <td scope="col">{{hiddenItem.option}}</td>
              <td scope="col">{{hiddenItem.percentage}}%</td>
              <td *ngIf="showDollarCol" scope="col">{{hiddenItem.amount | currency}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>
