import { MemberModel } from './../../../../model/member.model';
import { superannuationProviderSearch_Form, superannuationProviderSearch_List } from './selectors';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
// import { RequestAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { ClearSearchAction, RequestAction, SelectOptionAction } from './actions';
import { debounceTime } from 'rxjs/operators';
import { SuperannuationProviderItemModel } from 'src/app/model/rollin-edit.model';

@Component({
  selector: 'app-superannuation-provider-search',
  templateUrl: './superannuation-provider-search.component.html',
  styleUrls: ['./superannuation-provider-search.component.scss']
})
export class SuperannuationProviderSearchComponent extends ComponentBase implements OnInit, OnDestroy {

  @Output() onSelected = new EventEmitter<SuperannuationProviderItemModel>();

  form$ = this.store.pipe(select(superannuationProviderSearch_Form));
  list$ = this.store.pipe(select(superannuationProviderSearch_List));
  @Input() hidesearch: boolean = false;

  q: string = null;

  constructor(public store: Store<MemberPortalSharedState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.form$.pipe(debounceTime(500)).subscribe(x => {
      var regex = /^[^\s].*[^\s]$/;
      
      if (this.q != x.value.q && regex.test(x.value.q)) {
        this.q = x.value.q;
        this.dispatch(this.store, RequestAction({ q: this.q }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
  onSelectOption(item: SuperannuationProviderItemModel) {
    this.store.dispatch(SelectOptionAction({ payload: item }));
    this.onSelected.emit(item);
  }

  onCloseClick() {
    this.store.dispatch(ClearSearchAction());
  }

}
