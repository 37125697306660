<div id="unit-prices-view-container">

  <apx-chart class="apx-chart" #chart2 [series]="chartOptions.series" [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels"
    [grid]="chartOptions.grid" [stroke]="chartOptions.stroke" [title]="chartOptions.title"
    [tooltip]="chartOptions.tooltip"></apx-chart>


    <table class="table {{'VIEWS.Global.table-default-style' | translate}} table-sm ">
      <thead>
        <tr>
          <th>Date</th>
          <th>Unit Price</th>
        </tr>
      </thead>
      <tbody *ngFor="let data of (model$ | async)?.data">
        <tr>
          <td>{{data.x | date:'dd/MM/yyyy'}}</td>
          <td>{{data.y | currency}}</td>
        </tr>
      </tbody>
    </table>
</div>
