import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IAccessYourSuperFormComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperFormState = createSelector(memberPortalState, (state) => state.accessYourSuperFormComponentState);

export const accessYourSuperForm_CurrentStep = createSelector(
    getAccessYourSuperFormState,
    (state: IAccessYourSuperFormComponentState) => state.currentStep
);

export const accessYourSuperForm_ApplicationId = createSelector(
    getAccessYourSuperFormState,
    (state: IAccessYourSuperFormComponentState) => state.applicationId
);

export const accessYourSuperForm_ApplicationModel = createSelector(
    getAccessYourSuperFormState,
    (state: IAccessYourSuperFormComponentState) => state.applicationData
);

export const accessYourSuperForm_CurrentStepUpdated = createSelector(
    getAccessYourSuperFormState,
    (state: IAccessYourSuperFormComponentState) => state.currentStepUpdated
);

export const accessYourSuperForm_ApplicationTypes = createSelector(
    getAccessYourSuperFormState,
    (state: IAccessYourSuperFormComponentState) => state.applicationTypes
);