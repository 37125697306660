import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperRetirementComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperRetirementState = createSelector(memberPortalState, (state) => state.accessYourSuperRetirementComponentState);

export const accessYourSuperRetirement_Model = createSelector(
    getAccessYourSuperRetirementState,
    (state: IAccessYourSuperRetirementComponentState) => state.model
);

export const accessYourSuperRetirement_Form = createSelector(
    getAccessYourSuperRetirementState,
    (state: IAccessYourSuperRetirementComponentState) => state.form
)

export const accessYourSuperRetirement_FullAmountRadioOptions = createSelector(
    getAccessYourSuperRetirementState,
    (state: IAccessYourSuperRetirementComponentState) => state.fullAmountRadioOptions
)

export const accessYourSuperRetirement_CurrentTotalPercent = createSelector(
    getAccessYourSuperRetirementState,
    (state: IAccessYourSuperRetirementComponentState) => state.form.value.investmentOptionDrawdown.reduce((a, b) => a + b.percentage, 0)
);

export const accessYourSuperRetirement_SavedFormData = createSelector(
    getAccessYourSuperRetirementState,
    (state: IAccessYourSuperRetirementComponentState) => state.savedFormData
)