import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { LodgeClaimService } from '../../services/lodge-claim.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class LodgeClaimEffects {
  constructor(public toastr: ToastrService,
    private actions$: Actions,
    private service: LodgeClaimService) {
  }

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.submit(action.accountId, action.payload).pipe(
      map((data: any) => {
        if (data.message)
          this.toastr.success(data.message);

        return ActionsList.SubmitResponseAction({ payload: data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  list$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.EmployerListRequestAction),
    switchMap((action: any) =>
      this.service.getEmployers(action.accountId).pipe(
        map((data: any) => {
          return ActionsList.EmployerListResponseAction({ payload: data.data });
        }),
        catchError(err => {
          return EMPTY;
        })
      )),
  ));

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.MemberDataRequestAction),
    switchMap((action: any) => this.service.checkForMemberTfn(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.MemberDataResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
