<div class=" docs" *ngIf="screenWidth >= 992">
  <div class="d-flex flex-row h-100">
    <div class="col-md-4">
      <app-no-items message="No document found" *ngIf="(list$ |async)?.length == 0"></app-no-items>

      <ul class="list-group">
        <li *ngFor="let item of list$ | async"
          class="list-group-item list-group-item-action click d-flex align-items-center" (click)="selectPDF(item)">
          <mat-icon class="mr-3">picture_as_pdf</mat-icon>
          <div class=" flex-grow-1"><b>{{item.title}}</b>
            <div class="row">
              <div class="col-12">
                <small class="text-muted ">Created on: {{item.correspondenceDate | date:'dd/MM/yyyy'}}</small>
              </div>
            </div>
          </div>
          <mat-icon class="float-right arrow">keyboard_arrow_right</mat-icon>
        </li>
      </ul>
    </div>
    <div class="col-md-8 bg-light d-flex">
      <app-pdf-viewer url="{{selectedFile$ | async}}" class="flex-fill"></app-pdf-viewer>
      <mat-icon class="no-data-icon" *ngIf="!(selectedFile$ | async)">insert_drive_file</mat-icon>
    </div>
  </div>
</div>

<div class=" docs" *ngIf="screenWidth < 992">
  <div class="row h-100">
    <div class="col-12">
      <app-no-items message="No document found" *ngIf="(list$ |async)?.length == 0"></app-no-items>
      <ul class="p-0">
        <li *ngFor="let item of list$ | async;index as i"
          class="card shadow-sm m-1 p-2 click d-flex align-items-center flex-row w-100" (click)="selectPDF(item)">
          <mat-icon class="mr-3">picture_as_pdf</mat-icon>
          <div class=" flex-grow-1"><b>{{item.title}}</b>
            <div class="row">
              <div class="col-12">
                <small class="text-muted ">Created on: {{item.correspondenceDate | date:'dd/MM/yyyy'}}</small>
              </div>
            </div>
          </div>
          <mat-icon class="float-right arrow" *ngIf='downloading != i'>save_alt</mat-icon>
          <mat-spinner [diameter]="30" class="float-right arrow" *ngIf='downloading == i'></mat-spinner>
        </li>
      </ul>
    </div>

  </div>

</div>



<ng-template #myTemplate>
  <mat-dialog-content>
    <div class="mobile-pdf-view" *ngIf="screenWidth < 992" [hidden]="!(selectedFile$ | async)">

      <mat-toolbar class="p-2 float-right">

        <span class="ml-3 flex-fill">PDF Viewer</span>

        <app-button title="Close" icon="close" mat-dialog-close>
        </app-button>

      </mat-toolbar>

      <app-pdf-viewer url="{{selectedFile$ | async}}"></app-pdf-viewer>

    </div>
  </mat-dialog-content>


</ng-template>
