import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class ChatViewEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: ChatService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.details().pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError((err) => {
        return EMPTY;
      })
    )),
  ));

  chatClosed$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestChatClosedAction),
    switchMap((action: any) => this.service.closeChat().pipe(
      map((data: any) => {
        return ActionsList.ResponseChatClosedAction({ payload: data.data });
      }),
      catchError((err) => {
        return EMPTY;
      })
    )),

  ));

  deleteChat$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestDeleteChatAction),
    switchMap((action: any) => this.service.deleteChat()),
    map((data: any) => {
      return ActionsList.ResponseDeleteChatAction({ payload: data.data });
    })
  ));

  newClosed$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestNewChatAction),
    switchMap((action: any) => this.service.newChat().pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError((err) => {
        return EMPTY;
      })
    )),
  ));

  // isLoggedIn$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.IsLoggedInAction),
  //   switchMap((action: any) => this.service.isLoggedIn().pipe(
  //     map((data: any) => {
  //       return ActionsList.LoggedInAction({ payload: '' });
  //     }),
  //     catchError((err) => {
  //       return EMPTY;
  //     })
  //   )),
  // ));
}
