import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import {
  pensionChangePaymentSchedule_Data, pensionChangePaymentSchedule_FilteredFrequencies, pensionChangePaymentSchedule_FilteredPaymentTypes,
  pensionChangePaymentSchedule_Form, pensionChangePaymentSchedule_FormSubmitted, pensionChangePaymentSchedule_IsAcctPension,
  pensionChangePaymentSchedule_Submitting
} from './selectors';
import { GetPaymentScheduleRequestAction, ResetFormAction, SetFilteredFrequenciesAction, SetFilteredPaymentTypesAction, SubmitPaymentScheduleRequestAction } from './actions';
import { combineLatest, map } from 'rxjs';
import { PensionPaymentTypeEnum } from 'src/app/enums/pension-payment-type.enum';
import { Helper } from '@ifaa-components/ui-components';
import { AccountClassificationEnum } from 'src/app/enums/account-classification.enum';
import { FrequencyEnum } from 'src/app/enums/frequency.enum';
import { PensionChangePaymentScheduleForm } from './state';
import { PensionPaymentScheduleModel } from 'src/app/model/pension-payment-schedule.model';

@Component({
  selector: 'app-pension-change-schedule',
  templateUrl: './pension-change-schedule.component.html',
  styleUrls: ['./pension-change-schedule.component.scss'],
  host: {
    class: 'w-100'
  }
})

export class PensionChangeScheduleComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  data$ = this.store.pipe(select(pensionChangePaymentSchedule_Data));
  form$ = this.store.pipe(select(pensionChangePaymentSchedule_Form));
  paymentFrequencies$ = this.store.pipe(select(pensionChangePaymentSchedule_FilteredFrequencies));
  paymentTypes$ = this.store.pipe(select(pensionChangePaymentSchedule_FilteredPaymentTypes));
  isAcctPension$ = this.store.pipe(select(pensionChangePaymentSchedule_IsAcctPension));
  formSubmitted$ = this.store.pipe(select(pensionChangePaymentSchedule_FormSubmitted));
  submitting$ = this.store.pipe(select(pensionChangePaymentSchedule_Submitting));

  accountId: number = null;
  helper = new Helper();

  constructor(public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.accountId = x.accountId;
        this.store.dispatch(GetPaymentScheduleRequestAction({ accountId: x.accountId, isPension: x.accountClassificationId == AccountClassificationEnum.Pension }))
      }
    })

    // filter the payment types for 
    this.sub = combineLatest([this.isAcctPension$, this.data$])
      .pipe(
        map(([isAcctPension, data]) => ({ isAcctPension, data })))
      .subscribe(x => {
        if (x.data) {
          // Filter payment types
          if (x.isAcctPension) {
            const filteredList = x.data.paymentTypes.filter(x => x.key == PensionPaymentTypeEnum.Minimum || x.key == PensionPaymentTypeEnum.Nominated);
            this.store.dispatch(SetFilteredPaymentTypesAction({ paymentTypes: filteredList }))
          }
          else {
            const filteredList = x.data.paymentTypes.filter(x => x.key == PensionPaymentTypeEnum.Minimum || x.key == PensionPaymentTypeEnum.Nominated || x.key == PensionPaymentTypeEnum.Maximum);
            this.store.dispatch(SetFilteredPaymentTypesAction({ paymentTypes: filteredList }))
          }

          //Filter frequencies
          const filteredFrequencies = x.data.paymentFrequencies.filter(x => x.key === FrequencyEnum.Annually || x.key === FrequencyEnum.HalfYearly || x.key === FrequencyEnum.Quarterly || x.key === FrequencyEnum.Monthly);
          this.store.dispatch(SetFilteredFrequenciesAction({ frequencies: filteredFrequencies }))
        }
      })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.store.dispatch(ResetFormAction());
  }

  cancelApplication() {
    this.router.navigate(['/pension-details']);
  }

  async submitApplication() {
    var formData = await this.helper.getValue(this.form$);
    this.store.dispatch(SubmitPaymentScheduleRequestAction({
      accountId: this.accountId, submissionData: {
        paymentFrequencyId: formData.value.paymentFrequency,
        paymentTypeId: formData.value.paymentType,
        paymentAmount: formData.value.paymentAmount
      }
    }))
  }

  showDateWarning() {
    const currentDate = new Date();
    return currentDate >= new Date(currentDate.getFullYear(), currentDate.getMonth(), 10);
  }

  showMinMaxWarning(freqMin: number, freqMax: number) {
    return freqMin === 0 && freqMax === 0;
  }

  getFrequencyText(freqId: number) {
    switch (freqId) {
      case 1:
        return "annually";
      case 2:
        return "half-yearly";
      case 3:
        return "quarterly";
      case 4:
        return "monthly";
    }
  }

  valuesChanged(formData: PensionChangePaymentScheduleForm, apiData: PensionPaymentScheduleModel) {
    if (formData.paymentType !== apiData.currentPensionPaymentTypeId) {
      return true;
    };
    if (formData.paymentFrequency !== apiData.currentFrequencyId) {
      return true;
    }
    if (formData.paymentAmount !== apiData.currentFrequencyAmount) {
      return true;
    }

    return false;
  }
}
