import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IInvestmentEditState } from './state';
import { stat } from 'fs';

export const investmentPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const editinvestment = createSelector(investmentPortalState, (state) => state.investmentEditState);

export const editinvestment_Form = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form
);

export const editinvestment_LastModified = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.lastModifiedTime
);

export const editinvestment_CurrentStep = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.currentStep
);
export const editinvestment_originalCurrentInfo = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.originalCurrentInfo
);

export const editinvestment_FailedPerformanceMessage = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.failedPerformanceMessage
);

export const editinvestment_Steps = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.steps
);

export const editinvestment_SubmitText = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.submitText
);

export const editinvestment_currentInfo = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.currentInfo
);

export const editinvestment_InvestmentCustomWording = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.options?.investmentCustomWording
);

export const editinvestment_CurrentIsSum100Percent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.current.reduce((a, b) => a + b.percentage, 0) == 100 && state.form.isValid
);

export const editinvestment_CurrentTotalPercent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.current.reduce((a, b) => a + b.percentage, 0)
);

export const editinvestment_FutureIsSum100Percent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.future.reduce((a, b) => a + b.percentage, 0) == 100 && state.form.isValid
);

export const editinvestment_FutureTotalPercent = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => state.form.value.future.reduce((a, b) => a + b.percentage, 0)
);

export const editinvestment_IsLastStep = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => {
    var last = state.steps[state.steps.length - 1];
    return state.currentStep == last;
  }
);

export const editinvestment_AllDisclaimerChecked = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => {

    return state.form.value.disclaimer;
  }
);

export const editinvestment_IsSimpleSwitch = createSelector(
  editinvestment,
  (state: IInvestmentEditState) => {
    return state.isSimpleSwitch;
  }
)
