import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainMasterComponent } from "./layout/main/main.master";
import { MsalGuard } from '@azure/msal-angular';
import { AccountDataGuardService } from './../../route-guard/account-data-guard.service';

const futrRoutes: Routes = [

];

@NgModule({
  imports: [RouterModule.forChild(futrRoutes)],
  exports: [RouterModule]
})
export class FUTRRoutingModule { }
