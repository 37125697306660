import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import * as ActionsList from './actions';
import { EMPTY, Observable } from 'rxjs';

@Injectable()
export class AccessYourSuperUpdateSubmittedComponentEffects {
    constructor(private store: Store<any>,
        private actions$: Actions,
        private service: AccessYourSuperService) {
    }

    getCustomData$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.GetCustomDataAction),
        switchMap((action: any) => this.service.getCustomSectionData(action.accountId, action.applicationId, action.applicationTypeId).pipe(
            map((data: any) => {
                return ActionsList.SaveCustomDataAction({ payload: data.data });
            }),
            catchError(err => {
                return EMPTY;
            })
        )),
    ));

    getKiwiSaverData$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.GetKiwiSaverDataAction),
        switchMap((action: any) => this.service.getKiwiSaverSectionData(action.accountId, action.applicationId).pipe(
            map((data: any) => {
                return ActionsList.SaveCustomDataAction({ payload: data.data });
            }),
            catchError(err => {
                return EMPTY;
            })
        )),
    ));

    getSfhData$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.GetSfhDataAction),
        switchMap((action: any) => this.service.getSfhSectionData(action.accountId, action.applicationId).pipe(
            map((data: any) => {
                return ActionsList.SaveCustomDataAction({ payload: data.data });
            }),
            catchError(err => {
                return EMPTY;
            })
        )),
    ));
}
