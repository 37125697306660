import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { jetcoLodgeClaim_claimAmount, jetcoLodgeClaim_Form, jetcoLodgeClaim_lastModifiedTime } from './selectors';
import { CIRTState } from '../../../store/cirt.states';
import { JetcoLodgeClaimModel } from './state';
import { JetcoClaimAmountRequestAction, ResetFormAction, SubmitRequestAction } from './actions';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-tenant-jetco-lodge-claim',
  templateUrl: './lodge-claim.component.html',
  styleUrls: ['./lodge-claim.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class TenantJetcoLodgeClaimComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.cirtstore.pipe(select(jetcoLodgeClaim_Form));
  claimAmount$ = this.cirtstore.pipe(select(jetcoLodgeClaim_claimAmount));
  lastModified$ = this.cirtstore.pipe(select(jetcoLodgeClaim_lastModifiedTime));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  extensions: string[] = ['.pdf', '.png', '.jpg'];
  maxfilesize = 5242880;//5mb
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;
  accountId: number = 0;

  constructor(public store: Store<UIComponentState>,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    public cirtstore: Store<CIRTState>,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.form$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.value.startDate === curr.value.startDate))
      .subscribe(date => {
        if (date.value.startDate)
          this.store.dispatch(JetcoClaimAmountRequestAction({ start: date.value.startDate, end: null }));
      });

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.bottomSheet.open(this.customTemplate, {
          disableClose: true
        });

        this.dispatch(this.store, ResetFormAction());
      }
    });

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.accountId = x.accountId;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


  onSubmitClick(data: JetcoLodgeClaimModel) {
    this.dispatch(this.store, SubmitRequestAction({ accountId: this.accountId, payload: data }));
  }

  onGoToDashboardClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/']);


  }

}
