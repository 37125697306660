import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import * as ActionsList from './actions';
import { EMPTY, of } from 'rxjs';

@Injectable()
export class AccessYourSuperDocumentsComponentEffects {
    constructor(private store: Store<any>,
        private actions$: Actions,
        private service: AccessYourSuperService) {
    }

    saveDocument$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SaveDocumentAction),
        mergeMap((action: any) => this.service.saveDocument(action.accountId, action.applicationId, action.documentTypeId, action.payload, action.draftApplication).pipe(
            map((data: any) => {
                return ActionsList.UpdateSavedDocumentAction({ uploadedDocumentId: data.data, fileName: action.fileName, documentTypeId: action.documentTypeId, documentIndex: action.documentIndex, controlId: action.controlId })
            }),
            catchError((err: any, caught: any) => {
                return of(ActionsList.SetFileRequestFailedAction({ controlId: action.controlId, file: action.payload }))
            })
        )),
    ))

    deleteDocument$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.DeleteDocumentAction),
        switchMap((action: any) => this.service.deleteDocument(action.accountId, action.applicationId, action.uploadedDocumentId, action.draftApplication).pipe(
            map((data: any) => {
                return ActionsList.UpdateDeletedDocumentAction({ uploadedDocumentId: action.uploadedDocumentId, overviewIndex: action.overviewIndex, uploadIndex: action.uploadIndex, controlId: action.controlId })
            }),
            catchError(err => {
                return EMPTY;
            })
        )),
    ))

    replaceDocument$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.ReplaceDocumentAction),
        switchMap((action: any) => this.service.deleteDocument(action.accountId, action.applicationId, action.uploadedDocumentId, action.draftApplication).pipe(
            map((data: any) => {
                return ActionsList.UpdateReplacedDocumentAction({ documentTypeId: action.documentTypeId, controlId: action.controlId, documentIndex: action.documentIndex, fileName: action.fileName, payload: action.payload });
            }),
            catchError(err => {
                return of(ActionsList.SetFileRequestFailedAction({ controlId: action.controlId, file: action.originalFile }))
            })
        )),
    ))

    completeDocumentsComponent$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.CompleteDocumentsAction),
        switchMap((action: any) => this.service.completeDocuments(action.accountId, action.applicationId, action.submission).pipe(
            map(() => {
                return ActionsList.DocumentsCompletedAction();
            }),
            catchError(err => {
                return EMPTY;
            })
        )),
    ))
}
