import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { AvailableAccountBalanceModel } from 'src/app/model/available-account-balance.model';

@Component({
    selector: 'available-account-balance',
    templateUrl: './available-account-balance.component.html',
    styleUrls: ['./available-account-balance.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class AvailableAccountBalanceComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set data (value:AvailableAccountBalanceModel) {
        if (value?.calculationDate)
            this.accountBalanceLabel = `Account balance as at ${value.calculationDate}`;

        this.model=value;
    }

    model:AvailableAccountBalanceModel = null;

    accountBalanceLabel: string = null;

    constructor(
        public store: Store<AppState>) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }
}