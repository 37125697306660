import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { commomState_LoggedinInfo, commomState_CommunicationPreferenceList, commonState_SystemConfig_AccountFeatureToggles, commonState_MemberData } from 'src/app/store/common/common.selectors';
import { Helper } from '@ifaa-components/ui-components';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';


@Component({
  selector: 'app-member-view',
  templateUrl: './member-view.component.html',
  styleUrls: ['./member-view.component.scss']
})
export class MemberViewComponent extends ComponentBase implements OnInit, OnDestroy {

  dataValidation = {
    firstName: false,
    lastName: false,
    dob: false,
    residentialAddress: false
  }
  data$ = this.appstore.pipe(select(commonState_MemberData));
  @Input() hideCommunicationPreference: boolean = false;
  @Input() hideOccupation: boolean = false;
  @Input() hideMemberNumber: boolean = false;
  @Input() hidePostalAddress: boolean = false;
  @Input() hideTfn: boolean = true;
  @Input() hideDob: boolean = true;
  @Input() itemclass: string = 'w-50';
  @Input() tfnTooltip: string;
  @Input() set greenIdValidation(val: boolean) {
    if (val) {
      this.dataValidation = {
        firstName: true,
        lastName: true,
        dob: true,
        residentialAddress: true
      }
    }
    else {
      this.dataValidation = {
        firstName: false,
        lastName: false,
        dob: false,
        residentialAddress: false
      }
    }
  }

  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  helper = new Helper();
  communicationOption$ = this.appstore.pipe(select(commomState_CommunicationPreferenceList));
  featureToggles$ = this.appstore.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  communicationDescription = '';

  displayCommunicationPreferenceFeatureToggle = FeatureToggleName.member.personalDetails.communicationPreference.view;
  displayOccupationFeatureToggle = FeatureToggleName.member.personalDetails.occupation.view;
  displayTfnFeatureToggle = FeatureToggleName.member.personalDetails.tfn.view;
  displayLegalNameFeatureToggle = FeatureToggleName.member.personalDetails.legalName.view;

  showLegalName:boolean = false;
  displayLegalNameToggle:boolean = false;

  featureToggleHelper: FeatureToggleHelper = new FeatureToggleHelper();

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    // this.dispatch(this.store, RequestAction());

    this.sub = this.featureToggles$.subscribe(x => {
      if (x) {
        this.displayLegalNameToggle = this.featureToggleHelper.checkToggle(FeatureToggleName.member.personalDetails.legalName.view,
          x, null, false);
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onToggleLegalNameClick() {
    this.showLegalName = !this.showLegalName;
  }

}
