import { AvailableAccountBalanceModel } from "./available-account-balance.model";
import { BankDetailsModel } from "./bank-details.model";

export class AccessYourSuperApplicationTypesListModel{
    [key: string]: AccessYourSuperApplicationType[]
}

export class AccessYourSuperApplicationTypesListResponseModel{
    applicationTypes:AccessYourSuperApplicationTypesListModel;
    hasError:boolean;
    errorMessage:string;
}

export class AccessYourSuperApplicationType{
    applicationId:number;
    applicationTypeId:number;
    conditionName:string;
    conditionDescription:string;
    conditionDisclaimer:string;
    requiredSections:number[];
    directToAto:boolean;
    redirectToAtoWording:string;
    redirectToAtoLink:string;
    currentStep:number;
}

export class AccessYourSuperEmployerDetailsModel{
    lastEmployerName: string;
    stillWithEmployer?: boolean = null;
    finishDate?: Date|string = null;
    waitingOnFinalContribution?: boolean = null;
}

export class AccessYourSuperDocumentOverviewModel{
    documentTypeId: number;
    documentName: string;
    documentDescription: string;
    documentAmountRequired: number;
    isDocumentRequired:boolean;
    documentDisclaimer:string;
    authorityGiven:boolean;
    canRequestAuthority:boolean;
    referenceRequired:boolean;
    referenceLabel:string;
    referenceValue:string;
    documentsUploaded:boolean;
    documentList: AccessYourSuperSupportingDocumentModel[];
}

export class AccessYourSuperSupportingDocumentModel{
    uploadedDocumentId: number | null;
    applicationId: number;
    documentTypeId: number;
    originalFileName: string;
    systemFileName: string;
    fileLocation: string;
    fileCreatedDate: Date;
    authorityGiven:boolean;
}

export class AccessYourSuperApplicationDataModel{
    conditionData: AccessYourSuperApplicationType;
    accountBalances: AvailableAccountBalanceModel;
    bankDetails: BankDetailsModel;
    employerDetails: AccessYourSuperEmployerDetailsModel;
    applicationDocuments: AccessYourSuperDocumentOverviewModel[];
    customWording: { [key:string] : string };
}



// submission models
export class AccessYourSuperEmployerDetailsSubmissionModel{
    lastEmployerName: string;
    stillWithEmployer?: boolean = null;
    finishDate?: Date = null;
    waitingOnFinalContribution?: boolean = null;
    currentStep:number;
}

export class AccessYourSuperBankDetailsSubmissionModel{
    accountName:string;
    accountNumber:string;
    bsb:string;
    bankName:string;
    currentStep:number;
}

export class AccessYourSuperDocumentSubmissionModel{
    customerReferenceNumber:string;
    documents:AccessYourSuperSupportingDocumentModel[]
}