import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { RollInEditModel, SuperannuationProviderItemModel } from 'src/app/model/rollin-edit.model';

export const ResetAction = createAction('[Roll in Edit] reset');
export const ContinueAction = createAction('[Roll in Edit] continue');

export const ConfirmBackAction = createAction('[Roll in Edit] back to start');

export const SelectedProviderAction = createAction('[Roll in Edit] selected provider',
  props<{ payload: SuperannuationProviderItemModel }>());

export const SubmitRequestAction = createAction('[Roll in Edit] confirm rollin',
  props<{ accountId: number, payload: RollInEditModel }>());

export const SubmitResponseAction = createAction('[Roll in Edit]  confirm rollin completed');
