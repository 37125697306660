export class RollInEditModel {
  provider: SuperannuationProviderItemModel = new SuperannuationProviderItemModel();
  memberNumber: string = '';
  amountToTransferOption: number = 0;
  amountToTransfer: number = 0;
  confirm: RollInEditConfirmModel = new RollInEditConfirmModel();
  step: 'start' | 'confirm' | 'done' = 'start';
}

export class RollInEditConfirmModel {
  insurance: boolean = false;
  termsAndConditions: boolean = false;
}

export class SuperannuationProviderItemModel {
  name: string = '';
  productName: string = '';
  abn: string = '';
  usi: string = '';
}
