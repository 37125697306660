import { createReducer, Action, on } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { required, requiredTrue } from 'ngrx-forms/validation';
import { RequestAction, ResetFormStateAction, ResponseAction, SetDisclaimerAction, SetFinancialYearDropdownOptionsAction, SetTaxDeductionRadioOptionsAction } from './actions';
import { INoticeOfIntentTaxDeductionFormComponentState, NoticeOfIntentTaxDeductionFormComponentForm } from './state';

export const state: INoticeOfIntentTaxDeductionFormComponentState = {
  submitted: false,
  form: createFormGroupState('NoticeOfIntentFormComponentForm', new NoticeOfIntentTaxDeductionFormComponentForm()),
  taxDeductionClaimTypes: [],
  financialYearDropdownOptions: [],
  disclaimer: '',
  isSubmitting: false
};

export const validateNoticeOfIntentTaxDeductionFormComponentForm = updateGroup<NoticeOfIntentTaxDeductionFormComponentForm>({
  disclaimer: validate(requiredTrue),
  claimAmount: (state, parentState) => {
    if (parentState.value.claimType === 0) {
      const newTypeClaimValidation = () => {
        var errors = {};

        if (parentState.value.claimAmount <= 0) {
          errors['customError'] = `Claim amount must be greater than $0.`
        }

        if (parentState.value.financialYear === 0) {
          if (parentState.value.financialYearContributions.currentYear === 0) {
            errors['customError'] = 'You have no personal contributions for this financial year.'
          }
          else {
            if (parentState.value.financialYearContributions.currentYearAgeCutOffAmount >= 0) {
              if (parentState.value.claimAmount > parentState.value.financialYearContributions.currentYearAgeCutOffAmount) {
                errors['customError'] = `You are eligible to claim up to $${parentState.value.financialYearContributions.currentYearAgeCutOffAmount} only since you can’t claim a deduction for personal contributions you made more than 28 days after the month you turned 75.`
              }
            }
            else if (parentState.value.claimAmount > parentState.value.financialYearContributions.currentYear) {
              errors['customError'] = `Must be less than or equal to $${parentState.value.financialYearContributions.currentYear}.`
            }
          }
        } else {
          if (parentState.value.financialYearContributions.previousYear === 0) {
            errors['customError'] = 'You have no personal contributions for this financial year.'
          }
          else {
            if (parentState.value.financialYearContributions.previousYearAgeCutOffAmount >= 0) {
              if (parentState.value.claimAmount > parentState.value.financialYearContributions.previousYearAgeCutOffAmount) {
                errors['customError'] = `You are eligible to claim up to $${parentState.value.financialYearContributions.previousYearAgeCutOffAmount} only since you can’t claim a deduction for personal contributions you made more than 28 days after the month you turned 75.`
              }
            }
            else if (parentState.value.claimAmount > parentState.value.financialYearContributions.previousYear) {
              errors['customError'] = `Must be less than or equal to $${parentState.value.financialYearContributions.previousYear}.`
            }
          }
        }

        return errors;
      }
      state = validate(state, required, newTypeClaimValidation);
    }
    else {

      const variantTypeClaimValidation = () => {
        var errors = {};

        if (parentState.value.claimAmount < 0) {
          errors['customError'] = `Claim amount must be greater than or equal to $0.`
        }

        if ((parentState.value.financialYear === 0 && parentState.value.financialYearContributions.currentYear === 0) ||
          (parentState.value.financialYear === 1 && parentState.value.financialYearContributions.previousYear === 0)) {
          errors['customError'] = 'You have no personal contributions for this financial year.'
        }

        if (parentState.value.claimAmount >= parentState.value.originalClaimAmount) {
          errors['customError'] = `Must be less than $${parentState.value.originalClaimAmount}.`
        }

        return errors;
      }

      state = validate(state, variantTypeClaimValidation);
    }
    return state;
  },
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      isSubmitting: true
    }
  }),
  on(ResponseAction, (state) => {
    return {
      ...state,
      submitted: true,
      isSubmitting: false
    };
  }),
  on(SetTaxDeductionRadioOptionsAction, (state, { payload }) => {
    return {
      ...state,
      taxDeductionClaimTypes: payload
    }
  }),
  on(SetFinancialYearDropdownOptionsAction, (state, { payload }) => {
    return {
      ...state,
      financialYearDropdownOptions: payload
    }
  }),
  on(ResetFormStateAction, (state) => {
    return {
      submitted: false,
      form: createFormGroupState('NoticeOfIntentFormComponentForm', new NoticeOfIntentTaxDeductionFormComponentForm()),
      taxDeductionClaimTypes: [],
      financialYearDropdownOptions: [],
      disclaimer: '',
      isSubmitting: false
    }
  }),
  on(SetDisclaimerAction, (state, { disclaimer }) => {
    return {
      ...state,
      disclaimer
    }
  })
);


const editNoticeOfIntentTaxDeductionFormComponentReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateNoticeOfIntentTaxDeductionFormComponentForm
)

export function noticeOfIntentTaxDeductionFormComponentReducer(state: any | undefined, action: Action) {
  return editNoticeOfIntentTaxDeductionFormComponentReducerFormState(state, action);
}