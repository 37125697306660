<div id="consolidate-super-container" *featuretoggle="rollInFeatureToggle">
  <div class="d-flex flex-column">
    <div class="header">
      <label class="description">Do you have lost super? Do you have multiple super accounts?</label>
    </div>

    <app-no-items message="You have not requested a rollover in the last 30 days"
    *ngIf="(list$ | async).length == 0"></app-no-items>

    <div id="carouselExampleIndicators" class="carousel slide mt-1" data-ride="carousel"
      *ngIf="list$ | async; let list">
      <ol class="carousel-indicators" *ngIf="list.length > 1">
        <li data-target="#carouselExampleIndicators" *ngFor="let item of list; index as i"
          [ngClass]="{'active': i == 0}" [attr.data-slide-to]="i"></li>
      </ol>
      <div class="carousel-inner">
        <div *ngFor="let item of list; index as i" class="carousel-item transfers" [ngClass]="{'active': i == 0}">
          <!--  -->
          <div class="card  d-flex  flex-row align-items-center ml-3 mr-3"
          [ngClass]="{'pending': item.status == 'Pending', 'processed': item.status == 'Processed','requested': item.status == 'Requested'}">
            <div class="d-flex flex-column m-2 align-items-center icon">
              <mat-icon class="" *ngIf="item.status == 'Requested'">hourglass_empty</mat-icon>
              <mat-icon class="" *ngIf="item.status == 'Pending'">hourglass_empty</mat-icon>
              <mat-icon class="" *ngIf="item.status == 'Processed'">done</mat-icon>
              {{item.status}}
            </div>
            <div>
              <h6>{{item.transferringFund}}</h6>
              <label>Account number: {{item.accountNumber}}</label>
            </div>
          </div>
        </div>


      </div>
      <a class="carousel-control-prev no-text-decoration" href="#carouselExampleIndicators" role="button"
        data-slide="prev" *ngIf="list.length > 1">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </a>
      <a class="carousel-control-next no-text-decoration" href="#carouselExampleIndicators" role="button"
        data-slide="next" *ngIf="list.length > 1">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </a>
    </div>



  </div>
</div>
