import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IPensionChangePaymentScheduleState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const pensionChangePaymentSchedule = createSelector(memberPortalState, (state) => state.pensionChangePaymentScheduleState);

export const pensionChangePaymentSchedule_Form = createSelector(
  pensionChangePaymentSchedule,
  (state: IPensionChangePaymentScheduleState) => state.form
);

export const pensionChangePaymentSchedule_Data = createSelector(
    pensionChangePaymentSchedule,
  (state: IPensionChangePaymentScheduleState) => state.data
);

export const pensionChangePaymentSchedule_FilteredPaymentTypes = createSelector(
  pensionChangePaymentSchedule,
(state: IPensionChangePaymentScheduleState) => state.filteredPaymentTypes
);

export const pensionChangePaymentSchedule_FilteredFrequencies = createSelector(
  pensionChangePaymentSchedule,
(state: IPensionChangePaymentScheduleState) => state.filteredFrequencies
);

export const pensionChangePaymentSchedule_IsAcctPension = createSelector(
  pensionChangePaymentSchedule,
(state: IPensionChangePaymentScheduleState) => state.isAcctPension
);

export const pensionChangePaymentSchedule_FormSubmitted = createSelector(
  pensionChangePaymentSchedule,
(state: IPensionChangePaymentScheduleState) => state.formSubmitted
);

export const pensionChangePaymentSchedule_Submitting = createSelector(
  pensionChangePaymentSchedule,
(state: IPensionChangePaymentScheduleState) => state.submitting
);