import { FormGroupState } from 'ngrx-forms';

export interface IInvestmentEditState {
  form: FormGroupState<CurrentInvestmentModel>;
  lastModifiedTime: Date;
  currentStep: number;
  steps: number[];
  currentInfo: CurrentInvestmentModel;
  originalCurrentInfo: CurrentInvestmentModel;
  options: CurrentInvestmentModel;
  isPension: boolean;
  futureModelList: InvestmentItemModel[];
  submitText:string;
  failedPerformanceMessage: string;
  isSimpleSwitch:boolean;
}


export class InvestmentModel {
  memberNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  secondName: string = '';
  taxFileNumber: string = '';
}

export class CurrentInvestmentModel {
  current: InvestmentItemModel[] = [];
  future: InvestmentItemModel[] = [];
  currentFuture: InvestmentItemModel[] = [];
  hasPendingCurrentOptions: boolean = false;
  failedPerformanceMessage: string;
  disclaimer: boolean = false;
  investmentCustomWording: {[key:string]:string}
}

export class InvestmentItemModel {
  optionId: number = 0;
  option: string = '';
  percentage: number = 0;
  amount: number = 0;
  asAt: string | null = null;
  investmentOptionFee: string = null;
  investmentOptionStatusId :number = 0;
}
