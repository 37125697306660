import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { InvestmentSwitchHistoryModel } from "src/app/model/investment-switch-history.model";

export interface IInvestmentSwitchHistoryState {
  data: InvestmentSwitchHistoryModel;
  filter: FormGroupState<InvestmentSwitchHistoryFilter>;
  isLoading: boolean;
  switchTypes: KeyValueModel[];
  switchStatuses: KeyValueModel[];
}

export class InvestmentSwitchHistoryFilter {
  from: string = '';
  to: string = '';
  currentPage: number = 0;
  switchType: number = 0;
  switchStatus: number = 0;
}
