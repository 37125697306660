<div id="investment-complex-view-container">

  <ng-container *featuretoggle="updateInvestmentsFeatureToggle">
    <div *ngIf="_showOptions">
      <h3 class="title">What would you like to do?</h3>
      <div class="row mt-2">
        <div class="col-lg d-flex">
          <div *ngIf="currentInfo$ | async; let currentInfo"
            class="option1 card flex-row d-flex align-items-center mb-2 align-items-stretch align-items-center flex-fill"
            [ngClass]="{'disabled': currentInfo.hasPendingCurrentOptions}"
            (click)="onEditItemClick(1, [0,1,3], currentInfo.hasPendingCurrentOptions)">
            <div class="ic-container d-flex align-items-center">
              <mat-icon class="ic mb-4">currency_exchange</mat-icon>
            </div>
            <div class="flex-fill description d-flex align-items-center">Change where my balance is currently invested
            </div>
            <div class="d-flex align-items-center">
              <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
            </div>
          </div>


        </div>
        <div class="col-lg d-flex ">
          <div class="option1 flex-row d-flex align-items-center card  mb-2 align-items-stretch flex-fill"
            (click)="onEditItemClick(2, [0,2,3], false)">
            <div class="ic-container d-flex align-items-center">
              <mat-icon class="ic mb-4">monetization_on</mat-icon>
            </div>
            <div class="flex-fill description  d-flex align-items-center">
              <span *ngIf="!isPensionAccount">
                Change where my future contributions are invested
              </span>
              <span *ngIf="isPensionAccount">
                Change where my pension payments are paid from
              </span>
            </div>
            <div class="d-flex align-items-center">
              <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
            </div>
          </div>

        </div>
        <div class="col-lg d-flex">
          <div *ngIf="currentInfo$ | async; let currentInfo"
            class="option1 card flex-row d-flex align-items-center mb-2 align-items-stretch flex-fill"
            [ngClass]="{'disabled': currentInfo.hasPendingCurrentOptions}"
            (click)="onEditItemClick(1, [0,1,2,3], currentInfo.hasPendingCurrentOptions)">
            <div class="ic-container d-flex align-items-center">
              <mat-icon class="ic mb-4">display_settings</mat-icon>
            </div>
            <div class="flex-fill description  d-flex align-items-center">
              <span *ngIf="!isPensionAccount">
                Change where both my current balance and future contributions are invested
              </span>
              <span *ngIf="isPensionAccount">
                Change both my current balance and future pension payments
              </span>
            </div>
            <div class="d-flex align-items-center">

              <mat-icon class="arrow">keyboard_arrow_right</mat-icon>

            </div>
          </div>

        </div>

      </div>
    </div>
  </ng-container>

  <h3 class="mt-3" *ngIf="!isDashboardView">Current Options</h3>
  <div class="table-container">
    <div class="d-flex flex-fill flex-column table-view">
      <div>
        <h4 class="d-flex align-items-center ">
          <div class="flex-fill d-flex">
            Your balance is currently invested in
            <mat-icon class="ml-2 help-icon" *ngIf="(currentInfo$ | async); let data"
              matTooltip="Your current balance is as at {{getAsAtBalanceDate(data) | date:'dd MMM yyyy'}} and has been calculated using the latest unit price.">
              help_outline</mat-icon>
          </div>

          <div *ngIf="(currentInfo$ | async)?.hasPendingCurrentOptions"
            class=" badge badge-info float-right d-flex align-items-center click"
            matTooltip="Your current investment switch is in progress, which restricts changes to the allocation of your existing balance until it is complete.">
            <mat-icon class="animate__delay-1s animate__animated animate__tada">hourglass_top</mat-icon>
            Pending Changes
          </div>
        </h4>
      </div>
      <table class="table mr-2 {{'VIEWS.Global.table-default-style' | translate}}">
        <thead>
          <tr>
            <th scope="col">Option</th>
            <th scope="col">%</th>
            <th scope="col">$</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of filterCurrent((currentInfo$|async)?.current); index as i">
            <tr [ngClass]="{'hover-row':item.isCompositeOption}">
              <td>{{item.option}}</td>
              <td>{{item.percentage}}%</td>
              <td>{{item.amount | currency}}</td>
            </tr>
            <app-investment-edit-hover *ngIf="item.isCompositeOption" [option]="item"
              [hiddenOptions]="(currentInfo$|async)?.hiddenCurrent" [showDollarCol]="true">
            </app-investment-edit-hover>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="d-flex flex-fill flex-column table-right table-view">
      <h4 *ngIf="!isPensionAccount">Any future contributions or rollovers received into the fund will be invested in
      </h4>
      <h4 *ngIf="isPensionAccount">Any future pension payments to be paid from</h4>
      <table class="table  {{'VIEWS.Global.table-default-style' | translate}}">
        <thead>
          <tr>
            <th scope="col">Option</th>
            <th scope="col">%</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of filterFuture((currentInfo$|async)?.future); index as i">
            <tr [ngClass]="{'hover-row':item.isCompositeOption}">
              <td>{{item.option}}</td>
              <td>{{item.percentage}}%</td>
            </tr>
            <app-investment-edit-hover *ngIf="item.isCompositeOption" [option]="item"
              [hiddenOptions]="(currentInfo$|async)?.hiddenFuture" [showDollarCol]="false">
            </app-investment-edit-hover>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row" *ngIf="!_showOptions">
    <div class="col-sm-12">
      <div *ngIf="((currentInfo$|async)?.current?.length > 3 || (currentInfo$|async)?.future?.length > 3) "
        class="moreLess">
        <a [routerLink]="[]" (click)="onShowMoreOrLess()">{{maxShow == 3 ? 'Read more' : 'Read less'}}</a>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="_showOptions">
    <div class="alert alert-info m-3" [innerHTML]="'VIEWS.ManageInvestment.additionalMessage' | translate"></div>
  </div>

</div>