import { IAccountSummaryState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { AccountSummaryResponseAction } from './actions';
import { AccountComponentsModel } from 'src/app/model/account-component.model';

export const accountSummaryState: IAccountSummaryState = {
  accountSummary: new AccountComponentsModel(),
};

const reducer = createReducer(accountSummaryState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(AccountSummaryResponseAction, (state, { payload }) => {
    return {
      ...state,
      accountSummary: payload
    }
  })
);

export function accountSummaryReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}

