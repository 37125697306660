import { Helper } from '@ifaa-components/ui-components';
import { distinctUntilChanged, pairwise } from 'rxjs/operators';
import { AccountModel } from './../../../../model/account.model';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_Accounts, memberAccountDropdown_SelectedAccount } from './selectors';
import { RequestAction, SelectAccountAction } from './actions';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { combineLatest } from 'rxjs';
import { AccountFeatureTogglesRequestAction } from 'src/app/store/common/common.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-member-account-dropdown',
  templateUrl: './member-account-dropdown.component.html',
  styleUrls: ['./member-account-dropdown.component.scss']
})
export class MemberAccountDropdownComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(memberAccountDropdown_Accounts));
  selected$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  helper: Helper = new Helper();
  constructor(public store: Store<MemberPortalSharedState>,
    public router: Router,
    public appstore: Store<AppState>) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();

    if (! await this.hasDataLoaded()) {
      this.sub = combineLatest([this.loggedIn$, this.selected$])
        .pipe(distinctUntilChanged((x, y) => {
          return JSON.stringify(x) === JSON.stringify(y)
        }))
        .subscribe(([loggedIn, selectedAccount]) => {
          // If we have the selected account already from another component, we dont need to send the request again
          if (loggedIn && !selectedAccount) {
            this.dispatch(this.store, RequestAction());
          }
        })
    }

    this.sub = this.selected$.pipe(distinctUntilChanged((x, y) => {
      return JSON.stringify(x) === JSON.stringify(y)
    }),
      pairwise())
      .subscribe(x => {
        // Send feature toggle request again if member swaps account
        if (x[1] && (x[0] !== x[1])) {
          this.dispatch(this.store, AccountFeatureTogglesRequestAction({ accountId: x[1].accountId }))
        }
      })

  }

  async hasDataLoaded() {
    var loggedIn = await this.helper.getValue(this.loggedIn$);
    return loggedIn.memberNumber;
  }
  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSelecteAccountClick(item: AccountModel) {
    this.dispatch(this.store, SelectAccountAction({ payload: null }))
    setTimeout(() => {
      this.dispatch(this.store, SelectAccountAction({ payload: item }))
      this.router.navigate(['dashboard']);
    }, 400);

  }
}

