import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { INotificationDropdownState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const notificationDropdown = createSelector(memberPortalState, (state) => state.notificationDropdownState);

export const notificationDropdown_List = createSelector(
  notificationDropdown,
  (state: INotificationDropdownState) => state.list
);
