import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IAccessYourSuperTypeSelectionComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperTypeSelectionState = createSelector(memberPortalState, (state) => state.accessYourSuperTypeSelectionComponentState);

export const accessYourSuperTypeSelection_Form = createSelector(
    getAccessYourSuperTypeSelectionState,
    (state: IAccessYourSuperTypeSelectionComponentState) => state.form
)

export const accessYourSuperTypeSelection_FirstDropdownValues = createSelector(
    getAccessYourSuperTypeSelectionState,
    (state: IAccessYourSuperTypeSelectionComponentState) => state.firstDropdownValues
)

export const accessYourSuperTypeSelection_SecondDropdownValues = createSelector(
    getAccessYourSuperTypeSelectionState,
    (state: IAccessYourSuperTypeSelectionComponentState) => state.secondDropdownValues
)

export const accessYourSuperTypeSelection_ApplicationId = createSelector(
    getAccessYourSuperTypeSelectionState,
    (state: IAccessYourSuperTypeSelectionComponentState) => state.applicationId
);

export const accessYourSuperTypeSelection_ApplicationModel = createSelector(
    getAccessYourSuperTypeSelectionState,
    (state: IAccessYourSuperTypeSelectionComponentState) => state.applicationData
)