import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { accessYourSuperStart_Filter, accessYourSuperStart_Model, accessYourSuperStart_ApplicationData, accessYourSuperStart_ApplicationToCancelled, accessYourSuperStart_UpdatingSubmittedDocuments } from './selectors';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { CancelApplicationAction, MoveApplicationToCancelledAction, RequestAction, RequestContinueApplicationAction, ResetComponentStateAction } from './actions';
import { AccessYourSuperApplicationsModel } from 'src/app/model/access-your-super-applications.model';
import { combineLatest } from 'rxjs';
import { distinct } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Helper } from '@ifaa-components/ui-components';
import { SaveApplicationDataAction, SetCurrentStepAction } from '../access-your-super-form/actions';
import { SaveApplicationDataForUpdateSubmittedAction } from '../access-your-super-update-submitted/actions';
import { accessYourSuperForm_CurrentStep } from '../access-your-super-form/selector';

@Component({
  selector: 'app-access-your-super-start',
  templateUrl: './access-your-super-start.component.html',
  styleUrls: ['./access-your-super-start.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class AccessYourSuperStartComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.store.pipe(select(accessYourSuperStart_Model));
  filter$ = this.store.pipe(select(accessYourSuperStart_Filter));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  applicationToCancelled$ = this.store.pipe(select(accessYourSuperStart_ApplicationToCancelled));
  applicationData$ = this.store.pipe(select(accessYourSuperStart_ApplicationData));
  currentStep$ = this.store.pipe(select(accessYourSuperForm_CurrentStep));
  updatingSubmittedDocuments$ = this.store.pipe(select(accessYourSuperStart_UpdatingSubmittedDocuments));

  applicationId: number = 0;
  currentList: AccessYourSuperApplicationsModel[] = [];
  cancelDialogText: string = "Please confirm that you would like to cancel this request. This action cannot be reversed. Once cancelled, you will need to start a new application if you wish to send a benefit payment request."

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    // sends dispatch request to update the UI so application is moved to cancelled filter
    this.sub = this.applicationToCancelled$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, MoveApplicationToCancelledAction({ applicationId: this.applicationId }))
      }
    })

    // send api request when we have the selected account
    this.sub = this.selectedAccount$.pipe(distinct(x => {
      if (x) {
        return x.accountId
      }
    })).subscribe(x => {
      var accountId = x?.accountId;

      if (accountId) {
        // send api request to get applications for account
        this.dispatch(this.store, RequestAction({ accountId: accountId }));
      }
    });

    this.sub = combineLatest([this.filter$, this.model$])
      .pipe(
        map(([filter, model]) => ({ filter, model })))
      .subscribe(x => {
        if (x.model && x.filter) {
          this.currentList = x.model[x.filter.value.activeOptionButton]
        }
      }
      );

    this.sub = combineLatest([this.applicationData$, this.currentStep$, this.updatingSubmittedDocuments$]).pipe(
      map(([applicationData, currentStep, updatingSubmittedDocuments]) => ({ applicationData, currentStep, updatingSubmittedDocuments })))
      .subscribe(x => {
        if (!x.updatingSubmittedDocuments) {
          // Save the current step and application data so it is ready for the access-your-super-form component
          if (x.applicationData && x.currentStep === null) {
            this.dispatch(this.store, SetCurrentStepAction({ nextStep: x.applicationData.conditionData.currentStep }))
            this.dispatch(this.store, SaveApplicationDataAction({ applicationId: x.applicationData.conditionData.applicationId, model: x.applicationData }))
          }
          // When current step is set, navigate to create-application
          if (x.applicationData && x.currentStep !== null) {
            this.router.navigate(['/access-your-super/create-application']);
          }
        }
        else {
          // save the application data into the review component state and go to page
          this.dispatch(this.store, SaveApplicationDataForUpdateSubmittedAction({ applicationId: x.applicationData.conditionData.applicationId, model: x.applicationData }))
          this.router.navigate(['/access-your-super/review'])
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.dispatch(this.store, ResetComponentStateAction())
  }

  createNewApplication() {
    this.dispatch(this.store, SetCurrentStepAction({ nextStep: 0 }))
    this.router.navigate(['/access-your-super/create-application']);
  }

  async continueApplication(applicationId: number, draftApplication: boolean) {
    this.applicationId = applicationId;
    var helper = new Helper();
    var account = await helper.getValue(this.selectedAccount$);
    this.dispatch(this.store, RequestContinueApplicationAction({ accountId: account.accountId, applicationId: applicationId, draftApplication: draftApplication }))
  }

  async cancelApplication(event: any, applicationId: number) {
    this.applicationId = applicationId;
    if (event === 'ok') {
      var helper = new Helper();
      var account = await helper.getValue(this.selectedAccount$);
      this.dispatch(this.store, CancelApplicationAction({ accountId: account.accountId, applicationId: applicationId }))
    }
  }
}
