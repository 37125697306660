export class CoreApiHelper{
    urls: string[] = ['systemconfig', 'isloggedin', 'accounts', 'get-feature-toggles']
    constructor(){

    }
    
    checkUrl = (url:string) => {
        var urlFragments = url.split('/');
        var endOfUrl = urlFragments.slice(-1).pop();

        if (this.urls.includes(endOfUrl))
            return true;

        return false;
    }
}