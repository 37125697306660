<div id="maintenance-container" class="d-flex ">
    <div class="d-flex w-100 content-wrapper" appanimatecss="{{'LOGIN_VIEW.ANIMATE_CONTENT_WRAPPER' | translate}}">
        <div class="left-side">
            <div class="left-side-content"></div>

        </div>
        <div class="right-side flex-fill d-flex align-items-center">
            <div class="backdrop"></div>

            <div appanimatecss="{{'LOGIN_VIEW.ANIMATE_WRAPPER' | translate}}" class="wrapper">
                <div class="p-3 ">
                    <a target="_blank" href="{{'website' | tenantconfig | async }}">
                        <div class="logo"></div>
                    </a>

                    <!-- <img src="{{'fundLogo' | tenantconfig | async }}" class="logo mobile"> -->
                    <div class="page-title">

                    </div>
                    <div class=" d-flex flex-row w-100 ">

                        <div class="flex-fill d-flex flex-column w-100">

                            <h1 class="card-title mb-1 ">The member portal is currently unavailable due to a significant event.</h1>
                            <ng-container *ngIf="toggle.message">
                                <h4 [innerHtml]="toggle.message" class="card-subtitle mb-4 ">
                                </h4>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>



</div>