import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ComponentBase } from "src/app/views/component-base";
import { AppState } from "src/app/store/app.states";
import { InvestmentSwitchHistoryDetailModel, InvestmentSwitchHistoryInvestmentOptionModel, OptionSwapModel } from "src/app/model/investment-switch-history.model";
import { InvestmentSwitchTypeOptionsTypeEnum } from "src/app/enums/investment-switch-options.enum";
import { AccountClassificationEnum } from "src/app/enums/account-classification.enum";

@Component({
    selector: 'app-investment-switch-history-card',
    templateUrl: './investment-switch-history-card.component.html',
    styleUrls: ['./investment-switch-history-card.component.scss']
})
export class InvestmentSwitchHistoryCardComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set details(value: InvestmentSwitchHistoryDetailModel) {
        this.investmentSwitchDetails = value;
    }
    @Input() set accountClass(value: number) {
        this.accountClassification = value;
    }

    accountClassification: number = null;
    investmentSwitchDetails: InvestmentSwitchHistoryDetailModel = null;

    constructor(public store: Store<AppState>) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }

    combineOptions(from: InvestmentSwitchHistoryInvestmentOptionModel[], to: InvestmentSwitchHistoryInvestmentOptionModel[]) {
        let optionsList = [] as OptionSwapModel[];

        optionsList = from.map((option) => ({
            optionId: option.optionId,
            option: option.option,
            from: option.percentage,
            to: 0
        }))

        for (let option of to) {
            // check if option exists in localList
            var existingOption = optionsList.find(x => x.optionId === option.optionId);

            // if it does, assign to the 0 percentage
            if (existingOption) {
                existingOption.to = option.percentage;
            } else {
                optionsList.push({
                    optionId: option.optionId,
                    option: option.option,
                    from: from.length === 0 ? null : 0,
                    to: option.percentage
                })
            }
        }

        return optionsList
    }

    getSwitchType(type: InvestmentSwitchTypeOptionsTypeEnum) {
        if (type === InvestmentSwitchTypeOptionsTypeEnum.current)
            return "Current investment mix";

        return this.accountClassification === AccountClassificationEnum.Accumulation ? "Future contributions" : "Future pension payments";
    }
}
