import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ComponentBase } from "src/app/views/component-base";
import { investmentSwitchHistory_Data, investmentSwitchHistory_Filter, investmentSwitchHistory_IsLoading, investmentSwitchHistory_SwitchStatues, investmentSwitchHistory_SwitchTypes } from "./selectors";
import { RequestAction, ResetAction, ResetFilterAction, SwitchHistoryChangeSwitchStatusFilterAction, SwitchHistoryChangeSwitchTypeFilterAction, SwitchHistoryIncrementPageAction, SwitchHistorySetFromToDateAction } from "./actions";
import { AppState } from "src/app/store/app.states";
import { memberAccountDropdown_SelectedAccount } from "../member-account-dropdown/selectors";
import { distinctUntilChanged, skipUntil, skipWhile } from "rxjs";
import { InvestmentSwitchHistoryFilter } from "./state";
import { FormGroupControls } from "ngrx-forms";
import { Helper, KeyValueModel } from "@ifaa-components/ui-components";

@Component({
  selector: 'app-investment-switch-history',
  templateUrl: './investment-switch-history.component.html',
  styleUrls: ['./investment-switch-history.component.scss']
})
export class InvestmentSwitchHistoryComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  data$ = this.store.pipe(select(investmentSwitchHistory_Data));
  filter$ = this.store.pipe(select(investmentSwitchHistory_Filter));
  isLoading$ = this.store.pipe(select(investmentSwitchHistory_IsLoading));
  switchTypes$ = this.store.pipe(select(investmentSwitchHistory_SwitchTypes));
  switchStatuses$ = this.store.pipe(select(investmentSwitchHistory_SwitchStatues));
  query: InvestmentSwitchHistoryFilter = new InvestmentSwitchHistoryFilter();
  filter: string = '';
  accountId: number;
  accountClassification: number;
  helper = new Helper();

  switchType = 'All';
  switchStatus = 'All';

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.filter$.subscribe(async x => {
      if (!this.accountId) return;
      if (x) {
        this.filter = await this.formatQuery(x.value);
        if (this.isQueryDifferent(x.value)) {
          this.store.dispatch(RequestAction({ accountId: this.accountId, page: x.value.currentPage, filter: this.filter }));
        }
      }
    });

    this.sub = this.selectedAccount$.pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))).subscribe(x => {
      if (x) {
        this.accountId = x.accountId;
        this.accountClassification = x.accountClassificationId;
        this.store.dispatch(RequestAction({ accountId: this.accountId, page: 0, filter: this.filter }));
      }
    })
  }

  ngOnDestroy() {
    this.store.dispatch(ResetAction());
    super.ngOnDestroyBase();
  }

  onClearFilterClick() {
    this.store.dispatch(ResetFilterAction())
  }

  isQueryDifferent(filter: InvestmentSwitchHistoryFilter): boolean {
    if (filter.currentPage == -1)
      return false;

    if (JSON.stringify(this.query) !== JSON.stringify(filter)) {
      this.query = filter;
      return true;
    }
    return false;
  }

  async formatQuery(value: InvestmentSwitchHistoryFilter): Promise<string> {
    if (!value) return '';

    var list = [];
    if (value.from) {
      list.push("from:" + value.from);
    }
    if (value.to) {
      list.push("to:" + value.to);
    }
    if (value.switchStatus) {
      list.push("status:" + value.switchStatus)
    }
    if (value.switchType) {
      list.push("type:" + value.switchType)
    }
    return list.join(';');
  }

  onLoadMoreTransactions() {
    this.store.dispatch(SwitchHistoryIncrementPageAction());
  }

  currentFinancialYear(filter: FormGroupControls<InvestmentSwitchHistoryFilter>) {
    var dates = this.helper.getFinancialYear(new Date());
    this.store.dispatch(SwitchHistorySetFromToDateAction({ from: dates.from.toISOString(), to: dates.to.toISOString() }));
  }

  previousFinancialYear(filter: FormGroupControls<InvestmentSwitchHistoryFilter>) {
    var today = new Date();
    var dates = this.helper.getFinancialYear(new Date(today.getFullYear() - 1, today.getMonth(), today.getDay()));

    this.store.dispatch(SwitchHistorySetFromToDateAction({ from: dates.from.toISOString(), to: dates.to.toISOString() }));
  }

  clearYear() {
    this.store.dispatch(SwitchHistorySetFromToDateAction({ from: '', to: '' }));
  }

  onLoadMore() {
    this.store.dispatch(SwitchHistoryIncrementPageAction());
  }

  selectTypeFilter(item: KeyValueModel) {
    this.switchType = item.value;
    this.store.dispatch(SwitchHistoryChangeSwitchTypeFilterAction({ filter: item.key as number }))
  }

  selectStatusFilter(item: KeyValueModel) {
    this.switchStatus = item.value;
    this.store.dispatch(SwitchHistoryChangeSwitchStatusFilterAction({ filter: item.key as number }))
  }
}
