import { FormGroupState } from 'ngrx-forms';
import { NoticeOfIntentTaxDeductionFormComponentModel, NoticeOfIntentTaxDeductionModel } from 'src/app/model/notice-of-intent-tax-deduction.model';

export interface INoticeOfIntentTaxDeductionContainerState {
  currentStep:number;
  applications: NoticeOfIntentTaxDeductionModel[];
  form: FormGroupState<NoticeOfIntentTaxDeductionContainerDislaimerForm>;
  formComponentData: NoticeOfIntentTaxDeductionFormComponentModel;
}

export class NoticeOfIntentTaxDeductionContainerDislaimerForm{
  disclaimer:boolean = false;
}