import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
// import { RequestAction } from '../../modules/shared/components/member-view/actions';
import { RequestAction as InsuranceRequestAction } from '../../modules/shared/components/insurance-details/actions';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { insuranceDetails_Model } from 'src/app/modules/shared/components/insurance-details/selectors';
import { insurancePreQuestionnaire_ShowStartQuestionnaire, insurancePreQuestionnaire_ShowPreQuestionnaire } from 'src/app/modules/shared/components/insurance-pre-questionnaire/selectors';
import { ResetPreQuestionnaireRequestAction } from 'src/app/modules/shared/components/insurance-pre-questionnaire/actions';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { ShowQuestionnaireRequestAction } from 'src/app/modules/shared/components/insurance-questionnaire/actions';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';
import { Helper } from '@ifaa-components/ui-components';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { InsuranceDetailsModel } from 'src/app/model/insurance-details.model';

@Component({
  selector: 'app-insurance-view',
  templateUrl: './insurance-view.component.html',
  styleUrls: ['./insurance-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class InsuranceViewComponent extends ComponentBase implements OnInit, OnDestroy {

  showQuestionnaireFeatureToggle = FeatureToggleName.member.account.insuranceQuestionnaire.view;
  showInsuranceFeatureToggle = FeatureToggleName.member.account.insuranceDetails.view;

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  showQuestionnaire$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  showStartQuestion$ = this.store.pipe(select(insurancePreQuestionnaire_ShowStartQuestionnaire));
  showPreQuestionnaire$ = this.store.pipe(select(insurancePreQuestionnaire_ShowPreQuestionnaire));
  model$ = this.store.pipe(select(insuranceDetails_Model));
  accountFeatureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));

  featureToggleHelper = new FeatureToggleHelper();
  helper = new Helper();
  showEffectiveDate = false;
  effectiveDateColumnName = '';
  effectiveDateGreaterThan = '';

  constructor(public store: Store<AppState>,
    public translate: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(async x => {
      if (x) {

        this.dispatch(this.store, InsuranceRequestAction({ accountId: x.accountId }));

        var featureToggles = await this.helper.getValue(this.accountFeatureToggles$);

        if (this.featureToggleHelper.checkToggle(this.showQuestionnaireFeatureToggle, featureToggles, x)) {
          this.store.dispatch(ShowQuestionnaireRequestAction({ accountId: x.accountId }));
          this.dispatch(this.store, ResetPreQuestionnaireRequestAction());
        }

      }
    });

    this.sub = this.translate.get('VIEWS.Insurance.ShowEffectiveDate').subscribe(x => {
      this.showEffectiveDate = x;
    });

    this.sub = this.translate.get('VIEWS.Insurance.EffectiveDateColumnName').subscribe(x => {
      this.effectiveDateColumnName = x;
    });
    this.sub = this.translate.get('VIEWS.Insurance.EffectiveDateGreaterThan').subscribe(x => {
      this.effectiveDateGreaterThan = x;
    });

    // this.dispatch(this.store, RequestAction());

  }

  getCoverText(data: InsuranceDetailsModel) {
    if (this.showEffectiveDate && data.hasInsurance) {
      var date = null;
      switch (this.effectiveDateColumnName) {
        case 'LastModified':
          if (this.effectiveDateGreaterThan) {
            var dateToCompare = moment(this.effectiveDateGreaterThan);
            date = moment.max([moment(data.lastModified), dateToCompare]);
          } else {
            date = data.lastModified;
          }
          break;
        case 'PremiumStartDate':
          date = data.premiumStartDate;
          break;
      }
      let momentDate = moment(date);
      if (!momentDate.isValid()) {
        return null;
      }
      return ' as at ' + momentDate.format('DD-MMMM-YYYY');
    }
    return null;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
