import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import * as ActionsList from './actions';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccessYourSuperLastEmployerComponentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: AccessYourSuperService) {
  }

  saveFormData$ = createEffect(() =>this.actions$.pipe(
    ofType(ActionsList.SaveLastEmployerDetailsAction),
    switchMap((action: any) => this.service.saveLastEmployerData(action.accountId, action.applicationId, action.lastEmployerDetails).pipe(
      map(() => {
        return ActionsList.LastEmployerDetailsSavedAction();
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
