<div id="investment-view-container" *ngIf="form$ | async; let form">

  <div *ngIf="(currentStep$ | async) == 0" appanimatecss>
    <app-investment-complex-view *ngIf="'VIEWS.InvestmentEdit.ShowComplexComponent' | translate"
      [isDashboardView]="isDashboardView" [showOptions]="_showOptions"></app-investment-complex-view>
    <app-investment-simple-view *ngIf="!('VIEWS.InvestmentEdit.ShowComplexComponent' | translate)"
      [isDashboardView]="isDashboardView"></app-investment-simple-view>
  </div>

  <div *ngIf="(currentStep$ | async) == 1" appanimatecss>
    <h2 class="mb-3" [innerHTML]="'VIEWS.ManageInvestment.WHERE_TO_INVEST_TEXT' | translate"></h2>
    <p class="description">
      Please provide the percentage of your balance you would like to be invested in each option. The percentage
      should
      be a whole number, can be a minimum of 5% and must total 100%.
    </p>
    <p class="description">
      Once submitted you will not be able to change this request. You will not be able to submit an additional request
      until your request has been processed.
    </p>
    <p class="description">Investment switches will be processed when the price for the requested date has been loaded.
    </p>

    <table class="table mr-2 {{'VIEWS.Global.table-default-style' | translate}} ">
      <thead>
        <tr>
          <th scope="col" *ngIf="screenWidth>992">Option</th>
          <ng-container *featuretoggle="showFeesColumnToggle">
            <th scope="col" *ngIf="screenWidth>992">Investment Fee*</th>
          </ng-container>
          <th scope="col" *ngIf="screenWidth>992">Current (%)</th>
          <th scope="col" *ngIf="screenWidth>992">Current ($)</th>
          <th scope="col" *ngIf="screenWidth>992">%</th>
          <th *ngIf="screenWidth<=992">Options</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of form?.controls.current.controls; let i = index; trackBy: trackByFn">
          <td *ngIf="screenWidth>992"> {{item.value.option}} <span *ngIf="item.value.hasSuperScriptCode">*</span></td>
          <ng-container *featuretoggle="showFeesColumnToggle">
            <td scope="col" *ngIf="screenWidth>992">{{item.value.investmentOptionFee}}</td>
          </ng-container>
          <td *ngIf="screenWidth>992">{{getCurrentPercentage(item.value.option, (currentInfo$|async)?.current)}}%</td>
          <td *ngIf="screenWidth>992">{{getCurrentAmount(item.value.option, (currentInfo$|async)?.current) |
            currency}}
          </td>
          <td *ngIf="screenWidth>992">
            <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="0">
            </app-entry-number>
            <!-- <pre>{{item.controls.percentage | json}}</pre>  -->
          </td>
          <td *ngIf="screenWidth<=992" class="mobile-data">

            <h2 class="option-value">
              {{item.value.option}} <span *ngIf="item.value.hasSuperScriptCode">*</span>
            </h2>

            <div class="d-flex flex-column ">
              <div *featuretoggle="showFeesColumnToggle" class="d-flex flex-row">
                <h5 class="current-header">Investment Fee*:</h5>
                <h5 class="current-data">{{item.value.investmentOptionFee}}</h5>
              </div>
              <div class="d-flex flex-row">
                <h5 class="current-header">Current (%):</h5>
                <h5 class="current-data">{{getCurrentPercentage(item.value.option, (currentInfo$|async)?.current)}}%
                </h5>
              </div>
              <div class="d-flex flex-row">
                <h5 class="current-header">Current ($):</h5>
                <h5 class="current-data">{{getCurrentAmount(item.value.option, (currentInfo$|async)?.current) |
                  currency}}</h5>
              </div>


            </div>
            <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="0">
            </app-entry-number>
          </td>
        </tr>
        <tr>
          <th *ngIf="screenWidth>992"></th>
          <th *ngIf="screenWidth>992"></th>
          <th *ngIf="screenWidth>992"></th>

          <ng-container *featuretoggle="showFeesColumnToggle">
            <th scope="col" *ngIf="screenWidth>992"></th>
          </ng-container>
          <th>
            <div *ngIf="form.value.current.length > 0" class="alert "
              [ngClass]="{'alert-success': currentIsSum100Percent$ | async, 'alert-danger': !(currentIsSum100Percent$|async)}">
              Total: {{currentTotalPercent$ | async}}% of 100%
            </div>
          </th>
        </tr>
      </tbody>
    </table>

    <div *ngIf="(failedPerformanceMessage$ | async); let failedPerformanceMessage">
      <div class="alert alert-info" [innerHTML]="failedPerformanceMessage"></div>
    </div>

    <div class="alert alert-info" *featuretoggle="showFeesColumnToggle"
      [innerHTML]="(customWording$ | async)?.investmentOptionFeeDisclaimer"></div>
    <div *ngIf="(customWording$ | async)?.currentInvestmentOptionClosing" class="alert alert-info"
      [innerHTML]="(customWording$ | async)?.currentInvestmentOptionClosing"></div>
    <div class="d-flex flex-row">
      <div class="w-50"></div>
      <div class="w-50 d-flex flex-row-reverse">
        <app-button class=" m-1 " title="Next" icon="east" (onClick)="onNextItemClick()"
          [isDisabled]="!(currentIsSum100Percent$ | async)">
        </app-button>
        <app-button class=" m-1 " title="Back" icon="west" (onClick)="onBackItemClick()">
        </app-button>
      </div>
    </div>

  </div>


  <div *ngIf="(currentStep$ | async) == 2" appanimatecss>
    <h2 class="mb-3" *ngIf="!isPensionAccount">Where would you like to invest future contributions?
    </h2>
    <h2 class="mb-3" *ngIf="isPensionAccount">Where would you like your future pension payments paid from?
    </h2>

    <p *ngIf="!isPensionAccount">
      Please provide the percentage of your balance you would like to be invested in each option. The percentage
      should
      be a whole number, can be a minimum of 5% and must total 100%.
    </p>
    <p *ngIf="isPensionAccount">
      Please indicate from which option(s) you wish to be paid from. The percentage should be a whole number, can be a
      minimum of 5% and must total 100%.
    </p>
    <p>
      This update will be immediate.
    </p>

    <table class="table mr-2 {{'VIEWS.Global.table-default-style' | translate}}">
      <thead>
        <tr>
          <th scope="col" *ngIf="screenWidth>992">Option</th>
          <ng-container *featuretoggle="showFeesColumnToggle">
            <th scope="col" *ngIf="screenWidth>992">Investment Fee*</th>
          </ng-container>
          <th scope="col" *ngIf="screenWidth>992">Current (%)</th>
          <th scope="col" *ngIf="screenWidth>992">%</th>
          <th *ngIf="screenWidth<=992">Options</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of form?.controls.future.controls; let i = index; trackBy: trackByFn">
          <td *ngIf="screenWidth>992">{{item.value.option}} <span *ngIf="item.value.hasSuperScriptCode">*</span></td>
          <ng-container *featuretoggle="showFeesColumnToggle">
            <td scope="col" *ngIf="screenWidth>992">{{item.value.investmentOptionFee}}</td>
          </ng-container>
          <td *ngIf="screenWidth>992">{{getCurrentPercentage(item.value.option, (currentInfo$|async)?.future)}}%</td>
          <td *ngIf="screenWidth>992">
            <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="0">
            </app-entry-number>
            <!-- <pre>{{item.controls.percentage | json}}</pre>  -->
          </td>
          <td *ngIf="screenWidth<=992" class="mobile-data">

            <h2 class="option-value">
              {{item.value.option}} <span *ngIf="item.value.hasSuperScriptCode">*</span>
            </h2>
            <div class="d-flex flex-column ">
              <div *featuretoggle="showFeesColumnToggle" class="d-flex flex-row">
                <h5 class="current-header">Investment Fee*:</h5>
                <h5 class="current-data">{{item.value.investmentOptionFee}}</h5>
              </div>
              <div class="d-flex flex-row">
                <h5 class="current-header">Current (%):</h5>
                <h5 class="current-data">{{getCurrentPercentage(item.value.option, (currentInfo$|async)?.future)}}%
                </h5>
              </div>
            </div>
            <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="0">
            </app-entry-number>
          </td>
        </tr>
        <tr>
          <th *ngIf="screenWidth>992"></th>
          <th *ngIf="screenWidth>992"></th>

          <ng-container *featuretoggle="showFeesColumnToggle">
            <th scope="col" *ngIf="screenWidth>992"></th>
          </ng-container>
          <th>
            <div *ngIf="form.value.future.length > 0" class="alert "
              [ngClass]="{'alert-success': futureIsSum100Percent$ | async, 'alert-danger': !(futureIsSum100Percent$|async)}">
              Total: {{futureTotalPercent$ | async}}% of 100%
            </div>
          </th>
        </tr>
      </tbody>

    </table>
    <div *ngIf="(failedPerformanceMessage$ | async); let failedPerformanceMessage">
      <div class="alert alert-info" [innerHTML]="failedPerformanceMessage"></div>
    </div>
    <div class="alert alert-info" *featuretoggle="showFeesColumnToggle"
      [innerHTML]="(customWording$ | async)?.investmentOptionFeeDisclaimer"></div>
    <div *ngIf="(customWording$ | async)?.futureInvestmentOptionClosing" class="alert alert-info"
      [innerHTML]="(customWording$ | async)?.futureInvestmentOptionClosing"></div>
    <div class="d-flex flex-row">
      <div class="w-50"></div>
      <div class="w-50 d-flex flex-row-reverse">
        <app-button class=" m-1 " title="Next" icon="east" (onClick)="onNextItemClick()"
          [isDisabled]="!(futureIsSum100Percent$ | async)">
        </app-button>
        <app-button class=" m-1 " title="Back" icon="west" (onClick)="onBackItemClick()">
        </app-button>
      </div>
    </div>

  </div>

  <div *ngIf="(currentStep$ | async) == 3" appanimatecss>
    <h2 class="mb-3">Disclaimer
    </h2>
    <div class="description" [innerHtml]="(customWording$ | async)?.submissionDisclaimer"></div>
    <app-checkbox [control]="form.controls.disclaimer" class="w-100  mr-1 ml-1"
      title="I have read and understood the above disclaimer.">
    </app-checkbox>
    <div class="d-flex flex-row">
      <div class="w-50"></div>
      <div class="w-50 d-flex flex-row-reverse">

        <app-button loading class=" m-1 " title="Submit" icon="east" (onClick)="onSubmitClick(form.value)"
          [isDisabled]="!(allDisclaimerChecked$ | async)">
        </app-button>
        <app-button class=" m-1 " title="Back" icon="west" (onClick)="onBackItemClick()">
        </app-button>
      </div>
    </div>

  </div>
  <div *ngIf="(currentStep$ | async) == 4" appanimatecss>
    <h2 class="mb-3">We have received your request
    </h2>
    <p [innerHTML]="submitText">
    </p>
    <app-button loading class="float-right  mb-4 mt-4" title="Return to Dashboard" icon="keyboard_return" type="submit"
      (onClick)="onGoToDashboardClick()">
    </app-button>

  </div>
</div>