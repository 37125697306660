<div id="limited-service-banner" appanimatecss="animate__fadeInDown animate__delay-1s">
  <mat-accordion class="limited-service-panel">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="mr-2 animate__animated animate__tada animate__repeat-2 animate__delay-2s">campaign</mat-icon>
          <span class="align-self-center banner-title">Member portal is
            currently on 'limited-service'. </span>
        </mat-panel-title>

      </mat-expansion-panel-header>

      <ul class="mt-2 limited-service-text">
        <li *ngFor="let item of limitedServiceBanners" [innerHtml]="item.message" [ngClass]="{'no-disc': item.name === 'Member_LimitedServiceBanner:View'}"></li>
      </ul>

    </mat-expansion-panel>
  </mat-accordion>
</div>
