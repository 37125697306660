import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { GetChangePaymentScheduleRequestsRequestAction, ResetFormAction} from './actions';
import { pensionChangePaymentScheduleRequests_Data } from './selectors';

@Component({
  selector: 'app-pension-change-schedule-requests',
  templateUrl: './pension-change-schedule-requests.component.html',
  styleUrls: ['./pension-change-schedule-requests.component.scss'],
  host: {
    class: 'w-100'
  }
})

export class PensionChangeScheduleRequestsComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  data$ = this.store.pipe(select(pensionChangePaymentScheduleRequests_Data));

  constructor(public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x.accountId) {
        this.store.dispatch(GetChangePaymentScheduleRequestsRequestAction({ accountId: x.accountId }))
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.store.dispatch(ResetFormAction());
  }

  goBack(){
    this.router.navigate(['/pension-details'])
  }
}
