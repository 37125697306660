import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IAccessYourSuperStartComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperStartState = createSelector(memberPortalState, (state) => state.accessYourSuperStartComponentState);

export const accessYourSuperStart_Model = createSelector(
    getAccessYourSuperStartState,
    (state: IAccessYourSuperStartComponentState) => state.model
);
  
export const accessYourSuperStart_Filter = createSelector(
    getAccessYourSuperStartState,
    (state: IAccessYourSuperStartComponentState) => state.filter
);

export const accessYourSuperStart_GetSelectedAccount = createSelector(
    getAccessYourSuperStartState,
    (state: IAccessYourSuperStartComponentState) => state.applicationAccountId
)

export const accessYourSuperStart_ApplicationToCancelled = createSelector(
    getAccessYourSuperStartState,
    (state: IAccessYourSuperStartComponentState) => state.applicationToCancelled
)

export const accessYourSuperStart_ApplicationData = createSelector(
    getAccessYourSuperStartState,
    (state: IAccessYourSuperStartComponentState) => state.applicationData
)

export const accessYourSuperStart_UpdatingSubmittedDocuments = createSelector(
    getAccessYourSuperStartState,
    (state: IAccessYourSuperStartComponentState) => state.updatingSubmittedDocuments
)