import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "src/app/modules/shared/store/shared.reducers";
import { MemberPortalSharedState } from "src/app/modules/shared/store/shared.states";
import { ISevereFinancialHardshipFinancialDetailsV1ComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getSevereFinancialHardshipFinancialDetailsV1State = createSelector(memberPortalState, (state) => state.severeFinancialHardshipFinancialDetailsV1ComponentState);

export const severeFinancialHardshipFinancialDetailsV1_Form = createSelector(
    getSevereFinancialHardshipFinancialDetailsV1State,
    (state: ISevereFinancialHardshipFinancialDetailsV1ComponentState) => state.form
);

export const severeFinancialHardshipFinancialDetailsV1_FormValid = createSelector(
    getSevereFinancialHardshipFinancialDetailsV1State,
    (state: ISevereFinancialHardshipFinancialDetailsV1ComponentState) => {
        return {
            financialDetails:state.form.value,
            isValid:state.form.isValid
        }
    }
);