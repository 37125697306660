import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email, requiredTrue } from 'ngrx-forms/validation';
import { IMemberEditState } from './state';
import { RequestAction, ResetAction, ResponseAction, SubmitResponseAction } from './actions';
import { MemberModel } from 'src/app/model/member.model';
import { AddressModel, Helper } from '@ifaa-components/ui-components';

export const formName = 'memberEditForm';
var helper = new Helper();

export const state: IMemberEditState = {
  form: createFormGroupState(formName, new MemberModel()),
  lastModifiedTime: null,
  originalEmail: '',
  accountId: 0
};

export const validateEditMemberForm = updateGroup<MemberModel>({
  firstName: validate(required),
  lastName: validate(required),
  email: validate(required, email),
  taxFileNumber: validate(helper.tfnValidator),
  tfnTermsAndCondition: (state, parentState) => {
    if (!parentState.value.hasTFN && parentState.value.taxFileNumber) {
      state = validate(state, requiredTrue);
    }
    return state;

  }
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetAction, (state) => {
    return {
      ...state,
      lastModifiedTime: null
    };
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      form: setValue(new MemberModel())(state.form),
      lastModifiedTime: null
    };
  }),


  on(ResponseAction, (state, { payload }) => {
    var clone = helper.clone(payload);
    return {
      ...state,
      originalEmail: clone.email ?? '',
      form: setValue(Object.assign(new MemberModel(), clone,
        {
          postalAddress: Object.assign(new AddressModel(), clone.postalAddress),
          residentialAddress: Object.assign(new AddressModel(), clone.residentialAddress),
        }
      ))(state.form),
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

const editMemberReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditMemberForm,
);

export function memberEditReducer(state: any | undefined, action: Action) {
  return editMemberReducerFormState(state, action);
}
