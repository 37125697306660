import { createAction, props } from "@ngrx/store";
import { NoticeOfIntentTaxDeductionFormComponentModel, NoticeOfIntentTaxDeductionModel } from "src/app/model/notice-of-intent-tax-deduction.model";

export const RequestAction = createAction('[notice of intent tax deduction] request',  props<{ accountId: number }>());

export const ResponseAction = createAction('[notice of intent tax deduction] response ',
  props<{ payload: NoticeOfIntentTaxDeductionModel[] }>());

export const SetCurrentStepAction = createAction('[notice of intent tax deduction] set current step', props<{currentStep:number}>())

export const GetFormDataAction = createAction('[notice of intent tax deduction] get form data', props<{accountId:number}>());
export const SetFormDataAction = createAction('[notice of intent tax deduction] set form data', props<{formData:NoticeOfIntentTaxDeductionFormComponentModel}>())

export const ResetComponentStateAction = createAction('[notice of intent tax deduction] reset state');