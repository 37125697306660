import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';


@Component({
  selector: 'app-elevated-access-banner',
  templateUrl: './elevated-access-banner.component.html',
  styleUrls: ['./elevated-access-banner.component.scss']
})
export class ElevatedAccessBannerComponent extends ComponentBase implements OnInit, OnDestroy {

  displayClose = false;
  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  ngOnInit() {
    this.displayClose = !this.inIframe()
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onCloseClick() {
    window.close();
  }

}

