import { InsuranceQuestionnaireModel, InsuranceQuestionnaireResultModel, InsurancePreQuestionnaireRequestModel, InsuranceRequestModel } from 'src/app/model/insurance-questionnaire.model';
import {
  insuranceQuestionnaireStart_Form, insuranceQuestionnaireStart_State, insuranceQuestionnaireStart_Validated, insuranceQuestionnaireStart_CurrentStep,
  insuranceQuestionnaireStart_TotalSteps, insuranceQuestionnaireStart_Submitted, insuranceQuestionnaireStart_ConfirmedDate, insuranceQuestionnaireStart_isLegallyRecognisedAsGender, insuranceQuestionnaireStart_Gender
} from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ConfirmRequestAction, NextStepAction, PreviousStepAction, ResetFormAction, SubmitRequestAction, ValidateFormAction, ConfirmReadInsuranceRequestInsuranceGuideAction, LegallyRecognisedAsGenderAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_ElevatedAccess, commomState_LoggedinInfo, commomState_SystemConfig_Config, commomState_SystemConfig_Messages } from 'src/app/store/common/common.selectors';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { insuranceQuestionnaire_AgeNextBirthday, insuranceQuestionnaire_Form } from '../insurance-questionnaire/selectors';
import { insurancePreQuestionnaire_Form } from '../insurance-pre-questionnaire/selectors';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';


@Component({
  selector: 'app-insurance-questionnaire-start',
  templateUrl: './insurance-questionnaire-start.component.html',
  styleUrls: ['./insurance-questionnaire-start.component.scss']
})
export class InsuranceQuestionnaireStartComponent extends ComponentBase implements OnInit, OnDestroy {

  state$ = this.store.pipe(select(insuranceQuestionnaireStart_State));
  currentStep$ = this.store.pipe(select(insuranceQuestionnaireStart_CurrentStep));
  validated$ = this.store.pipe(select(insuranceQuestionnaireStart_Validated));
  form$ = this.store.pipe(select(insuranceQuestionnaireStart_Form));
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  totalSteps$ = this.appstore.pipe(select(insuranceQuestionnaireStart_TotalSteps));
  submitted$ = this.appstore.pipe(select(insuranceQuestionnaireStart_Submitted));
  confirmedDate$ = this.appstore.pipe(select(insuranceQuestionnaireStart_ConfirmedDate));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  ageNextBirthday$ = this.appstore.pipe(select(insuranceQuestionnaire_AgeNextBirthday));
  elevatedAccess$ = this.appstore.pipe(select(commomState_ElevatedAccess));
  isLegallyRecognisedAsGender$ = this.appstore.pipe(select(insuranceQuestionnaireStart_isLegallyRecognisedAsGender));
  gender$ = this.appstore.pipe(select(insuranceQuestionnaireStart_Gender));

  submitQuestionnaireFeatureToggle = FeatureToggleName.member.account.insuranceQuestionnaire.update;
  insuranceRequestForm$ = this.appstore.pipe(select(insuranceQuestionnaire_Form));
  insurancePreQuestionnaire$ = this.appstore.pipe(select(insurancePreQuestionnaire_Form));
  messages$ = this.appstore.pipe(select(commomState_SystemConfig_Messages));
  elevatedAccess = false;

  hasPanelOpened: boolean = false;
  selectedAccount: number = 0;
  insuranceQuestionnaire: InsuranceRequestModel;
  insurancePreQuestionnaire: InsurancePreQuestionnaireRequestModel;


  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.insurancePreQuestionnaire$.subscribe(x => {
      this.insurancePreQuestionnaire = x.value as InsurancePreQuestionnaireRequestModel;
    });
    this.sub = this.elevatedAccess$.subscribe(x => {
      this.elevatedAccess = x;
    })
    this.sub = this.insuranceRequestForm$.subscribe(x => {
      this.insuranceQuestionnaire = x.value as InsuranceRequestModel;

      if (this.insuranceQuestionnaire) {

        if (!this.insuranceQuestionnaire.confirmReadInsuranceRequestInsuranceGuide) {
          this.router.navigate(['/insurance']);
        }
        else {
          this.dispatch(this.store, ConfirmReadInsuranceRequestInsuranceGuideAction({ confirmReadInsuranceRequestInsuranceGuide: this.insuranceQuestionnaire.confirmReadInsuranceRequestInsuranceGuide }));
        }
      }
    });


    this.dispatch(this.store, ResetFormAction());

    this.sub = this.validated$.subscribe(validated => {
      if (!validated)
        this.dispatch(this.store, ValidateFormAction());
    });

    this.sub = this.currentStep$.subscribe(step => {
      this.dispatch(this.store, ValidateFormAction());
    });

    this.sub = this.selectedAccount$.subscribe(account => {
      if (account) {
        if (this.selectedAccount != 0 && this.selectedAccount != account.accountId)
          this.router.navigate(['/']);

        this.selectedAccount = account.accountId;

      }
    });

  }

  onDutyToTakeReasonableClicked(opened: boolean) {
    if (opened)
      this.hasPanelOpened = true;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onPreviousClick() {
    this.dispatch(this.store, PreviousStepAction());
  }

  onNextClick() {
    this.dispatch(this.store, NextStepAction());
  }


  onSubmitClick(item: InsuranceQuestionnaireModel) {
    if (this.elevatedAccess) return;
    var request = {
      ...item,
      confirmReadInsuranceRequestInsuranceGuide: this.insuranceQuestionnaire.confirmReadInsuranceRequestInsuranceGuide,
      hasPreExistingConditionOrDisability: this.insurancePreQuestionnaire.hasPreExistingConditionOrDisability,
      engageInHazardousPursuits: this.insurancePreQuestionnaire.engageInHazardousPursuits,
      isPlanningToTakeLeaveWithoutPay: this.insurancePreQuestionnaire.isPlanningToTakeLeaveWithoutPay,
    } as InsuranceQuestionnaireModel;

    this.dispatch(this.store, SubmitRequestAction({ accountId: this.selectedAccount, data: request }));
  }

  onReturnClick() {
    this.router.navigate(['/']);
  }

  onConfirmClick(item: InsuranceQuestionnaireResultModel) {
    this.dispatch(this.store, ConfirmRequestAction({ accountId: this.selectedAccount, data: item }));
  }

  progressBar(currentStep: number, totapSteps: number): number {
    if (currentStep < 0) return 100;
    return Math.floor((100 / totapSteps) * currentStep);
  }

}
