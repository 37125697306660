import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.states";


/**
 * Sanitize HTML
 */
@Pipe({
  name: 'convertNan'
})
export class ConvertNanPipe implements PipeTransform {
  /**
   * Pipe Constructor
   *
   * @param _sanitizer: DomSanitezer
   */
  // tslint:disable-next-line
  constructor(public store: Store<AppState>, protected _sanitizer: DomSanitizer) {
  }

  // Displays 0 instead of values disappearing from UI due to NaN
  transform(value: number): number {
    if (Number.isNaN(value))
        return 0;
    
    return value;
  }
}
