import { AppState } from 'src/app/store/app.states';
import { Directive, Renderer2, Input, ViewContainerRef, TemplateRef, OnChanges, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { FeatureToggleLevel } from 'src/app/model/feature-toggle-level.model';
import { FeatureToggleValue } from 'src/app/model/feature-toggle-value.model';

@Directive({
  selector: '[featuretoggleglobal]',
})
export class FeatureToggleGlobalDirective implements OnChanges, OnDestroy {

  featuretoggle$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));

  sub:Subscription = null;

  @Input() featuretoggleglobal: string;

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public view: ViewContainerRef,
    public template: TemplateRef<any>) {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges() {
    this.sub = this.featuretoggle$
      .pipe(
        distinctUntilChanged((x,y) => {
          if (JSON.stringify(x[0]) !== JSON.stringify(y[0]))
            return false;

          return true;
        }))
      .subscribe((featureToggles) => {
        if (featureToggles) {
          var globalFlag = featureToggles.find(x => x.level === FeatureToggleLevel.global &&
            x.name === this.featuretoggleglobal &&
            x.value === FeatureToggleValue.available);

          // Check if global flag is turned on
          if (globalFlag) {
           
              this.view.createEmbeddedView(this.template);
              return;
           
          }
        }
        this.view.clear();
      });
  }


}
