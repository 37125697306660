// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NoticeOfIntentTaxDeductionFormSubmissionModel } from '../model/notice-of-intent-tax-deduction.model';


@Injectable()
export class NoticeOfIntentTaxDeductionService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getApplicationList(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/NoticeOfIntentTaxDeduction/account/${accountId}/retrieveAllSubmissions`, { headers: this.headers() });
  }

  getFormData(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/NoticeOfIntentTaxDeduction/account/${accountId}/getFormData`, { headers: this.headers() });
  }

  submitForm(accountId: number, body: NoticeOfIntentTaxDeductionFormSubmissionModel): Observable<any> {
    return this.http.post<NoticeOfIntentTaxDeductionFormSubmissionModel>(environment.apiUrl + `/member-portal/NoticeOfIntentTaxDeduction/account/${accountId}/submit`, body, { headers: this.headers() });
  }
}
