// import { commomState_LoggedinInfo } from './../store/common/common.selectors';
import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { commonState_SystemConfig_AccountFeatureToggles } from '../store/common/common.selectors';
import { memberAccountDropdown_SelectedAccount } from '../modules/shared/components/member-account-dropdown/selectors';
import { debounceTime, distinctUntilChanged, map, skipWhile } from 'rxjs/operators';
import { FeatureToggleValue } from '../model/feature-toggle-value.model';
import { FeatureToggleLevel } from '../model/feature-toggle-level.model';

@Injectable()
export class MemberFeatureToggleGuardService implements CanActivate {

    featuretoggle$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

    constructor(public store: Store<AppState>,
        private router: Router) {
    }

    // This guard is used to protect features which are accessed at a member level and are not specific to account type (update details for e.g)..
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let featureFlag = route.data.flag as string;
        return combineLatest([this.featuretoggle$, this.selectedAccount$])
            .pipe(
                debounceTime(200),
                distinctUntilChanged((x, y) => {
                    if (JSON.stringify(x[1]) !== JSON.stringify(y[1]) ||
                        JSON.stringify(x[0]) !== JSON.stringify(y[0]))
                        return false;

                    return true;
                }),
                skipWhile(x => !x[0] || !x[1]),
                map(x => {
                    var globalFlag = x[0].find(flag => flag.level === FeatureToggleLevel.global &&
                        flag.name === featureFlag &&
                        flag.value === FeatureToggleValue.available);

                    if (!globalFlag) {
                        this.router.navigate(["/"])
                        return false;
                    }
                    else {
                        return true;
                    }
                })
            );
    }
}
