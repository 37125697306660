
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { greaterThan, lessThan, lessThanOrEqualTo, required } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction, SaveFormDataAction, SetFullAmountRadioOptionsAction } from './actions';
import { AccessYourSuperUnrestrictedNonPreservedForm, IAccessYourSuperUnrestrictedNonPreservedComponentState } from './state';

export const state: IAccessYourSuperUnrestrictedNonPreservedComponentState = {
  model: null,
  form: createFormGroupState('AccessYourSuperUnrestrictedNonPreservedForm', new AccessYourSuperUnrestrictedNonPreservedForm()),
  fullAmountRadioOptions: null,
  savedFormData:null,
};

export const validateUnrestrictedNonPreservedForm = updateGroup<AccessYourSuperUnrestrictedNonPreservedForm>({
  paymentType: validate(required),
  fullAmountSelection: (state, parentState) => {
    if (parentState.value.paymentType === 0){
      state = validate(state, required)
    }
    return state;
  },
  partialAmountSpecified: (state, parentState) => {
    if (parentState.value.paymentType === 1){

      //TODO: Uncomment this validation in PD-5188 and remove validation below once Acurity fixes account components
      // state = validate(state, required, greaterThan(0), lessThanOrEqualTo(parentState.value.unrestrictedNonPreserved))
      state = validate(state, required, greaterThan(0))
    }
    return state;
  },
  partialWithdrawalSelection: (state, parentState) => {
    if (parentState.value.paymentType === 1){
      state = validate(state,required)
    }
    return state;
  },
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      model: null,
      form: createFormGroupState('AccessYourSuperUnrestrictedNonPreservedForm', new AccessYourSuperUnrestrictedNonPreservedForm()),
      savedFormData:null
    }
  }),
  on(SetFullAmountRadioOptionsAction, (state, { payload }) => {
    return {
      ...state,
      fullAmountRadioOptions: payload
    }
  }),
  on(SavedFormDataAction, (state, {}) => {
    return {
      ...state,
      savedFormData:new Date()
    }
  })
);

const editUnrestrictedNonPreservedReducerFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateUnrestrictedNonPreservedForm
)

export function accessYourSuperUnrestrictedNonPreservedComponentReducer(state: any | undefined, action: Action) {
  return editUnrestrictedNonPreservedReducerFormState(state, action);
}
