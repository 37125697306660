import { investmentOption_currentInfo } from './selectors';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestCurrentDataAction} from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';


@Component({
  selector: 'app-investment-options',
  templateUrl: './investment-options.component.html',
  styleUrls: ['./investment-options.component.scss']
})
export class InvestmentOptionsComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() showButton: boolean = false;

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  currentInfo$ = this.store.pipe(select(investmentOption_currentInfo));
  
  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestCurrentDataAction({ account: x.accountId }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
