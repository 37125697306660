import { FormGroupState } from 'ngrx-forms';
import { SuperannuationProviderItemModel } from 'src/app/model/rollin-edit.model';

export interface ISuperannuationProviderSearchState {
  form: FormGroupState<SuperannuationProviderSearchModel>;
  lastModifiedTime: Date;
}


export class SuperannuationProviderSearchModel {
  list: SuperannuationProviderItemModel[] = [];
  selected: SuperannuationProviderItemModel = new SuperannuationProviderItemModel();
  q: string = '';
}