<div id="pension-change-schedule-container" *ngIf="data$|async; let data">
    <ng-container *ngIf="!data.pendingRequestExists">
        <ng-container *ngIf="!(formSubmitted$|async)">
            <p>Your pension income payment details:</p>

            <form [ngrxFormState]="form" *ngIf="form$|async; let form">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column col-12 pension-info">

                        <div class="pension-info-group">
                            <app-readonly class="col-6" title="Annual maximum"
                                [value]="data.annualMaximum | currency" />
                            <app-readonly class="col-6" title="Annual minimum"
                                [value]="data.annualMinimum | currency" />
                        </div>
                        <div class="pension-info-group">
                            <app-readonly class="col-6" title="Total paid financial year to date"
                                [value]="data.paymentAmountAlreadyMade | currency" />
                            <app-readonly class="col-6" title="Your next scheduled payment date"
                                [value]="data.pensionNextPaymentDate | date" />
                        </div>
                    </div>
                    <div class="d-flex flex-column col-12 schedule-change">
                        <div class="d-flex flex-row flex-wrap">
                            <div class="col-12 col-md-4">
                                <div class="title d-flex flex-row align-items-center">Payment frequency:
                                    <mat-icon class="ml-2 tooltip-readonly"
                                        [matTooltip]="data.formWording.paymentFrequencyTooltip">help_outline</mat-icon>
                                </div>
                                <app-entry-dropdown class="w-100" title="" [customList]="paymentFrequencies$"
                                    [control]="form.controls.paymentFrequency">
                                </app-entry-dropdown>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="title d-flex flex-row align-items-center">Payment type:
                                </div>
                                <app-entry-dropdown class="w-100" title="" [customList]="paymentTypes$"
                                    [control]="form.controls.paymentType">
                                </app-entry-dropdown>
                            </div>

                            <div class="col-12 col-md-4">
                                <ng-container *ngIf="form.value.paymentType !== 1">
                                    <app-readonly title="Payment amount" [value]="form.value.paymentAmount | currency"
                                        [tooltip]="data.formWording.paymentAmountTooltip">
                                    </app-readonly>
                                </ng-container>

                                <ng-container *ngIf="form.value.paymentType === 1">
                                    <div class="title d-flex flex-row align-items-center">Payment amount:
                                        <mat-icon class="ml-2 tooltip-readonly"
                                            [matTooltip]="data.formWording.paymentAmountTooltip">help_outline</mat-icon>
                                    </div>
                                    <app-entry-number *ngIf="form.value.paymentType === 1" title=""
                                        [control]="form.controls.paymentAmount" icon="attach_money" appanimatecss
                                        [decimalplaces]="2" [showincremental]="false">
                                    </app-entry-number>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="nominated-amt-info">
                                <i>Payment amount must be between {{form.value.freqMin | currency}} and
                                    {{form.value.freqMax
                                    | currency}}.</i>
                            </p>
                            <p *ngIf="showMinMaxWarning(form.value.freqMin, form.value.freqMax)"
                                class="nominated-amt-info">
                                <i>You have received
                                    the maximum annual pension amount permitted for the current financial year. Your
                                    next
                                    pension payment will be in the next financial year at your chosen frequency of
                                    {{getFrequencyText(form.value.paymentFrequency)}}. Your payment amount will be
                                    recalculated at the start of the next financial year. Please
                                    click 'Submit' if you wish to continue.</i>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <app-button class="mt-3 ml-1" title="Exit"
                        icon="{{'BTN.change-pension-schedule.cancel.icon' | translate}}"
                        color="{{'BTN.change-pension-schedule.cancel.color' | translate}}"
                        (onClick)="cancelApplication()">
                    </app-button>
                    <app-button class="mt-3 ml-1" title="Submit"
                        icon="{{'BTN.change-pension-schedule.start.icon' | translate}}"
                        color="{{'BTN.change-pension-schedule.start.color' | translate}}"
                        (onClick)="submitApplication()"
                        [isDisabled]="(submitting$|async) || form.isInvalid || !valuesChanged(form.value, data)">
                    </app-button>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="formSubmitted$|async">
            <p>{{data.formWording.submissionWording}}</p>
            <p *ngIf="showDateWarning()">
                {{data.formWording.dateBasedSubmissionWording}}
            </p>
            <div class="d-flex justify-content-end">
                <app-button class="mt-3 ml-1" title="Finish"
                    icon="{{'BTN.change-pension-schedule.start.icon' | translate}}"
                    color="{{'BTN.change-pension-schedule.start.color' | translate}}" routerLink="/pension-details">>
                </app-button>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data.pendingRequestExists">
        <div class="col-12">
            <i>You currently have a pension schedule change request in progress. You will not be able to submit another
                change request until it has been processed.</i>
        </div>
        <div class="d-flex justify-content-between">
            <app-button class="mt-3 ml-1" title="Exit" icon="{{'BTN.change-pension-schedule.cancel.icon' | translate}}"
                color="{{'BTN.change-pension-schedule.cancel.color' | translate}}" (onClick)="cancelApplication()">
            </app-button>
        </div>
    </ng-container>
</div>