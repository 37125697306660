<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="verified_user">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title"> {{'VIEWS.UnderlyingInvestments.title' | translate | textTransform}}</div>
      </div>
    </app-toolbar-options>
  </div>

  <div class="page-content" appanimatecss>
    <app-component-wrapper title="{{title}}" icon="request_quote">
      <div class="d-flex flex-column" slot="start">
        <app-account-underlying-investment-summary
          (titleEvent)="setTitle($event)"></app-account-underlying-investment-summary>
      </div>
    </app-component-wrapper>
  </div>
</div>
