import { KeyValueModel } from '@ifaa-components/ui-components';
import { FormGroupState } from 'ngrx-forms';
import { AccessYourSuperApplicationDataModel, AccessYourSuperApplicationTypesListModel } from 'src/app/model/access-your-super-form.models';

export interface IAccessYourSuperTypeSelectionComponentState {
  form:FormGroupState<AccessYourSuperTypeSelectionDropdowns>;
  firstDropdownValues:KeyValueModel[];
  secondDropdownValues:KeyValueModel[];
  applicationId:number;
  applicationData:AccessYourSuperApplicationDataModel;
}

export class AccessYourSuperTypeSelectionDropdowns{
  firstDropdown:string = null;
  secondDropdown:string = null;
}