import { TransactionService } from './../../../../services/transaction.service';
import { Injectable } from '@angular/core';
import { Actions,  ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { AccountsService } from 'src/app/services/accounts.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccountSummaryEffects {
  constructor(private actions$: Actions,
    private accountsService: AccountsService) {
  }

  getAccountSummary$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.AccountSummaryRequestAction),
    switchMap((action: any) => this.accountsService.getAccountSummary(action.accountId).pipe(
      map((response: any) => {
        return ActionsList.AccountSummaryResponseAction({ payload: response.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
