export enum AccessYourSuperDocumentTypeEnum{
    BankStatement = 1,
    MedicalCertificate = 2,
    DoctorCertificate = 3,
    Q251Letter = 4,
    Q230Letter = 5,
    ExpenseStatement = 6,
    AtoApprovalLetter = 7, 
    ParticipatingKiwisaverScheme = 8,
    AddressProof = 9,
    NzStatutoryDeclaration = 10,
    EmploymentSeparationCertificate = 11,
    OtherDocuments = 12,
    IncomeStatement = 13,
    BankStatementWithTransactions = 14,
    LivingArrangement = 15,
    ShareholdingCert = 16
}