<div id="user-top-dropdown" class="dropdown ">
  <button class="btn {{'VIEWS.user-top-dropdown.btn-color' | translate}} dropdown-toggle d-flex align-items-center"
    type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <span *ngIf="screenWidth>=992">Profile</span>
    <mat-icon *ngIf="screenWidth<992">account_circle</mat-icon>
    <mat-icon *ngIf="screenWidth>=992">expand_more</mat-icon>
  </button>
  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
    <ng-container *featuretoggle="changePasswordFeatureToggle">
      <li *ngIf="!(elevatedAccess$ | async)"><a class="dropdown-item d-flex align-items-center p-2 click"
          [routerLink]="'/change-password'">
          <mat-icon class="mr-3">lock</mat-icon>
          {{'Change password' | textTransform}}
        </a>
      </li>
    </ng-container>
    <li *ngIf="!inIframe()">
      <a class="dropdown-item d-flex align-items-center p-2 signout click" (click)="sigout()">
        <mat-icon class="mr-3">exit_to_app</mat-icon>
        {{'Sign out' | textTransform}}

      </a>
    </li>

  </ul>
</div>
