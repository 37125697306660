import { AppState } from 'src/app/store/app.states';
// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { FeatureToggleValue } from 'src/app/model/feature-toggle-value.model';
import { FeatureToggleLevel } from 'src/app/model/feature-toggle-level.model';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { memberAccountDropdown_SelectedAccount } from '../components/member-account-dropdown/selectors';

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'featureToggle'
})
export class FeatureTogglePipe implements PipeTransform {
  /**
   * Pipe Constructor
   *
   * @param _sanitizer: DomSanitezer
   */
  // tslint:disable-next-line
  constructor(public store: Store<AppState>, protected _sanitizer: DomSanitizer) {
  }

  accountFeatureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  async transform(value: string): Promise<string> {
    var featureToggles = await this.getValue(this.accountFeatureToggles$);
    var selectedAccount = await this.getValue(this.selectedAccount$);

    if (!value) return FeatureToggleValue.available;

    var globalFlag = featureToggles.find(x => x.level === FeatureToggleLevel.global &&
        x.name === value);

      if (!globalFlag) {
        return FeatureToggleValue.hidden;
      }

      if (globalFlag.value !== FeatureToggleValue.available)
        return globalFlag.value;

      var accountFlag = featureToggles.find(x => x.level === FeatureToggleLevel.accountClassification &&
        x.name === value &&
        Number(x.levelReference) === selectedAccount?.accountClassificationId);

      if (!accountFlag ) {
        return FeatureToggleValue.hidden;
      }

      return accountFlag.value;
  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  getValue<T>(observable: Observable<T>): Promise<T> {
    return observable
      .pipe(
        filter(this.hasValue),
        first()
      )
      .toPromise();
  }

}
