
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction, SetKiwiSaverStepAction } from './actions';
import { AccessYourSuperKiwiSaverFormOne, AccessYourSuperKiwiSaverFormThree, AccessYourSuperKiwiSaverFormTwo, IAccessYourSuperKiwiSaverComponentState } from './state';
import { required, requiredTrue } from 'ngrx-forms/validation';
import { AddressModel } from '@ifaa-components/ui-components';

export const state: IAccessYourSuperKiwiSaverComponentState = {
  model: null,
  formOne: createFormGroupState('AccessYourSuperKiwiSaverFormOne', new AccessYourSuperKiwiSaverFormOne()),
  formTwo: createFormGroupState('AccessYourSuperKiwiSaverFormTwo', new AccessYourSuperKiwiSaverFormTwo()),
  formThree: createFormGroupState('AccessYourSuperKiwiSaverFormThree', new AccessYourSuperKiwiSaverFormThree()),
  savedFormData: null,
  kiwiSaverStep: 1
};

const maxDateValidator = (input: string | null | undefined) => {
  const errors = {};

  // Create a date set at midnight instead of 10am.
  var finishDate = new Date(new Date(input).setHours(0, 0, 0, 0));

  if (finishDate > new Date()) {
    errors['futureDateError'] = "Date cannot be in the future";
  }
  return errors;
}

export const validateKiwiSaverFormOne = updateGroup<AccessYourSuperKiwiSaverFormOne>({
  currentEmployerName: validate(required),
  lastAusEmployerName: (state, parentState) => {
    if (!parentState.value.isCurrentEmployerLastAusEmployer) {
      state = validate(state, required)
    }
    return state;
  },
  lastAusEmploymentDate: validate(required, maxDateValidator),
  isEmployerConfirmationRequested: (state, parentState) => {
    if (parentState.value.isEmployerConfirmationRequired) {
      state = validate(state, required, requiredTrue)
    }
    return state;
  },
})

export const validateKiwiSaverFormTwo = updateGroup<AccessYourSuperKiwiSaverFormTwo>({
  lastAusResidentialAddress: validate(required),
  nzResidentialAddress: validate(required)
})

export const validateKiwiSaverFormThree = updateGroup<AccessYourSuperKiwiSaverFormThree>({
  nzIrdNumber: validate(required),
  kiwiSaverSchemeName: validate(required),
  kiwiSaverSchemeRegistrationNumber: validate(required),
  kiwiSaverSchemeAccountNumber: validate(required),
  schemePostalAddress: validate(required)
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload,
      kiwiSaverStep:payload.submissionData.kiwiSaverStep,
      formOne: setValue(Object.assign(new AccessYourSuperKiwiSaverFormOne(),
        {
          currentEmployerName: payload.submissionData.currentEmployerName,
          isCurrentEmployerLastAusEmployer: !payload.submissionData.lastAusEmployerName && payload.submissionData.kiwiSaverStep > 1 ? true : false,
          lastAusEmployerName: payload.submissionData.lastAusEmployerName,
          lastAusEmploymentDate: !payload.submissionData.lastAusEmploymentDate? '': new Date(payload.submissionData.lastAusEmploymentDate).toISOString().split('T')[0],
          isEmployerConfirmationRequested: !payload.isEmployerConfirmationRequired ? false : payload.submissionData.isEmployerConfirmationRequested ? true : false,
          isEmployerConfirmationRequired: payload.isEmployerConfirmationRequired
        }
      ))(state.formOne),
      formTwo: setValue(Object.assign(new AccessYourSuperKiwiSaverFormTwo(),
        {
          lastAusResidentialAddress: Object.assign(new AddressModel(), {...payload.submissionData.lastAusResidentialAddress,isRequired:true}),
          nzResidentialAddress: Object.assign(new AddressModel(), payload.submissionData.nzResidentialAddress ?
            {...payload.submissionData.nzResidentialAddress,isRequired:true} : state.formTwo.value.nzResidentialAddress),
        }
      ))(state.formTwo),
      formThree: setValue(Object.assign(new AccessYourSuperKiwiSaverFormThree(),
        {
          nzIrdNumber: payload.submissionData.nzIrdNumber,
          kiwiSaverSchemeName: payload.submissionData.kiwiSaverSchemeName,
          kiwiSaverSchemeRegistrationNumber: payload.submissionData.kiwiSaverSchemeRegistrationNumber,
          kiwiSaverSchemeAccountNumber: payload.submissionData.kiwiSaverSchemeAccountNumber,
          schemePostalAddress: Object.assign(new AddressModel(), payload.submissionData.schemePostalAddress ?
          {...payload.submissionData.schemePostalAddress,isRequired:true} : state.formThree.value.schemePostalAddress)
        }
      ))(state.formThree)
    };
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      model: null,
      formOne: createFormGroupState('AccessYourSuperKiwiSaverFormOne', new AccessYourSuperKiwiSaverFormOne()),
      formTwo: createFormGroupState('AccessYourSuperKiwiSaverFormTwo', new AccessYourSuperKiwiSaverFormTwo()),
      formThree: createFormGroupState('AccessYourSuperKiwiSaverFormThree', new AccessYourSuperKiwiSaverFormThree()),
      savedFormData: null,
      kiwiSaverStep:1
    }
  }),
  on(SavedFormDataAction, (state) => {
    return {
      ...state,
      savedFormData:new Date()
    }
  }),
  on(SetKiwiSaverStepAction, (state, {step}) => {
    return {
      ...state,
      kiwiSaverStep: step,
      savedFormData:null
    }
  })
);

const editKiwiSaverReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.formOne,
  validateKiwiSaverFormOne
)

const editKiwiSaverReducerFormStateTwo = wrapReducerWithFormStateUpdate(
  editKiwiSaverReducerFormState,
  s => s.formTwo,
  validateKiwiSaverFormTwo
)

const editKiwiSaverReducerFormStateThree = wrapReducerWithFormStateUpdate(
  editKiwiSaverReducerFormStateTwo,
  s => s.formThree,
  validateKiwiSaverFormThree
)

export function accessYourSuperKiwiSaverComponentReducer(state: any | undefined, action: Action) {
  return editKiwiSaverReducerFormStateThree(state, action);
}
