import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IInvestmentOptionState } from './state';

export const investmentPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const investmentOption = createSelector(investmentPortalState, (state) => state.investmentOptionState);

export const investmentOption_currentInfo = createSelector(
  investmentOption,
  (state: IInvestmentOptionState) => state.currentInfo
);

