<div id="view-assets-container">
  <div class="page-header">
    <app-toolbar-options icon="file_copy">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'View Assets' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper icon="analytics" header="Super">
      <div class="d-flex flex-column" slot="start">
        <app-account-underlying-view></app-account-underlying-view>
      </div>
    </app-component-wrapper>
    <p [innerHTML]="'viewAssets_BottomPageDescription'|message|async"></p>

  </div>
</div>
