import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AccessYourSuperApplicationType, AccessYourSuperDocumentOverviewModel, AccessYourSuperDocumentSubmissionModel } from "src/app/model/access-your-super-form.models";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import {
    SetupSupportingDocumentOverviewAction, ResetFileRequestFailedAction, GreenIdVerifiedAction, ResetComponentStateAction, SaveDocumentAction, DeleteDocumentAction,
    AddNewAppFileAction, CompleteDocumentsAction, RemoveAppFileAction, ReplaceDocumentAction
} from "./actions";
import { SetCurrentStepAction } from "../access-your-super-form/actions";
import { accessYourSuperDocuments_Authorities, accessYourSuperDocuments_DeletedDocument, accessYourSuperDocuments_DocumentsCompleted, accessYourSuperDocuments_FilesAndAuthorities, accessYourSuperDocuments_Form, accessYourSuperDocuments_ReferenceValue, accessYourSuperDocuments_ReplacedDocument, accessYourSuperDocuments_FileRequestFailed, accessYourSuperDocuments_SupportingDocuments, accessYourSuperDocuments_UploadedDocument, accessYourSuperDocuments_FormValidity } from "./selectors";
import { AppFileModel, Helper } from "@ifaa-components/ui-components";
import { FormGroupState, MarkAsTouchedAction, SetErrorsAction, SetValueAction } from "ngrx-forms";
import { AccessYourSuperSupportingDocumentModel } from "src/app/model/access-your-super-form.models";
import { debounceTime, distinctUntilChanged, map, pairwise, skipWhile } from "rxjs/operators";
import { commomState_IsLoading } from "src/app/store/common/common.selectors";
import { AccessYourSuperDocumentDetails } from "./state";
import { combineLatest } from "rxjs";
import { commomState_ElevatedAccess } from 'src/app/store/common/common.selectors';

@Component({
    selector: 'app-access-your-super-documents',
    templateUrl: './access-your-super-documents.component.html',
    styleUrls: ['./access-your-super-documents.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class AccessYourSuperDocumentsComponent extends ComponentBase implements OnInit, OnDestroy {

    helper = new Helper();

    @Output() onDocumentsSaved: EventEmitter<any> = new EventEmitter();
    @Output() documentsInvalid: EventEmitter<any> = new EventEmitter();

    @Input() set data(value: AccessYourSuperDocumentOverviewModel[]) {
        var clone = this.helper.clone(value);
        this.model = clone;
    }
    @Input() set nextStep(value: number) {
        this.step = value;
    }
    @Input() set backStep(value: number) {
        this.previousStep = value;
    }
    @Input() set applicationId(value: number) {
        this.id = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set conditionData(value: AccessYourSuperApplicationType) {
        this.applicationType = value;
    }
    @Input() set wording(value: { [key: string]: string }) {
        this.proofOfIdentificationText = value?.proofOfIdentificationText;
    }
    @Input() set draftApplication(value: boolean) {
        this.applicationInDraft = value;
    }
    @Input() set componentTitle(value:string){
        this.title=value;
    }

    proofOfIdentificationText: string = null;
    model: AccessYourSuperDocumentOverviewModel[] = null;
    step: number = null;
    previousStep: number = null;
    id: number = null;
    accountId: number = null;
    applicationType: AccessYourSuperApplicationType = null;
    extensions: string[] = ['.pdf', '.png', '.jpg'];
    applicationInDraft: boolean = null;
    title:string = "Documents";

    form$ = this.store.pipe(select(accessYourSuperDocuments_Form));
    uploadedDocument$ = this.store.pipe(select(accessYourSuperDocuments_UploadedDocument));
    deletedDocument$ = this.store.pipe(select(accessYourSuperDocuments_DeletedDocument));
    replacedDocument$ = this.store.pipe(select(accessYourSuperDocuments_ReplacedDocument));
    uploadFileFailed$ = this.store.pipe(select(accessYourSuperDocuments_FileRequestFailed));
    supportingDocuments$ = this.store.pipe(select(accessYourSuperDocuments_SupportingDocuments));
    documentsCompleted$ = this.store.pipe(select(accessYourSuperDocuments_DocumentsCompleted));
    authorities$ = this.store.pipe(select(accessYourSuperDocuments_Authorities));
    referenceValue$ = this.store.pipe(select(accessYourSuperDocuments_ReferenceValue));
    filesAndAuthorities$ = this.store.pipe(select(accessYourSuperDocuments_FilesAndAuthorities))
    loading$ = this.store.pipe(select(commomState_IsLoading));
    formValid$ = this.store.pipe(select(accessYourSuperDocuments_FormValidity));
    elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));
    elevatedAccess = false;
  

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        if (this.model.length) {
            this.model = this.model.sort((x,y) => x.isDocumentRequired === y.isDocumentRequired ? 0 : x.isDocumentRequired? -1 :1);
            this.model.forEach((documentOverview) => {
                this.dispatch(this.store, SetupSupportingDocumentOverviewAction(
                    {
                        documentTypeId: documentOverview.documentTypeId,
                        referenceRequired: documentOverview.referenceRequired,
                        documentAmount: documentOverview.documentList.length === 0 ? 1 : documentOverview.documentList.length,
                        authorityGiven: documentOverview.authorityGiven,
                        referenceValue: documentOverview.referenceRequired && documentOverview.referenceValue ? documentOverview.referenceValue : null,
                        previouslyProvided: documentOverview.referenceValue ? true : null,
                        isDocumentRequired: documentOverview.isDocumentRequired
                    }
                ))
            })
        }

        this.elevatedAccess$.subscribe(x => {
            this.elevatedAccess = x;
          });

        // Setup the form values once the document arrays have been created
        // Debounce of 100ms so this is only called once
        this.sub = this.supportingDocuments$.pipe(
            distinctUntilChanged((x, y) => {
                return x.controls.length === y.controls.length;
            }),
            debounceTime(100)
        ).subscribe(x => {
            if (x.controls.length > 0) {
                this.model.forEach((documentOverview, overviewIndex) => {
                    var docList = documentOverview.documentList;
                    // Create the placeholders for the documents if they dont exist
                    if (!docList.length) {
                        docList.push(new AccessYourSuperSupportingDocumentModel())
                    }
                    else {
                        if (this.applicationInDraft) {
                            // If the uploaded documents exist, we need update the form values
                            docList.forEach((uploadedDocument, uploadIndex) => {
                                if (x.controls[overviewIndex].controls.files.controls[uploadIndex] && uploadedDocument.originalFileName) {
                                    this.dispatch(this.store, new SetValueAction(x.controls[overviewIndex].controls.files.controls[uploadIndex]?.id, {
                                        filename: uploadedDocument.originalFileName
                                    } as AppFileModel))
                                }
                            })

                            // If the last index of the documentList has a fileName attached, we know the application is a continuation
                            // so add an empty file placeholder
                            if (docList[docList.length - 1].originalFileName) {
                                this.dispatch(this.store, AddNewAppFileAction({ overviewIndex: overviewIndex }))
                                docList.push(new AccessYourSuperSupportingDocumentModel())
                            }
                        }
                    }
                })
            }
        })

        // Subscription listens for changes in files and authorities to handle form validation
        this.sub = this.filesAndAuthorities$.pipe(
            distinctUntilChanged((x, y) => {
                return JSON.stringify(x) === JSON.stringify(y)
            })
        ).subscribe(x => {
            if (x) {
                for (const index in x) {
                    var control = x[index];

                    // Only validate if the document is required
                    if (control.isRequired) {
                        var uploadedFileIndex = control.files.findIndex(o => o.filename !== "" && o.filename !== undefined);

                        // No file uploaded and no authority given
                        if (uploadedFileIndex === -1 && !control.authority) {
                            const errors = {};
                            errors['required'] = 'At least one document is required';
                            this.dispatch(this.store, new SetErrorsAction(control.filesControlId, errors))
                        }
                        // no file uploaded and authority given
                        else if (uploadedFileIndex === -1 && control.authority) {
                            this.dispatch(this.store, new SetErrorsAction(control.filesControlId, {}));
                        }
                        // file uploaded
                        else {
                            this.dispatch(this.store, new SetErrorsAction(control.filesControlId, {}));
                        }
                    }
                }
            }
        })

        // Subscription listens to changes in authorities for documents so parent model can be updated
        this.sub = this.authorities$.pipe(
            distinctUntilChanged((x, y) => {
                return JSON.stringify(x) === JSON.stringify(y)
            }),
            pairwise(),
            skipWhile(x => x[0].length !== x[1].length)
        ).subscribe((x) => {
            if (x[1]) {
                x[1].forEach((authority, index) => {
                    if (x[0][index] !== authority)
                        this.model[index].authorityGiven = authority;
                })
            }
        })

        // Subscription listens to changes in the referenceValue so parent model can be updated
        this.sub = this.referenceValue$.pipe(
            distinctUntilChanged((x, y) => {
                if (x.authorityGiven !== y.authorityGiven)
                    return false;
                return x.value === y.value
            }),
        ).subscribe(x => {
            if (x && x.required && x.authorityGiven) {
                var overview = this.model.find(o => o.referenceRequired);
                overview.referenceValue = x.value;

                if (!x.value || x.value?.length === 0) {
                    const errors = {};
                    errors['required'] = 'This is required';
                    this.dispatch(this.store, new SetErrorsAction(x.controlId, errors))
                }
                else {
                    this.dispatch(this.store, new SetErrorsAction(x.controlId, {}))
                }
            }
            else if (x && x.required && !x.authorityGiven) {
                this.dispatch(this.store, new SetErrorsAction(x.controlId, {}))
            }
        })

        // Subscription to handle successful saving of document
        this.sub = this.uploadedDocument$.subscribe(async x => {
            if (x) {
                // Add document to parent model
                var overviewIndex = this.model.findIndex(o => o.documentTypeId === x.documentTypeId);
                var docList = this.model[overviewIndex].documentList;

                docList[x.documentIndex].uploadedDocumentId = x.uploadedDocumentId;
                docList[x.documentIndex].originalFileName = x.fileName;
                this.dispatch(this.store, new SetValueAction(x.controlId, { filename: x.fileName, submitting: false }))

                // Add new file to parent placeholder and ui component
                if (docList[docList.length - 1].originalFileName) {
                    this.model[overviewIndex].documentList.push(new AccessYourSuperSupportingDocumentModel());
                    this.dispatch(this.store, AddNewAppFileAction({ overviewIndex: overviewIndex }))
                }

                this.onDocumentsSaved.emit(this.model);
            }
        })

        // Subscription to handle successful deletion of document
        this.sub = this.deletedDocument$.subscribe(x => {
            if (x) {
                this.dispatch(this.store, new MarkAsTouchedAction(x.controlId));

                // Remove the document from the model
                var documentList = this.model[x.overviewIndex].documentList;
                documentList.splice(x.uploadIndex, 1);

                // delete the entry-file-component
                this.dispatch(this.store, RemoveAppFileAction({ overviewIndex: x.overviewIndex, uploadIndex: x.uploadIndex }))

                this.onDocumentsSaved.emit(this.model);
            }
        })

        // Subscription to handle replacing an uploaded document
        this.sub = this.replacedDocument$.subscribe(x => {
            if (x) {
                // Remove the replaced document from the model
                var overviewIndex = this.model.findIndex(o => o.documentTypeId === x.documentTypeId);
                this.model[overviewIndex].documentList[x.documentIndex] = new AccessYourSuperSupportingDocumentModel();

                // Send the save document request once the replaced document is deleted
                this.dispatch(this.store, new SetErrorsAction(x.controlId, {}));
                this.dispatch(this.store, SaveDocumentAction({ accountId: this.accountId, applicationId: this.id, documentTypeId: x.documentTypeId, controlId: x.controlId, documentIndex: x.documentIndex, fileName: x.fileName, payload: x.payload, draftApplication:this.applicationInDraft }))

                this.onDocumentsSaved.emit(this.model);
            }
        })

        this.sub = this.uploadFileFailed$.subscribe(x => {
            if (x) {
                this.dispatch(this.store, new SetValueAction(x.controlId, { ...x.file, submitting: false }))
                this.dispatch(this.store, new SetErrorsAction(x.controlId, { customError: 'There was an error uploading your file, please try again.' }))
                this.dispatch(this.store, ResetFileRequestFailedAction())
            }
        })

        this.sub = this.documentsCompleted$.subscribe(x => {
            if (x) {
                this.dispatch(this.store, SetCurrentStepAction({ nextStep: 8 }));
                // Save the documents in parent state
                this.onDocumentsSaved.emit(this.model);
            }
        })

        this.sub = combineLatest([this.formValid$, this.supportingDocuments$])
            .pipe(
                map(([formValid, supportingDocuments]) => ({ formValid, supportingDocuments })),
                pairwise(),
                skipWhile(x => x[0].supportingDocuments.controls.length !== x[1].supportingDocuments.controls.length),
                debounceTime(200)
            )
            .subscribe(x => {
                if (x) {
                    if (!x[1].formValid)
                        this.documentsInvalid.emit(x[1].formValid);
                }
            });
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction())
    }

    goBack() {
        this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.previousStep }))
        // Save the documents in parent state
        this.onDocumentsSaved.emit(this.model);
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }

    onVerified(verified: boolean) {
        this.dispatch(this.store, GreenIdVerifiedAction({ verified }));
    }

    async goNextStep() {
        var form = await this.helper.getValue(this.form$);
        var documentWithCrn = form.value.supportingDocuments.find(o => o.requestAuthority && o.referenceRequired)
        var documents = [];

        documents = form.value.supportingDocuments.map(o => {
            return {
                documentTypeId: o.documentTypeId,
                authorityGiven: o.requestAuthority
            }
        })

        var payload = {
            customerReferenceNumber: documentWithCrn?.referenceValue,
            documents
        } as AccessYourSuperDocumentSubmissionModel;

        this.dispatch(this.store, CompleteDocumentsAction({ accountId: this.accountId, applicationId: this.id, submission: payload }));
    }

    trackByFn(index, item) {
        return index;
    }

    async uploadDocument(document: any, documentTypeId: number, overviewIndex: number, documentIndex: number, fileName: string, controlId: any) {
        var uploadedDocumentId = this.model[overviewIndex].documentList[documentIndex]?.uploadedDocumentId;

        if (uploadedDocumentId) {
            var docs = await this.helper.getValue(this.supportingDocuments$);
            var originalFile = docs.value[overviewIndex].files[documentIndex];
            this.dispatch(this.store, ReplaceDocumentAction({ accountId: this.accountId, applicationId: this.id, uploadedDocumentId: uploadedDocumentId, overviewIndex: overviewIndex, documentIndex: documentIndex, controlId: controlId, documentTypeId: documentTypeId, fileName: fileName, payload: document, originalFile: originalFile, draftApplication:this.applicationInDraft }))
        }
        else {
            this.dispatch(this.store, SaveDocumentAction({ accountId: this.accountId, applicationId: this.id, documentTypeId: documentTypeId, controlId: controlId, documentIndex: documentIndex, fileName: fileName, payload: document, draftApplication:this.applicationInDraft }))
            this.dispatch(this.store, new SetErrorsAction(controlId, {}));
        }
    }

    // controlId is emitted from the component as we dont want to remove the data from within the component
    // until we confirm that if its been uploaded, the delete operation is successful
    removeFile(controlId: any, overviewIndex: number, uploadIndex: number) {
        var uploadedDocumentId = this.model[overviewIndex].documentList[uploadIndex].uploadedDocumentId;

        if (uploadedDocumentId) {
            // send api request to delete file which will then reset the field if successful
            this.dispatch(this.store, DeleteDocumentAction({ accountId: this.accountId, applicationId: this.id, uploadedDocumentId: uploadedDocumentId, overviewIndex: overviewIndex, uploadIndex: uploadIndex, controlId: controlId, draftApplication:this.applicationInDraft }))
        }
        // if the file hasnt been uploaded yet with an id, just reset the field
        else {
            this.store.dispatch(new SetValueAction(controlId, new AppFileModel()));
        }
    }

    checkFileUploaded(files: AppFileModel[]) {
        var fileUploaded = false;

        files.forEach(file => {
            if (file.filename?.length > 0)
                fileUploaded = true;
        })

        return fileUploaded;
    }

    clearReferenceValue(item: FormGroupState<AccessYourSuperDocumentDetails>) {
        var clone = this.helper.clone(item.value);
        clone.referenceValue = '';
        clone.previouslyProvided = null;

        this.dispatch(this.store, new SetValueAction(item.id, clone));
    }
}
