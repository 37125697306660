import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperDrawdownModel, AccessYourSuperPaymentDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { InvestmentItemModel } from "src/app/model/investment-item.model";

export interface IAccessYourSuperCeasedPaidEmploymentComponentState {
    model:AccessYourSuperPaymentDetailSectionModel;
    form:FormGroupState<AccessYourSuperCeasedPaidEmploymentForm>;
    savedFormData:Date;
    taxOptions:KeyValueModel[];
}

export class AccessYourSuperCeasedPaidEmploymentForm extends AccessYourSuperDrawdownModel{
    paymentType:number=0;
    partialAmountSpecified:number=0;
    isBeforeTax:boolean=true;
    restrictedNonPreservedAmount:number=0;
    partialWithdrawalSelection:boolean=false;
    investmentOptionDrawdown:InvestmentItemModel[] = [];
}