<div id="lodge-claim-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Lodge CIRT Claim </div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper class="lodge-claim-component" icon="">
      <div class="d-flex flex-column" slot="start">

        <app-no-items message="You currently have no valid employments to lodge a benefit claim against."
        *ngIf="(form$ | async).value.employers.length <= 0"></app-no-items>

        <!-- <div class="alert alert-info w-100" *ngIf="(form$ | async).value.employers.length <= 0">
          You currently have no valid employments to lodge a benefit claim against.
        </div> -->
        <mat-horizontal-stepper *ngIf="(form$ | async).value.employers.length > 0" [linear]="true" #stepper
          [selectedIndex]="(currentStep$ | async)">
          <mat-step *ngIf="form$ | async; let form">
            <h2>Your Details</h2>
            <div class="header" *ngIf="!form.value.hasTFN">
              <h3 class="mt-4 mb-0">Please provide your Tax File Number
              </h3>
            </div>
            <app-entry title="Tax File Number" [control]="form.controls.taxFileNumber" icon="email"
              *ngIf="!form.value.hasTFN">
            </app-entry>
            <div class="header">
              <h3 class="mt-4 mb-0">Bank Details
              </h3>
            </div>
            <div class="subheader ">
              <h5 class="mt-0 mb-3">Claims will be paid via Direct Deposit to your nominated bank account. Please
                confirm your bank details below</h5>
            </div>
            <div class="d-flex flex-row">
              <div class="flex-fill">
                <app-entry title="Account Name" [control]="form.controls.bankDetails.controls.accountName" icon="email">
                </app-entry>
              </div>
            </div>
            <div class="row">
              <div class="col col-md-6">
                <app-entry title="BSB" [control]="form.controls.bankDetails.controls.bsb" icon="email">
                </app-entry>
              </div>
              <div class="col col-md-6">
                <app-entry title="Account Number" [control]="form.controls.bankDetails.controls.accountNumber"
                  icon="email">
                </app-entry>
              </div>
            </div>
            <div class="header">
              <h3 class="mt-4 mb-0">Claim Type
              </h3>
            </div>
            <div class="subheader ">
              <h5 class="mt-0 mb-3"> To claim all money available in your account, select the option to make a full
                claim<br>
                If you do not wish to claim the contributions from all employers or, you wish to claim a specific
                amount, select "Make a partial claim". </h5>
            </div>
            <app-button-toggle [control]="form.controls.amountType" list="amountTypes"></app-button-toggle>
            <div class="header">
              <h3 class="mt-4 mb-0">Reason for Claim
              </h3>
            </div>
            <app-button-toggle [control]="form.controls.claimType" list="claimTypes"></app-button-toggle>
            <div class="question d-flex flex-row description mt-2">
              <div>
                <div class="title">Wait for Final Contributions?</div>
                <div class="custom-control custom-radio answer">
                  <app-entry-radio title="Wait for Final Contributions?" list="yesNo"
                    [control]="form.controls.waitForFinalContributions"></app-entry-radio>
                </div>
              </div>
            </div>
            <div class="question d-flex flex-row description mt-2">
              <div>
                <div class="title">Are you currently on a Working Holiday Maker Visa? </div>
                <div class="custom-control custom-radio answer">
                  <app-entry-radio title="Are you currently on a Working Holiday Maker Visa? " list="yesNo"
                    [control]="form.controls.workingHolidayMakerVisa"></app-entry-radio>
                </div>
              </div>
            </div>
            <h2 class="mt-3">Claim Amounts</h2>
            <div class="header">
              <h3 class="mt-4 mb-0">Available Employments
              </h3>
            </div>
            <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
              <div class="d-flex flex-row employer-container">
                <div class="card shadow-sm flex-fill m-2 h-100 "
                  *ngFor="let item of form.controls.employers.controls;let i = index;">
                  <div class="header">
                    <h6 class="card-title">Employment Info</h6>
                  </div>
                  <div class="card-body d-flex flex-column pb-3">
                    <div class="card-text description d-flex flex-fill flex-column pl-2">
                      <app-readonly title="Employer" [value]="item.value.name"></app-readonly>
                      <app-readonly title="Start Date" [value]="item.value.startDate | date:'dd/MM/yyyy' ">
                      </app-readonly>
                      <app-readonly title="Available Amount" [value]="item.value.amount | currency">
                      </app-readonly>
                      <app-readonly title="Claim Amount" value="Yes" *ngIf="form.value.amountType === 2" class="w-100">
                      </app-readonly>
                    </div>
                    <div class="question d-flex flex-row description mt-2" *ngIf="form.value.amountType === 1">
                      <div>
                        <div class="custom-control custom-radio answer">
                          <app-button-toggle class="w-100 claim" title="Claim Amount?"
                            [control]="item.controls.claimAmount" list="yesNo"></app-button-toggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="header">
              <h3 class="mt-4 mb-0">Claim Documentation</h3>
            </div>
            <div class="subheader ">
              <h5 class="mt-0 mb-3">
                To allow us to process your claim securely please provide a copy of Certified Identification and the top
                section of your bank statement (which includes your full name, account number and BSB).
              </h5>
            </div>
            <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
              <div>
                <div class="d-flex flex-fill flex-row mt-2 align-items-center">
                  <div *ngFor="let file of claimDocumentationList$ | async;let i = index;">
                    <mat-icon *ngIf="(claimDocumentationList$ | async).length>1 && file.value.base64" class="ml-1 click"
                      (click)="onRemoveAttachmentClick(i)">
                      close</mat-icon>
                    <app-entry-file [file]="file" class="d-flex flex-fill  mr-1 ml-1 mb-4" title="Attachment"
                      [extension]="extensions" [maxSize]="maxfilesize">
                    </app-entry-file>
                  </div>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step *ngIf="form$ | async; let form">
            <div class="header">
              <h3 class="mt-4 mb-0">Confirm
              </h3>
            </div>
            <div class="subheader ">
              <h5 class="mt-0 mb-3">Please review to ensure all details are correct</h5>
            </div>
            <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
              <div class="alert alert-info">
                <ul style="list-style: auto;">
                  <li>All details must be given, in order for your claim to be processed </li>
                  <li>If you are applying for a benefit payment other than as a result of redundancy, please contact the
                    administrator for the information required </li>
                  <li>Claims other than as a result of redundancy, MUST be summitted to the administrator WITHIN 12
                    months of employment termination to be eligible for reduced tax rates. For more information on
                    current rates, visit <a href="https://www.cirt.com.au">www.cirt.com.au</a></li>
                  <li>A $40 transaction fee applies to most claims </li>
                  <li>Contributions are usually made to CIRT the month after they are earned. Unless notified otherwise,
                    your claim will be processed after your final contribution has been received</li>
                  <li>PAYG summaries will not be issued for payments but will be reported to the ATO via Single Touch
                    Payroll. You will find the associated income statement in your ATO MyGov account</li>
                </ul>
              </div>
            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </app-component-wrapper>
    <app-component-wrapper *ngIf="(form$ | async).value.employers.length > 0" class="lodge-claim-component actions"
      icon="" title="">
      <div class="d-flex flex-column" slot="start">
        <div class="d-flex flex-row components-actions mt-0 pt-0" slot="actions" *ngIf="form$ | async; let form">
          <app-button title="Next" *ngIf="(currentStep$ | async) < (totalSteps$ | async)" [form]="form"
            [isDisabled]="form.isDisabled || form.isInvalid" icon="east" (onClick)="onNextClick()">
          </app-button>
          <app-button title="Submit" *ngIf="(currentStep$ | async) === (totalSteps$ | async)"
            [isDisabled]="form.isDisabled || form.isInvalid" icon="send" (onClick)="onSubmitClick(form.value)">
          </app-button>
          <app-button class=" invert-btn mr-3" title="Back" icon="west"
            *ngIf="(currentStep$ | async) > 0 && (currentStep$ | async) <= (totalSteps$ | async)"
            (onClick)="onPreviousClick(stepper)">
          </app-button>
        </div>
      </div>
    </app-component-wrapper>
  </div>
  <ng-template #myTemplate>
    <div class="p-4">
      <h3 class="mb-3">Thank you for your Claim submission
      </h3>
      <app-button loading class="float-right  mb-4 mt-4" title="Return to Dashboard" icon="keyboard_return"
        type="submit" (onClick)="onGoToDashboardClick()">
      </app-button>
    </div>
  </ng-template>
</div>
