import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccountUnderlyingInvestmentSummaryModel } from 'src/app/model/account-underlying-investment-summary.model';

//Underlying Investment Summary
export const RequestUnderlyingInvestmentSummaryAction = createAction('[Account Underlying Investment Summary view] get data', props<{ accountId: number }>());
export const ResponseUnderlyingInvestmentSummaryAction = createAction('[Account Underlying Investment Summary view] set data', props<{ payload: AccountUnderlyingInvestmentSummaryModel }>());

export const InvestmentOptionChangedAction = createAction('[Account Underlying Investment Summary view] investment option', props<{ investmentOptionId: string }>());

export const ShowInvestmentOptionsAction = createAction('[Account Underlying Investment Summary view] show invetsmentsplitn');


