<div *ngIf="showSection(4)" class="mb-4">
    <h3 class="mb-0">Employment and final contribution</h3>
    <div *ngIf="model.employerDetails" class="d-flex flex-wrap">
        <app-readonly title="Last employer name" [value]="model.employerDetails.lastEmployerName"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly title="Are you still working with this employer?"
            [value]="model.employerDetails.stillWithEmployer? 'Yes' : 'No'" class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly *ngIf="!model.employerDetails.stillWithEmployer"
            title="What date did you finish with this employer?" [value]="model.employerDetails.finishDate | date"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly *ngIf="!model.employerDetails.stillWithEmployer"
            title="Are you waiting for your final employer contribution?"
            [value]="model.employerDetails.waitingOnFinalContribution ? 'Yes' : 'No'"
            class="w-50 p-1 flex-fill"></app-readonly>
    </div>
</div>

<div *ngIf="showSection(5) && customData" class="mb-4">
    <h3 class="mb-0">Benefit payment details
    </h3>
    <ng-container *ngIf="model.conditionData.applicationTypeId !== 17">
        <div class="d-flex flex-wrap">
            <app-readonly *ngIf="model.conditionData.applicationTypeId !== 11"
                [title]="customData.formLabels.paymentType"
                [value]="customData.submissionData.paymentTypeId !== 3  ? 'Full amount' : 'Partial amount'"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly
                *ngIf="customData.submissionData.paymentTypeId === 1 || customData.submissionData.paymentTypeId === 2"
                [title]="customData.formLabels.benefitAmount"
                [value]="customData.submissionData.paymentTypeId === 1 ? customData.formLabels.fullAmountOptionOne : customData.formLabels.fullAmountOptionTwo"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly *ngIf="customData.submissionData.paymentTypeId === 3"
                [title]="customData.formLabels.benefitAmount"
                [value]="customData.submissionData.benefitAmount | currency" class="w-50 p-1 flex-fill"></app-readonly>
            <!-- PD-5292: Uncomment this field once the RNP compoent is being returned from Acurity -->
            <!-- <app-readonly *ngIf="customData.submissionData.paymentTypeId === 4"
                [title]="customData.formLabels.benefitAmount"
                [value]="model.accountBalances.restrictedNonPreserved | currency"
                class="w-50 p-1 flex-fill"></app-readonly>-->
            <app-readonly *ngIf="customData.submissionData.paymentTypeId === 5"
                [title]="customData.formLabels.benefitAmount" [value]="customData.formLabels.fullAmountOptionOne"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly *ngIf="customData.submissionData.paymentTypeId === 6"
                [title]="customData.formLabels.benefitAmount" [value]="customData.formLabels.fullAmountOptionThree"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly
                *ngIf="customData.submissionData.paymentTypeId === 7 || customData.submissionData.paymentTypeId === 8"
                [title]="customData.formLabels.benefitAmount"
                [value]="customData.submissionData.paymentTypeId === 7 ? customData.formLabels.fullAmountOptionOne : customData.formLabels.fullAmountOptionTwo"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly *ngIf="customData.submissionData.otherCondition" [title]="customData.formLabels.atoApproved"
                [value]="customData.formLabels.atoApproved ? 'Yes' : 'No'" class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly
                *ngIf="customData.submissionData.isPermanentlyRetired || customData.submissionData.isPermanentlyRetired === false"
                [title]="customData.formLabels.permanentlyRetired"
                [value]="customData.submissionData.isPermanentlyRetired ? 'Yes' : 'No'"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly
                *ngIf="customData.submissionData.isBeforeTax === true || customData.submissionData.isBeforeTax === false"
                title="Tax option"
                [value]="customData.submissionData.isBeforeTax ? customData.formLabels.beforeTax : customData.formLabels.afterTax"
                class="w-50 p-1 flex-fill"></app-readonly>
            <ng-container *ngIf="customData.submissionData.disabilityCondition">
                <app-readonly *ngIf="customData.submissionData.disabilityCondition.illHealthDate"
                    [title]="customData.formLabels.illHealthDate"
                    [value]="customData.submissionData.disabilityCondition.illHealthDate | date"
                    class="w-50 p-1 flex-fill"></app-readonly>
                <app-readonly *ngIf="customData.submissionData.disabilityCondition.disabilityTypeId"
                    [title]="customData.formLabels.releaseCondition"
                    [value]="customData.submissionData.disabilityCondition.disabilityTypeId === 1 ? customData.formLabels.releaseConditionOptionOne : customData.formLabels.releaseConditionOptionTwo"
                    class="w-50 p-1 flex-fill"></app-readonly>
            </ng-container>
            <app-readonly *ngIf="customData.sfhSubmissionData?.sfhSummary"
                [title]="customData.formLabels.summaryQuestion" [value]="customData.sfhSubmissionData.sfhSummary"
                class="w-100 p-1 flex-fill"></app-readonly>
            <app-readonly
                *ngIf="customData.sfhSubmissionData?.receivingQualifyingSupport === true || customData.sfhSubmissionData?.receivingQualifyingSupport === false"
                [title]="customData.reachedPreservationAgePlusThirtyNineWeeks ? customData.formLabels.receivingQualifyingSupport39Weeks : customData.formLabels.receivingQualifyingSupport26Weeks"
                [value]="customData.sfhSubmissionData.receivingQualifyingSupport ? 'Yes' : 'No'"
                class="w-50 p-1 flex-fill"></app-readonly>
        </div>
    </ng-container>
    <ng-container *ngIf="customData.sfhSubmissionData">
        <h3 class="mb-2">Dependants</h3>
        <div class="d-flex flex-wrap">
            <ng-container
                *ngFor="let dependant of customData.sfhSubmissionData.dependantAndFinanceData.dependants; let i = index;">
                <h5 class="w-100 flex-fill mb-0">Dependant {{i+1}}</h5>
                <app-readonly title="Dependant name" [value]="dependant.name" class="w-50 p-1 flex-fill"></app-readonly>
                <app-readonly title="Dependant age" [value]="dependant.age" class="w-50 p-1 flex-fill"></app-readonly>
                <app-readonly title="Dependant relationship" [value]="dependant.relationship"
                    class="w-100 mb-2 p-1 flex-fill"></app-readonly>
            </ng-container>
        </div>
        <h3>Financial details</h3>
        <ng-container [ngSwitch]="customData.sfhSubmissionData.formVersion">
            <sfh-financial-details-review-v1 *ngSwitchCase="1"
                [data]="customData.sfhSubmissionData.dependantAndFinanceData.financialDetails"
                [labels]="customData.formLabels"></sfh-financial-details-review-v1>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="model.conditionData.applicationTypeId === 17">
        <div class="d-flex flex-wrap">
            <app-readonly [title]="customData.formLabels.currentEmployerName"
                [value]="customData.submissionData.currentEmployerName" class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.lastAusEmployerConfirmation"
                [value]="customData.submissionData.lastAusEmployerName ? 'No' : 'Yes'"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly *ngIf="customData.submissionData.lastAusEmployerName"
                [title]="customData.formLabels.lastAusEmployer" [value]="customData.submissionData.lastAusEmployerName"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly *ngIf="customData.submissionData.lastAusEmploymentDate"
                [title]="customData.formLabels.lastAusEmploymentDate"
                [value]="customData.submissionData.lastAusEmploymentDate | date"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly *ngIf="customData.isEmployerConfirmationRequired"
                [title]="customData.formLabels.employerConfirmationInput"
                [value]="customData.submissionData.isEmployerConfirmationRequested ? 'Yes' : 'No'"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.lastAusAddress"
                [value]="customData.submissionData.lastAusResidentialAddress.fullAddress"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.nzAddress"
                [value]="customData.submissionData.nzResidentialAddress.fullAddress"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.irdNumber" [value]="customData.submissionData.nzIrdNumber"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.kiwisaverSchemeName"
                [value]="customData.submissionData.kiwiSaverSchemeName" class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.registrationNumber"
                [value]="customData.submissionData.kiwiSaverSchemeRegistrationNumber"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.schemeAccountNumber"
                [value]="customData.submissionData.kiwiSaverSchemeAccountNumber"
                class="w-50 p-1 flex-fill"></app-readonly>
            <app-readonly [title]="customData.formLabels.schemeAddress"
                [value]="customData.submissionData.schemePostalAddress.fullAddress"
                class="w-50 p-1 flex-fill"></app-readonly>
        </div>
    </ng-container>
    <div class="mt-4" *ngIf="customData.submissionData.partialWithdrawalInvestmentSelection">
        <h3 class="mb-0">Investment drawdown</h3>
        <div class="d-flex flex-wrap">
            <ng-container *ngFor="let option of customData.submissionData.investmentDrawdown">
                <app-readonly [title]="option.option" [value]="option.percentage + '%'"
                    class="w-50 p-1 flex-fill"></app-readonly>
            </ng-container>
        </div>
    </div>
</div>

<div *ngIf="showSection(6)" class="mb-4">
    <h3 class="mb-0">Bank details</h3>
    <div *ngIf="model.employerDetails" class="d-flex flex-wrap">
        <app-readonly title="Financial institution name" [value]="model.bankDetails.bankName"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly title="Account name" [value]="model.bankDetails.accountName"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly title="Account BSB" [value]="model.bankDetails.bsb" class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly title="Account number" [value]="model.bankDetails.accountNumber"
            class="w-50 p-1 flex-fill"></app-readonly>
    </div>
</div>

<div *ngIf="showSection(7)" class="mb-4">
    <h3 class="mb-0">Documents</h3>
    <div *ngIf="model.applicationDocuments" class="d-flex flex-wrap">
        <ng-container *ngFor="let overview of model.applicationDocuments">
            <div class="w-50 p-1 flex-fill">
                <div _ngcontent-boi-c187="" id="app-readonly-container" class="info r">
                    <div _ngcontent-boi-c187="" class="title d-flex flex-row align-items-center">
                        {{overview.documentName}} ({{overview.isDocumentRequired ? 'Required' :
                        'Optional'}}{{overview.documentAmountRequired > 1 ? ', ' + overview.documentAmountRequired
                        :null}})
                        <mat-icon *ngIf="!overview.authorityGiven" class="ml-2 mr-3">upload_file</mat-icon>
                        <mat-icon *ngIf="overview.authorityGiven" class="ml-2 mr-3">task</mat-icon>
                    </div>
                    <div _ngcontent-boi-c187="" class="d-flex align-items-center value-container mt-1 mb-1">
                        <div *ngIf="!overview.authorityGiven" class="d-flex flex-row align-items-center">
                            <p class="align-self-center mb-0 app-copyable">{{checkIfDocumentsExist(overview) ?
                                'Supporting document(s) uploaded' : 'Document(s) not uploaded'}}</p>
                        </div>
                        <div *ngIf="overview.authorityGiven" class="d-flex flex-row align-items-center">
                            <p class="align-self-center mb-0 app-copyable">Authority to request document given
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>