<div id="account-underlying-investment-summary-view-container">
  <p class="text-center" *ngIf="(modelSummary$ | async)  && noOfInvestments == 0">
    Your underlying investments will be available after your first contribution or rollover is processed.
  </p>

  <h6 class="mobile-change-investment" *ngIf="screenWidth<=992">Change investment option</h6>
  <mat-expansion-panel *ngIf="screenWidth<=992" class="m-1" #mobileMenu
    [hideToggle]="(investmentOptions$|async)?.length < 2">
    <mat-expansion-panel-header *ngIf="selectedInvestmentOption$|async; let currentInvestmentSummary">
      <mat-panel-title>
        {{currentInvestmentSummary?.investmentOptionName}}
      </mat-panel-title>
      <mat-panel-description>
        <small> {{currentInvestmentSummary?.percentAllocated | number : '1.2-2' }} %</small>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="(investmentOptions$|async)?.length > 1">
      <ng-container *ngTemplateOutlet="menu;">
      </ng-container>
    </div>
  </mat-expansion-panel>
  <div *ngIf="selectedInvestmentOption$|async; let currentInvestmentSummary">

    <mat-drawer-container class="investment-container">
      <mat-drawer #drawer mode="side" [opened]="screenWidth>992 && (investmentOptions$|async)?.length > 1">
        <ng-container *ngTemplateOutlet="menu;">
        </ng-container>
      </mat-drawer>
      <mat-drawer-content class="{{screenWidth>992 && (investmentOptions$|async)?.length > 1?'pl-4':''}} ">
        <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex" *ngIf="screenWidth>992">
          <a class="navbar-brand flex-fill">{{currentInvestmentSummary?.investmentOptionName}}</a>
          <div class=" badge badge-pill badge-primary" type="button">
            {{currentInvestmentSummary?.percentAllocated | number : '1.2-2' }} %
          </div>
        </nav>
        <div class="d-flex flex-column  top-content w-100">
          <div class="d-flex  {{screenWidth>992?'flex-row':'flex-column'}}">
            <div class="d-flex flex-fill" style="margin:auto">
              <apx-chart #chart1 [series]="chartOptions.series" [states]="chartOptions.states"
                [chart]="chartOptions.chart" [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"
                [legend]="chartOptions.legend" [dataLabels]="chartOptions.dataLabels" [colors]="chartOptions.colors"
                [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis">
              </apx-chart>
            </div>
            <div class="d-flex flex-fill data-info flex-wrap">

              <app-readonly title="Account balance" icon="attach_money" class="w-50"
                value="{{currentInvestmentSummary?.accounBalance | currency}}">
              </app-readonly>

              <app-readonly icon="event" title="As at" class="w-50"
                value="{{currentInvestmentSummary?.asAt  | date }}"></app-readonly>

              <app-readonly *ngIf="currentInvestmentSummary?.unitPrice" icon="attach_money" title="Unit price" [value]="currentInvestmentSummary?.unitPrice" class="w-50"
                tooltip="{{getUnitPriceDate()}}">
              </app-readonly>

              <app-readonly  *ngIf="currentInvestmentSummary?.noOfUnits" icon="tag" title="No of units" class="w-50"
                [value]="currentInvestmentSummary?.noOfUnits | dash"></app-readonly>

            </div>
          </div>
          <div class="d-flex flex-row w-100 mt-3">
            <div class="flex-fill d-flex">
              <div class="assets w-100">
                <div class="asset-type"><strong [innerHtml]="setAssetTitle()"></strong></div>

                <table class="table">

                  <tbody>
                    <tr *ngFor="let investmentOptionDetail of currentInvestmentSummary.investmentOptionDetails;">
                      <td>
                        <div class="circle"
                          [ngStyle]="{'background-color': getInvestmentDetailColor(investmentOptionDetail.consolidateByName)}">
                        </div>
                      </td>
                      <td>
                        {{investmentOptionDetail.investmentOptionDetailName}}
                      </td>
                      <td class="number" *featuretoggle="displayDollarColumn">{{investmentOptionDetail.amountInvested |
                        currency}}</td>
                      <td class="number">{{investmentOptionDetail.percentage | number : '1.2-2'}} %</td>
                    </tr>

                    <tr *ngIf="!isConsolidatedView" class="amount-summary">
                      <td></td>
                      <td>Total</td>
                      <td class="number" *featuretoggle="displayDollarColumn">{{currentInvestmentSummary.amountInvested
                        | currency}}</td>
                      <td class="number">{{getInvestmentOptionPercentageTotal() | number : '1.2-2'}} %</td>
                    </tr>
                  </tbody>
                </table>


              </div>
            </div>
          </div>
        </div>

      </mat-drawer-content>
    </mat-drawer-container>

  </div>

</div>

<ng-template #menu>
  <div *ngIf="modelSummary$|async; let symmary">
    <!-- <h6 class="investment-option-header">Consolidated</h6> -->
    <div class="investment-option {{isConsolidatedView?'selected':''}}" (click)="viewConsolidation(mobileMenu)">
      <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-fill flex-column">
          <h6>Consolidated</h6>
          <mat-progress-bar mode="determinate" value="100" class="mb-1"></mat-progress-bar>
          (100%)
        </div>
        <div>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
    </div>
    <h6 class="investment-option-header d-flex">
      <mat-icon>alt_route</mat-icon>
      <div class="d-flex flex-fill">Split by investment option</div>
      <small class="mr-2 badge badge-info">{{(investmentOptions$|async)?.length}}</small>
    </h6>

    <div class="investment-option {{i == selectedOption && !isConsolidatedView?'selected':''}}"
      *ngFor="let item of (investmentOptions$|async); let i = index" (click)="onSelectedOptionChanged(i, mobileMenu)">
      <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-fill flex-column">
          <h6>{{item.value}}</h6>
          <mat-progress-bar mode="determinate" value="{{getPercentage(item.key, symmary)}}"
            class="mb-1"></mat-progress-bar>
          ({{getPercentage(item.key, symmary) | number : '1.2-2'}}%)
        </div>
        <div>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>
