import { historicalChartView_Model, historicalChartView_SelectedFilter } from './../selectors';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../../views/component-base';
import { AppState } from '../../../../../store/app.states';
import { MemberPortalSharedState } from '../../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../../member-account-dropdown/selectors';
import { TranslateService } from '@ngx-translate/core';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';
import { commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { RequestAction } from '../actions';

@Component({
  selector: 'app-account-bal-without-graph',
  templateUrl: './account-bal-without-graph.component.html',
  styleUrls: ['./account-bal-without-graph.component.scss']
})
export class AccountBalWithoutGraphComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {

  model$ = this.store.pipe(select(historicalChartView_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  accountFeatureToggles$ = this.appStore.pipe(select(commonState_SystemConfig_AccountFeatureToggles));

  viewTransactionsFeatureToggle = FeatureToggleName.member.account.transactions.view;
  viewOtherStatementsFeatureToggle = FeatureToggleName.member.account.viewStatements.view;
  lodgeCirtClaimFeatureToggle = FeatureToggleName.member.account.lodgeCirtClaim.view;
  jetcoClaimFeatureToggle = FeatureToggleName.member.account.JetcoClaim.view;

  featureToggleHelper = new FeatureToggleHelper();

  constructor(public store: Store<MemberPortalSharedState>,
    public appStore: Store<AppState>,
    public translate: TranslateService
  ) {
    super();
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x){
        var nextSearch = {
          accountId: x.accountId,
          from: null,
          to: null
        };
        this.dispatch(this.store, RequestAction(nextSearch));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}

