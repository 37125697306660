import { AppState } from 'src/app/store/app.states';
import { Directive, Renderer2, Input, ViewContainerRef, TemplateRef, OnChanges, OnDestroy, ComponentFactoryResolver, ElementRef, ComponentRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { InsuranceDetailsModel } from 'src/app/model/insurance-details.model';
import { SMRTNoInsuranceComponent } from '../../smrt/components/smrt-no-insurance/smrt-no-insurance.component';
import { NoInsuranceComponent } from '../components/no-insurance/no-insurance.component';


@Directive({
  selector: '[fundComponentLoader]',
})
export class FundComponentLoaderDirective {

  @Input() fundComponentLoader: 'NoInsurance' | 'OtherValue';
  @Input() data: any;
  @Input() fund: any;

  constructor(
    public store: Store<AppState>,
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    
  }

  ngOnInit() {
    var componentRef: ComponentRef<any>;
    switch (this.fundComponentLoader) {
      case 'NoInsurance':
        if (environment.code == 'smrt') {
          let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(SMRTNoInsuranceComponent);

          componentRef = this.viewContainerRef.createComponent(componentFactory);
          componentRef.instance.fund = this.fund;
          componentRef.instance.data = this.data as InsuranceDetailsModel;

        }
        else {
          let componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(NoInsuranceComponent);
          componentRef = this.viewContainerRef.createComponent(componentFactory);
          componentRef.instance.fund = this.fund;
          componentRef.instance.data = this.data as InsuranceDetailsModel;
        }

        if (componentRef) {
          const host = this.element.nativeElement;
          host.insertBefore(componentRef.location.nativeElement, host.firstChild)
        }
        break;
    }


  }

}
