import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { FUTRState } from '../../store/futr.states';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { commomState_LoggedinInfo, commonState_SystemConfig_AccountFeatureToggles, commonState_SystemConfig_LimitedServiceToggles } from 'src/app/store/common/common.selectors';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { AccountFeatureToggle } from 'src/app/model/account-feature-toggles.model';
import { FeatureToggleValue } from 'src/app/model/feature-toggle-value.model';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-tenant-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class TenantDashboardComponent extends ComponentBase implements OnInit, OnDestroy {

  viewTransactionsFeatureToggle = FeatureToggleName.member.account.transactions.view;
  memberProfileSummaryFeatureToggle = FeatureToggleName.member.memberProfileSummary.view;
  accountSummaryFeatureToggle = FeatureToggleName.member.account.accountSummary.view;
  manageInvestmentsFeatureToggle = FeatureToggleName.member.account.manageInvestments.view;
  updateInvestmentsFeatureToggle = FeatureToggleName.member.account.manageInvestments.update;
  rollInFeatureToggle = FeatureToggleName.member.account.rollIn.view;
  recentEmployerDetailsFeatureToggle = FeatureToggleName.member.account.recentEmployerDetails.view;
  dbAccountSummaryFeatureToggle = FeatureToggleName.member.account.definedBenefitAccountSummary.view;
  accountBalWithGraphFeatureToggle = FeatureToggleName.member.account.accountBalWithGraph.view;
  accountBalWithoutGraphFeatureToggle = FeatureToggleName.member.account.accountBalWithoutGraph.view;

  showQuestionnaire$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  featureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  loggedIn$ = this.store.pipe(select(commomState_LoggedinInfo));
  limitedServiceToggles$ = this.store.pipe(select(commonState_SystemConfig_LimitedServiceToggles));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  addSpacingForWidget: boolean = false;
  isPensionAccount: boolean = false;

  limitedServiceToggles: AccountFeatureToggle[] = null;

  constructor(public store: Store<AppState>,
    private router: Router,
    public futrstore: Store<FUTRState>,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.loggedIn$.subscribe(x => {

    });

    this.sub = this.limitedServiceToggles$.subscribe(x => {
      if (x) {
        this.limitedServiceToggles = x.filter(flag => flag.name !== FeatureToggleName.member.maintenance.view);
      }
    });


    this.sub = combineLatest([this.featureToggles$, this.selectedAccount$])
      .subscribe(x => {
        if (x[0] && x[1]) {
          // If account is insurance, dont even check the toggles so we dont need the widget space
          if (x[1].accountClassificationId === 5) {
            return;
          }
          else {
            var accountSummary = x[0].find(flag => flag.name === FeatureToggleName.member.account.accountSummary.view);
            var dbAccountSummary = x[0].find(flag => flag.name === FeatureToggleName.member.account.definedBenefitAccountSummary.view);
            this.isPensionAccount = x[1].accountTypeName == 'Pension' || x[1].isTtrType;

            if (accountSummary.value === FeatureToggleValue.available && x[1].accountClassificationId !== 6) {
              this.addSpacingForWidget = true;
            }
            else if (dbAccountSummary.value === FeatureToggleValue.available && x[1].accountClassificationId === 6) {
              this.addSpacingForWidget = true;
            }
          }
        }
      })
  }


  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onStartClick() {
    this.router.navigate(['rollin']);
  }

  onTellMyEmployerClick() {
    this.router.navigate(['tell-your-employer']);
  }

}
