import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import * as ActionsList from './actions';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccessYourSuperRetirementComponentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: AccessYourSuperService) {
  }

  getCustomSectionData$ = createEffect(() =>this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getCustomSectionData(action.accountId, action.applicationId, action.applicationTypeId).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({payload:data.data});
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  saveCustomSectionData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SaveFormDataAction),
    switchMap((action:any) => this.service.saveRetirementCustomSectionData(action.accountId, action.payload).pipe(
      map((data: any) => {
        return ActionsList.SavedFormDataAction()
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ))
}
