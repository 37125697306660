<div id="investment-switch-history-card" class="card" appanimatecss>
    <div class="top-row">
        <p class="switch-type">{{getSwitchType(investmentSwitchDetails.switchType)}}</p>
        <p class="badge d-flex align-items-center justify-content-center"
            [ngClass]="(investmentSwitchDetails.statusId === 1 ? 'badge-info' : investmentSwitchDetails.statusId === 2 ? 'badge-danger' : 'badge-success' )">
            {{investmentSwitchDetails.switchStatus}}</p>
    </div>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="d-flex align-items-center ">
                <p class="request-date">Requested on: {{investmentSwitchDetails.requestedDate | date:'dd/MM/yyyy, h:mm
                    a'}}</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row flex-wrap">
            <div class="body p-0 col-12">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column align-items-center option-details" *ngFor="let option of combineOptions(investmentSwitchDetails.options?.from,
                    investmentSwitchDetails.options?.to)">
                        <p class="option-name m-0">{{option.option}}</p>
                        <p class="option-percentage m-0">
                            <ng-container *ngIf="option.from !== null">
                                {{option.from}}% <mat-icon class="arrow">arrow_right_alt</mat-icon>
                            </ng-container>
                            {{option.to}}%
                        </p>
                    </div>
                </div>
            </div>

        </div>

    </mat-expansion-panel>
</div>