import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.services';
import { SMRTStartupValidationModel } from '../views/startup/state';

@Injectable()
export class StartupService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  validate(model: SMRTStartupValidationModel): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/smrt/member-portal/Startup/validate`, model, { headers: null });
  }

}
