import { AddressModel } from "@ifaa-components/ui-components";
import { InvestmentItemModel } from "./investment-item.model";


export class AccessYourSuperPaymentDetailSectionModel{
    formLabels: {[key:string]:string};
    currentInvestmentMix: InvestmentItemModel[];
    submissionData: AccessYourSuperPaymentDetailSubmissionModel;
    reachedPreservationAgePlusThirtyNineWeeks: boolean;
}

export class AccessYourSuperSfhDetailSectionModel extends AccessYourSuperPaymentDetailSectionModel{
    sfhSubmissionData: AccessYourSuperSfhDetailSubmissionModel;
}

export class AccessYourSuperPaymentDetailSubmissionModel{
    accessYourSuperPaymentDetailId: number;
    accessYourSuperApplicationId: number;
    paymentTypeId?:number;
    benefitAmount?:number;
    isBeforeTax?:boolean;
    isPermanentlyRetired?:boolean;
    partialWithdrawalInvestmentSelection?:boolean;
    investmentDrawdown?: InvestmentItemModel[];
    disabilityCondition?: AccessYourSuperDisabilityConditionModel;
    otherCondition?: AccessYourSuperOtherConditionModel;
}

export class AccessYourSuperSfhDetailSubmissionModel extends AccessYourSuperPaymentDetailSubmissionModel{
    sfhSummary?:string = "";
    receivingQualifyingSupport?:boolean = false;
    dependantAndFinanceData: AccessYourSuperSfhDependantAndFinanceDataModel = null;
    formVersion?: number = 0;
    currentStep: number = 0;
}

export class AccessYourSuperSfhDependantModel {
    name: string = '';
    age: number = 0;
    relationship: string = '';
}

export class AccessYourSuperSfhDependantAndFinanceDataModel{
    dependants?: AccessYourSuperSfhDependantModel[];
    financialDetails?: {[key:string]:string} // this is a dynamic object at this point until we get into the actual component
}

export class AccessYourSuperDrawdownModel{
    paymentType:number;
    partialAmountSpecified:number;
    partialWithdrawalSelection:boolean;
    investmentOptionDrawdown:InvestmentItemModel[];
}

export class AccessYourSuperDisabilityConditionModel{
    isCeasedEmployment?: boolean;
    medicalConditionSummary?: string;
    illHealthDate: Date;
    disabilityTypeId?: number;
}

export class AccessYourSuperOtherConditionModel{
    isAtoApproved:boolean;
}

export class AccessYourSuperKiwiSaverDetailSectionModel{
    formLabels: {[key:string]:string};
    submissionData: AccessYourSuperKiwiSaverDetailSubmissionModel;
    isEmployerConfirmationRequired: boolean;
}

export class AccessYourSuperKiwiSaverDetailSubmissionModel{
    accessYourSuperKiwiSaverDetailId: number;
    accessYourSuperApplicationId: number;
    currentEmployerName?: string;
    lastAusEmployerName?: string;
    lastAusEmploymentDate?: Date;
    isEmployerConfirmationRequested?: boolean;
    nzIrdNumber?: string;
    kiwiSaverSchemeName?: string;
    kiwiSaverSchemeRegistrationNumber?: string;
    kiwiSaverSchemeAccountNumber?: string;
    lastAusResidentialAddress?: AddressModel;
    nzResidentialAddress?: AddressModel;
    schemePostalAddress?: AddressModel;
    kiwiSaverStep:number;
}