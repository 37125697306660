import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccessYourSuperTypeSelectionComponentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: AccessYourSuperService) {
  }

  submitApplicationSelection$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SelectApplicationTypeAction),
    switchMap((action:any) => this.service.submitApplicationTypeSelection(action.accountId, action.applicationType).pipe(
      map((data:any) => {
        return ActionsList.SaveApplicationIdAction({applicationId: data.data})
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getApplicationData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.GetApplicationDataAction),
    switchMap((action:any) => this.service.getApplicationSpecificData(action.accountId, action.applicationType).pipe(
      map((data:any) => {
        return ActionsList.SaveApplicationDataAction({model:data.data})
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ))
}
