import { VervModule } from './modules/verv/verve.module';
import { environment } from './../environments/environment';
import { Type } from '@angular/core';
import { TenantDashboardComponent as VerveTenantDashboardComponent } from './modules/verv/views/dashboard/dashboard.component'
import { TenantDashboardComponent as CIRTTenantDashboardComponent } from './modules/cirt/views/dashboard/dashboard.component'
import { TenantDashboardComponent as SMRTTenantDashboardComponent } from './modules/smrt/views/dashboard/dashboard.component'
import { TenantDashboardComponent as FUTRTenantDashboardComponent } from './modules/futr/views/dashboard/dashboard.component'
import { MainMasterComponent as VervMainMasterComponent } from './modules/verv/layout/main/main.master'
import { MainMasterComponent as CIRTMainMasterComponent } from './modules/cirt/layout/main/main.master'
import { MainMasterComponent as SMRTMainMasterComponent } from './modules/smrt/layout/main/main.master'
import { MainMasterComponent as FUTRMainMasterComponent } from './modules/futr/layout/main/main.master'
import { NotLoggedInMasterComponent as VervNotLoggedInMasterComponent } from './modules/verv/layout/not-loggedin/not-loggedin.master';
import { NotLoggedInMasterComponent as CIRTNotLoggedInMasterComponent } from './modules/cirt/layout/not-loggedin/not-loggedin.master';
import { NotLoggedInMasterComponent as FUTRNotLoggedInMasterComponent } from './modules/futr/layout/not-loggedin/not-loggedin.master';
import { NotLoggedInMasterComponent as SMRTNotLoggedInMasterComponent } from './modules/smrt/layout/not-loggedin/not-loggedin.master';
import { CIRTModule } from './modules/cirt/cirt.module';
import { SMRTModule } from './modules/smrt/smrt.module';
import { FUTRModule } from './modules/futr/futr.module';

export class GetTenantModule {
  static getModule(list: any[]) {

    if (environment.code == 'verv')
      list.push(VervModule);

    if (environment.code == 'cirt')
      list.push(CIRTModule);

    if (environment.code == 'smrt')
      list.push(SMRTModule);

    if (environment.code == 'futr')
      list.push(FUTRModule);
  }
}


export class GetTenantNotLoggedInLayout {
  static layout(): Type<any> {

    if (environment.code == 'verv')
      return VervNotLoggedInMasterComponent;

    if (environment.code == 'cirt')
      return CIRTNotLoggedInMasterComponent;

    if (environment.code == 'smrt')
      return SMRTNotLoggedInMasterComponent;

    if (environment.code == 'futr')
      return FUTRNotLoggedInMasterComponent;

    return null;
  }

}

export class GetTenantDashboard {
  static layout(): Type<any> {

    if (environment.code == 'verv')
      return VerveTenantDashboardComponent;

    if (environment.code == 'cirt')
      return CIRTTenantDashboardComponent;

    if (environment.code == 'smrt')
      return SMRTTenantDashboardComponent;

    if (environment.code == 'futr')
      return FUTRTenantDashboardComponent;

    return null;
  }

}

export class GetTenantMainLayout {
  static mainLayout(): Type<any> {

    if (environment.code == 'verv')
      return VervMainMasterComponent;

    if (environment.code == 'cirt')
      return CIRTMainMasterComponent;

    if (environment.code == 'smrt')
      return SMRTMainMasterComponent;

    if (environment.code == 'futr')
      return FUTRMainMasterComponent;

    return null;
  }

}

export function GetMainLayout(): Type<any> {

  if (environment.code == 'verv')
    return VervMainMasterComponent;

  if (environment.code == 'cirt')
    return CIRTMainMasterComponent;

  if (environment.code == 'smrt')
    return SMRTMainMasterComponent;

  return null;

}
