import { OkModel } from './../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { SuperannuationProviderItemModel } from 'src/app/model/rollin-edit.model';

export const ResetAction = createAction('[Superannuation provider search] reset');
export const ClearSearchAction = createAction('[Superannuation provider search] clear search');
export const RequestAction = createAction('[Superannuation provider search] request data',props<{ q: string }>());

export const ResponseAction = createAction('[Superannuation provider search] response data ',
  props<{ payload: SuperannuationProviderItemModel[] }>());


export const SelectOptionAction = createAction('[Superannuation provider search] select option',props<{ payload: SuperannuationProviderItemModel }>());