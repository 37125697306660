<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="account_balance_wallet">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Consolidate' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex flex-fill">
      <app-component-wrapper icon="account_circle" header="Member Details" class="w-50 m-2" *ngIf="screenWidth>992">
        <div class="d-flex flex-column" slot="start">
          <app-member-view></app-member-view>
        </div>
      </app-component-wrapper>

      <app-component-wrapper icon="business" header="Details of Other Fund" class="w-50 m-2">
        <div class="d-flex flex-column" slot="start">
          <app-rollin-edit></app-rollin-edit>
        </div>
      </app-component-wrapper>
    </div>


  </div>
</div>
