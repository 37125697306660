<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="verified_user">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Contributions' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper fragmentid="insurance_questionnaire" icon="airplay" header="Contributions Limits">
      <div class="d-flex flex-column" slot="start">
        <app-contributions-limits></app-contributions-limits>
      </div>
    </app-component-wrapper>


  </div>
</div>
