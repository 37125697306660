<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column w-100" slot="start">
        <div *ngIf="limitedServiceToggles?.length > 0" class="mb-3">
          <app-limited-service-banner [toggles]="limitedServiceToggles"></app-limited-service-banner>
        </div>
        <div class="d-flex flex-row justify-content-between">

          <div class="page-title">Overview</div>

        </div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content pt-4" appanimatecss>


    <div class="d-flex align-items-stretch widgets-row-low">
      <div class="first-row d-flex {{screenWidth>992?'flex-row':'flex-column'}} w-100">
        <app-member-view-overview *featuretoggle="memberProfileSummaryFeatureToggle"
          class="d-flex flex-fill widget-1 w-50" [ngClass]="{'mr-3': addSpacingForWidget && screenWidth>992}">
        </app-member-view-overview>

        <app-component-wrapper id="app-consolidate-super-wrapper" icon="merge_type" header="Consolidate your super"
          *featuretoggle="consolidateSuperFeatureToggle" class=" d-flex flex-fill widget-1 w-50"
          [ngClass]="{'ml-3': addSpacingForWidget && screenWidth>992}">
          <div class="d-flex flex-column" slot="start">

            <app-consolidate-super class="w-100"></app-consolidate-super>

          </div>
          <div class="d-flex flex-row components-actions" slot="actions">
            <app-button icon="{{'BTN.consolidate-super.start.icon' | translate}}" title="Start"
              (onClick)="onStartClick()">
            </app-button>
          </div>
        </app-component-wrapper>

        <!-- <app-consolidate-super *featuretoggle="consolidateSuperFeatureToggle" class="d-flex flex-fill   w-50"></app-consolidate-super> -->
      </div>
    </div>

    <app-component-wrapper class="historical-graph-component" icon="" header=""
      *featuretoggle="accountBalWithGraphFeatureToggle">
      <div class="d-flex flex-column" slot="start">
        <app-historical-chart-view></app-historical-chart-view>
      </div>
    </app-component-wrapper>


    <div class="w-100 academy mt-4">
      <a target="_blank" href="https://vervesuper.com.au/learn-members/">
        <img class="w-100" src="https://vervassets.blob.core.windows.net/web/dashboard-learn3.png">
      </a>
    </div>
    <!--
    <app-component-wrapper icon="analytics" header="Super">
      <div class="d-flex flex-column" slot="start">
        <app-account-underlying-view></app-account-underlying-view>
      </div>
    </app-component-wrapper> -->

    <!--
    <app-component-wrapper icon="supervisor_account" header="Beneficiaries">
      <div class="d-flex flex-column" slot="start">
        <app-beneficiaries-view></app-beneficiaries-view>
      </div>
    </app-component-wrapper> -->


  </div>
</div>
