<div id="member-statements-container">
  <div class="page-header">
    <app-toolbar-options icon="article">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Other Documents' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

   <app-component-wrapper fragmentid="insurance_questionnaire" icon="content_copy" header="Document list" >
      <div class="d-flex flex-column" slot="start">
        <app-member-document-viewer  category="other-documents"></app-member-document-viewer>
      </div>
    </app-component-wrapper>
 <!--
    <app-component-wrapper fragmentid="insurance_details" icon="admin_panel_settings" header="Your insurance cover" >
      <div class="d-flex flex-column" slot="start">
        <app-insurance-details></app-insurance-details>
      </div>
    </app-component-wrapper> -->

  </div>
</div>
