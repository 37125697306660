<div id="member-alert-view-container">
  <mat-expansion-panel *ngIf="list.length >0" class="animate__animated animate__fadeInDown">
    <mat-expansion-panel-header>
      <mat-panel-description>

      </mat-panel-description>

      <mat-panel-title>
        <mat-icon
          class="mr-3 animate__animated  animate__swing animate__delay-2s animate__repeat-2">notifications_active</mat-icon>
        <span class="notification-title" *ngIf="list.length == 1">You have a new notification</span>
        <span class="notification-title" *ngIf="list.length > 1">You have {{list.length}} new notifications</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let item of list" class="d-flex flex-fill flex-row m-2 alert-item ">
      <div class="flex-fill d-flex flex-row align-items-center">
        <div class="flex-fill pr-3 alert-text"> {{item.text}}</div>
      </div>
      <div *ngIf="item.actionType == '1'">
        <div (click)="getLink(item.action)" class="d-flex flex-row align-items-center badge badge-light click ">
          {{item.actionName}}
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
