import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperPaymentDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { InvestmentItemModel } from "src/app/model/investment-item.model";

export interface IAccessYourSuperTerminalMedicalConditionComponentState {
    model:AccessYourSuperPaymentDetailSectionModel;
    form:FormGroupState<AccessYourSuperTerminalMedicationConditionForm>;
    savedFormData:Date;
    releaseConditionOptions:KeyValueModel[];
    fullAmountRadioOptions:KeyValueModel[];
}

export class AccessYourSuperTerminalMedicationConditionForm{
    paymentType:number=0;
    fullAmountSelection:number=0;
    partialAmountSpecified:number=0;
    releaseCondition:number=1;
    illHealthDate:string = '';
    showMedicalConditionSummary:boolean = false;
    medicalConditionSummary:string = '';
    partialWithdrawalSelection:boolean=false;
    investmentOptionDrawdown:InvestmentItemModel[] = [];
}