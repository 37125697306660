import { ConfirmationDialog } from '@ifaa-components/ui-components';
import { switchInvestmentsState_Form, switchInvestmentsState_LastModified } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestOptionsAction, RequestSwitchOptionAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-switch-investments-edit',
  templateUrl: './switch-investments-edit.component.html',
  styleUrls: ['./switch-investments-edit.component.scss']
})
export class SwitchInvestmentsEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(switchInvestmentsState_Form));
  lastModified$ = this.store.pipe(select(switchInvestmentsState_LastModified));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  selectedAccount: number = 0;
  constructor(
    public store: Store<MemberPortalSharedState>,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.selectedAccount = x.accountId;
        this.dispatch(this.store, RequestOptionsAction({ accountId: x.accountId }));
      }
    });
    this.sub = this.lastModified$.subscribe(date => {
      if (date) {
        this.dispatch(this.store, RequestOptionsAction({ accountId: this.selectedAccount }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSwitchOptionClick(data: number) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '350px',
      data: 'Do you really want to continue?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dispatch(this.store, RequestSwitchOptionAction({ accountId: this.selectedAccount, selectedId: data }));
      }
    });
  }
}
