import { createAction, props } from "@ngrx/store";
import { AccessYourSuperBankDetailsSubmissionModel } from "src/app/model/access-your-super-form.models";

export const ResetComponentStateAction = createAction('[Access your super bank details component] reset state');
export const SearchBankNameAction = createAction('[Access your super bank details component] search bank name', props<{ bsb:string }>());
export const SetBankNameAction = createAction('[Access your super bank details component] set bank name', props<{ payload:string }>());
export const SetFieldValuesAction = createAction('[Access your super bank details component] set field values', props<{bankName:string, accountName:string, accountNumber: string, bsb: string}>());

export const SaveBankDetailsAction = createAction('[Access your super bank details component] save bank details', props<{accountId:number, applicationId:number, payload:AccessYourSuperBankDetailsSubmissionModel}>());
export const SetBankDetailsSavedAction = createAction('[Access your super bank details component] set bank details saved');
