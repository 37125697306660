<div *ngIf="displayType =='progress'">
  <app-component-wrapper class="w-50 flex-fill" compclass="flex-fill h-100">
    <div class="d-flex flex-column flex-fill" slot="start">
      <div id="account-contribution-limit-view-container">
        <div>
          <div class="header">
            <h2>Contribution limits</h2>
          </div>
          <div class="row ml-3 mt-1 p-1" *ngIf="data$ | async; let data">
            <div class="col-4">
              <div>Concessional:</div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-12 info">
                  <span class="low">{{data.concessionalAmount | currency}}</span>
                  <span class="high">{{data.concessionalCap | currency}}</span>
                  <div class="progress-bar-div">
                    <mat-progress-bar class="concessional-progress-bar" [mode]="mode"
                      value="{{calculateProgressPercentage(data.concessionalAmount, data.concessionalCap)}}">
                    </mat-progress-bar>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div>Non-concessional:</div>
            </div>
            <div class="col-8 info">
              <span class="low">{{data.nonConcessionalAmount | currency}}</span>
              <span class="high">{{data.nonConcessionalCap | currency}}</span>
              <div class="progress-bar-div">
                <mat-progress-bar class="nonconcessional-progress-bar" [mode]="mode"
                  value="{{calculateProgressPercentage(data.nonConcessionalAmount, data.nonConcessionalCap)}}">
                </mat-progress-bar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-component-wrapper>
</div>


<div *ngIf="displayType ==''">
  <div id="contributions-limits-view-container" *ngIf="data$ | async; let data">

    <p class="description" [innerHTML]="'contributionLimits_Description'| message | async">
    </p>

    <div class="table-responsive mt-4" *ngIf="datatype =='table'">
      <table class="table {{'VIEWS.Global.table-default-style' | translate}} table-sm ">
        <thead>
          <tr>
            <th>Type</th>
            <th>Cap</th>
            <th>Amount Processed</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Concessional</td>
            <td>{{data.concessionalCap | currency}}</td>
            <td>{{data.concessionalAmount | currency}}</td>
          </tr>
          <tr>
            <td>Non-Concessional</td>
            <td>{{data.nonConcessionalCap | currency}}</td>
            <td>{{data.nonConcessionalAmount | currency}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex flex-row  widgets-row mt-4" *ngIf="datatype =='gauge'">
      <div class="w-50 widget">
        <h4 class="title">Concessional</h4>
        <div class="gauge gauge__liveupdate" id="gauge">
          <div class="gauge--container" id="gauge-container">
            <div class="gauge--marker"></div>
            <div class="gauge--background"></div>
            <div class="gauge--center"></div>
            <div class="gauge--data"
              style="transform: rotate(-{{calculatePercentage(data.concessionalAmount,data.concessionalCap)}}deg);">
            </div>
            <div class="gauge--data over" *ngIf="data.concessionalExceeded" style="transform: rotate(170deg);"></div>
          </div>
          <div class="gauge--labels mdl-typography--headline">
            <span class="gauge--label__low">
              <div class="flex-fill in-wrapper d-flex flex-column processed">
                <label class="in">
                  {{data.concessionalAmount | currency}}
                </label>
                <h5>Processed</h5>
              </div>
            </span>
            <span class="gauge--label__spacer"></span>
            <span class="gauge--label__high">
              <div class="flex-fill out-wrapper d-flex  flex-column cap">
                <label class="out">
                  {{data.concessionalCap | currency}}
                </label>
                <h5 [ngClass]="{'exceeded': data.concessionalExceeded}">Cap </h5>
              </div>
            </span>
          </div>
        </div>

      </div>
      <div class="w-50 widget">
        <h4 class="title">Non-Concessional</h4>

        <div class="gauge gauge__liveupdate" id="gauge">
          <div class="gauge--container" id="gauge-container">
            <div class="gauge--marker"></div>
            <div class="gauge--background"></div>
            <div class="gauge--center"></div>
            <div class="gauge--data"
              style="transform: rotate(-{{calculatePercentage(data.nonConcessionalAmount,data.nonConcessionalCap)}}deg);">
            </div>
            <div class="gauge--data over" *ngIf="data.nonConcessionalExceeded" style="transform: rotate(170deg);"></div>
          </div>
          <div class="gauge--labels mdl-typography--headline">
            <span class="gauge--label__low">
              <div class="flex-fill in-wrapper d-flex flex-column processed">
                <label class="in">
                  {{data.nonConcessionalAmount | currency}}
                </label>
                <h5>Processed</h5>
              </div>
            </span>
            <span class="gauge--label__spacer"></span>
            <span class="gauge--label__high">
              <div class="flex-fill out-wrapper d-flex  flex-column cap">
                <label class="out">
                  {{data.nonConcessionalCap | currency}}
                </label>
                <h5 [ngClass]="{'exceeded': data.nonConcessionalExceeded}">Cap </h5>
              </div>
            </span>
          </div>
        </div>

      </div>
    </div>
    <p class="description mt-3" [innerHTML]="'contributionLimits_DescriptionBottom'| message | async">
    </p>
    <div class="d-flex flex-fill action">
      <app-button class=" " title="Make a contribution" icon="east" type="submit" routerLink='/make-a-contribution'>
      </app-button>
    </div>
    <!-- <a  routerLink='/personal-contributions' class="btn btn-primary no-text-decoration float-right">Make a contribution</a> -->
  </div>
</div>
