import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { SetCurrentStepAction } from '../access-your-super-form/actions';

/**
 * @title Stepper overview
 */
@Component({
    selector: 'app-access-your-super-stepper',
    templateUrl: 'access-your-super-progress-stepper.component.html',
    styleUrls: ['access-your-super-progress-stepper.component.scss'],
    host: {
        class: "w-100"
    }
})
export class AccessYourSuperStepper extends ComponentBase implements OnInit, OnDestroy {

    @Input() set stepTitles(value: any[]) {
        this.titles = value;
    }
    @Input() onSelectionChangeFn:(titles:any[], event:any) => void;

    @ViewChild('stepper') private stepper: MatStepper;

    titles: any[];
    nextValidStep:number=0;

    constructor(public store: Store<AppState>, private cdRef : ChangeDetectorRef) {
        super();
    }

    ngOnDestroy(): void {
        super.ngOnDestroyBase();
    }
    ngOnInit(): void {
        super.ngOnInitBase();
    }

    resetCompletion(index:number){
        var step = this.stepper.steps.find((x,stepindex) => index === stepindex);
        step.completed = false;
    }

    setSelectedIndex(index:number){
        if (this.titles.length > 0){
            this.titles.forEach((x,i) => {
                var step = this.stepper.steps.find((x,stepindex) => i === stepindex);
                if (i < index){
                    step.completed = true;
                }
            })
            this.stepper.selectedIndex = index;
        }

        if (this.nextValidStep < index){
            this.nextValidStep = index;
            this.cdRef.detectChanges();
        }
    }
}
