import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../component-base';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { commomState_LoggedinInfo, commonState_MemberData } from 'src/app/store/common/common.selectors';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { AccountModel } from 'src/app/model/account.model';
import { RequestAction } from './actions';
import { ToastrService } from 'ngx-toastr';
import { MessagePipe } from 'src/app/modules/shared/pipes/message';

@Component({
  selector: 'app-take-me-with-you-view',
  templateUrl: './take-me-with-you-view.component.html',
  styleUrls: ['./take-me-with-you-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class TakeMeWithYouViewComponent extends ComponentBase implements OnInit, OnDestroy {

  account: AccountModel;
  selected$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  data$ = this.appstore.pipe(select(commonState_MemberData));

  choiceOfFundLetter = '';
  constructor(public store: Store<MemberPortalSharedState>, public router: Router,
    public toastr: ToastrService,
    public message: MessagePipe,
    public appstore: Store<AppState>) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();
    // this.dispatch(this.store, MemberRequestAction());

    this.sub = this.data$.subscribe(async x => {
      var m = await this.message.transform('takeMeWithYou_ChoiceOfFundLetter');
      if (m && x) {
        this.choiceOfFundLetter = m.replace('{{memberNumber}}', x.memberNumber).replace('{{membersFirstName}}', x.firstName);
      }
    });

    this.sub = this.selected$.subscribe(x => {
      this.account = x;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  download() {
    this.store.dispatch(RequestAction({ accountId: this.account.accountId }));
  }

  copyText(html: string): void {
    var type = "text/html";
    var blob = new Blob([html], { type });
    var data = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
      () => {
        this.toastr.success("Text has been copied to clipboard");
      },
      () => {
        /* failure */
      }
    );
  }
}
