import { MemberPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IPensionChangePaymentScheduleRequestsState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const pensionChangePaymentScheduleRequests = createSelector(memberPortalState, (state) => state.pensionChangePaymentScheduleRequestsState);

export const pensionChangePaymentScheduleRequests_Data = createSelector(
  pensionChangePaymentScheduleRequests,
  (state: IPensionChangePaymentScheduleRequestsState) => state.data
);