import { featureKey } from './../../store/shared.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IMemberAlertViewState } from './state';

export const state = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const alertState = createSelector(state, (state) => state.memberAlertViewState);

export const memberAlertView_Model = createSelector(
  alertState,
  (state: IMemberAlertViewState) => state.model
);
