export class AccessYourSuperHelper{
    getCustomSectionTitle = (applicationTypeId: number) : string => {
        switch (applicationTypeId) {
            case 1: {
                return "Retirement benefit payment";
            }
            case 2: {
                return "Ceased work after 60";
            }
            case 3: {
                return "Permanent retirement after preservation age";
            }
            case 4: {
                return "Benefit payment of the RNP component";
            }
            case 5: {
                return "Pension Commutations";
            }
            case 8: {
                return "Terminal medical condition benefit payment";
            }
            case 10: {
                return "Severe financial hardship";
            }
            case 11: {
                return "Compassionate grounds"
            }
            case 13: {
                return "URNP Component"
            }
            case 17: {
                return "Transfer to KiwiSaver"
            }
        }
    }
}