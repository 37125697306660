
import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, required, requiredTrue } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction } from './actions';
import { AccessYourSuperCompassionateGroundsForm, IAccessYourSuperCompassionateGroundsComponentState } from './state';

export const state: IAccessYourSuperCompassionateGroundsComponentState = {
    model: null,
    form: createFormGroupState('AccessYourSuperCompassionateGroundsForm', new AccessYourSuperCompassionateGroundsForm()),
    savedFormData: null,
};

export const validateCompassionateGroundsForm = updateGroup<AccessYourSuperCompassionateGroundsForm>({
    partialAmountSpecified: (state, parentState) => {
        var totalBalance = parentState.value.investmentOptionDrawdown.reduce((a, b) => a + b.amount, 0);
        totalBalance = Math.round(totalBalance * 1000) / 1000;
        state = validate(state, required, greaterThan(0), lessThanOrEqualTo(totalBalance))
        return state;
    },
    isAtoApproved: validate(required)
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(ResponseAction, (state, { payload }) => {
        return {
            ...state,
            model: payload
        };
    }),
    on(ResetComponentStateAction, (state, { }) => {
        return {
            ...state,
            model: null,
            form: createFormGroupState('AccessYourSuperCompassionateGroundsForm', new AccessYourSuperCompassionateGroundsForm()),
            savedFormData: null
        }
    }),
    on(SavedFormDataAction, (state, { }) => {
        return {
            ...state,
            savedFormData: new Date()
        }
    })
);

const editCompassionateGroundsReducerFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateCompassionateGroundsForm
)

export function accessYourSuperCompassionateGroundsComponentReducer(state: any | undefined, action: Action) {
    return editCompassionateGroundsReducerFormState(state, action);
}
