
import { IMemberViewState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { MemberModel } from '../../../../model/member.model';


export const loginState: IMemberViewState = {
  // model: new MemberModel()
};

const reducer = createReducer(loginState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  // on(ResponseAction, (state, { payload }) => {
  //   return {
  //     ...state,
  //     model: payload
  //   };
  // })
);

export function memberViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
