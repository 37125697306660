import { environment } from '../../environments/environment';
import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class NotAuthGuardService implements CanActivate {

  constructor(public store: Store<AppState>,
    private authService: MsalService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    if (this.authService.instance.getAllAccounts().length == 0) {
      this.router.navigate([environment.postLogoutRedirectUri]);
    } else {
      this.router.navigate(['/dashboard']);
    }
    return false;
  }
}
