import {  AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "@ifaa-components/ui-components";
import { select, Store } from "@ngrx/store";
import { distinct, distinctUntilChanged, map, pairwise } from "rxjs/operators";
import { AccessYourSuperKiwiSaverDetailSubmissionModel } from "src/app/model/access-your-super-custom-sections.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { RequestAction, ResetComponentStateAction, SaveFormDataAction, SetKiwiSaverStepAction } from "./actions";
import { accessYourSuperKiwiSaver_FormOne, accessYourSuperKiwiSaver_FormTwo, accessYourSuperKiwiSaver_FormThree, accessYourSuperKiwiSaver_Model, accessYourSuperKiwiSaver_SavedFormData, accessYourSuperKiwiSaver_KiwiSaverStep } from "./selectors";
import { SetCurrentStepAction } from "src/app/modules/shared/components/access-your-super-form/actions";
import { FeatureToggleName } from "src/app/model/feature-toggle-name.model";
import { Observable, combineLatest } from "rxjs";
import { SetErrorsAction, SetValueAction } from "ngrx-forms";
import { AccessYourSuperStepper } from "../../access-your-super-progress-stepper/access-your-super-progress-stepper.component";

@Component({
    selector: 'app-access-your-super-kiwi-saver',
    templateUrl: './access-your-super-kiwi-saver.component.html',
    styleUrls: ['./access-your-super-kiwi-saver.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class AccessYourSuperKiwiSaverComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {

    @Input() set nextStep(value: number) {
        this.step = value;
    }
    @Input() set applicationId(value: number) {
        this.id = value;
    }
    @Input() set backStep(value: number) {
        this.previousStep = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set typeId(value: number) {
        this.applicationTypeId = value;
    }

    employerConfirmationFeatureToggle = FeatureToggleName.member.account.accessYourSuper.kiwiSaverEmployerConfirmation.view;

    model$ = this.store.pipe(select(accessYourSuperKiwiSaver_Model));
    formOne$ = this.store.pipe(select(accessYourSuperKiwiSaver_FormOne));
    formTwo$ = this.store.pipe(select(accessYourSuperKiwiSaver_FormTwo));
    formThree$ = this.store.pipe(select(accessYourSuperKiwiSaver_FormThree));
    savedFormData$ = this.store.pipe(select(accessYourSuperKiwiSaver_SavedFormData));
    kiwiSaverStep$ = this.store.pipe(select(accessYourSuperKiwiSaver_KiwiSaverStep));

    combinedFormData$ = null;

    step: number = null;
    previousStep: number = null
    id: number = null;
    applicationTypeId: number = null;
    kiwiSaverDetailId: number = null;
    accountId: number = null;
    stepperData: any[] = [];


    helper = new Helper();


    @ViewChildren(AccessYourSuperStepper) private kiwiSaverStepper: QueryList<AccessYourSuperStepper>;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngAfterViewInit(){
        this.sub = combineLatest([this.kiwiSaverStepper?.changes, this.kiwiSaverStep$])
            .pipe(map(([queryList, step]) => ({queryList, step})))
            .subscribe(x => {
                if (x.queryList && x.step){
                    x.queryList.first.setSelectedIndex(x.step-1)
                }
            })
    }

    async ngOnInit() {
        super.ngOnInitBase();

        this.combinedFormData$ = combineLatest([this.formOne$, this.formTwo$, this.formThree$]).pipe(
            map(([formOne, formTwo, formThree]) => ({ formOne, formTwo, formThree })),
        )

        this.sub = this.combinedFormData$.pipe(
            pairwise(),
            distinctUntilChanged((x, y) => {
                return JSON.stringify(x) === JSON.stringify(y)
            })).subscribe(x => {
                if (x) {
                    if (!x[0].formOne.value.isCurrentEmployerLastAusEmployer && x[1].formOne.value.isCurrentEmployerLastAusEmployer) {
                        this.store.dispatch(new SetErrorsAction(x[1].formOne.controls.lastAusEmployerName.id, {}))
                        this.store.dispatch(new SetErrorsAction(x[1].formOne.controls.lastAusEmploymentDate.id, {}))
                    }
                }
            })

        this.sub = this.model$.pipe(distinct())
            .subscribe(async x => {
                if (!x) {
                    this.dispatch(this.store, RequestAction({ accountId: this.accountId, applicationId: this.id }))
                }

                if (x) {
                    if (x.submissionData) {
                        this.kiwiSaverDetailId = x.submissionData.accessYourSuperKiwiSaverDetailId;
                        this.dispatch(this.store, SetKiwiSaverStepAction({ step: x.submissionData.kiwiSaverStep }));

                        this.stepperData.push({
                            title: 'Employment details',
                            completed: x.submissionData.kiwiSaverStep > 1 ? true : false,
                            stepIndex: 1
                        })
                        this.stepperData.push({
                            title: 'Addresses',
                            completed: x.submissionData.kiwiSaverStep > 2 ? true : false,
                            stepIndex: 2
                        })
                        this.stepperData.push({
                            title: 'KiwiSaver details',
                            completed: false,
                            stepIndex: 3
                        })
                    }
                }
            });


        // Go next step when form has been successfully saved
        this.sub = this.savedFormData$.subscribe(async x => {
            if (x) {
                var currentStep = await this.helper.getValue(this.kiwiSaverStep$);
                if (currentStep !== 3) {
                    this.dispatch(this.store, SetKiwiSaverStepAction({ step: currentStep + 1 }));
                }
                else {
                    this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.step }))
                }
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction());
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }

    async goNextStep() {
        var payload = null;
        var currentStep = await this.helper.getValue(this.kiwiSaverStep$);

        switch (currentStep) {
            case 1: {
                var formOne = await this.helper.getValue(this.formOne$);
                if (formOne.isTouched) {
                    payload = {
                        accessYourSuperApplicationId: this.id,
                        accessYourSuperKiwiSaverDetailId: this.kiwiSaverDetailId,
                        currentEmployerName: formOne.value.currentEmployerName,
                        lastAusEmployerName: formOne.value.isCurrentEmployerLastAusEmployer ? null : formOne.value.lastAusEmployerName,
                        lastAusEmploymentDate: !formOne.value.lastAusEmploymentDate ? null : new Date(formOne.value.lastAusEmploymentDate),
                        isEmployerConfirmationRequested: formOne.value.isEmployerConfirmationRequired ? formOne.value.isEmployerConfirmationRequested : null,
                        kiwiSaverStep: 1
                    } as AccessYourSuperKiwiSaverDetailSubmissionModel;
                    this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
                }
                else {
                    this.dispatch(this.store, SetKiwiSaverStepAction({ step: currentStep + 1 }));
                }
                break;
            }
            case 2: {
                var formTwo = await this.helper.getValue(this.formTwo$);
                if (formTwo.controls.lastAusResidentialAddress.isTouched || formTwo.controls.nzResidentialAddress.isTouched) {
                    payload = {
                        accessYourSuperApplicationId: this.id,
                        accessYourSuperKiwiSaverDetailId: this.kiwiSaverDetailId,
                        lastAusResidentialAddress: formTwo.value.lastAusResidentialAddress,
                        nzResidentialAddress: formTwo.value.nzResidentialAddress,
                        kiwiSaverStep: 2
                    } as AccessYourSuperKiwiSaverDetailSubmissionModel;
                    this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
                }
                else {
                    this.dispatch(this.store, SetKiwiSaverStepAction({ step: currentStep + 1 }));
                }
                break;
            }
            case 3: {
                var formThree = await this.helper.getValue(this.formThree$);
                if (formThree.isTouched || formThree.controls.schemePostalAddress.isTouched) {
                    payload = {
                        accessYourSuperApplicationId: this.id,
                        accessYourSuperKiwiSaverDetailId: this.kiwiSaverDetailId,
                        nzIrdNumber: formThree.value.nzIrdNumber,
                        kiwiSaverSchemeName: formThree.value.kiwiSaverSchemeName,
                        kiwiSaverSchemeRegistrationNumber: formThree.value.kiwiSaverSchemeRegistrationNumber,
                        kiwiSaverSchemeAccountNumber: formThree.value.kiwiSaverSchemeAccountNumber,
                        schemePostalAddress: formThree.value.schemePostalAddress,
                        kiwiSaverStep: 3
                    } as AccessYourSuperKiwiSaverDetailSubmissionModel;
                    this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
                }
                else {
                    this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.step }))
                }
                break;
            }
        }
    }

    async goBack() {
        var currentStep = await this.helper.getValue(this.kiwiSaverStep$);
        if (currentStep === 1) {
            this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.previousStep }))
        }
        else {
            this.dispatch(this.store, SetKiwiSaverStepAction({ step: currentStep - 1 }));
        }
    }

    onStepperSelectionChange(titles: any[], event: any) {
        this.dispatch(this.store, SetKiwiSaverStepAction({ step: event.selectedIndex + 1 }));
    }
}
