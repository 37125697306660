import { featureKey } from './../../store/shared.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IAccountUnderlyingInvestmentSummaryViewState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccountUnderlyingInvestmentSummaryView = createSelector(memberPortalState, (state) => state.accountUnderlyingInvestmentSummaryViewState);

export const getAccountUnderlyingInvestmentSummaryView_Model = createSelector(
  getAccountUnderlyingInvestmentSummaryView,
  (state: IAccountUnderlyingInvestmentSummaryViewState) => state.model
);

export const getAccountUnderlyingInvestmentSummaryView_Model_investmentOptions = createSelector(
  getAccountUnderlyingInvestmentSummaryView,
  (state: IAccountUnderlyingInvestmentSummaryViewState) => state.model?.investmentOptions
);


export const getAccountUnderlyingInvestmentSummaryView_SelectedInvestmentOption = createSelector(
  getAccountUnderlyingInvestmentSummaryView,
  (state: IAccountUnderlyingInvestmentSummaryViewState) => state.selectedInvestmentOption
);

export const getAccountUnderlyingInvestmentSummaryView_hasMultipleInvestments = createSelector(
  getAccountUnderlyingInvestmentSummaryView,
  (state: IAccountUnderlyingInvestmentSummaryViewState) => state.hasMultipleInvestments
);

export const getAccountUnderlyingInvestmentSummaryView_noOfInvestments = createSelector(
  getAccountUnderlyingInvestmentSummaryView,
  (state: IAccountUnderlyingInvestmentSummaryViewState) => state.noOfInvestments
);

export const getAccountUnderlyingInvestmentSummaryView_isConsolidatedView = createSelector(
  getAccountUnderlyingInvestmentSummaryView,
  (state: IAccountUnderlyingInvestmentSummaryViewState) => state.isConsolidatedView
);


