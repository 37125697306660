<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Overview</div>
        <!-- <div class="page-sub-title ">Please use the website to review your personal information and feel free to contact
          us at any time to discuss any issues you may have.</div> -->
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>


    <div class="d-flex align-items-stretch widgets-row" style="margin-bottom: 60px;">
      <div class="first-row d-flex {{screenWidth>992?'flex-row':'flex-column'}}  w-100">

        <app-member-view-overview *featuretoggle="memberProfileSummaryFeatureToggle" class=" widget-1 w-50" [ngClass]="{'mr-3': screenWidth>992}">
        </app-member-view-overview>
        <div class="d-flex flex-column flex-fill">
          <ng-container *ngIf="(jetco_amount$ | async) == 0">
            <app-current-employer-view *featuretoggle="currentEmployerSummaryFeatureToggle" class="w-100 d-flex flex-fill">
            </app-current-employer-view>
          </ng-container>
          <app-jetco-claim-amount *featuretoggle="jetcoClaimSummaryFeatureToggle" class=" w-100 flex-fill" [ngClass]="{'d-none': (jetco_amount$ | async) == 0}">
          </app-jetco-claim-amount>

        </div>
      </div>
    </div>

    <app-component-wrapper  *featuretoggle="accountBalWithGraphFeatureToggle" class="historical-graph-component" icon="" header="">
      <div class="d-flex flex-column" slot="start">
        <app-historical-chart-view></app-historical-chart-view>
      </div>
    </app-component-wrapper>

    <app-component-wrapper *featuretoggle="employerBalancesSummaryFeatureToggle" class="employer-balances-component" icon="" header="Employer Balances">
      <div class="d-flex flex-column" slot="start">
        <app-employer-balances></app-employer-balances>
      </div>
    </app-component-wrapper>




  </div>
</div>
