
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { greaterThan, lessThan, lessThanOrEqualTo, required } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction, SaveFormDataAction, SetFullAmountRadioOptionsAction } from './actions';
import { AccessYourSuperCeasedWorkSixtyForm, IAccessYourSuperCeasedWorkSixtyComponentState } from './state';

export const state: IAccessYourSuperCeasedWorkSixtyComponentState = {
  model: null,
  form: createFormGroupState('AccessYourSuperCeasedWorkSixtyForm', new AccessYourSuperCeasedWorkSixtyForm()),
  fullAmountRadioOptions: null,
  savedFormData:null,
};

export const validateCeasedWorkSixtyForm = updateGroup<AccessYourSuperCeasedWorkSixtyForm>({
  paymentType: validate(required),
  fullAmountSelection: (state, parentState) => {
    if (parentState.value.paymentType === 0){
      state = validate(state, required)
    }
    return state;
  },
  partialAmountSpecified: (state, parentState) => {
    if (parentState.value.paymentType === 1){
      var totalBalance = parentState.value.investmentOptionDrawdown.reduce((a,b) => a + b.amount, 0);
      totalBalance = Math.round(totalBalance * 1000) / 1000;
      state = validate(state, required, greaterThan(0), lessThanOrEqualTo(totalBalance))
    }
    return state;
  },
  partialWithdrawalSelection: (state, parentState) => {
    if (parentState.value.paymentType === 1){
      state = validate(state,required)
    }
    return state;
  },
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      model: null,
      form: createFormGroupState('AccessYourSuperCeasedWorkSixtyForm', new AccessYourSuperCeasedWorkSixtyForm()),
      savedFormData:null
    }
  }),
  on(SetFullAmountRadioOptionsAction, (state, { payload }) => {
    return {
      ...state,
      fullAmountRadioOptions: payload
    }
  }),
  on(SavedFormDataAction, (state, {}) => {
    return {
      ...state,
      savedFormData:new Date()
    }
  })
);

const editCeasedWorkSixtyReducerFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateCeasedWorkSixtyForm
)

export function accessYourSuperCeasedWorkSixtyComponentReducer(state: any | undefined, action: Action) {
  return editCeasedWorkSixtyReducerFormState(state, action);
}
