import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccountFeesModel } from 'src/app/model/account-fees.model';


export const RequestAction = createAction('[AccountFees] get data',  props<{ accountId: number }>());
export const ResponseAction = createAction('[AccountFees] set data',  props<{ payload: AccountFeesModel }>());



