import { KeyValueModel } from '@ifaa-components/ui-components';
import { createAction, props } from '@ngrx/store';
import { InvestmentSwitchHistoryModel } from 'src/app/model/investment-switch-history.model';

export const RequestAction = createAction('[Investment Switch History] get switch history', props<{ accountId: number, page: number, filter: string }>());
export const ResetAction = createAction('[Investment Switch History] reset data');
export const ResponseAction = createAction('[Investment Switch History] set switch history', props<{ data: InvestmentSwitchHistoryModel }>());
export const ResetFilterAction = createAction('[Investment Switch History] reset filter');
export const SwitchHistoryIncrementPageAction = createAction('[Investment Switch History] history increment page');
export const SwitchHistorySetFromToDateAction = createAction('[Investment Switch History] set  from to dates',
    props<{ from: string, to: string }>());

export const SwitchHistoryChangeSwitchTypeFilterAction = createAction('[Investment Switch History] change switch type filter', props<{ filter: number }>());
export const SwitchHistoryChangeSwitchStatusFilterAction = createAction('[Investment Switch History] change switch type filters', props<{ filter: number }>());