import { KeyValueModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { NoticeOfIntentTaxDeductionContributions, NoticeOfIntentTaxDeductionFormSubmissionModel } from "src/app/model/notice-of-intent-tax-deduction.model";

export const RequestAction = createAction('[notice of intent tax deduction form] request',  props<{ accountId: number, payload:NoticeOfIntentTaxDeductionFormSubmissionModel }>());

export const ResponseAction = createAction('[notice of intent tax deduction form] successful submission');

export const SetTaxDeductionRadioOptionsAction = createAction('[notice of intent tax deduction form] set tax deduction type radio options', props<{payload:KeyValueModel[]}>());
export const SetFinancialYearDropdownOptionsAction = createAction('[notice of intent tax deduction form] set financial year dropdown options', props<{payload:KeyValueModel[]}>());
export const SetFinancialYearContributionAmountsAction = createAction('[notice of intent tax deduction form] set financial years contribution amounts', props<{payload:NoticeOfIntentTaxDeductionContributions}>());

export const SetDisclaimerAction = createAction('[notice of intent tax deduction form] set disclaimer', props<{disclaimer:string}>());

export const ResetFormStateAction = createAction('[notice of intent tax deduction form] reset state');