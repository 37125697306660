import { KeyValueModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { AccessYourSuperPaymentDetailSectionModel, AccessYourSuperPaymentDetailSubmissionModel, AccessYourSuperSfhDependantModel, AccessYourSuperSfhDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";

export const ResetComponentStateAction = createAction('[Access your super severe financial hardship component] reset state');
export const RequestAction = createAction('[Access your super severe financial hardship component] get data', props<{ accountId: number, applicationId: number }>());
export const ResponseAction = createAction('[Access your super severe financial hardship component] set data', props<{ payload: AccessYourSuperSfhDetailSectionModel }>());

export const SetFullAmountRadioOptionsAction = createAction('[Access your super severe financial hardship component] set full amount radio options', props<{ payload: KeyValueModel[] }>());
export const SetTaxOptionsAction = createAction('[Access your super severe financial hardship component] set tax options', props<{ payload: KeyValueModel[] }>());


export const SaveFormDataAction = createAction('[Access your super severe financial hardship component] save form data', props<{ accountId: number, payload: AccessYourSuperPaymentDetailSubmissionModel }>());
export const SavedFormDataAction = createAction('[Access your super severe financial hardship component] saved form data');

export const SetDependendantFormDataAction = createAction('[Access your super severe financial hardship component] set dependant form data', props<{ dependants: AccessYourSuperSfhDependantModel[] }>())

export const SetSfhStepAction = createAction('[Access your super severe financial hardship component] set sfh saver step', props<{ step: number }>());
export const SetFormVersionAction = createAction('[Access your super severe financial hardship component] set form version', props<{ formVersion: number }>());

export const SetFormThreeValidAction = createAction('[Access your super severe financial hardship component] set form three valid', props<{ isValid:boolean }>());