// import { commomState_LoggedinInfo } from './../store/common/common.selectors';
import { AppState } from './../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { memberAccountDropdown_SelectedAccount } from '../modules/shared/components/member-account-dropdown/selectors';

@Injectable()
export class AccumGuardService implements CanActivate {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  constructor(public store: Store<AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      this.selectedAccount$.subscribe(account => {
        if (account && !account.isPensionType) {
          obs.next(true);
        }

        obs.next(false);
      })
    });
  }
}
