import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MemberPortalSharedState } from "../../store/shared.states";
import { featureKey } from '../../store/shared.reducers';
import { IAccessYourSuperLastEmployerComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperLastEmployerState = createSelector(memberPortalState, (state) => state.accessYourSuperLastEmployerComponentState);

export const accessYourSuperLastEmployer_Form = createSelector(
    getAccessYourSuperLastEmployerState,
    (state: IAccessYourSuperLastEmployerComponentState) => state.form
);

export const accessYourSuperLastEmployer_LastEmployerDetailsSaved = createSelector(
    getAccessYourSuperLastEmployerState,
    (state: IAccessYourSuperLastEmployerComponentState) => state.lastEmployerDetailsSaved
)