import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { NoticeOfIntentTaxDeductionService } from 'src/app/services/notice-of-intent-tax-deduction.service';

@Injectable()
export class NoticeOfIntentTaxDeductionContainerComponentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private service: NoticeOfIntentTaxDeductionService) {
  }

  getApplicationList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getApplicationList(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getFormData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.GetFormDataAction),
    switchMap((action:any) => this.service.getFormData(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.SetFormDataAction({ formData: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ))
}
