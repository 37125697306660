<div id="historical-graph-view-container">
  <div class="total-amount displayGraph mt-3 d-flex flex-column align-items-center">
    <!-- <div>
      <img src="https://sharedassetstorage.blob.core.windows.net/web/SMRT/SEA-Desktop.png">
    </div> -->
    <div class="content-data d-flex">
      <div>
        <div class="icon-wrapper d-flex align-items-center">

          <mat-icon>paid</mat-icon>
        </div>

      </div>
      <div>
        <h1>{{(model$ | async).totalAmount | currency}}</h1>
        <h3>{{'HISTORICAL_CHART_VIEW.BALANCE_AS_AT' | translate}} {{(model$ | async).asAt | date:'dd MMM yyyy'}}

          <span *ngIf="(model$ | async).pendingAmount != 0" matTooltip="Including pending transactions of {{(model$ | async).pendingAmount | currency}}. &#13;
        Pending transactions are contributions that have been received but not yet finalised.">
            <mat-icon>help_outline</mat-icon>
          </span>
        </h3>
      </div>

    </div>
    <div class="alert alert-info w-100 d-flex flex-row align-items-center" *ngIf="'HISTORICAL_CHART_VIEW.TEMPORARILY_UNAVAILABLE' | translate">
      <mat-icon>info</mat-icon>
      <div>{{'HISTORICAL_CHART_VIEW.TEMPORARILY_UNAVAILABLE' | translate}}</div>
    </div>

  </div>

  <div class="d-flex mt-3 links">

    <app-button class="{{'BTN.historical-chart-view.viewStatement.class'|translate}}"
      *featuretoggle="viewOtherStatementsFeatureToggle" icon="east"
      color="{{'BTN.historical-chart-view.viewStatement.color'|translate}}" routerLink="/member-statements"
      title="View Statements"></app-button>

    <app-button *featuretoggle="viewTransactionsFeatureToggle" icon="east" routerLink="/transaction-listing" class=""
      title="View all transactions"></app-button>

    <app-button *featuretoggle="lodgeCirtClaimFeatureToggle" icon="attach_money" routerLink="/cirt/lodge-claim"
      title="Make a CIRT Claim"></app-button>

    <app-button *featuretoggle="jetcoClaimFeatureToggle" icon="attach_money" routerLink="/cirt/jetco-lodge-claim"
      title="Make a JETCO Claim"></app-button>

  </div>

</div>
