import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AccountFeatureToggle } from "src/app/model/account-feature-toggles.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";


@Component({
  selector: 'app-limited-service-banner',
  templateUrl: './limited-service-banner.component.html',
  styleUrls: ['./limited-service-banner.component.scss']
})
export class LimitedServiceBannerComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() set toggles(value:AccountFeatureToggle[]){
    this.limitedServiceBanners = value;
  }
  
  limitedServiceBanners:AccountFeatureToggle[]=null;
  showMoreFlag=false;

  constructor(public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  showMore(){
    this.showMoreFlag = !this.showMoreFlag;
  }
}
