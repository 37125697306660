import { KeyValueModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { AccessYourSuperPaymentDetailSectionModel, AccessYourSuperPaymentDetailSubmissionModel } from "src/app/model/access-your-super-custom-sections.model";

export const ResetComponentStateAction = createAction('[Access your super terminal medical condition component] reset state');
export const RequestAction = createAction('[Access your super terminal medical condition component] get data', props<{ accountId: number, applicationId:number, applicationTypeId:number }>());
export const ResponseAction = createAction('[Access your super terminal medical condition component] set data', props<{ payload: AccessYourSuperPaymentDetailSectionModel }>());

export const SetFullAmountRadioOptionsAction = createAction('[Access your super terminal medical condition component] set full amount radio options', props<{payload:KeyValueModel[]}>());
export const SetReleaseConditionOptionsAction = createAction('[Access your super terminal medical condition component] set release condition options', props<{payload:KeyValueModel[]}>());


export const SaveFormDataAction = createAction('[Access your super terminal medical condition component] save form data', props<{accountId:number, payload:AccessYourSuperPaymentDetailSubmissionModel}>());
export const SavedFormDataAction = createAction('[Access your super terminal medical condition component] saved form data');
