import { createReducer, on, Action } from '@ngrx/store';
import { onNgrxForms, onNgrxFormsAction, SetValueAction } from 'ngrx-forms';
import { IInvestmentOptionState } from './state';
import { ResponseCurrentDataAction } from './actions';

export const state: IInvestmentOptionState = {
  currentInfo: null
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseCurrentDataAction, (state, { payload }) => {
    return {
      ...state,
      currentInfo: payload
    };
  })
);

export function investmentOptionReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
