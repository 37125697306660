<div *ngIf="model$ | async; let model">
    <h2>Compassionate grounds</h2>
    <div *ngIf="form$ | async; let form">
        <form [ngrxFormState]="form" class="ml-3">
            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title mb-1">{{model.formLabels.atoApproved}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio list="yesNo" [control]="form.controls.isAtoApproved"></app-entry-radio>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">{{model.formLabels.benefitAmount}}
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-number title="Please enter an amount"
                            [control]="form.controls.partialAmountSpecified" icon="attach_money" appanimatecss
                            [decimalplaces]="2" [showincremental]="false">
                        </app-entry-number>
                    </div>
                </div>
            </div>
        </form>

        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                    color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button *ngIf="showBackButton" [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill {{'BTN.access-your-super.back.class' | translate}}"
                    title="Back" icon="{{'BTN.access-your-super.back.icon' | translate}}"
                    color="{{'BTN.access-your-super.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                    color="{{'BTN.access-your-super.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || !form.value.isAtoApproved" (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>
