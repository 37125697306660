import { createAction, props } from "@ngrx/store";
import { AccessYourSuperKiwiSaverDetailSectionModel, AccessYourSuperPaymentDetailSectionModel, AccessYourSuperSfhDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";

export const GetCustomDataAction = createAction("[Access your super review component] get custom section data", props<{accountId:number, applicationId:number, applicationTypeId:number}>());
export const SaveCustomDataAction = createAction("[Access your super review component] save custom section data", props<{payload: AccessYourSuperPaymentDetailSectionModel|AccessYourSuperKiwiSaverDetailSectionModel|AccessYourSuperSfhDetailSectionModel}>());

export const GetKiwiSaverDataAction = createAction("[Access your super review component] get kiwi saver data", props<{accountId: number, applicationId:number}>());
export const GetSfhDataAction = createAction("[Access your super review component] get severe financial hardship data", props<{accountId: number, applicationId:number}>());

export const ResetComponentStateAction = createAction("[Access your super review component] reset component state");

export const SubmitApplicationAction = createAction("[Access your super review component] submit application", props<{accountId:number, applicationId:number}>());
export const ApplicationSubmittedAction = createAction("[Access your super review component] application submitted");
