import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccessYourSuperApplicationDataModel, AccessYourSuperApplicationTypesListResponseModel, AccessYourSuperDocumentOverviewModel } from 'src/app/model/access-your-super-form.models';

export const SetCurrentStepAction = createAction('[Access your super form component] set current step', props<{ nextStep: number }>());
export const UpdateCurrentStepAction = createAction('[Access your super form component] update application current step', props<{accountId:number, currentStep:number, applicationId:number}>())
export const CurrentStepUpdatedAction = createAction('[Access your super form component] current step updated')

export const SaveApplicationDataAction = createAction('[Access your super form component] save application data', props<{applicationId:number,model:AccessYourSuperApplicationDataModel}>());
export const SaveLastEmployerDetailsAction = createAction('[Access your super form component] save last employer details', props<{lastEmployerName:string, stillWithEmployer:boolean, finishDate?:Date|string, waitingOnFinalContribution?:boolean}>())
export const SaveBankDetailsAction = createAction('[Access your super form component] save bank details', props<{bankName:string, accountName:string, accountNumber:string, bsb:string}>())
export const SaveDocumentModelAction = createAction('[Access your super form component] save documents', props<{payload:AccessYourSuperDocumentOverviewModel[]}>());

export const ResetComponentStateAction = createAction('[Access your super form component] reset component state');

export const UpdateDocumentRequiredAction = createAction('[Access your super documents component] set document mandatory', props<{documentTypeId:number, isRequired:boolean}>())

export const GetApplicationTypesRequestAction = createAction('[Access your super form component] get application types', props<{ accountType: number, accountId: number }>());
export const GetApplicationTypesResponseAction = createAction('[Access your super form component] set application types', props<{ payload: AccessYourSuperApplicationTypesListResponseModel }>());