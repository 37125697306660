
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ContinueApplicationAction, MoveApplicationToCancelledAction, RequestAction, ResetComponentStateAction, ResponseAction, SetApplicationToCancelledAction, UpdateSubmittedApplicationAction } from './actions';
import { AccessYourSuperFilterModel, IAccessYourSuperStartComponentState } from './state';
import { Helper } from '@ifaa-components/ui-components';

export const accessYourSuperStartFilter = 'accessYourSuperStartFilter';
export const state: IAccessYourSuperStartComponentState = {
  model: null,
  filter: createFormGroupState(accessYourSuperStartFilter, new AccessYourSuperFilterModel()),
  applicationAccountId: null,
  applicationToCancelled: null,
  applicationData: null,
  updatingSubmittedDocuments:false
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(RequestAction, (state, { accountId }) => {
    return {
      ...state,
      applicationAccountId: accountId
    }
  }),
  on(ResponseAction, (state, { payload }) => {
    // Reorder draft and submitted applications from more recent descending
    var helper = new Helper();

    var model = helper.clone(payload);

    if (model['Cancelled']?.length > 0) {
      model['Cancelled'] = model['Cancelled'].sort((a, b) => new Date(b?.lastModified).getTime() - new Date(a?.lastModified).getTime());
    }

    if (model['Submitted']?.length > 0) {
      model['Submitted'] = model['Submitted'].sort((a, b) => new Date(b?.lastModified).getTime() - new Date(a?.lastModified).getTime());
    }

    return {
      ...state,
      model: model
    };
  }),
  on(SetApplicationToCancelledAction, (state, { }) => {
    return {
      ...state,
      applicationToCancelled: new Date()
    }
  }),
  on(MoveApplicationToCancelledAction, (state, { applicationId }) => {
    var localModel = Object.assign({}, state.model);
    var applicationIndex = localModel['Draft']?.findIndex(o => o.applicationId === applicationId);
    if (applicationIndex != -1) {
      var application = Object.assign({}, localModel['Draft'][applicationIndex]);
      application.lastModified = new Date();

      var cancelledList = Object.assign([], localModel['Cancelled'])
      cancelledList.push({ ...application })
      localModel['Cancelled'] = cancelledList;

      var draftList = Object.assign([], localModel['Draft'])
      draftList.splice(applicationIndex, 1);
      localModel['Draft'] = draftList;

      return {
        ...state,
        applicationToCancelled: null,
        model: localModel
      }
    }
    return state;
  }),
  on(ContinueApplicationAction, (state, { model }) => {
    return {
      ...state,
      applicationData: model,
      updatingSubmittedDocuments:false
    }
  }),
  on(UpdateSubmittedApplicationAction, (state, {model}) => {
    return {
      ...state,
      applicationData:model,
      updatingSubmittedDocuments:true
    }
  }),
  on(ResetComponentStateAction, (state) => {
    return {
      ...state,
      model: null,
      filter: createFormGroupState(accessYourSuperStartFilter, new AccessYourSuperFilterModel()),
      applicationToCancelled: null,
      applicationData: null,
      updatingSubmittedDocuments:false
    }
  })
);

export function accessYourSuperStartComponentReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
