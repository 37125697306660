import { createReducer, Action, on } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { SetupSupportingDocumentOverviewAction, ResetFileRequestFailedAction, GreenIdVerifiedAction, ResetComponentStateAction,
   SetFileRequestFailedAction, UpdateSavedDocumentAction, UpdateDeletedDocumentAction, AddNewAppFileAction, DocumentsCompletedAction,
    RemoveAppFileAction, UpdateReplacedDocumentAction } from './actions';
import { AccessYourSuperDocumentsForm, IAccessYourSuperDocumentsComponentState } from './state';
import { requiredTrue } from 'ngrx-forms/validation';
import { AppFileModel, Helper } from '@ifaa-components/ui-components';

export const state: IAccessYourSuperDocumentsComponentState = {
  form: createFormGroupState('AccessYourSuperDocumentsForm', new AccessYourSuperDocumentsForm()),
  uploadedDocument:null,
  deletedDocument:null,
  replacedDocument:null,
  fileRequestFailed:null,
  documentsCompleted:null
};

var helper = new Helper();

export const validateAccessYourSuperDocumentsForm = updateGroup<AccessYourSuperDocumentsForm>({
  greenIdVerified:validate(requiredTrue),
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      form: createFormGroupState('AccessYourSuperDocumentsForm', new AccessYourSuperDocumentsForm()),
      uploadedDocument: null,
      deletedDocument:null,
      replacedDocument:null,
      fileRequestFailed:null,
      documentsCompleted:null
    }
  }),
  on(GreenIdVerifiedAction, (state, {verified}) => {
    return {
      ...state,
      form: setValue(Object.assign(new AccessYourSuperDocumentsForm(),
        {
          ...state.form.value,
          greenIdVerified: verified
        }
      ))(state.form)
    }
  }),
  on(SetupSupportingDocumentOverviewAction, (state,{documentTypeId, documentAmount, referenceRequired, authorityGiven, referenceValue, previouslyProvided, isDocumentRequired}) => {
    var clone = helper.clone(state.form.value.supportingDocuments)
    var files = [];

    for (let i = 0; i < documentAmount; i++) {
      files.push(new AppFileModel())
    }

    var documentOverview = {
      documentTypeId,
      files,
      requestAuthority:authorityGiven,
      referenceValue,
      referenceRequired,
      previouslyProvided:previouslyProvided,
      isDocumentRequired
    }

    return {
      ...state,
      form: setValue(Object.assign(new AccessYourSuperDocumentsForm(),
        {
          ...state.form.value,
          supportingDocuments:[...clone, documentOverview]
        }
      ))(state.form)
    }
  }),
  on(AddNewAppFileAction, (state,{overviewIndex}) => {
    var clone = helper.clone(state.form.value.supportingDocuments)

    clone[overviewIndex].files.push(new AppFileModel())

    return {
      ...state,
      form: setValue(Object.assign(new AccessYourSuperDocumentsForm(),
        {
          ...state.form.value,
          supportingDocuments:[...clone]
        }
      ))(state.form),
      uploadedDocument:null
    }
  }),
  on(RemoveAppFileAction, (state, {overviewIndex,uploadIndex}) => {
    var clone = helper.clone(state.form.value.supportingDocuments);

    clone[overviewIndex].files.splice(uploadIndex,1);

    return {
      ...state,
      form: setValue(Object.assign(new AccessYourSuperDocumentsForm(),
        {
          ...state.form.value,
          supportingDocuments:[...clone]
        }
      ))(state.form)
    }
  }),
  on(UpdateSavedDocumentAction, (state, {uploadedDocumentId, fileName, documentTypeId, documentIndex, controlId}) => {
    return {
      ...state,
      loading:false,
      uploadedDocument: {
        uploadedDocumentId,
        fileName,
        documentTypeId,
        documentIndex,
        controlId
      }
    }
  }),
  on(UpdateDeletedDocumentAction, (state, {uploadedDocumentId, overviewIndex, uploadIndex, controlId}) => {
    return {
      ...state,
      loading:false,
      deletedDocument:{
        uploadedDocumentId,
        overviewIndex,
        uploadIndex,
        controlId
      }
    }
  }),
  on(UpdateReplacedDocumentAction, (state, {documentTypeId, controlId, documentIndex, fileName, payload}) => {
    return {
      ...state,
      replacedDocument:{
        documentTypeId,
        controlId,
        documentIndex,
        fileName,
        payload
      }
    }
  }),
  on(SetFileRequestFailedAction, (state, {controlId, file}) => {
    return {
      ...state,
      fileRequestFailed:{
        controlId,
        file
      }
    }
  }),
  on(ResetFileRequestFailedAction, (state, {}) => {
    return {
      ...state,
      fileRequestFailed:null
    }
  }),
  on(DocumentsCompletedAction, (state) => {
    return {
      ...state,
      documentsCompleted: new Date()
    }
  })
);

const editAccessYourSuperDocumentsReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateAccessYourSuperDocumentsForm
)

export function accessYourSuperDocumentsComponentReducer(state: any | undefined, action: Action) {
  return editAccessYourSuperDocumentsReducerFormState(state, action);
}
