import { KeyValueModel } from '@ifaa-components/ui-components';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IInsuranceQuestionnaireStartState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const insuranceQuestionnaireStart = createSelector(memberPortalState, (state) => state.insuranceQuestionnaireStartState);

export const insuranceQuestionnaireStart_State = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state
);

export const insuranceQuestionnaireStart_Form = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.form
);

export const insuranceQuestionnaireStart_isLegallyRecognisedAsGender = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.form.controls.isLegallyRecognisedAsGender
);

export const insuranceQuestionnaireStart_Gender = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.form.controls.gender
);

export const insuranceQuestionnaireStart_ConfirmedDate = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.confirmedTime
);

export const insuranceQuestionnaireStart_Validated = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.validated
);

export const insuranceQuestionnaireStart_CurrentStep = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.currentStep
);

export const insuranceQuestionnaireStart_TotalSteps = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.totalSteps
);

export const insuranceQuestionnaireStart_Submitted = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => state.submitted
);

export const insuranceQuestionnaireStart_StatementsApplyToYou = createSelector(
  insuranceQuestionnaireStart,
  (state: IInsuranceQuestionnaireStartState) => [
    { key: false, value: 'No, none of the above statements apply to me' } as KeyValueModel,
    { key: true, value: 'Yes, one or more of the above statements apply to me' } as KeyValueModel,
  ]
);
