import { AppState } from './../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class MsalCheckService implements CanActivate {

  constructor(public store: Store<AppState>,
    private authService: MsalService,

    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('login-error')) {
      return true;
    }

    this.router.navigate(['/dashboard'])
  }
}
