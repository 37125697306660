import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Component } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { Title } from "@angular/platform-browser";
import { Meta } from '@angular/platform-browser';
import { AppState } from 'src/app/store/app.states';
import { commomState_loginViewVideoUrl } from 'src/app/store/common/common.selectors';
import { SetLoginVideoUrlAction } from 'src/app/store/common/common.actions';
@Component({
  selector: 'login-master',
  templateUrl: './not-loggedin.master.html',
  styleUrls: ['./not-loggedin.master.scss']
})
export class NotLoggedInMasterComponent extends ComponentBase {
  bg: string = "";


  constructor(public store: Store<UIComponentState>,
    public appstore: Store<AppState>,
    private titleService: Title,
    public dialog: MatDialog,
    private meta: Meta,
    private router: Router) {
    super();
    this.titleService.setTitle("Future Super – Member Portal");
    this.meta.addTag({ name: 'description', content: 'Future Super Member Portal. Log in to access your super account details, super balance and change your details.' });
    this.meta.addTag({ name: 'keywords', content: 'Future Super, Future Super Member Portal, Future Super Login' });
  }

  ngOnInit() {
    super.ngOnInitBase();
    //this.appstore.dispatch(SetLoginVideoUrlAction({ url: 'https://sharedassetstorage.blob.core.windows.net/web/FUTR/FS_HomePage_Hero_02.mp4' }));

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
