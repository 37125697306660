import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Helper, KeyValueModel } from "@ifaa-components/ui-components";
import { select, Store } from "@ngrx/store";
import { FormGroupState, SetErrorsAction, SetValueAction } from "ngrx-forms";
import { distinct, distinctUntilChanged, map, pairwise } from "rxjs/operators";
import { AccessYourSuperPaymentTypeEnum } from "src/app/enums/access-your-super-payment-type.enum";
import { AccessYourSuperSfhDependantModel, AccessYourSuperSfhDetailSubmissionModel } from "src/app/model/access-your-super-custom-sections.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { SetCurrentStepAction } from "../../access-your-super-form/actions";
import { RequestAction, ResetComponentStateAction, SaveFormDataAction, SetFullAmountRadioOptionsAction, SetTaxOptionsAction, SetSfhStepAction, SetDependendantFormDataAction, SetFormVersionAction, SetFormThreeValidAction } from "./actions";
import { accessYourSuperSevereFinancialHardship_FormOne, accessYourSuperSevereFinancialHardship_Model, accessYourSuperSevereFinancialHardship_SavedFormData, accessYourSuperSevereFinancialHardship_FullAmountRadioOptions, accessYourSuperSevereFinancialHardship_TaxOptions, accessYourSuperSevereFinancialHardship_CurrentTotalPercent, accessYourSuperSevereFinancialHardship_SfhStep, accessYourSuperSevereFinancialHardship_FormTwo, accessYourSuperSevereFinancialHardship_FormVersion, accessYourSuperSevereFinancialHardship_FormThreeValid } from "./selectors";
import { InvestmentItemModel } from "src/app/model/investment-item.model";
import { FeatureToggleHelper } from "src/app/helper/featureToggleHelper";
import { commonState_SystemConfig_AccountFeatureToggles } from "src/app/store/common/common.selectors";
import { memberAccountDropdown_SelectedAccount } from "../../member-account-dropdown/selectors";
import { FeatureToggleName } from "src/app/model/feature-toggle-name.model";
import { combineLatest } from "rxjs";
import { AccessYourSuperStepper } from "../../access-your-super-progress-stepper/access-your-super-progress-stepper.component";
import { FinancialDetailsOutput, SfhDependantsForm } from "./state";

@Component({
    selector: 'app-access-your-super-severe-financial-hardship',
    templateUrl: './access-your-super-severe-financial-hardship.component.html',
    styleUrls: ['./access-your-super-severe-financial-hardship.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class AccessYourSuperSevereFinancialHardshipComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set nextStep(value: number) {
        this.step = value;
    }
    @Input() set applicationId(value: number) {
        this.id = value;
    }
    @Input() set backStep(value: number) {
        this.previousStep = value;
    }
    @Input() set typeId(value: number) {
        this.applicationTypeId = value;
    }
    showBackButton: boolean = false;

    model$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_Model));
    taxOptions$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_TaxOptions));
    fullAmountRadioOptions$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_FullAmountRadioOptions));
    savedFormData$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_SavedFormData));
    currentTotalPercent$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_CurrentTotalPercent));
    featureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    sfhStep$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_SfhStep));
    formVersion$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_FormVersion));

    formOne$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_FormOne));
    formTwo$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_FormTwo));
    formThreeValid$ = this.store.pipe(select(accessYourSuperSevereFinancialHardship_FormThreeValid));

    step: number = null;
    previousStep: number = null
    id: number = null;
    applicationTypeId: number = null;
    paymentDetailId: number = null;
    investmentOptions: InvestmentItemModel[];
    accountId: number = null;
    maxDate: Date = new Date();
    totalBalance: number = null;
    stepperData: any[] = [];
    financialDetails: {[key:string]:string} = null;

    showBenefitAmountTooltip: boolean = false;
    featureToggleHelper: FeatureToggleHelper = new FeatureToggleHelper();
    helper = new Helper();

    combinedFormData$ = null;

    @ViewChildren(AccessYourSuperStepper) private sfhStepper: QueryList<AccessYourSuperStepper>;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngAfterViewInit() {
        this.sub = combineLatest([this.sfhStepper?.changes, this.sfhStep$])
            .pipe(map(([queryList, step]) => ({ queryList, step })))
            .subscribe(x => {
                if (x.queryList && x.step) {
                    x.queryList.first.setSelectedIndex(x.step - 1)
                }
            })
    }

    async ngOnInit() {
        super.ngOnInitBase();

        this.combinedFormData$ = combineLatest([this.formOne$, this.formTwo$]).pipe(
            map(([formOne, formTwo]) => ({ formOne, formTwo })),
        )

        this.sub = combineLatest([this.selectedAccount$, this.featureToggles$]).subscribe(x => {
            if (x[0] && x[1]) {
                this.accountId = x[0].accountId;
                this.showBenefitAmountTooltip = this.featureToggleHelper.checkToggle(FeatureToggleName.member.account.accessYourSuper.benefitAmountTooltip.view,
                    x[1], x[0], true);
            }
        })

        this.sub = this.sfhStep$.subscribe(x => {
            if (x) {
                if (x > 1) {
                    this.showBackButton = true;
                }
                else {
                    this.showBackButton = false;
                }
            }
        })

        this.sub = this.model$.pipe(distinct())
            .subscribe(async x => {
                if (!x) {
                    this.dispatch(this.store, RequestAction({ accountId: this.accountId, applicationId: this.id }))
                }

                if (x) {
                    var formOne = await this.helper.getValue(this.formOne$);

                    // Update form one with saved data
                    if (x.sfhSubmissionData) {
                        this.dispatch(this.store, SetFormVersionAction({ formVersion: x.sfhSubmissionData.formVersion }));
                        this.dispatch(this.store, SetSfhStepAction({ step: x.sfhSubmissionData.currentStep }));

                        this.stepperData.push({
                            title: 'Benefit payment details',
                            completed: x.sfhSubmissionData.currentStep > 1 ? true : false,
                            stepIndex: 1
                        })
                        this.stepperData.push({
                            title: 'Dependants',
                            completed: x.sfhSubmissionData.currentStep > 2 ? true : false,
                            stepIndex: 2
                        })
                        this.stepperData.push({
                            title: 'Financial details',
                            completed: false,
                            stepIndex: 3
                        })

                        if (x.sfhSubmissionData.sfhSummary)
                            this.store.dispatch(new SetValueAction(formOne.controls.sfhSummary.id, x.sfhSubmissionData.sfhSummary));

                        // Update form two dependant data if it exists
                        if (x.sfhSubmissionData.dependantAndFinanceData?.dependants?.length > 0) {
                            this.dispatch(this.store, SetDependendantFormDataAction({ dependants: x.sfhSubmissionData.dependantAndFinanceData.dependants }))
                        }
                    }

                    var fullAmountRadioOptions = [] as KeyValueModel[];

                    if (x.reachedPreservationAgePlusThirtyNineWeeks) {
                        fullAmountRadioOptions.push({ key: 0, value: x.formLabels.fullAmountOptionOne });
                        fullAmountRadioOptions.push({ key: 1, value: x.formLabels.fullAmountOptionTwo });
                        this.dispatch(this.store, SetFullAmountRadioOptionsAction({ payload: fullAmountRadioOptions }))
                        this.store.dispatch(new SetValueAction(formOne.controls.receivingQualifyingSupportErrorMsg.id, x.formLabels.receivingQualifyingSupport39WeeksError))
                    }
                    else {
                        fullAmountRadioOptions.push({ key: 0, value: x.formLabels.fullAmountOptionThree });
                        this.dispatch(this.store, SetFullAmountRadioOptionsAction({ payload: fullAmountRadioOptions }))
                        this.store.dispatch(new SetValueAction(formOne.controls.receivingQualifyingSupportErrorMsg.id, x.formLabels.receivingQualifyingSupport26WeeksError))
                    }

                    var taxOptions = [] as KeyValueModel[];
                    taxOptions.push({ key: true, value: x.formLabels.beforeTax });
                    this.dispatch(this.store, SetTaxOptionsAction({ payload: taxOptions }))

                    var investmentOptions = Object.assign([], x.currentInvestmentMix);
                    this.totalBalance = x.currentInvestmentMix.reduce((a, b) => a + b.amount, 0)

                    this.store.dispatch(new SetValueAction(formOne.controls.memberReachedPreservationAge.id, x.reachedPreservationAgePlusThirtyNineWeeks));

                    // update form values if we are updating an existing application
                    if (x.submissionData) {
                        this.paymentDetailId = x.submissionData.accessYourSuperPaymentDetailId;

                        if (x.submissionData.paymentTypeId !== AccessYourSuperPaymentTypeEnum.PartialAmount) {
                            this.store.dispatch(new SetValueAction(formOne.controls.paymentType.id, 0));

                            if (x.reachedPreservationAgePlusThirtyNineWeeks) {
                                this.store.dispatch(new SetValueAction(formOne.controls.fullAmountSelection.id, x.submissionData.paymentTypeId - 1));

                                x.submissionData.isPermanentlyRetired === true || x.submissionData.isPermanentlyRetired === false ?
                                    this.store.dispatch(new SetValueAction(formOne.controls.isPermanentlyRetired.id, x.submissionData.isPermanentlyRetired)) :
                                    null;
                            } else {
                                this.store.dispatch(new SetValueAction(formOne.controls.fullAmountSelection.id, 0));
                            }
                        }
                        else {
                            this.store.dispatch(new SetValueAction(formOne.controls.paymentType.id, 1));
                        }

                        x.submissionData.benefitAmount ?
                            this.store.dispatch(new SetValueAction(formOne.controls.partialAmountSpecified.id, x.submissionData.benefitAmount)) :
                            null;

                        x.submissionData.partialWithdrawalInvestmentSelection === true || x.submissionData.partialWithdrawalInvestmentSelection === false ?
                            this.store.dispatch(new SetValueAction(formOne.controls.partialWithdrawalSelection.id, x.submissionData.partialWithdrawalInvestmentSelection)) :
                            null;

                        // update the investmentOptions in form state if the member saved a custom drawdown
                        if (x.submissionData.partialWithdrawalInvestmentSelection) {
                            // if we have any specified investment options saved that are not in the current investment mix, we need to discard all of those that were saved
                            var drawdownMatchesCurrentInvestmentMix = true;

                            x.submissionData.investmentDrawdown.forEach(drawDown => {
                                var investmentOptionIndex = investmentOptions.findIndex(o => o.optionId === drawDown.optionId)

                                // if we dont get an investmentOption, set the flag to false. Otherwise update the investmentOptions array with the saved percentage
                                if (investmentOptionIndex === -1)
                                    drawdownMatchesCurrentInvestmentMix = false;
                                else {
                                    investmentOptions[investmentOptionIndex] = { ...investmentOptions[investmentOptionIndex], percentage: drawDown.percentage }
                                }
                            })

                            // if our current investment mix matches the saved options, update the form value with the updated investmentOptions array
                            // if it doesnt match, update the form value with the members current investment mix so they will have to reselect an investment drawdown
                            if (drawdownMatchesCurrentInvestmentMix) {
                                this.store.dispatch(new SetValueAction(formOne.controls.investmentOptionDrawdown.id, investmentOptions));
                            }
                            else {
                                this.store.dispatch(new SetValueAction(formOne.controls.investmentOptionDrawdown.id, x.currentInvestmentMix));
                            }
                        }
                        // if they didn't specify a custom drawdown, set the options with their investment mix
                        else {
                            this.store.dispatch(new SetValueAction(formOne.controls.investmentOptionDrawdown.id, investmentOptions));
                        }
                    }
                    else {
                        this.store.dispatch(new SetValueAction(formOne.controls.investmentOptionDrawdown.id, investmentOptions));
                    }


                }
            })

        this.sub = this.formOne$.pipe(pairwise(), distinctUntilChanged((x, y) => {
            // check if text box value is NaN to avoid infinite loop..
            if (Number.isNaN(y[1].value.partialAmountSpecified))
                return true;

            if (x[1].value.paymentType !== y[1].value.paymentType ||
                x[1].value.fullAmountSelection !== y[1].value.fullAmountSelection ||
                x[1].value.investmentOptionDrawdown !== y[1].value.investmentOptionDrawdown ||
                x[1].value.partialAmountSpecified !== y[1].value.partialAmountSpecified ||
                x[1].value.partialWithdrawalSelection !== y[1].value.partialWithdrawalSelection)
                return false;

            return true;
        })).subscribe(form => {
            if (form) {
                // If user has swapped from partial amount back to full amount, reset all form validations
                if (form[0].value.paymentType === 1 && form[1].value.paymentType === 0) {
                    this.store.dispatch(new SetErrorsAction(form[1].controls.partialAmountSpecified.id, {}));
                    this.store.dispatch(new SetErrorsAction(form[1].controls.investmentOptionDrawdown.id, {}));
                }

                // Reset validation if user swaps from custom selection to auto selection of investment dropdown
                if (form[0].value.partialWithdrawalSelection !== form[1].value.partialWithdrawalSelection) {
                    this.store.dispatch(new SetErrorsAction(form[1].controls.investmentOptionDrawdown.id, {}));
                }

                if (form[1].value.paymentType === 1 && form[1].value.partialWithdrawalSelection) {
                    var drawdownTotal = 0;
                    var calculatedAmountHigherThanBalance = false;

                    form[1].value.investmentOptionDrawdown.forEach((investmentOption, index) => {
                        drawdownTotal += investmentOption.percentage;
                        if (!calculatedAmountHigherThanBalance) {
                            calculatedAmountHigherThanBalance = investmentOption.amount < (form[1].value.partialAmountSpecified * (investmentOption.percentage / 100))
                        }
                    })

                    // If drawdownTotal is 100, remove error. If it is not 100, set validation error
                    if (drawdownTotal === 100 && !calculatedAmountHigherThanBalance) {
                        this.store.dispatch(new SetErrorsAction(form[1].controls.investmentOptionDrawdown.id, {}));
                    } else {
                        var errors = {};

                        if (drawdownTotal !== 100) {
                            errors['percentageTotal'] = 'Total must be 100.'
                        }

                        if (calculatedAmountHigherThanBalance) {
                            errors['calculatedTotal'] = "A calculated amount cannot be higher than the investment accounts balance."
                        }
                        this.store.dispatch(new SetErrorsAction(form[1].controls.investmentOptionDrawdown.id, errors));
                    }
                }
            }
        })

        // formTwo fields are dynamic so we need to perform validations in a subscription
        this.sub = this.formTwo$.pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))).subscribe(form => {
            if (form) {
                form.controls.dependants.controls.forEach(x => {
                    // validate name field
                    if (x.value.name === "") {
                        this.store.dispatch(new SetErrorsAction(x.controls.name.id, { customError: 'Dependant name cannot be blank.' }))
                    }
                    else {
                        this.store.dispatch(new SetErrorsAction(x.controls.name.id, {}))
                    }

                    // validate age field
                    if (x.value.age === null || x.value.age < 0) {
                        this.store.dispatch(new SetErrorsAction(x.controls.age.id, { customError: 'Dependant age must be equal to or greater than 0.' }))
                    }
                    else {
                        this.store.dispatch(new SetErrorsAction(x.controls.age.id, {}))
                    }

                    // validate relationship field
                    if (x.value.relationship === "") {
                        this.store.dispatch(new SetErrorsAction(x.controls.relationship.id, { customError: 'Dependant relationship cannot be blank.' }))
                    }
                    else {
                        this.store.dispatch(new SetErrorsAction(x.controls.relationship.id, {}))
                    }
                })
            }
        })

        // Go next step when form has been successfully saved
        this.sub = this.savedFormData$.subscribe(async x => {
            if (x) {
                var currentStep = await this.helper.getValue(this.sfhStep$);
                if (currentStep !== 3) {
                    this.dispatch(this.store, SetSfhStepAction({ step: currentStep + 1 }));
                }
                else {
                    this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.step }))
                }
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction());
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }

    async goNextStep() {
        var payload = null;
        var currentStep = await this.helper.getValue(this.sfhStep$);
        var formVersion = await this.helper.getValue(this.formVersion$);

        switch (currentStep) {
            case 1: {
                var formOne = await this.helper.getValue(this.formOne$);
                if (formOne.isTouched) {
                    var paymentTypeId = null;

                    if (formOne.value.memberReachedPreservationAge) {
                        if (formOne.value.paymentType === 0 && formOne.value.fullAmountSelection === 0) {
                            paymentTypeId = AccessYourSuperPaymentTypeEnum.FullAmountCloseAccount;
                        }
                        else if (formOne.value.paymentType === 0 && formOne.value.fullAmountSelection === 1) {
                            paymentTypeId = AccessYourSuperPaymentTypeEnum.FullAmountKeepAccountOpen;
                        }
                        else {
                            paymentTypeId = AccessYourSuperPaymentTypeEnum.PartialAmount;
                        }
                    }
                    else {
                        if (formOne.value.paymentType === 0 && formOne.value.fullAmountSelection === 0) {
                            paymentTypeId = AccessYourSuperPaymentTypeEnum.FullGrossAmountTenThousand;
                        }
                        else {
                            paymentTypeId = AccessYourSuperPaymentTypeEnum.PartialAmount;
                        }
                    }


                    var investmentDrawdown = formOne.value.investmentOptionDrawdown.map(investmentOption => ({
                        optionId: investmentOption.optionId,
                        option: investmentOption.option,
                        amount: investmentOption.amount,
                        percentage: investmentOption.percentage
                    } as InvestmentItemModel));

                    payload = {
                        accessYourSuperPaymentDetailId: this.paymentDetailId,
                        accessYourSuperApplicationId: this.id,
                        paymentTypeId: paymentTypeId,
                        benefitAmount: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount ? formOne.value.partialAmountSpecified : null,
                        isPermanentlyRetired: formOne.value.memberReachedPreservationAge ? formOne.value.isPermanentlyRetired : null,
                        isBeforeTax: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount ? formOne.value.isBeforeTax : null,
                        currentStep: currentStep,
                        sfhSummary: formOne.value.sfhSummary,
                        receivingQualifyingSupport: formOne.value.receivingQualifyingSupport,
                        partialWithdrawalInvestmentSelection: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount ? formOne.value.partialWithdrawalSelection : null,
                        investmentDrawdown: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount && formOne.value.partialWithdrawalSelection ? investmentDrawdown : null,
                    } as AccessYourSuperSfhDetailSubmissionModel;

                    this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
                }
                else {
                    this.dispatch(this.store, SetSfhStepAction({ step: currentStep + 1 }));
                }
                break;
            }
            case 2: {
                var formTwo = await this.helper.getValue(this.formTwo$);
                if (formTwo.isTouched) {

                    payload = {
                        accessYourSuperApplicationId: this.id,
                        accessYourSuperPaymentDetailId: this.paymentDetailId,
                        dependantAndFinanceData: {
                            dependants: formTwo.value.dependants,
                        },
                        currentStep: currentStep,
                        formVersion: formVersion
                    } as AccessYourSuperSfhDetailSubmissionModel;

                    this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
                }
                else {
                    this.dispatch(this.store, SetSfhStepAction({ step: currentStep + 1 }));
                }
                break;
            }
            case 3: {
                payload = {
                    accessYourSuperApplicationId: this.id,
                    accessYourSuperPaymentDetailId: this.paymentDetailId,
                    dependantAndFinanceData: {
                        // spread the formThree values into a new object as it will go into the dynamic property on the backend
                        financialDetails: { ...this.financialDetails }
                    },
                    currentStep: currentStep,
                    parentStep: this.step,
                    formVersion: formVersion
                } as AccessYourSuperSfhDetailSubmissionModel;

                this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
                break;
            }
        }
    }

    async goBack() {
        var currentStep = await this.helper.getValue(this.sfhStep$);
        if (currentStep === 1) {
            this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.previousStep }))
        }
        else {
            this.dispatch(this.store, SetSfhStepAction({ step: currentStep - 1 }));
        }
    }

    onStepperSelectionChange(titles: any[], event: any) {
        this.dispatch(this.store, SetSfhStepAction({ step: event.selectedIndex + 1 }));
    }

    trackByFn(index, item) {
        return index;
    }

    removeDependant(index: number, dependantForm: FormGroupState<SfhDependantsForm>) {
        var clone = [...dependantForm.controls.dependants.value];
        clone.splice(index, 1);
        this.store.dispatch(new SetValueAction(dependantForm.controls.dependants.id, clone));
    }

    addDependant(dependantForm: FormGroupState<SfhDependantsForm>) {
        this.store.dispatch(new SetValueAction(dependantForm.controls.dependants.id, [...dependantForm.controls.dependants.value, new AccessYourSuperSfhDependantModel()]))
    }

    async financialDetailsValid(payload:FinancialDetailsOutput){
        this.financialDetails = payload.financialDetails;
        var isValid = await this.helper.getValue(this.formThreeValid$);

        if (isValid !== payload.isValid)
            this.dispatch(this.store, SetFormThreeValidAction({isValid:payload.isValid}))
    }
}