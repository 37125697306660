import { getAccountUnderlyingInvestmentSummaryView_Model, getAccountUnderlyingInvestmentSummaryView_SelectedInvestmentOption, getAccountUnderlyingInvestmentSummaryView_hasMultipleInvestments, getAccountUnderlyingInvestmentSummaryView_noOfInvestments, getAccountUnderlyingInvestmentSummaryView_isConsolidatedView, getAccountUnderlyingInvestmentSummaryView_Model_investmentOptions } from './selectors';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestUnderlyingInvestmentSummaryAction, InvestmentOptionChangedAction, ShowInvestmentOptionsAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { ChartComponent, ApexTooltip, ApexXAxis, ApexYAxis, ApexNoData, ApexTitleSubtitle, ApexStates } from "ng-apexcharts";
import { environment } from 'src/environments/environment';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexLegend, ApexDataLabels } from "ng-apexcharts";
import { AppState } from 'src/app/store/app.states';
import { MatChip } from '@angular/material/chips';
import { InvestmentSummary, InvestmentOptionDetails, AccountUnderlyingInvestmentSummaryModel } from 'src/app/model/account-underlying-investment-summary.model';
import { Helper, KeyValueModel } from '@ifaa-components/ui-components';
import { DatePipe } from '@angular/common';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';

export type ChartOptions = {
  title: ApexTitleSubtitle;
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  colors: any[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  noData: ApexNoData;
  states: ApexStates;
};


@Component({
  selector: 'app-account-underlying-investment-summary',
  templateUrl: './account-underlying-investment-summary-view.component.html',
  styleUrls: ['./account-underlying-investment-summary-view.component.scss']
})
export class AccountUnderlyingInvestmentSummaryViewComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  selectedInvestmentOption$ = this.store.pipe(select(getAccountUnderlyingInvestmentSummaryView_SelectedInvestmentOption));
  hasMultipleInvestments$ = this.store.pipe(select(getAccountUnderlyingInvestmentSummaryView_hasMultipleInvestments));
  noOfInvestments$ = this.store.pipe(select(getAccountUnderlyingInvestmentSummaryView_noOfInvestments));
  isConsolidatedView$ = this.store.pipe(select(getAccountUnderlyingInvestmentSummaryView_isConsolidatedView));
  modelSummary$ = this.store.pipe(select(getAccountUnderlyingInvestmentSummaryView_Model));
  investmentOptions$ = this.store.pipe(select(getAccountUnderlyingInvestmentSummaryView_Model_investmentOptions));

  displayDollarColumn = FeatureToggleName.member.account.underlyingInvestmentView.tableDollarColumn.view;
  manageInvestmentsFeatureToggle = FeatureToggleName.member.account.manageInvestments.view;

  noOfInvestments: number = 0;
  hasMultipleInvestments: boolean = false;
  isConsolidatedView: boolean = true;
  @ViewChild("chart1") chart: ChartComponent;
  @ViewChild("mobileMenu") mobileMenu: any;
  public chartOptions: Partial<ChartOptions>;
  @ViewChild('chip') chip: MatChip;
  @ViewChild('chip2') chip2: MatChip;
  helper = new Helper();

  @Output() titleEvent = new EventEmitter<string>();
  currentInvestmentSummary: InvestmentSummary;
  datepipe: DatePipe = new DatePipe('en-AU');
  drawerOpened = false;
  selectedOption: number = 0;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.drawerOpened = true;
    }, 500);
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestUnderlyingInvestmentSummaryAction({ accountId: x.accountId }));
      }
    });

    this.sub = this.hasMultipleInvestments$.subscribe(x => {
      this.hasMultipleInvestments = x;
      this.setTitle();
    });

    this.sub = this.noOfInvestments$.subscribe(x => {
      this.noOfInvestments = x;
      this.setTitle();
    });

    this.sub = this.isConsolidatedView$.subscribe(x => {
      this.isConsolidatedView = x;
      this.setTitle();
    });

    this.sub = this.modelSummary$.subscribe(x => { });

    this.sub = this.selectedInvestmentOption$.subscribe(x => {
      if (x) {
        this.currentInvestmentSummary = x;
        this.setChartData();
        this.setAssetTitle();
      }
    });
  }

  async onSelectedOptionChanged(index: number, mobileMenu: any) {
    if (mobileMenu)
      mobileMenu.expanded = false;
    this.selectedOption = index;
    var list = await this.helper.getValue(this.investmentOptions$);
    var selected = list[index];
    this.onOptionsSelected(selected.key.toString());
  }

  getPercentage(id: number, summary: AccountUnderlyingInvestmentSummaryModel) {
    var model = summary.investmentSummaries;
    var selected = model.filter(x => x.investmentOptionId.toString() == id.toString());
    return selected[0].percentAllocated
  }

  getInvestmentOptionDetailColors(investmentOptionDetails: InvestmentOptionDetails[]) {
    var defaultColors = environment.underlyingInvestmentsChartColors as KeyValueModel[];
    if (defaultColors) {
      try {
        let colors: string[] = [];
        investmentOptionDetails.forEach(element => {
          var defaultColor = defaultColors.filter(o => o.key == element.consolidateByName);
          if (defaultColor) {
            colors.push(defaultColor[0].value);
          }
        });
        return colors;
      } catch (error) {
        return undefined;
      }
    }
    else
      return undefined;

  }

  setChartData() {

    var investmentOptionDetails = [...this.currentInvestmentSummary.investmentOptionDetails];
    investmentOptionDetails = investmentOptionDetails.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);

    var colors = this.getInvestmentOptionDetailColors(investmentOptionDetails);
    var sortedLabels = investmentOptionDetails.map(p => { return p.consolidateByName });
    var sortedSeries = investmentOptionDetails.map(p => { return p.amountInvested });

    this.chartOptions = {
      chart: {
        width: this.screenWidth > 992 ? 400 : 250,
        type: "donut",
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
      },
      title: {
        text: 'Assets',
      },
      noData: {
        text: 'Loading...'
      },
      labels: sortedLabels,
      dataLabels: {
        enabled: false,
        formatter: function (val, opts) {
          return val + " %"
        },
        style: {
          fontSize: '11px',
        },
      },
      series: sortedSeries,
      legend: {
        show: false,
      },
      colors: colors,
      xaxis: {},
      yaxis: {
        show: false,
        labels: {
          formatter: (value) => {
            return value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          }
        },
      },

    };
  }
  viewConsolidation(mobileMenu: any) {
    if (mobileMenu)
      mobileMenu.expanded = false;
    this.dispatch(this.store, InvestmentOptionChangedAction({ investmentOptionId: '-1' }));
  }

  viewSplitInvestments() {
    this.dispatch(this.store, ShowInvestmentOptionsAction());
  }

  onOptionsSelected(value: string) {
    this.dispatch(this.store, InvestmentOptionChangedAction({ investmentOptionId: value }));
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  setTitle() {
    var title = 'Underlying Investment';

    if (this.isConsolidatedView && this.hasMultipleInvestments) {
      title = title.concat(' - Consolidated');
    }
    if (!this.isConsolidatedView && this.hasMultipleInvestments) {
      title = title.concat(' - Split by Investment option');
    }
    if ((this.isConsolidatedView && !this.hasMultipleInvestments) || this.noOfInvestments == 0) {
      title = title;
    }

    setTimeout(() => {
      this.titleEvent.emit(title);
    }, 0);
  }

  setAssetTitle() {
    var assetName = 'Asset classes - ';

    if (this.currentInvestmentSummary.summaryLink) {
      return `${assetName}<a href=${this.currentInvestmentSummary.summaryLink} target='_blank' class='summary-link'>${this.currentInvestmentSummary?.investmentOptionName}</a>`
    }

    return assetName.concat(this.currentInvestmentSummary?.investmentOptionName);
  }

  getInvestmentOptionPercentageTotal() {
    //Consolidated Percentage Total
    if (this.currentInvestmentSummary.investmentOptionId == '-1') {
      return this.currentInvestmentSummary.percentAllocated;
    }
    else {
      //Sum Investment Option Details percentage
      var percenatgeTotal = 0;
      this.currentInvestmentSummary.investmentOptionDetails.forEach(element => {
        percenatgeTotal += element.percentage;
      });

      return percenatgeTotal;
    }
  }

  getInvestmentDetailColor(consolidateByName: string) {
    var defaultColors = environment.underlyingInvestmentsChartColors as KeyValueModel[];
    if (defaultColors) {
      var defaultColor = defaultColors.filter(o => o.key == consolidateByName);
      if (defaultColor && defaultColor[0])
        return defaultColor[0].value;
      return 'black';
    }
  }

  getUnitPriceDate() {
    if (this.currentInvestmentSummary.investmentOptionId == '-1') {
      return null;
    }
    else {
      var message = "Unit price is as at ";
      var formattedDate = this.datepipe.transform(this.currentInvestmentSummary.asAt, 'dd-MMM-YYYY')
      return message.concat(formattedDate);
    }
  }
}

