<div id="pension-change-schedule-requests-container">
    <ng-container *ngIf="data$|async; let data">
        <ng-container *ngIf="data.length > 0">
            <div class="col-12">
                <p>Pension schedule change requests submitted online.</p>
                <table *ngIf="screenWidth > 768"
                    class="col-12 table {{'VIEWS.Global.table-default-style' | translate}}">
                    <thead>
                        <tr>
                            <th width="20%" scope="col">Payment frequency</th>
                            <th width="20%" scope="col">Payment type</th>
                            <th width="20%" scope="col">Payment amount</th>
                            <th width="20%" scope="col">Submitted on</th>
                            <th width="20%" scope="col">Case status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of data">
                            <tr>
                                <td>{{item.paymentFrequency}}</td>
                                <td>{{item.paymentType}}</td>
                                <td>{{item.paymentAmount | currency}}</td>
                                <td>{{item.submittedOn | date}}</td>
                                <td>{{item.caseStatus}}</td>
                        </ng-container>
                    </tbody>
                </table>

                <div *ngIf="screenWidth <= 768">
                    <ng-container *ngFor="let item of data">
                        <div class="mobile-view-section">
                            <div class="mobile-view-details">
                                <h2 class="top-info section-header">
                                    Case status: {{item.caseStatus}}
                                </h2>
                                <h5 class="top-info section-sub-header">
                                    Submitted on: {{item.submittedOn | date}}
                                </h5>
                            </div>
                            <table class="col-12 table {{'VIEWS.Global.table-default-style' | translate}}">
                                <thead>
                                    <tr>
                                        <th width="20%" scope="col">Payment frequency</th>
                                        <th width="20%" scope="col">Payment type</th>
                                        <th width="20%" scope="col">Payment amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{item.paymentFrequency}}</td>
                                        <td>{{item.paymentType}}</td>
                                        <td>{{item.paymentAmount | currency}}</td>
                                </tbody>
                            </table>
                            <!-- <div>
                                <h5>
                                    Payment frequency: {{item.paymentFrequency}}
                                </h5>
                                <h5>Payment type: {{item.paymentType}}</h5>
                                <h5>Payment amount: {{item.paymentAmount | currency}}</h5>
                            </div> -->
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="data.length === 0">
            <div class="col-12">
                <i>You have not submitted any requests to change your pension payment schedule. Once you have submitted
                    a request you will be able to track its status from this page.</i>
            </div>
        </ng-container>
    </ng-container>

    <div class="d-flex justify-content-between col-12">
        <app-button class="mt-2 ml-1" title="Exit" icon="{{'BTN.change-pension-schedule.cancel.icon' | translate}}"
            color="{{'BTN.change-pension-schedule.cancel.color' | translate}}" (onClick)="goBack()">
        </app-button>
    </div>
</div>