import { AppState } from 'src/app/store/app.states';
import { Directive, Renderer2, Input, ViewContainerRef, TemplateRef, OnChanges, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { Subscription, combineLatest } from 'rxjs';
import { memberAccountDropdown_SelectedAccount } from '../components/member-account-dropdown/selectors';
import { distinctUntilChanged } from 'rxjs/operators';
import { FeatureToggleLevel } from 'src/app/model/feature-toggle-level.model';
import { FeatureToggleValue } from 'src/app/model/feature-toggle-value.model';

@Directive({
  selector: '[featuretoggle]',
})
export class FeatureToggleDirective implements OnChanges, OnDestroy {

  featuretoggle$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  sub: Subscription = null;

  @Input() featuretoggle: string;

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public view: ViewContainerRef,
    public template: TemplateRef<any>) {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges() {
    this.sub = combineLatest([this.featuretoggle$, this.selectedAccount$])
      .pipe(
        distinctUntilChanged((x, y) => {
          if (JSON.stringify(x[1]) !== JSON.stringify(y[1]) ||
            JSON.stringify(x[0]) !== JSON.stringify(y[0]))
            return false;

          return true;
        }))
      .subscribe(([featureToggles, selectedAccount]) => {
        if (featureToggles && selectedAccount) {

          var globalFlag = featureToggles.find(x => x.level === FeatureToggleLevel.global &&
            x.name === this.featuretoggle &&
            x.value === FeatureToggleValue.available);

          // Check if global flag is turned on
          if (globalFlag) {

            // Check if account classification flag is turned on
            var accountFlag = featureToggles.find(x => x.level === FeatureToggleLevel.accountClassification &&
              x.name === this.featuretoggle &&
              x.value === FeatureToggleValue.available &&
              Number(x.levelReference) === selectedAccount.accountClassificationId);

            if (accountFlag) {

              // Check if its been disabled/hidden for that account type
              var accountTypeFlag = featureToggles.find(x => x.level === FeatureToggleLevel.accountType &&
                x.name === this.featuretoggle &&
                x.value !== FeatureToggleValue.available &&
                Number(x.levelReference) === selectedAccount.accountTypeId);

              if (!accountTypeFlag) {
                this.view.createEmbeddedView(this.template);
                return;
              }
            }
          }
        }
        this.view.clear();
      });
  }


}
