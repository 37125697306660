<div id="investment-switch-history-container" *ngIf="data$ | async; let data">

    <ng-container>
        <p>Your investment switch history for requests submitted online:</p>

        <app-search-filter>
            <div filters class="d-flex flex-row flex-fill align-items-center filters"
                *ngIf="filter$ | async; let filter">

                <!-- desktop filters -->
                <div class="d-none d-lg-block flex-fill desktop-filters">
                    <div class="d-flex flex-row flex-fill">

                        <!-- type filter -->
                        <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center"
                            mat-button [matMenuTriggerFor]="menuType">
                            Type <span class="badge badge-light ml-1">
                                {{ switchType | dash}}
                            </span>
                            <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
                        </button>

                        <!-- status filter -->
                        <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center"
                            mat-button [matMenuTriggerFor]="menuStatus">
                            Switch request status <span class="badge badge-light ml-1">
                                {{ switchStatus | dash}}
                            </span>
                            <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
                        </button>

                        <!-- date filter -->
                        <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center pl-2"
                            mat-button [matMenuTriggerFor]="menuDate">
                            From:<span class="badge badge-secondary m-1">
                                {{filter.value.from | date:'dd/MM/yyyy'| dash}}</span>
                            To:<span class="badge badge-secondary m-1">
                                {{filter.value.to | date:'dd/MM/yyyy' | dash}}</span>
                            <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
                        </button>

                    </div>
                </div>

                <!-- mobile filters -->
                <div class="flex-fill d-md-block d-lg-none">

                    <button class="p-1 mr-1 w-100 btn-trancation-type d-flex align-items-center justify-content-start"
                        mat-button [matMenuTriggerFor]="filterMenu">
                        Filters:
                        <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
                    </button>

                    <mat-menu #filterMenu="matMenu" class="mobile-menu">

                        <!-- type -->
                        <button
                            class="ml-2 badge badge-primary p-1 mr-1 w-100 btn-trancation-type d-flex align-items-center"
                            mat-menu-item [matMenuTriggerFor]="menuType">
                            Type <span class="badge badge-light ml-1">
                                {{ switchType | dash}}
                            </span>
                        </button>

                        <!-- status -->
                        <button
                            class="ml-2 badge badge-primary p-1 mr-1 w-100 btn-trancation-type d-flex align-items-center"
                            mat-menu-item [matMenuTriggerFor]="menuStatus">
                            Switch request status <span class="badge badge-light ml-1">
                                {{ switchStatus | dash}}
                            </span>
                        </button>

                        <!-- date -->
                        <button *ngIf="filter$ | async; let filter"
                            class="ml-2 badge badge-primary p-1 mr-1 w-100 btn-trancation-type d-flex align-items-center"
                            mat-menu-item [matMenuTriggerFor]="menuDate">
                            From:<span class="badge badge-secondary m-1">
                                {{filter.value.from | date:'dd/MM/yyyy'| dash}}</span>
                            To:<span class="badge badge-secondary m-1">
                                {{filter.value.to | date:'dd/MM/yyyy' | dash}}</span>
                        </button>
                    </mat-menu>
                </div>


                <!-- inner type menu -->
                <mat-menu #menuType="matMenu" class="switch-history-menu cash-transactions">
                    <button mat-menu-item *ngFor="let item of (switchTypes$ | async)" (click)="selectTypeFilter(item)">
                        <mat-icon>arrow_right</mat-icon>{{item.value}}
                    </button>
                </mat-menu>
                <!-- inner status menu -->
                <mat-menu #menuStatus="matMenu" class="switch-history-menu cash-transactions">
                    <button mat-menu-item *ngFor="let item of (switchStatuses$ | async)"
                        (click)="selectStatusFilter(item)">
                        <mat-icon>arrow_right</mat-icon>{{item.value}}
                    </button>
                </mat-menu>
                <!-- inner date menu -->
                <mat-menu #menuDate="matMenu" class="switch-history-menu p-3">
                    <button *ngIf="(filter$ | async)?.controls; let filter" mat-menu-item
                        class="d-flex align-items-center" (click)="clearYear()">
                        <mat-icon>close</mat-icon>
                        Clear dates
                    </button>
                    <button class="d-flex align-items-center" *ngIf="(filter$ | async)?.controls; let filter"
                        mat-menu-item (click)="currentFinancialYear(filter)">
                        <mat-icon>date_range</mat-icon>Current financial year
                    </button>
                    <button class="d-flex align-items-center" *ngIf="(filter$ | async)?.controls; let filter"
                        mat-menu-item (click)="previousFinancialYear(filter)">
                        <mat-icon>date_range</mat-icon>Previous financial year
                    </button>
                    <div class="custom-date-select" *ngIf="(filter$ | async)?.controls; let filter"
                        (click)="$event.stopPropagation();">
                        <div class="m-3">
                            <div class="d-flex align-items-center mb-3 flex-row">
                                <mat-icon class="mr-3">edit</mat-icon>Custom dates:
                            </div>
                            <div class="form-check pl-0 mr-3">
                                <app-entry-date title="From" [date]="(filter$ | async)?.controls.from"></app-entry-date>
                            </div>
                            <div class="form-check pl-0 mr-3">
                                <app-entry-date title="To" [date]="(filter$ | async)?.controls.to"></app-entry-date>
                            </div>
                        </div>
                    </div>

                </mat-menu>

                <div end>
                    <button mat-icon-button [matMenuTriggerFor]="menuMore">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menuMore="matMenu">
                        <button mat-menu-item (click)="onClearFilterClick()">Clear filters</button>
                    </mat-menu>
                </div>
            </div>
        </app-search-filter>




        <app-no-items message="You have not submitted any requests to change your investments for the selected time
        period."
            *ngIf="data.switchHistory.length === 0 && ((filter$ | async)?.value.from || (filter$ | async)?.value.to)"></app-no-items>

        <app-no-items message="No investment switch requests found."
            *ngIf="data.switchHistory.length === 0 && (!(filter$ | async)?.value.from && !(filter$ | async)?.value.to)"></app-no-items>

        <div class="switches-container" *ngIf="data.switchHistory.length > 0">
            <ng-container *ngFor="let details of data.switchHistory">
                <app-investment-switch-history-card [details]="details"
                    [accountClass]="accountClassification"></app-investment-switch-history-card>
            </ng-container>

            <div *ngIf="(filter$|async).value.currentPage !== -1 && !(isLoading$|async)">
                <app-button (onClick)="onLoadMore()" class="d-flex justify-content-center" title="Load more" icon="add">
                </app-button>
            </div>
        </div>
    </ng-container>

</div>