import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResetSecondDropdownValueAction, SaveApplicationDataAction, SaveApplicationIdAction, SetFirstDropdownValuesAction,
   SetSecondDropdownValuesAction } from './actions';
import { AccessYourSuperTypeSelectionDropdowns, IAccessYourSuperTypeSelectionComponentState } from './state';

export const state: IAccessYourSuperTypeSelectionComponentState = {
  form:createFormGroupState('accessYourSuperTypeSelectionDropdowns', new AccessYourSuperTypeSelectionDropdowns()),
  firstDropdownValues: [],
  secondDropdownValues: [],
  applicationId:null,
  applicationData:null
};

export const validateDropdowns = updateGroup<AccessYourSuperTypeSelectionDropdowns>({
  firstDropdown: validate(required),
  secondDropdown: validate(required),
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(SetFirstDropdownValuesAction, (state, {payload}) => {
    return {
      ...state,
      firstDropdownValues: payload
    }
  }),
  on(SetSecondDropdownValuesAction, (state, {payload}) => {
    return {
      ...state,
      secondDropdownValues: payload
    }
  }),
  on(ResetSecondDropdownValueAction, (state) => {
    return {
      ...state,
      form: setValue({
        ...state.form.value,
        secondDropdown: null
      })(state.form),
    }
  }),
  on(ResetComponentStateAction, (state) => {
    return {
      model:null,
      form:createFormGroupState('accessYourSuperTypeSelectionDropdowns', new AccessYourSuperTypeSelectionDropdowns()),
      firstDropdownValues:[],
      secondDropdownValues:[],
      applicationId:null,
      applicationData:null
    }
  }),
  on(SaveApplicationIdAction, (state, {applicationId}) => {
    return {
      ...state,
      applicationId: applicationId
    }
  }),
  on(SaveApplicationDataAction, (state, {model}) => {
    return {
      ...state,
      applicationData:model
    }
  })
);


const accessYourSuperTypeSelectionFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateDropdowns,
);

export function accessYourSuperTypeSelectionComponentReducer(state: any | undefined, action: Action) {
  return accessYourSuperTypeSelectionFormState(state, action);
}
