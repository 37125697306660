import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AccountFeatureToggle } from "../model/account-feature-toggles.model";
import { AccountModel } from "../model/account.model";
import { FeatureToggleLevel } from "../model/feature-toggle-level.model";
import { FeatureToggleValue } from "../model/feature-toggle-value.model";
import { memberAccountDropdown_SelectedAccount } from '../modules/shared/components/member-account-dropdown/selectors';
import { commonState_SystemConfig_AccountFeatureToggles, commonState_SystemConfig_AccountIdFeatureToggles } from '../store/common/common.selectors';
import { MemberPortalSharedState } from '../modules/shared/store/shared.states';
import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';

export class FeatureToggleHelper {

  constructor() {
  }

  checkToggle = (value: string, featureToggles: AccountFeatureToggle[], selectedAccount: AccountModel, checkAccountLevel: boolean = true) => {
    if (!value) return true;

    var globalFlag = featureToggles?.find(x => x.level === FeatureToggleLevel.global &&
      x.name === value);

    if (!globalFlag || globalFlag.value === FeatureToggleValue.hidden) {
      return false;
    }

    if (checkAccountLevel) {
      var accountFlag = featureToggles.find(x => x.level === FeatureToggleLevel.accountClassification &&
        x.name === value &&
        Number(x.levelReference) === selectedAccount?.accountClassificationId);

      if (!accountFlag || accountFlag.value === FeatureToggleValue.hidden)
        return false;
    }

    return true;
  }

}

@Injectable({ providedIn: 'root' })
export class FeatureToggleCheckService {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  featuretoggle$ = this.appStore.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  accountIdFeaturetoggle$ = this.appStore.pipe(select(commonState_SystemConfig_AccountIdFeatureToggles));
  toggleHelper = new FeatureToggleHelper()

  constructor(public store: Store<MemberPortalSharedState>,
    public appStore: Store<AppState>) {
  }


  checkToggle = (destroy$: Observable<any>, value: string, callback: any, checkAccountLevel = true) => {
    combineLatest([this.featuretoggle$, this.selectedAccount$, this.accountIdFeaturetoggle$])
      .pipe(takeUntil(destroy$))
      .subscribe(([featureToggles, selectedAccount, accountIdFeaturetoggle]) => {
        if (accountIdFeaturetoggle == selectedAccount?.accountId) {
          var toggleCheck = this.toggleHelper.checkToggle(value, featureToggles, selectedAccount, checkAccountLevel);
          if (toggleCheck)
            callback(true, selectedAccount);
          else {
            callback(false, selectedAccount);
          }
        }
        else {
          callback(false, selectedAccount);
        }
      });
  }
}
