import { HttpBackend, HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { TranslateLoader, TranslateModuleConfig } from "@ngx-translate/core";
import { Observable, filter, map } from "rxjs";

export class TranslateHttpLoader implements TranslateLoader {
  constructor(
      private httpHandler: HttpBackend,
      public prefix: string,
      public suffix: string,
  ) { }

  getTranslation(lang: string): Observable<unknown> {
      const httpRequest = new HttpRequest('GET', `${this.prefix}${lang}${this.suffix}`);

      return this.httpHandler.handle(httpRequest)
          .pipe(
              filter((httpEvent: HttpEvent<any>) => httpEvent instanceof HttpResponse),
              map((httpResponse: HttpResponse<any>) => httpResponse.body),
          );
  }
}

export function translateLoaderFactory(httpHandler: HttpBackend,): TranslateLoader {
  return new TranslateHttpLoader(httpHandler, './assets/i18n/', '.json');
}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  loader: {
      provide: TranslateLoader,
      useFactory: translateLoaderFactory,
      deps: [
          HttpBackend,
      ],
  }
};
