<div id="switch-investments-container">

  <form [ngrxFormState]="form" *ngIf="form$ | async; let form">
    <div class="d-flex flex-row mb-4">
      <div class="flex-fill" *ngFor="let item of form.value.options; index as i" appanimatecss>
        <div class="card flex-fill m-2 h-100 option-{{i}}">
          <div class="card-body d-flex flex-column">
            <div class="header">
              <h5 class="card-title">{{item.title}}</h5>
              <div class="card-subtitle mb-2" *ngIf="item.subTitle">{{item.subTitle}}</div>
            </div>
            <div class="card-text description d-flex flex-fill flex-column pl-2">
              <p *ngFor="let option of item.descriptionList" [innerHTML]="option">
              </p>
            </div>
            <div class="components-actions">
              <app-button loading class="w-100  mr-1 " title="Switch to this option" icon="arrow_right" [color]="'switchInvestments_ButtonColor'|message|async"
                *ngIf="item.id != form.value.selected && form.value.newSelected == 0"
                (onClick)="onSwitchOptionClick(item.id)">
              </app-button>
              <app-button loading class="w-100  mr-1 " title="Selected option" icon="arrow_right" color="link"
                [isDisabled]="true" *ngIf="item.id == form.value.selected">
              </app-button>
              <app-button loading class="w-100  mr-1 " title="Switch request pending" icon="hourglass_empty"
                [isDisabled]="true" *ngIf="item.id == form.value.newSelected">
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>
