import { AppState } from './../../../../store/app.states';
import { accountFees_Model } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config, commonState_MemberData } from 'src/app/store/common/common.selectors';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';

@Component({
  selector: 'app-account-fees',
  templateUrl: './account-fees.component.html',
  styleUrls: ['./account-fees.component.scss']
})
export class AccountFeesComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.appstore.pipe(select(accountFees_Model));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));

  viewProfileDetailsFeatureToggle = FeatureToggleName.member.personalDetails.view;
  changePasswordFeatureToggle = FeatureToggleName.member.changePassword.view;
  selected$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  @Input() itemclass: string = 'w-50';

  constructor(
    public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selected$.subscribe(account => {
      if (account) {
         this.dispatch(this.store, RequestAction({ accountId: account.accountId }));
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}
