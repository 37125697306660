import { MemberModel } from './../../../../model/member.model';
import { editMember_Form, editMember_LastModified, editMember_OriginalEmail } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, ResetAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { SetValueAction } from 'ngrx-forms';
import { CommunicationPreference } from 'src/app/enums/communication-preference.enum';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { ConfirmationDialog, Helper } from '@ifaa-components/ui-components';
import { MatDialog } from '@angular/material/dialog';
import { RequestMemberDataAction } from 'src/app/store/common/common.actions';

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss']
})
export class MemberEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editMember_Form));
  lastModified$ = this.store.pipe(select(editMember_LastModified));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  originalEmail$ = this.appstore.pipe(select(editMember_OriginalEmail));
  communicationPreference = null;

  editCommunicationPreferenceFeatureToggle = FeatureToggleName.member.personalDetails.communicationPreference.update;
  editEmailFeatureToggle = FeatureToggleName.member.personalDetails.email.update;
  editDobFeatureToggle = FeatureToggleName.member.personalDetails.dob.update;
  editTfnFeatureToggle = FeatureToggleName.member.personalDetails.tfn.update;
  editOccupationFeatureToggle = FeatureToggleName.member.personalDetails.occupation.update;
  updateProfileFeatureToggle = FeatureToggleName.member.personalDetails.update;
  helper = new Helper();

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    public dialog: MatDialog,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, RequestAction());

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestMemberDataAction());
        this.router.navigate(['personal-details']);
      }
    });

    this.sub = this.form$.subscribe(form => {
      if (this.communicationPreference != form.value.communicationPreference) {
        this.communicationPreference = form.value.communicationPreference;
        this.store.dispatch(new SetValueAction(form.controls.postalAddress.controls.isRequired.id, form.value.communicationPreference == CommunicationPreference.byPost));
      }

    });
  }

  ngOnDestroy() {
    this.store.dispatch(ResetAction());
    super.ngOnDestroyBase();
  }

  async onSubmitClick(data: MemberModel) {
    var originalEmail = await this.helper.getValue(this.originalEmail$);
    if (originalEmail == data.email) {
      this.dispatch(this.store, SubmitRequestAction({ payload: data }));
    }
    else {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        width: '450px',
        data: 'Please note that updating your email address will update your login email. Next time you login you will need to use the email address supplied in this update. Would you like to continue?'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dispatch(this.store, SubmitRequestAction({ payload: data }));

        }
      });
    }
  }

}
