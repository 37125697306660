import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { InvestmentItemModel } from "src/app/model/investment-item.model";
import { ComponentBase } from "src/app/views/component-base";

@Component({
    selector: 'app-investment-edit-hover',
    templateUrl: './investment-edit-hover.component.html',
    styleUrls: ['./investment-edit-hover.component.scss']
})
export class InvestmentEditHoverComponent extends ComponentBase implements OnInit, OnDestroy {
    @Input() set hiddenOptions (value:InvestmentItemModel[]){
        this.hiddenOps = value;
    }

    @Input() set option (value:InvestmentItemModel){
        this.compositeOption = value;
    }

    @Input() showDollarCol:boolean = false;

    compositeOption:InvestmentItemModel = null;
    hiddenOps:InvestmentItemModel[] = [];
    relevantOptions:InvestmentItemModel[] = [];

    ngOnDestroy(): void {
        super.ngOnDestroyBase();
    }
    ngOnInit(): void {
        this.relevantOptions = this.hiddenOps.filter(x => x.compositeOptionId === this.compositeOption.optionId);
    }
}