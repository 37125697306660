import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from './store/app.states';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IsLoggedInRequestAction, RequestMemberDataAction, SetIsAdviserElevatedAccessAction, SetIsFundElevatedAccessAction, SystemConfigurationRequestAction } from './store/common/common.actions';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DomSanitizer } from '@angular/platform-browser';
import { memberAccountDropdown_SelectedAccount } from './modules/shared/components/member-account-dropdown/selectors';
import { ShowQuestionnaireRequestAction } from './modules/shared/components/insurance-questionnaire/actions';
import {
  commomState_ElevatedAccess,
  commomState_LoggedinInfo, commomState_SystemConfig_Config, commomState_SystemConfig_FeatureToggle,
  commonState_SystemConfig_AccountFeatureToggles, commonState_SystemConfig_SiteError
} from './store/common/common.selectors';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IdTokenClaims } from '@azure/msal-common';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { FeatureToggleName } from './model/feature-toggle-name.model';
import { FeatureToggleCheckService, FeatureToggleHelper } from './helper/featureToggleHelper';
import { Helper, TenantService, UserServiceConfig } from '@ifaa-components/ui-components';
import { DOCUMENT } from '@angular/common';
import { combineLatest } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AreYouStillThereDialog } from './modules/shared/components/are-you-still-there-dialog/are-you-still-there.component';
import { AppInjector } from './app.module';
declare const window: any;

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

type MsalPayload = {
  idTokenClaims:{
    extension_Role:string;
  }
}

declare var gtag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'member-portal';
  featuretoggle$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));
  updateTraditionalPlaceName = FeatureToggleName.member.personalDetails.addressTraditionalPlaceName.update;

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));
  loggedinInfo$ = this.store.pipe(select(commomState_LoggedinInfo));
  resetChat = null;
  showChat = false;
  code = environment.code;
  accountFeatureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
  siteError$ = this.store.pipe(select(commonState_SystemConfig_SiteError));
  isElevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));
  hasError = false;
  dynamicCSSUrl: string;
  private readonly _destroying$ = new Subject<void>();
  featureToggleHelper = new FeatureToggleHelper();
  helper = new Helper();
  redirectToDashboard = false;
  showQuestionnaireFeatureToggle = FeatureToggleName.member.account.insuranceQuestionnaire.view;
  twilioChatFeatureToggle = FeatureToggleName.member.twilioChat.view;
  areYouStillThereRef: MatDialogRef<AreYouStillThereDialog, any> = null;
  toggleCheck = AppInjector.get(FeatureToggleCheckService);
  twilioConversationsLoaded = false;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public store: Store<AppState>,
    @Inject(DOCUMENT) private doc: any,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public tenantService: TenantService,
    private router: Router,
    private overlayContainer: OverlayContainer,
    public dialog: MatDialog,
    private idle: Idle
  ) {
    translate.addLangs(['en', 'en.' + environment.code]);
    translate.setDefaultLang('en');
    translate.use('en.' + environment.code);
    this.overlayContainer.getContainerElement().classList.add('dark-theme');
    this.dynamicCSSUrl = '/modules/dynamic.css';
    this.store.dispatch(IsLoggedInRequestAction());
  }

  onActivate(event) {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 800);
  }

  displayMessage(evt) {
    if (evt.data.action == 'elevatedAccessClearData') {
      sessionStorage.clear();
      localStorage.clear();
    }
  }

  openAreYouStillThere() {
    this.areYouStillThereRef = this.dialog.open(AreYouStillThereDialog, {
      width: '700px',
    });

    this.areYouStillThereRef.afterClosed().subscribe(() => {
      this.areYouStillThereRef = null;
    })
  }

  async ngOnInit(): Promise<void> {
    this.authService.initialize().subscribe(x => {
      this.authService.instance.enableAccountStorageEvents();
      this.checkAndSetActiveAccount();

      if (window.addEventListener) {
        window.addEventListener("message", this.displayMessage, false);
      }

      this.selectedAccount$
        .pipe(takeUntil(this._destroying$))
        .subscribe(x => {
          if (x) {
            this.store.dispatch(RequestMemberDataAction());

          }
        });


      combineLatest([this.selectedAccount$, this.loggedinInfo$])
        .pipe(
          takeUntil(this._destroying$),
          distinctUntilChanged((prev, curr) => prev?.[0]?.accountId === curr?.[0]?.accountId))
        .subscribe(async x => {
          if (x) {
            if (x[0])
              localStorage.removeItem('login-error');

            var featureToggles = await this.helper.getValue(this.accountFeatureToggles$);

            if (this.featureToggleHelper.checkToggle(this.showQuestionnaireFeatureToggle, featureToggles, x[0])) {
              this.store.dispatch(ShowQuestionnaireRequestAction({ accountId: x[0].accountId }));
            }

            var show = false;

            var toggle = this.featureToggleHelper.checkToggle(this.twilioChatFeatureToggle, featureToggles, x[0], false)

            if (x[1] && x[1].username && toggle === true) {
              show = true;
            }

            if (show && !this.twilioConversationsLoaded) {
              this.twilioConversationsLoaded = true;
              this.helper.loadScript('https://media.twiliocdn.com/sdk/js/conversations/v2.2/twilio-conversations.min.js', () => {
                this.showChat = true;
              });
            }

            // set the idle (60 mins) and timeout (10 mins) and start watching
            this.idle.setIdle(3600);
            this.idle.setTimeout(600);
            this.idle.watch();
          }
        });

      this.tenantConfig$
        .pipe(takeUntil(this._destroying$))
        .subscribe(x => {
          if (!x) {
            this.store.dispatch(SystemConfigurationRequestAction());
          }
          else {
            this.favIcon.href = x.favIcon;
            this.tenantService.tenantEmail = x.email;
            this.tenantService.tenantPhone = x.phoneNumber;
          }
        });

      combineLatest([this.selectedAccount$, this.featuretoggle$])
        .pipe(
          takeUntil(this._destroying$),
          distinctUntilChanged((prev, curr) => prev?.[0]?.accountId === curr?.[0]?.accountId))
        .subscribe(async x => {
          if (x[0] && x[1]) {
            var uiComponentConfig = AppInjector.get(UserServiceConfig);
            this.toggleCheck.checkToggle(this._destroying$, this.updateTraditionalPlaceName, (enabled) => {
              uiComponentConfig.showAddressTraditionalPlaceName = enabled;
            }, false);
          }
        })


      this.siteError$
        .pipe(takeUntil(this._destroying$))
        .subscribe(async x => {
          if (x) {
            await this.router.navigate(['/error'])
          }
        })

      this.setupGoogleTagManager();

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          // console.warn('EventType Login Failure - ' + result.error);          
          if ((result.error as any)?.errorCode == "popup_window_error") {
            localStorage.setItem('login-error-popup-block', 'true');
            alert('Please enable popups to continue with authentication');
            this.hasError = true;
            return;
          }

          if (result.eventType === EventType.LOGIN_FAILURE) {

            if ((result.error as any)?.errorCode == 'no_cached_authority_error') {
              // console.warn('EventType Login Failure - ' +(result.error as any)?.errorCode + ' - ' + result.error.message);          
              location.reload();
              return;
            }

            // code for when user is inactive
            if (result.error.message.indexOf('server_error: AADB2C:') == -1) {
              // console.warn('EventType Login Failure - ' + result.error.message);          
              return;
            }

            this.hasError = true;
          }
        });

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          // console.info('EventType Handle Redirect End - ' + result.payload);          
          if (this.hasError) {
            // console.warn('EventType Handle Redirect End Error - ' + result.error);
            localStorage.setItem('login-error', 'Unauthorized');
          }
          this.hasError = false;
        });

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          // console.warn('EventType InProgress');
          this.checkAndSetActiveAccount();
        })

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe(async (result: EventMessage) => {
          // console.warn('EventType LOGIN_SUCCESS - ' + result);          
          this.checkAndSetActiveAccount();
          let activeAccount = this.authService.instance.getActiveAccount();
          if (activeAccount) {

            var msalResult = result.payload as MsalPayload;

            localStorage.removeItem('login-error-popup-block')
            if (sessionStorage.getItem("login-type") === 'Elevated' && !this.redirectToDashboard) {
              this.redirectToDashboard = true;
              await this.router.navigate(['dashboard']);
            }

            var role = msalResult?.idTokenClaims?.extension_Role;
            if (role){
              if (role === "Elevated"){
                this.store.dispatch(SetIsFundElevatedAccessAction({isElevated:true}))
              }
              else if (role === "AdviserElevated"){
                this.store.dispatch(SetIsAdviserElevatedAccessAction({isElevated:true}))
              }
            }
          }
        });

      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      // On interruption reset the timers
      this.idle.onInterrupt
        .pipe(
          takeUntil(this._destroying$))
        .subscribe(() => {
          this.idle.setIdle(3600);
          this.idle.setTimeout(600);
        });

      // Open pop up once member is idle
      this.idle.onIdleStart
        .pipe(
          takeUntil(this._destroying$))
        .subscribe(() => {
          if (!this.areYouStillThereRef) {
            this.openAreYouStillThere();
          }
        });

      // Logout the member if timeout has been reached
      this.idle.onTimeout
        .pipe(
          takeUntil(this._destroying$))
        .subscribe(() => {
          let activeAccount = this.authService.instance.getActiveAccount();
          if (activeAccount) {
            this.authService.logout();
          }
        });
    });

  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      activeAccount = accounts.find(x => {
        return x.idTokenClaims?.extension_PlatformUserId &&
          (x.idTokenClaims?.extension_Role === "Member"
            || x.idTokenClaims?.extension_Role === "Elevated"
            || x.idTokenClaims?.extension_Role === "AdviserElevated"
          );
      });
      this.authService.instance.setActiveAccount(activeAccount);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setupGoogleTagManager() {
    if (!environment.ga) return;

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$
      .pipe(
        takeUntil(this._destroying$))
      .subscribe((e: NavigationEnd) => {
        gtag('config', environment.ga, { 'page_path': e.urlAfterRedirects });
      });

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.ga;
    document.head.prepend(script);

    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = ` window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.ga}');`;

    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
}
