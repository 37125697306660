import { FormGroupState } from "ngrx-forms";

export interface IAccessYourSuperLastEmployerComponentState {
    form:FormGroupState<AccessYourSuperLastEmployerForm>;
    lastEmployerDetailsSaved?:Date;
}

export class AccessYourSuperLastEmployerForm{
    lastEmployerName:string = '';
    stillWithEmployer:boolean = true;
    finishDate:string = '';
    waitingOnFinalContribution?:boolean = false;
}