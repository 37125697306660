import { submittedFormName } from './../insurance-questionnaire-start/reducer';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { documentViewer_List, documentViewer_SelectedFile } from './selectors';
import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { AppState } from 'src/app/store/app.states';
import { MemberCorrespondenceSummaryModel } from 'src/app/model/member-correspondence-summary.model';
import { ComponentBase } from 'src/app/views/component-base';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { RequestAction, FileRequestAction, ResetFileAction } from './actions';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-member-document-viewer',
  templateUrl: './document-viewer-view.component.html',
  styleUrls: ['./document-viewer-view.component.scss']
})
export class MemberDocumentViewerViewComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() category: string = '';
  accountId: number = 0;
  currentPDF: string = null;
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  list$ = this.store.pipe(select(documentViewer_List));
  selectedFile$ = this.store.pipe(select(documentViewer_SelectedFile));
  downloading: number = -1;
  isSafari: boolean = false;
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;

  constructor(public store: Store<AppState>,
    public toastr: ToastrService,
    public dialog: MatDialog,
    private http: HttpClient) {
    super();

  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(account => {
      if (account) {
        this.accountId = account.accountId;
        this.dispatch(this.store, RequestAction({ accountId: account.accountId, category: this.category }));
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  selectPDF(item: MemberCorrespondenceSummaryModel, index: number) {
    if (this.screenWidth < 992) {
      const dialogRef = this.dialog.open(this.customTemplate, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal'
      });

      dialogRef.afterClosed().subscribe(() => {
      });
    }

    this.downloading = index;
    this.store.dispatch(ResetFileAction());
    setTimeout(() => {
      this.store.dispatch(FileRequestAction({ accountId: this.accountId, correspondenceSource: item.correspondenceSource, correspondenceId: item.correspondenceId }));
    }, 10);
  }

  downloadFile(base64: string) {
    const downloadLink = document.createElement("a");

    downloadLink.href = base64;
    downloadLink.download = "document.pdf";
    downloadLink.click();
    this.downloading = -1;
    this.toastr.success('File downloaded');

  }

}
