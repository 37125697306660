import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "@ifaa-components/ui-components";
import { select, Store } from "@ngrx/store";
import { debounceTime, distinctUntilChanged, pairwise } from "rxjs/operators";
import { AccessYourSuperBankDetailsSubmissionModel } from "src/app/model/access-your-super-form.models";
import { BankDetailsModel } from "src/app/model/bank-details.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { SetCurrentStepAction } from "../access-your-super-form/actions";
import { ResetComponentStateAction, SaveBankDetailsAction, SearchBankNameAction, SetFieldValuesAction } from "./actions";
import { accessYourSuperBankDetails_BankDetailsSaved, accessYourSuperBankDetails_Form } from "./selectors";

@Component({
    selector: "app-access-your-super-bank-details",
    templateUrl: './access-your-super-bank-details.component.html',
    styleUrls: ['./access-your-super-bank-details.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class AccessYourSuperBankDetailsComponent extends ComponentBase implements OnInit, OnDestroy {

    @Output() onBankDetailsSaved: EventEmitter<any> = new EventEmitter();
    @Input() set wording (value:{[key:string]:string}){
        this.nameFieldTooltip = value?.bankDetailsNameTooltip;
    }
    @Input() set data(value: BankDetailsModel) {
        this.model = value;
    }
    @Input() set nextStep(value: number) {
        this.step = value;
    }
    @Input() set backStep(value: number) {
        this.previousStep = value;
    }
    @Input() set applicationId(value: number) {
        this.id = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }

    model: BankDetailsModel = null;
    step: number = null;
    previousStep: number = null;
    id: number = null;
    accountId: number = null;
    payload:AccessYourSuperBankDetailsSubmissionModel = null;
    nameFieldTooltip: string = null;

    form$ = this.store.pipe(select(accessYourSuperBankDetails_Form));
    bankDetailsSaved$ = this.store.pipe(select(accessYourSuperBankDetails_BankDetailsSaved));

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        var helper = new Helper();
        var formData = await helper.getValue(this.form$);

        if (this.model && formData) {
            this.dispatch(this.store, SetFieldValuesAction({bankName:this.model.bankName, accountName:this.model.accountName, accountNumber:this.model.accountNumber, bsb:this.model.bsb}))
        }

        this.sub = this.form$.pipe(
            pairwise(),
            distinctUntilChanged((x, y) => {
                if (x[1].value.bankAccountBsb !== y[1].value.bankAccountBsb)
                    return false;

                return true;
            }),
            debounceTime(500)
        ).subscribe(form => {
            if (form[0].value.bankAccountBsb !== form[1].value.bankAccountBsb) {
                this.dispatch(this.store, SearchBankNameAction({ bsb: form[1].value.bankAccountBsb }));
            }
        })

        this.sub = this.bankDetailsSaved$.subscribe(x => {
            if (x){
                this.onBankDetailsSaved.emit(this.payload);
                this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.step }));
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction())
    }

    goBack() {
        this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.previousStep }))
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }

    async goNextStep(){
        var helper = new Helper();
        var form = await helper.getValue(this.form$);

        this.payload = {
            accountName: form.value.bankAccountName,
            bsb: form.value.bankAccountBsb,
            accountNumber: `${form.value.bankAccountNumber}`,
            bankName: form.value.financialInstitutionName,
            currentStep: this.step
        };

        this.dispatch(this.store, SaveBankDetailsAction({accountId:this.accountId, applicationId:this.id, payload:this.payload}))
    }
}
