import { memberAlertView_Model } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';

import { Helper } from '@ifaa-components/ui-components';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MemberAlertModel } from 'src/app/model/member-alert.model';

@Component({
  selector: 'app-member-alert-view',
  templateUrl: './member-alert-view.component.html',
  styleUrls: ['./member-alert-view.component.scss']
})
export class MemberAlertViewComponent extends ComponentBase implements OnInit, OnDestroy {
  helper = new Helper();
  model$ = this.store.pipe(select(memberAlertView_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  list: MemberAlertModel[] = [];

  constructor(public store: Store<MemberPortalSharedState>,
    public router: Router,
    public translateService: TranslateService) {
    super();

  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });

    this.sub = this.model$.subscribe(x => {
      this.list = [];
      if (x) {
        x.forEach(async item => {
          var isVisible = await this.isAlertVisible(item.alertType);
          if (isVisible) {
            this.list.push(item)
          }
        })
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  async getLink(link: string) {
    if (link.startsWith('[')) {
      var name = link.replace('[', '').replace(']', '');
      var url = await this.helper.getValue(this.translateService.get('MEMBER_ALERTS.' + name));
      debugger
      window.open(url, "_blank");
    }
  }

  async isAlertVisible(alertType: string) {
    return await this.helper.getValue(this.translateService.get('MEMBER_ALERTS.DISPLAY.' + alertType));
  }
}

