import { Action, createReducer, on } from "@ngrx/store";
import { createFormGroupState, onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, ValidationErrors, wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { lessThan, required } from "ngrx-forms/validation";
import { LastEmployerDetailsSavedAction, ResetComponentStateAction } from "./actions";
import { AccessYourSuperLastEmployerForm, IAccessYourSuperLastEmployerComponentState } from "./state";

export const state: IAccessYourSuperLastEmployerComponentState = {
    form: createFormGroupState('AccessYourSuperLastEmployerForm', new AccessYourSuperLastEmployerForm()),
    lastEmployerDetailsSaved:null
}

const maxDateValidator = (input:string | null | undefined) => {
    const errors = {};

    // Create a date set at midnight instead of 10am.
    var finishDate = new Date(new Date(input).setHours(0,0,0,0));

    if (finishDate > new Date()){
        errors['futureDateError'] = "Date cannot be in the future";
    }
    return errors;
}

export const validateLastEmployerForm = updateGroup<AccessYourSuperLastEmployerForm>({
    lastEmployerName: validate(required),
    stillWithEmployer: validate(required),
    finishDate: (state, parentState) => {
        if (parentState.value.stillWithEmployer === false) {
            state = validate(state, required, maxDateValidator)
        }
        return state;
    },
    waitingOnFinalContribution: (state, parentState) => {
        if (parentState.value.stillWithEmployer === false) {
            state = validate(state, required)
        }
        return state;
    },
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(ResetComponentStateAction, (state) => {
        return {
            form: createFormGroupState('AccessYourSuperLastEmployerForm', new AccessYourSuperLastEmployerForm())
        }
    }),
    on(LastEmployerDetailsSavedAction, (state) => {
        return {
            ...state,
            lastEmployerDetailsSaved:new Date()
        }
    })
);

const editLastEmployerReducerFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateLastEmployerForm
)

export function accessYourSuperLastEmployerFormReducer(state: any | undefined, action: Action) {
    return editLastEmployerReducerFormState(state, action);
}
