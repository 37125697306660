// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MemberModel } from '../model/member.model';

@Injectable()
export class MembersService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getView(): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/member/`, { headers: this.headers() });
  }

  updateMemberDetails(obj: MemberModel): Observable<any> {
    return this.http.put<MemberModel>(environment.apiUrl + `/member-portal/member/updatedetails`, obj, { headers: this.headers() });
  }


  getAlerts(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/member/account/${accountId}/alerts`, { headers: this.headers() });
  }



  // get(search: string, page: number): Observable<any> {
  //   const params = new HttpParams()
  //     .set('search', search)
  //     .set('page', page.toString());
  //   return this.http.get(environment.apiUrl + '/members/', { headers: this.headers(), params: params });
  // }



  // getMemberNotes(memberId: number, page: number, type: string, from: string, to: string): Observable<any> {
  //   const params = new HttpParams()
  //     .set('memberId', memberId.toString())
  //     .set('page', page.toString())
  //     .set('type', type?.toString())
  //     .set('from', from?.toString())
  //     .set('to', to?.toString());
  //   return this.http.get(environment.apiUrl + `/members/${memberId}/notes`, { headers: this.headers(), params: params });
  // }

  // getMemberNotesType(memberId: number): Observable<any> {
  //   const params = new HttpParams()
  //     .set('memberId', memberId.toString());

  //   return this.http.get(environment.apiUrl + `/members/${memberId}/notestype`, { headers: this.headers(), params: params });
  // }

  // // postMemberNote(obj: MemberNotesModel): Observable<any> {
  // //   return this.http.post<MemberNotesModel>(environment.apiUrl + `/members/${obj.memberId}/notes`, obj, { headers: this.headers() });
  // // }



  // resetMemberPassword(memberId: number): Observable<any> {
  //   return this.http.get(environment.apiUrl + `/members/${memberId}/resetpassword`, { headers: this.headers() });
  // }

  // createWebUser(memberId: number): Observable<any> {
  //   return this.http.get(environment.apiUrl + `/members/${memberId}/createwebuser`, { headers: this.headers() });
  // }
}
