import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { ComponentBase } from "src/app/views/component-base";
import { AppState } from "src/app/store/app.states";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { AccessYourSuperApplicationDataModel, AccessYourSuperDocumentOverviewModel } from "src/app/model/access-your-super-form.models";
import { Helper } from "@ifaa-components/ui-components";
import { AccessYourSuperKiwiSaverDetailSectionModel, AccessYourSuperPaymentDetailSectionModel, AccessYourSuperSfhDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { AccessYourSuperHelper } from "src/app/helper/accessyourSuperHelper";

@Component({
  selector: 'app-access-your-super-review-display',
  templateUrl: './access-your-super-review-display.component.html',
  styleUrls: ['./access-your-super-review-display.component.scss']
})

export class AccessYourSuperReviewDisplayComponent extends ComponentBase implements OnInit, OnDestroy {

  helper = new Helper();
  
  @Input() set data(value: AccessYourSuperApplicationDataModel) {
    var clone = this.helper.clone(value);
    this.model = clone;
  }
  @Input() set customSection(value:AccessYourSuperPaymentDetailSectionModel|AccessYourSuperKiwiSaverDetailSectionModel|AccessYourSuperSfhDetailSectionModel){
    this.customData = value;
  }
  
  model: AccessYourSuperApplicationDataModel = null;
  customData:AccessYourSuperPaymentDetailSectionModel|AccessYourSuperKiwiSaverDetailSectionModel|AccessYourSuperSfhDetailSectionModel = null;
  accessYourSuperHelper: AccessYourSuperHelper = new AccessYourSuperHelper()

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();
  }

  showSection(sectionIndex: number) {
    return this.model.conditionData.requiredSections.includes(sectionIndex);
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  checkIfDocumentsExist(overview: AccessYourSuperDocumentOverviewModel) {
    var uploadedDocs = overview.documentList.filter(x => x.originalFileName)

    if (uploadedDocs.length > 0 || overview.authorityGiven || overview.documentsUploaded)
      return true;

    return false;
  }
}
