import { IPensionChangePaymentScheduleState, PensionChangePaymentScheduleForm } from "./state";
import { createFormGroupState, onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { Action, createReducer, on } from "@ngrx/store";
import { GetPaymentScheduleRequestAction, GetPaymentScheduleResponseAction, ResetFormAction, SetFilteredFrequenciesAction, SetFilteredPaymentTypesAction, SubmitPaymentScheduleErrorResponseAction, SubmitPaymentScheduleRequestAction, SubmitPaymentScheduleResponseAction } from "./actions";
import { Helper } from "@ifaa-components/ui-components";
import { PensionHelper } from "src/app/helper/pensionHelper";
import { PensionPaymentTypeEnum } from "src/app/enums/pension-payment-type.enum";
import { required } from "ngrx-forms/validation";

export const formName = 'PensionChangePaymentScheduleForm';

var helper = new Helper();
var pensionHelper = new PensionHelper();

export const state: IPensionChangePaymentScheduleState = {
  form: createFormGroupState(formName, new PensionChangePaymentScheduleForm()),
  data: null,
  isAcctPension: false,
  filteredPaymentTypes: [],
  filteredFrequencies: [],
  formSubmitted: false,
  submitting: false
};

export const validatePensionChangeScheduleForm = updateGroup<PensionChangePaymentScheduleForm>({
  paymentAmount: (state, parentState) => {
    const valueValidator = (input: number | null | undefined) => {
      const errors = {};

      if (input < parentState.value.freqMin || input > parentState.value.freqMax) {
        errors["customError"] = ""
      }

      return errors;
    }
    state = validate(state, required, valueValidator)
    return state;
  },
  paymentType: validate(required)
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    // get control id in index 1
    const actionSplit = action.controlId.split(".");
    const actionFormName = actionSplit[0];
    const controlId = actionSplit[1];

    if (actionFormName !== formName) return state;

    switch (controlId) {
      case "paymentType": {
        switch (action.value) {
          case 1: {
            return {
              ...state,
              form: setValue(Object.assign(new PensionChangePaymentScheduleForm(),
                {
                  ...state.form.value,
                  paymentAmount: state.form.value.freqMin
                }
              ))(state.form)
            }
          }
          case 2: {
            return {
              ...state,
              form: setValue(Object.assign(new PensionChangePaymentScheduleForm(),
                {
                  ...state.form.value,
                  paymentAmount: state.form.value.freqMin
                }
              ))(state.form)
            }
          }
          case 3: {
            return {
              ...state,
              form: setValue(Object.assign(new PensionChangePaymentScheduleForm(),
                {
                  ...state.form.value,
                  paymentAmount: state.form.value.freqMax
                }
              ))(state.form)
            }
          }
        }
      }
      case "paymentFrequency": {
        let freqId;

        switch (action.value) {
          case 1: {
            freqId = 1;
            break;
          }
          case 2: {
            freqId = 2;
            break;
          }
          case 3: {
            freqId = 3;
            break;
          }
          case 4: {
            freqId = 4;
            break;
          }
          default:
            break;
        }

        const freqMaxCalc = pensionHelper.calculateFreqAmount(freqId, state.data, true);
        const freqMinCalc = pensionHelper.calculateFreqAmount(freqId, state.data, false);
        const freqMax = freqMaxCalc > 0 ? freqMaxCalc : 0;
        const freqMin = freqMinCalc > 0 ? freqMinCalc : 0;

        const paymentAmount = state.form.value.paymentType !== PensionPaymentTypeEnum.Maximum ?
          freqMin : freqMax;

        return {
          ...state,
          form: setValue(Object.assign(new PensionChangePaymentScheduleForm(),
            {
              ...state.form.value,
              freqMax,
              freqMin,
              paymentAmount
            }
          ))(state.form)
        }
      }
    }
    return state;
  }),
  on(ResetFormAction, () => {
    return {
      form: createFormGroupState(formName, new PensionChangePaymentScheduleForm()),
      data: null,
      isAcctPension: false,
      filteredPaymentTypes: [],
      filteredFrequencies: [],
      formSubmitted: false,
      submitting: false
    }
  }),
  on(GetPaymentScheduleRequestAction, (state, { isPension }) => {
    return {
      ...state,
      isAcctPension: isPension
    }
  }),
  on(SubmitPaymentScheduleRequestAction, (state) => {
    return {
      ...state,
      submitting: true
    }
  }),
  on(GetPaymentScheduleResponseAction, (state, { scheduleData }) => {
    // freqMin and freqMax
    const freqMaxCalc = pensionHelper.calculateFreqAmount(scheduleData.currentFrequencyId, scheduleData, true);
    const freqMinCalc = pensionHelper.calculateFreqAmount(scheduleData.currentFrequencyId, scheduleData, false);
    const freqMax = freqMaxCalc > 0 ? freqMaxCalc : 0;
    const freqMin = freqMinCalc > 0 ? freqMinCalc : 0

    // payment amount based on payment type
    const paymentAmount = scheduleData.currentPensionPaymentTypeId == PensionPaymentTypeEnum.Minimum ? freqMin : scheduleData.currentPensionPaymentTypeId == PensionPaymentTypeEnum.Maximum ? freqMax : scheduleData.currentFrequencyAmount;
    const paymentType = scheduleData.currentPensionPaymentTypeId === PensionPaymentTypeEnum.Nominated ||
      scheduleData.currentPensionPaymentTypeId === PensionPaymentTypeEnum.Minimum ||
      scheduleData.currentPensionPaymentTypeId === PensionPaymentTypeEnum.Maximum ?
      scheduleData.currentPensionPaymentTypeId : null;

    return {
      ...state,
      data: scheduleData,
      form: setValue(Object.assign(new PensionChangePaymentScheduleForm(),
        {
          ...state.form.value,
          paymentFrequency: scheduleData.currentFrequencyId,
          paymentAmount,
          paymentType,
          freqMax,
          freqMin,
        }
      ))(state.form)
    }
  }),
  on(SetFilteredPaymentTypesAction, (state, { paymentTypes }) => {
    return {
      ...state,
      filteredPaymentTypes: paymentTypes
    }
  }),
  on(SetFilteredFrequenciesAction, (state, { frequencies }) => {
    return {
      ...state,
      filteredFrequencies: frequencies
    }
  }),
  on(SubmitPaymentScheduleResponseAction, (state) => {
    return {
      ...state,
      formSubmitted: true,
      submitting: false
    }
  }),
  on(SubmitPaymentScheduleErrorResponseAction, (state) => {
    return { ...state, submitting: false }
  })
);

const editPensionChangeScheduleFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validatePensionChangeScheduleForm
)

export function pensionChangePaymentScheduleReducer(state: any | undefined, action: Action) {
  return editPensionChangeScheduleFormState(state, action);
}

