<div id="no-insurance">
    <p class="alert alert-danger">You currently do not hold insurance with {{(tenantConfig$ | async)?.name}}</p>
    <p class="" [innerHTML]="'yourInsuranceCover_Description'|message|async">
    </p>
    <p class="d-flex flex-column align-items-start">
        <a class="btn no-decoration w-100 d-flex align-items-center click no-text-decoration" target="_blank"
            href="{{item.url}}" *ngFor="let item of data.fundDocuments">
            <mat-icon *ngIf="item.icon" class="mr-2 ml-2">{{item.icon}}</mat-icon>
            <label>{{item.title}}</label>
        </a>
    </p>

    <p [innerHTML]="('yourInsuranceCover_YourInsuranceCover' | message | async)">
    </p>
</div>