export class InsuranceQuestionnaireModel {
  ageNextBirthday: number = 0;
  gender: string = '';
  employmentArrangement?: number = null;
  occupationDescription?: number = null;
  isCitizenOrPermanent: boolean = false;
  isPaidEmployment: boolean = false;
  isHazardousOccupation: boolean = false;
  statementsApplyToYou: boolean = false;
  takeReasonableCare: boolean = false;
  confirmReadAndAcceptInsuranceGuide: boolean = false;
  youAcknowledge: boolean = false;
  confirmReadInsuranceRequestInsuranceGuide: boolean = false;
  isLegallyRecognisedAsGender: boolean = false;
  legallyRecognisedGender: string = '';
  hasPreExistingConditionOrDisability : boolean = false;
  engageInHazardousPursuits : boolean = false;
  isPlanningToTakeLeaveWithoutPay : boolean=false;
}

export class InsuranceQuestionnaireResultModel {
  deathCover: string = '';
  referenceNumber: string = '';
  tpdCover: string = '';
  costOfCoverWithTpd: string = '';
  costOfCoverWithoutTpd: string = '';
  optOutTdpCover: boolean = false;
  eligibleForTpd: boolean = false;

}

export class ShowQuestionnaireResultModel {
  show: boolean = false;
  ageNextBirthday: number = 0;
  offerExpiryDate: Date = null;
}

export class InsuranceRequestModel {
  confirmReadInsuranceRequestInsuranceGuide: boolean = false;
}

export class ShowPreQuestionnaireResultModel {
  show: boolean = false;
}

export class InsurancePreQuestionnaireRequestModel {
  hasPreExistingConditionOrDisability: boolean = false;
  engageInHazardousPursuits: boolean = false;
  isPlanningToTakeLeaveWithoutPay: boolean = false;
}


