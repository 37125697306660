import { getBPAYDetails_Item } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-bpay-details',
  templateUrl: './bpay-details.component.html',
  styleUrls: ['./bpay-details.component.scss']
})
export class BPAYDetailsComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  bpayDetail$ = this.store.pipe(select(getBPAYDetails_Item));
  spouseBPAYViewFeatureToggle = FeatureToggleName.member.account.spouseBPAYContribution.view;
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}
