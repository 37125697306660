<div id="pension-change-schedule-view">
    <div class="page-header">
      <app-toolbar-options icon="savings">
        <div class="d-flex flex-column" slot="start">
          <div class="page-title">{{'Pension details' | textTransform}}</div>
  
        </div>
      </app-toolbar-options>
    </div>
    <div class="page-content" appanimatecss>
      <div class="d-flex">
        <div class="w-100">
          <app-component-wrapper icon="savings" header="Pension payment schedule">
            <div class="d-flex flex-column" slot="start">
              <app-pension-change-schedule></app-pension-change-schedule>
            </div>
  
          </app-component-wrapper>
        </div>
      </div>
  
    </div>
  </div>
  