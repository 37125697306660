import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { IInvestmentSwitchHistoryState } from './state';

export const investmentPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const investmentSwitchHistory = createSelector(investmentPortalState, (state) => state.investmentSwitchHistoryState);

export const investmentSwitchHistory_Data = createSelector(
  investmentSwitchHistory,
  (state: IInvestmentSwitchHistoryState) => state.data
);

export const investmentSwitchHistory_Filter = createSelector(
  investmentSwitchHistory,
  (state: IInvestmentSwitchHistoryState) => state.filter
);

export const investmentSwitchHistory_IsLoading = createSelector(
  investmentSwitchHistory,
  (state: IInvestmentSwitchHistoryState) => state.isLoading
);

export const investmentSwitchHistory_SwitchTypes = createSelector(
  investmentSwitchHistory,
  (state: IInvestmentSwitchHistoryState) => state.switchTypes
);

export const investmentSwitchHistory_SwitchStatues = createSelector(
  investmentSwitchHistory,
  (state: IInvestmentSwitchHistoryState) => state.switchStatuses
);