import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { commonState_SystemConfig_LimitedServiceToggles } from '../store/common/common.selectors';
import { FeatureToggleName } from '../model/feature-toggle-name.model';
import { map, skipWhile } from 'rxjs/operators';

@Injectable()
export class BlackoutGuardService implements CanActivate {

    limitedServiceToggles$ = this.store.pipe(select(commonState_SystemConfig_LimitedServiceToggles));

    constructor(public store: Store<AppState>,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.limitedServiceToggles$.pipe(
            skipWhile(val => !val),
            map(limitedServiceToggles => {
                var siteBlackoutToggle = limitedServiceToggles.find(o => o.name === FeatureToggleName.member.maintenance.view)
                if (!siteBlackoutToggle)
                    return true;

                var currentDate = new Date();
                if (new Date(siteBlackoutToggle.startDate) <= currentDate && currentDate <= new Date(siteBlackoutToggle.endDate)) {
                    this.router.navigate(['/maintenance'])
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    }
}
