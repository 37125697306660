import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperCeasedWorkSixtyComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperCeasedWorkSixtyState = createSelector(memberPortalState, (state) => state.accessYourSuperCeasedWorkSixtyComponentState);

export const accessYourSuperCeasedWorkSixty_Model = createSelector(
    getAccessYourSuperCeasedWorkSixtyState,
    (state: IAccessYourSuperCeasedWorkSixtyComponentState) => state.model
);

export const accessYourSuperCeasedWorkSixty_Form = createSelector(
    getAccessYourSuperCeasedWorkSixtyState,
    (state: IAccessYourSuperCeasedWorkSixtyComponentState) => state.form
)

export const accessYourSuperCeasedWorkSixty_FullAmountRadioOptions = createSelector(
    getAccessYourSuperCeasedWorkSixtyState,
    (state: IAccessYourSuperCeasedWorkSixtyComponentState) => state.fullAmountRadioOptions
)

export const accessYourSuperCeasedWorkSixty_CurrentTotalPercent = createSelector(
    getAccessYourSuperCeasedWorkSixtyState,
    (state: IAccessYourSuperCeasedWorkSixtyComponentState) => state.form.value.investmentOptionDrawdown.reduce((a, b) => a + b.percentage, 0)
);

export const accessYourSuperCeasedWorkSixty_SavedFormData = createSelector(
    getAccessYourSuperCeasedWorkSixtyState,
    (state: IAccessYourSuperCeasedWorkSixtyComponentState) => state.savedFormData
)