import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { TakeMeWithYouService } from 'src/app/services/take-me-with-you.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class TakeMeWithYouEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private takeMeWithYouService: TakeMeWithYouService) {
  }

  getChoiceOfFund$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.takeMeWithYouService.getChoiceOfFund(action.accountId).pipe(
      map((response: any) => {
        const source = `data:${response.data.contentType};base64,${response.data.fileContents}`;
        const link = document.createElement("a");
        link.href = source;
        link.download = response.data.fileDownloadName;
        link.click();

        return ActionsList.ResponseAction({ payload: '' });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),

  ));
}
