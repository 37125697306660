<div id="member-profile-container">
  <div class="d-flex flex-column w-100" *ngIf="model$ | async; let model">

    <!-- [ngClass]="screenWidth>992?'w-50 p-1': 'w-100'" -->
    <div class="d-flex flex-row flex-fill">
      <div class="w-50 m-2">
        <app-readonly class="" title="Account number"
          [value]=" model.fundCode + model.accountNumber | dash"></app-readonly>
      </div>
      <div class="w-50 m-2">
        <app-readonly class="" title="Product" [value]="model.product | dash"></app-readonly>

      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="w-50 m-2">
        <app-readonly class="" title="Asset admin fee" [value]="model.adminAssetFee + '% p.a'"></app-readonly>

      </div>
      <div class="w-50 m-2">
        <app-readonly class="" title="Member fee" [value]="(model.memberFee | currency) + ' p.a'"></app-readonly>

      </div>
    </div>
  </div>
