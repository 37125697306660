export enum AccessYourSuperApplicationTypeEnum{
    Retirement = 1,
    CeasedWorkAfterSixty = 2,
    PermanentRetirementAfterPreseverationAge = 3,
    CeasedPaidEmploymentAndAccessRnp = 4,
    PensionCommutations = 5,
    TotalPermanentDisability = 6,
    PermanentIncapacity = 7,
    TerminalMedicalCondition = 8,
    FormerTempResidentDepartingAus = 9,
    SevereFinancialHardship = 10,
    CompassionateGrounds = 11,
    FirstHomeSuperSaverScheme = 12,
    UnrestrictedNonPreserved = 13,
    ExcessConcessionalContributions = 14,
    ExcessNonConcessionalContributions = 15,
    BenefitUnderTwoHundred = 16,
    TransferToKiwiSaver = 17,
    UnclaimedMoniesWithAto = 18
}