import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../store/shared.reducers";
import { MemberPortalSharedState } from "../../store/shared.states";
import { IAccessYourSuperDocumentsComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperDocumentsState = createSelector(memberPortalState, (state) => state.accessYourSuperDocumentsComponentState);

export const accessYourSuperDocuments_Form = createSelector(
    getAccessYourSuperDocumentsState,
    (state: IAccessYourSuperDocumentsComponentState) => state.form
)

export const accessYourSuperDocuments_UploadedDocument = createSelector(
    getAccessYourSuperDocumentsState,
    (state: IAccessYourSuperDocumentsComponentState) => state.uploadedDocument
)

export const accessYourSuperDocuments_DeletedDocument = createSelector(
    getAccessYourSuperDocumentsState,
    (state: IAccessYourSuperDocumentsComponentState) => state.deletedDocument
)

export const accessYourSuperDocuments_ReplacedDocument  = createSelector(
    getAccessYourSuperDocumentsState,
    (state: IAccessYourSuperDocumentsComponentState) => state.replacedDocument
)

export const accessYourSuperDocuments_FileRequestFailed = createSelector(
    getAccessYourSuperDocumentsState,
    (state: IAccessYourSuperDocumentsComponentState) => state.fileRequestFailed
)

export const accessYourSuperDocuments_SupportingDocuments = createSelector(
    getAccessYourSuperDocumentsState,
    (state:IAccessYourSuperDocumentsComponentState) => state.form.controls.supportingDocuments
)

export const accessYourSuperDocuments_DocumentsCompleted = createSelector(
    getAccessYourSuperDocumentsState,
    (state:IAccessYourSuperDocumentsComponentState) => state.documentsCompleted
)

export const accessYourSuperDocuments_FilesAndAuthorities = createSelector(
    getAccessYourSuperDocumentsState, 
    (state: IAccessYourSuperDocumentsComponentState) => {
        var filesAndAuthorities = {};

        state.form.controls.supportingDocuments.controls.forEach((overview, index) => {
            filesAndAuthorities[index] = {};
            filesAndAuthorities[index]['filesControlId'] = overview.controls.files.id;
            filesAndAuthorities[index]['files'] = overview.controls.files.value;
            filesAndAuthorities[index]['authorityControlId'] = overview.controls.requestAuthority.id;
            filesAndAuthorities[index]['authority'] = overview.controls.requestAuthority.value;
            filesAndAuthorities[index]['isRequired'] = overview.controls.isDocumentRequired.value;
        });

        return filesAndAuthorities;
    }
)

export const accessYourSuperDocuments_Authorities = createSelector(
    getAccessYourSuperDocumentsState,
    (state:IAccessYourSuperDocumentsComponentState) => {
        return state.form.controls.supportingDocuments.controls.map((overview,index) => {
            return overview.value.requestAuthority
        })
    }
)

export const accessYourSuperDocuments_ReferenceValue = createSelector(
    getAccessYourSuperDocumentsState,
    (state:IAccessYourSuperDocumentsComponentState) => {
        var referenceValueControl = state.form.controls.supportingDocuments.controls.find(o => o?.value?.referenceRequired)
        var referenceControlId = referenceValueControl?.controls.referenceValue.id;
        
        return {
            controlId: referenceControlId,
            value: referenceValueControl?.value?.referenceValue,
            required: referenceValueControl?.value?.referenceRequired,
            authorityGiven:referenceValueControl?.value.requestAuthority
        };
    }
)

export const accessYourSuperDocuments_FormValidity = createSelector(
    getAccessYourSuperDocumentsState,
    (state:IAccessYourSuperDocumentsComponentState) => state.form.isValid
)