import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MemberPensionService } from 'src/app/services/member-pension.service';
import { BeneficiaryService } from 'src/app/services/beneficiary.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class MemberPensionEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private service: MemberPensionService,
    private beneficiaryService: BeneficiaryService,
  ) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getPension(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getBeneficiaryData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestBeneficiariesAction),
    switchMap((action: any) => this.beneficiaryService.getMemberBeneficiaries(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseBeneficiariesAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
