import { ChangePasswordModel } from './../../model/change-password.model';
import { SubmitAction } from './actions';
import { changePassword_Form } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../component-base';
import { OnDestroy } from '@angular/core';
import { commomState_ElevatedAccess } from 'src/app/store/common/common.selectors';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class ChangePasswordComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(changePassword_Form));
  elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));
  elevatedAccess = false;
  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.elevatedAccess$.subscribe(x => {
      this.elevatedAccess = x;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmit(data: ChangePasswordModel) {
    if (this.elevatedAccess) return;
    this.dispatch(this.store, SubmitAction({ payload: data }));
  }

}
