<div id="notice-of-intent-view">
    <div class="page-header">
      <app-toolbar-options icon="text_snippet">
        <div class="d-flex flex-column" slot="start">
          <div class="page-title">{{'Notice of intent to claim or vary a tax deduction' | textTransform}}</div>
  
        </div>
      </app-toolbar-options>
    </div>
    <div class="page-content" appanimatecss>
        <app-component-wrapper  icon="paid" [header]="viewContainerTitle$|async">
            <div class="d-flex flex-column" slot="start">
                <app-notice-of-intent-tax-deduction-container></app-notice-of-intent-tax-deduction-container>
            </div>
        </app-component-wrapper>
    </div>
  </div>
  