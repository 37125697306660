export class NoticeOfIntentTaxDeductionModel {
    noiTaxDeductionApplicationid: number = 0;
    accountId: number = 0;
    financialYear: string = '';
    personalContributionAmount: string = '';
    claimAmount: string = '';
    submittedDate: string = '';
}

export class NoticeOfIntentTaxDeductionContributions {
    previousYear: number = 0;
    previousYearAgeCutOffAmount: number = null;
    currentYear: number = 0;
    currentYearAgeCutOffAmount: number = null;
}

export class NoticeOfIntentTaxDeductionFormComponentModel {
    formWording: { [key: string]: string } = {};
    contributions: NoticeOfIntentTaxDeductionContributions = new NoticeOfIntentTaxDeductionContributions();
    hasError: boolean = false;
    errorMessage: string = '';
}

export class NoticeOfIntentTaxDeductionFormSubmissionModel {
    financialYear: string = '';
    originalClaimAmount?: number = null;
    claimAmount: number = 0;
    hasWithdrawalPending: boolean = false;
}