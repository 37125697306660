import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentBase } from 'src/app/views/component-base';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'are-you-still-there',
  templateUrl: './are-you-still-there.component.html',
  styleUrls: ['./are-you-still-there.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class AreYouStillThereDialog extends ComponentBase implements OnInit, OnDestroy {

  tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));
  tenantCode:string = null;

  constructor(
    public store: Store<AppState>,
    private authService: MsalService,
    public dialogRef: MatDialogRef<AreYouStillThereDialog>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.tenantConfig$.subscribe(x => {
      this.tenantCode = x.fundCode.toLowerCase();
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  stillThere(){
    this.dialogRef.close();
  }

  logout(){
    this.authService.logout();
  }
}
