<div id="member-pension-view-container" *ngIf="data$ | async; let data">

  <div *ngIf="(selectedAccount$|async)?.accountClassificationId == 2">

    <h2>Retirement Pension</h2>

    <div class="d-flex flex-wrap">
      <app-readonly title="Commencement Date" value="{{data.commencementDate | date:'dd MMM yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Annual Minimum" [value]="data.minAnnualAmount | currency"
        class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Next Payment Date" value="{{data.pensionNextPaymentDate | date:'dd MMM yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Total Paid Financial Year to Date" [value]="data.totalPaidFinancialYearToDate | currency"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Payment Type" [value]="data.pensionPaymentTypeDescription" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Payment Amount" [value]="data.paymentAmount | currency" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Payment Period" [value]="data.frequencyDescription" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>



    </div>
  </div>

  <div *ngIf="(selectedAccount$|async)?.accountClassificationId == 3">

    <h4>Transition to Retirement Pension</h4>


    <div *ngIf="data$ | async; let data" class="d-flex flex-wrap">
      <app-readonly title="Commencement Date" value="{{data.commencementDate | date:'dd MMM yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Annual Minimum" [value]="data.minAnnualAmount | currency"
        class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Next Payment Date" value="{{data.pensionNextPaymentDate | date:'dd MMM yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Annual Maximum" [value]="data.annualMaximumAmount | currency"
        class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Payment Type" [value]="data.pensionTypeName" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Total Paid Financial Year to Date" [value]="data.totalPaidFinancialYearToDate | currency"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Payment Period" [value]="data.frequencyDescription" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Payment Amount" [value]="data.paymentAmount | currency" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>


    </div>
  </div>

  <h4 class="mt-3">Reversionary Beneficiary Details</h4>

  <app-no-items message="No beneficiary found" *ngIf="(beneficiaryList$ |async)?.length === 0"></app-no-items>

  <table class="table {{'VIEWS.Global.table-default-style' | translate}}" *ngIf="(beneficiaryList$ |async)?.length > 0">
    <thead>
      <tr>
        <th scope="col">Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of beneficiaryList$ | async">
        <th>{{item.name}}</th>
      </tr>

    </tbody>
  </table>

  <div class="d-flex flex-row flex-fill mt-4 d-flex flex-wrap" *ngIf="data$ | async; let data">
    <app-button (onClick)="updateSchedule()" *featuretoggle="changePensionScheduleFeatureToggle" class=" mr-1 d-flex flex-fill" title="Change Payment Schedule" icon="east">
    </app-button>

    <app-button (onClick)="viewRequests()" *featuretoggle="changePensionScheduleFeatureToggle" class=" mr-1 d-flex flex-fill" title="Payment Schedule Requests" icon="manage_history">
    </app-button>

    <app-button *featuretoggle="editBankDetailsFeatureToggle" class=" mr-1 ml-1 d-flex" title="Centrelink Schedule" icon="event">
    </app-button>

    <app-button *featuretoggle="centrelinkScheduleFeatureToggle" class=" mr-1 ml-1 d-flex" title="Edit Bank Details" icon="account_balance">
    </app-button>
  </div>

</div>
