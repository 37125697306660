export class InsuranceDetailsModel {
  hasInsurance: boolean = false;
  insuranceCoverUrl: string = '';
  fundDocuments: InsuranceDetailsFundDocumentModel[] = [];
  insurances: InsuranceItem[] = [];
  premiumStartDate? : Date = null;
  lastModified? : Date = null;
}

export class InsuranceDetailsFundDocumentModel {
  title: string = '';
  icon: string = '';
  url: string = '';
}

export class InsuranceItem {
  insuranceCoverTypeCode: string = '';
  insuranceCoverTypeName: string = '';
  insuranceCoverTypeDescription :string = '';
  waitingPeriod: string = '';
  lapseDate: Date = null;
  premium: number = 0;
  amount: number = 0;
  insuranceCoverStatusCode: string = '';
  insuranceCoverToolTipText: string = ''; 
}
