<div id="switch-investments-view-container">
  <div class="page-header">
    <app-toolbar-options icon="switch_left">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Switch Investments' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>


    <app-component-wrapper icon="content_copy" header="Options">
      <div class="d-flex flex-column" slot="start">
        <app-switch-investments-edit></app-switch-investments-edit>
      </div>
    </app-component-wrapper>

    <app-component-wrapper icon="admin_panel_settings" header="Important Information" headerclass="danger">
      <div class="d-flex flex-column" slot="start">
        <p class="description">
          Generally investment switch requests will be processed within a few days of receipt. Once you submit your investment switch request, it cannot be revoked. The Trustee reserves the right to limit the number of switches a member can make if they are deemed to be switching excessively. The first four investment switches per financial year are free. A fee of $55.00 will be charged for each switch thereafter.
        </p>
      </div>
    </app-component-wrapper>


  </div>
</div>
