import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { BeneficiaryService } from 'src/app/services/beneficiary.service';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';

@Injectable()
export class BeneficiariesEditEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    public toastr: ToastrService,
    private service: BeneficiaryService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getMemberBeneficiaries(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.updateBeneficiaries(action.accountId, action.payload).pipe(
      map((data: any) => {
        this.toastr.success(data.message);
        return ActionsList.SubmitResponseAction({ payload: data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
