import { FormGroupState } from "ngrx-forms";

export interface IAccessYourSuperBankDetailsComponentState {
    form:FormGroupState<AccessYourSuperBankDetailsForm>;
    bankDetailsSaved?:Date;
}

export class AccessYourSuperBankDetailsForm{
    financialInstitutionName:string="";
    bankAccountName:string="";
    bankAccountNumber:string="";
    bankAccountBsb:string="";
}