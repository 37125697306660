import { FormGroupState } from 'ngrx-forms';
import { LoginModel } from 'src/app/model/login.model';


export interface IStartupState {
  form: FormGroupState<SMRTStartupValidationModel>;
  url: string;
  isLoading: boolean;
  isUnavailable: boolean;
  isUnavailableMessage: string;
}


export class SMRTStartupValidationModel {
  email = '';
  token = '';
}


