import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UnderlyingInvestmentService } from 'src/app/services/underlying-investment.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccountUnderlyingInvestmentSummaryViewEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private service: UnderlyingInvestmentService) {
  }

  getSummaryData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestUnderlyingInvestmentSummaryAction),
    switchMap((action: any) => this.service.getUnderlyingInvestmentSummary(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseUnderlyingInvestmentSummaryAction({ payload: data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
