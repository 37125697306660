import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperSevereFinancialHardshipComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperSevereFinancialHardshipState = createSelector(memberPortalState, (state) => state.accessYourSuperSevereFinancialHardshipComponentState);

export const accessYourSuperSevereFinancialHardship_Model = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.model
);

export const accessYourSuperSevereFinancialHardship_FormOne = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.formOne
);

export const accessYourSuperSevereFinancialHardship_FormTwo = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.formTwo
);

export const accessYourSuperSevereFinancialHardship_FullAmountRadioOptions = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.fullAmountRadioOptions
);

export const accessYourSuperSevereFinancialHardship_TaxOptions = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.taxOptions
);

export const accessYourSuperSevereFinancialHardship_CurrentTotalPercent = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.formOne.value.investmentOptionDrawdown.reduce((a, b) => a + b.percentage, 0)
);

export const accessYourSuperSevereFinancialHardship_SavedFormData = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.savedFormData
);

export const accessYourSuperSevereFinancialHardship_SfhStep = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.sfhStep
);

export const accessYourSuperSevereFinancialHardship_FormVersion = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.formVersion
);

export const accessYourSuperSevereFinancialHardship_FormThreeValid = createSelector(
    getAccessYourSuperSevereFinancialHardshipState,
    (state: IAccessYourSuperSevereFinancialHardshipComponentState) => state.formThreeValid
);