<ng-container *ngIf="!applicationUpdated">
    <h2>Review your submitted application</h2>
    <div class="review-container" *ngIf="customData">
        <app-access-your-super-review-display [data]="applicationData$|async"
            [customSection]="customData"></app-access-your-super-review-display>
    </div>

    <ng-container *ngIf="applicationData$|async">
        <app-access-your-super-documents [data]="(applicationData$|async).applicationDocuments"
            [applicationId]="applicationId$ | async" [account]="(selectedAccount$ | async).accountId"
            [actionRowVisible]="false" [conditionData]="(applicationData$|async).conditionData"
            [wording]="(applicationData$|async).customWording" [draftApplication]="false"
            componentTitle="Add additional documentation"></app-access-your-super-documents>
    </ng-container>

    <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
        class="d-flex flex-wrap mt-4">
        <div [ngClass]="{'mt-1': screenWidth<768}">
            <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="leavePage()"></app-button>
        </div>
        <div>
            <app-button class="flex-fill" title="Update application"
                icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                color="{{'BTN.access-your-super.continue.color' | translate}}" (onClick)="submit()"></app-button>
        </div>
    </div>
</ng-container>

<div *ngIf="applicationUpdated">
    <h2 class="mb-4">Your benefit access request has been successfully updated</h2>
    <p class="mb-0 ml-3">Additional documents have been added to your benefit access request. You will be notified
        accordingly after processing is completed or if we need any further information. </p>
    <div class="d-flex justify-content-end">
        <app-button class="mt-3 ml-1" title="Finish" icon="{{'BTN.access-your-super.start.icon' | translate}}"
            color="{{'BTN.access-your-super.start.color' | translate}}" routerLink="/access-your-super">>
        </app-button>
    </div>
</div>