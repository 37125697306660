import { IPensionChangePaymentScheduleRequestsState } from "./state";
import { onNgrxForms, onNgrxFormsAction, SetValueAction } from "ngrx-forms";
import { Action, createReducer, on } from "@ngrx/store";
import { GetChangePaymentScheduleRequestsResponseAction, ResetFormAction } from "./actions";

export const state: IPensionChangePaymentScheduleRequestsState = {
  data:null
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, () => {
    return {
      data:null
    }
  }),
  on(GetChangePaymentScheduleRequestsResponseAction, (state, {requests}) => {
    return {
      ...state,
      data:requests
    }
  })
);

export function pensionChangePaymentScheduleRequestsReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}

