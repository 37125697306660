<div id="recent-employment-details-container">
  <div class="d-flex flex-column">
    <app-no-items message="Employment details are not available at this time."
      *ngIf="(employer$ | async)?.length === 0"></app-no-items>

    <div id="recentEmploymentCarouselIndicators" class="carousel slide mt-1" data-ride="carousel"
      *ngIf="employer$ | async; let list">
      <ol class="carousel-indicators" *ngIf="list.length > 1">
        <li data-target="#recentEmploymentCarouselIndicators" *ngFor="let item of list; index as i"
          [ngClass]="{'active': i == 0}" [attr.data-slide-to]="i"></li>
      </ol>
      <div class="carousel-inner">


        <div *ngFor="let employer of list; index as i" class="carousel-item transfers pl-1 pr-1"
          [ngClass]="{'active': i == 0}">
          <div class="card  d-flex  flex-row align-items-center ml-3 mr-3">
            <div class="w-100">
              <div class="employeer">Employer</div>
              <h6 class="d-flex align-items-center">
                {{employer.employerName}}
              </h6>
              <div class="d-flex flex-wrap">
                <app-readonly class="" *featuretoggleglobal="recentEmployerDetailDatesFeatureToggle" title="Start date"
                  [ngClass]=" screenWidth>992?'w-50 p-1': 'w-100'"
                  [value]="employer.startDate | date:'dd/MM/yyyy'"></app-readonly>
                <app-readonly class="" *featuretoggleglobal="recentEmployerDetailDatesFeatureToggle" title="End date"
                  [ngClass]="screenWidth>992?'w-50 p-1': 'w-100'"
                  [value]="employer.endDate | date:'dd/MM/yyyy' | dash"></app-readonly>
                <app-readonly class="" title="Last contribution" [ngClass]="screenWidth>992?'w-50 p-1': 'w-100'"
                  [value]="employer.lastContributionAmount | currency"></app-readonly>
                <app-readonly class="" title="Last contribution date" [value]="employer.lastContributionDate |
                date:'dd MMM yyyy'" [ngClass]="screenWidth>992?'w-50 p-1': 'w-100'"></app-readonly>

                <a target="_blank" *ngIf="employer.employerSuperannuationPlanURL"
                  class="d-flex flex-row btn btn-primary w-100 mt-2 employer-superannuation-plan-url no-text-decoration "
                  href="{{employer.employerSuperannuationPlanURL}}"><span
                    matTooltip="This plan summary contains information that is specific to your employer super plan. &#13; It forms part of and should be read in conjunction with your smartMonday Product Disclosure Statement (PDS).">
                    <mat-icon>file_download</mat-icon>
                    Employer
                    plan details</span></a>

                <div class="d-flex align-items-center mt-2" *ngIf="employer.employerPlanLinkUrl">
                  <div class="flex-fill d-flex description  ">Click&nbsp;<a target="_blank" class="d-contents"
                      href="{{employer.employerPlanLinkUrl}}">here</a>&nbsp;to view your employer plan

                  </div>
                  <mat-icon class="help ml-2"
                    matTooltip="{{'VIEWS.RecentEmployments.toolTip_Text' | translate}}">help_outline</mat-icon>
                </div>
              </div>
            </div>
          </div>


        </div>
        <a class="carousel-control-prev no-text-decoration" href="#recentEmploymentCarouselIndicators" role="button"
          data-slide="prev" *ngIf="list.length > 1">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <a class="carousel-control-next no-text-decoration" href="#recentEmploymentCarouselIndicators" role="button"
          data-slide="next" *ngIf="list.length > 1">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </div>



    </div>
  </div>
