import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { beneficiariesViewReducer } from '../components/beneficiaries-view/reducer';
import { beneficiariesEditReducer } from '../components/beneficiaries-edit/reducer';
import { memberViewReducer } from '../components/member-view/reducer';
import { memberViewOverviewReducer } from '../components/member-view-overview/reducer';
import { memberEditReducer } from '../components/member-edit/reducer';
import { cashTransactionListReducer } from '../components/cash-transactions/reducer';
import { memberAccountDropdownReducer } from '../components/member-account-dropdown/reducer'
import { historicalChartViewReducer } from '../components/historical-chart/reducer'
import { insuranceQuestionnaireReducer } from '../components/insurance-questionnaire/reducer'
import { insuranceQuestionnaireStartReducer } from '../components/insurance-questionnaire-start/reducer'
import { insuranceDetailsReducer } from '../components/insurance-details/reducer'
import { rollInEditReducer } from '../components/rollin-edit/reducer'
import { switchInvestmentsReducer } from '../components/switch-investments-edit/reducer'
import { contributionsLimitsReducer } from '../components/contributions-limits/reducer'
import { superannuationProviderSearchReducer } from '../components/superannuation-provider-search/reducer'
import { personalContributionEditReducer } from '../components/personal-contribution-edit/reducer'
import { consolidateSuperReducer } from '../components/consolidate-super/reducer'
import { unitPriceViewReducer } from '../components/unit-prices-view/reducer'
import { documentViewerListReducer } from '../components/document-viewer/reducer'
import { notificationDropdownReducer } from '../components/notification-dropdown/reducer'
import { MemberPortalSharedState } from './shared.states';
import { accountUnderlyingViewReducer } from '../components/account-underlying-view/reducer';
import { bpayDetailsViewReducer } from '../components/bpay-details/reducer';
import { memberPensionViewReducer } from '../components/member-pension-view/reducer';
import { investmentEditReducer } from '../components/investment-edit/reducer';
import { accessYourSuperStartComponentReducer } from '../components/access-your-super-start/reducer';
import { accessYourSuperFormComponentReducer } from '../components/access-your-super-form/reducer';
import { accessYourSuperTypeSelectionComponentReducer } from '../components/access-your-super-type-selection/reducer';
import { accessYourSuperLastEmployerFormReducer } from '../components/access-your-super-last-employer/reducer';
import { accessYourSuperRetirementComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-retirement/reducer';
import { accessYourSuperCeasedWorkSixtyComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-ceased-work-sixty/reducer';
import { accessYourSuperBankDetailsComponentReducer } from '../components/access-your-super-bank-details/reducer';
import { accountUnderlyingInvestmentSummaryViewReducer } from '../components/account-underlying-investment-summary-view/reducer';
import { accessYourSuperPermanentRetirementPreservationComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-permanent-retirement-preservation/reducer';
import { accessYourSuperCeasedPaidEmploymentComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-ceased-paid-employment/reducer';
import { accessYourSuperPensionCommutationsComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-pension-commutations/reducer';
import { accessYourSuperDocumentsComponentReducer } from '../components/access-your-super-documents/reducer';
import { insurancePreQuestionnaireReducer } from '../components/insurance-pre-questionnaire/reducer';
import { accessYourSuperReviewComponentReducer } from '../components/access-your-super-review/reducer';
import { recentEmploymentDetailsReducer } from '../components/recent-employment-details/reducer';
import { accountSummaryReducer } from '../components/account-summary/reducer';
import { investmentOptionReducer } from '../components/investment-options/reducer';
import { accessYourSuperTerminalMedicalConditionComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-terminal-medical-condition/reducer';
import { accessYourSuperSevereFinancialHardshipComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-severe-financial-hardship/reducer';
import { accessYourSuperCompassionateGroundsComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-compassionate-grounds/reducer';
import { accessYourSuperUnrestrictedNonPreservedComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-unrestricted-non-preserved/reducer';
import { accessYourSuperKiwiSaverComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-kiwi-saver/reducer';
import { chatViewReducer } from '../components/chat/reducer';
import { AccessYourSuperUpdateSubmittedComponentReducer } from '../components/access-your-super-update-submitted/reducer';
import { noticeOfIntentTaxDeductionContainerReducer } from '../components/notice-of-intent-tax-deduction-container/reducer';
import { noticeOfIntentTaxDeductionFormComponentReducer } from '../components/notice-of-intent-tax-deduction-form/reducer';
import { severeFinancialHardshipFinancialDetailsV1ComponentReducer } from '../components/access-your-super-custom-sections/access-your-super-severe-financial-hardship/financialDataFormVersions/v1/reducer';
import { memberAlertViewReducer } from '../components/member-alert/reducer';
import { pensionChangePaymentScheduleReducer } from '../components/pension-change-schedule/reducer';
import { pensionChangePaymentScheduleRequestsReducer } from '../components/pension-change-schedule-requests/reducer';
import { accountFeesReducer } from '../components/account-fees/reducer';
import { investmentSwitchHistoryReducer } from '../components/investment-switch-history/reducer';


export const featureKey = 'member-portal-shared';

export const reducers = new InjectionToken<ActionReducerMap<MemberPortalSharedState>>(featureKey, {
  factory: () => ({
    memberViewState: memberViewReducer,
    memberPensionViewState: memberPensionViewReducer,
    memberViewOverviewState: memberViewOverviewReducer,
    beneficiariesViewState: beneficiariesViewReducer,
    bpayDetailsViewState: bpayDetailsViewReducer,
    beneficiariesEditState: beneficiariesEditReducer,
    accountUnderlyingViewState: accountUnderlyingViewReducer,
    accountUnderlyingInvestmentSummaryViewState: accountUnderlyingInvestmentSummaryViewReducer,
    memberEditState: memberEditReducer,
    cashTransactionListState: cashTransactionListReducer,
    memberAccountDropdownState: memberAccountDropdownReducer,
    historicalChartViewState: historicalChartViewReducer,
    insuranceQuestionnaireState: insuranceQuestionnaireReducer,
    insurancePreQuestionnaireState: insurancePreQuestionnaireReducer,
    insuranceQuestionnaireStartState: insuranceQuestionnaireStartReducer,
    insuranceDetailsState: insuranceDetailsReducer,
    switchInvestmentsState: switchInvestmentsReducer,
    rollInEditState: rollInEditReducer,
    superannuationProviderSearchState: superannuationProviderSearchReducer,
    contributionsLimitsState: contributionsLimitsReducer,
    personalContributionEditState: personalContributionEditReducer,
    consolidateSuperState: consolidateSuperReducer,
    accountSummaryState: accountSummaryReducer,
    unitPriceViewState: unitPriceViewReducer,
    documentViewerListState: documentViewerListReducer,
    notificationDropdownState: notificationDropdownReducer,
    investmentEditState: investmentEditReducer,
    accessYourSuperStartComponentState: accessYourSuperStartComponentReducer,
    accessYourSuperFormComponentState: accessYourSuperFormComponentReducer,
    accessYourSuperTypeSelectionComponentState: accessYourSuperTypeSelectionComponentReducer,
    accessYourSuperLastEmployerComponentState: accessYourSuperLastEmployerFormReducer,
    accessYourSuperBankDetailComponentState: accessYourSuperBankDetailsComponentReducer,
    accessYourSuperDocumentsComponentState: accessYourSuperDocumentsComponentReducer,
    accessYourSuperRetirementComponentState: accessYourSuperRetirementComponentReducer,
    accessYourSuperCeasedWorkSixtyComponentState: accessYourSuperCeasedWorkSixtyComponentReducer,
    accessYourSuperPermanentRetirementPreservationComponentState: accessYourSuperPermanentRetirementPreservationComponentReducer,
    accessYourSuperCeasedPaidEmploymentComponentState: accessYourSuperCeasedPaidEmploymentComponentReducer,
    accessYourSuperPensionCommutationsComponentState: accessYourSuperPensionCommutationsComponentReducer,
    accessYourSuperReviewComponentState: accessYourSuperReviewComponentReducer,
    recentEmploymentDetailState: recentEmploymentDetailsReducer,
    investmentOptionState: investmentOptionReducer,
    accessYourSuperTerminalMedicalConditionComponentState: accessYourSuperTerminalMedicalConditionComponentReducer,
    accessYourSuperSevereFinancialHardshipComponentState: accessYourSuperSevereFinancialHardshipComponentReducer,
    accessYourSuperCompassionateGroundsComponentState: accessYourSuperCompassionateGroundsComponentReducer,
    accessYourSuperUnrestrictedNonPreservedComponentState: accessYourSuperUnrestrictedNonPreservedComponentReducer,
    accessYourSuperKiwiSaverComponentState: accessYourSuperKiwiSaverComponentReducer,
    chatViewState: chatViewReducer,
    accessYourSuperUpdateSubmittedComponentState: AccessYourSuperUpdateSubmittedComponentReducer,
    noticeOfIntentTaxDeductionContainerComponentState: noticeOfIntentTaxDeductionContainerReducer,
    noticeOfIntentTaxDeductionFormComponentState: noticeOfIntentTaxDeductionFormComponentReducer,
    severeFinancialHardshipFinancialDetailsV1ComponentState: severeFinancialHardshipFinancialDetailsV1ComponentReducer,
    memberAlertViewState: memberAlertViewReducer,
    accountFeesState: accountFeesReducer,
    pensionChangePaymentScheduleState: pensionChangePaymentScheduleReducer,
    pensionChangePaymentScheduleRequestsState: pensionChangePaymentScheduleRequestsReducer,
    investmentSwitchHistoryState: investmentSwitchHistoryReducer    
  })
});
