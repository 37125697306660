<div id="member-view-container">

  <div *ngIf="data$ | async; let data" class="d-flex flex-wrap">

    <ng-container *ngIf="!hideCommunicationPreference">
      <app-readonly title="Communication Preferences" [value]="data.communicationPreferenceDescription"
        *featuretoggle="displayCommunicationPreferenceFeatureToggle" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    </ng-container>
    <app-readonly *ngIf="!hideMemberNumber && (loggedIn$ | async); let loggedin" title="Member Number"
      [value]="loggedin.memberNumber" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="!displayLegalNameToggle || !data.preferredName" title="First Name" [value]="data.firstName"
      [isRequired]="dataValidation.firstName" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="!displayLegalNameToggle || !data.preferredName" title="Middle Name" [value]="data.secondName"
      class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="!displayLegalNameToggle || !data.preferredName" title="Last Name" [value]="data.lastName"
      [isRequired]="dataValidation.lastName" class="{{itemclass}} p-1 flex-fill"></app-readonly>
      <app-readonly title="Chosen Name" [value]="data.preferredName"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <ng-container *ngIf="displayLegalNameToggle && data.preferredName">

      <div class="p-1 w-100">
        <div id="app-readonly-container" class="d-flex flex-row align-items-center">
          <div class="title">{{'Legal Name' | textTransform}}:</div>
          <mat-icon class="ml-2 mb-1 tooltip-readonly legalNameToggle" [matTooltip]="showLegalName ? 'Hide legal name':'Show legal name'" matSuffix
            (click)="onToggleLegalNameClick()">{{showLegalName? "visibility":"visibility_off"}}</mat-icon>
        </div>
      </div>
      <ng-container *ngIf="showLegalName">
        <app-readonly title="First Name" [value]="data.firstName" [isRequired]="dataValidation.firstName"
          class="{{itemclass}} p-1 flex-fill"></app-readonly>
        <app-readonly title="Middle Name" [value]="data.secondName" class="{{itemclass}} p-1 flex-fill"></app-readonly>
        <app-readonly title="Last Name" [value]="data.lastName" [isRequired]="dataValidation.lastName"
          class="{{itemclass}} w-100 p-1 flex-fill"></app-readonly>
      </ng-container>

    </ng-container>
    <app-readonly title="Email Address" [value]="data.email" class="{{itemclass}} w-100 p-1 flex-fill"></app-readonly>
    <app-readonly *ngIf="!hidePostalAddress" class="w-100" title="Postal Address"
      [value]="helper.formatAddress(data.postalAddress)" class="{{itemclass}} p-1 flex-fill">
    </app-readonly>
    <app-readonly class="w-100" title="Residential Address" [value]="helper.formatAddress(data.residentialAddress)"
      [isRequired]="dataValidation.residentialAddress" class="{{itemclass}} p-1 flex-fill">
    </app-readonly>
    <app-readonly title="Mobile" [value]="data.mobile" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <app-readonly title="Home Phone" [value]="data.homePhone" class="{{itemclass}} p-1 flex-fill"></app-readonly>
    <ng-container *ngIf="!hideOccupation">
      <app-readonly title="Occupation" [value]="data.occupation" *featuretoggle="displayOccupationFeatureToggle"
        class="{{itemclass}} p-1 flex-fill w-100"></app-readonly>
    </ng-container>
    <app-readonly *ngIf="!hideDob" title="Date of birth" [value]="data.dateOfBirth | date:'dd MMM yyyy'"
      [isRequired]="dataValidation.dob" class="{{itemclass}} p-1"></app-readonly>
    <ng-container *ngIf="!hideTfn">
      <app-readonly *featuretoggle="displayTfnFeatureToggle" title="Tax File Number"
        [value]="(data.hasTFN) ? 'Supplied' : 'Not provided'" class="p-1"
        [tooltip]="tfnTooltip ? tfnTooltip : null"></app-readonly>
    </ng-container>
  </div>
</div>
