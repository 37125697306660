<div id="dashboard-container">
    <div class="page-header">
      <app-toolbar-options icon="verified_user">
        <div class="d-flex flex-column" slot="start">
          <div class="page-title">{{'Investments' | textTransform}}</div>
  
        </div>
      </app-toolbar-options>
    </div>
    <div class="page-content" appanimatecss>
  
      <app-component-wrapper icon="price_change" header="Investment Switch History">
        <div class="d-flex flex-column " slot="start">
  
          <app-investment-switch-history></app-investment-switch-history>
  
        </div>
      </app-component-wrapper>
  
    </div>
  </div>
  