import { KeyValueModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { AccessYourSuperPaymentDetailSectionModel, AccessYourSuperPaymentDetailSubmissionModel } from "src/app/model/access-your-super-custom-sections.model";
import { InvestmentItemModel } from "../../investment-edit/state";

export const ResetComponentStateAction = createAction('[Access your super permanent retirement preservation component] reset state');
export const RequestAction = createAction('[Access your super permanent retirement preservation component] get data', props<{ accountId: number, applicationId:number, applicationTypeId:number }>());
export const ResponseAction = createAction('[Access your super permanent retirement preservation component] set data', props<{ payload: AccessYourSuperPaymentDetailSectionModel }>());

export const SetFullAmountRadioOptionsAction = createAction('[Access your super permanent retirement preservation component] set full amount radio options', props<{payload:KeyValueModel[]}>());
export const SetCurrentInvestmentMixAction = createAction('[Access your super permanent retirement preservation component] set current investment mix', props<{payload:InvestmentItemModel[]}>());


export const SaveFormDataAction = createAction('[Access your super permanent retirement preservation component] save form data', props<{accountId:number, payload:AccessYourSuperPaymentDetailSubmissionModel}>());
export const SavedFormDataAction = createAction('[Access your super permanent retirement preservation component] saved form data');
