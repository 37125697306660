
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setErrors, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { greaterThan, required, greaterThanOrEqualTo } from 'ngrx-forms/validation';
import { ISevereFinancialHardshipFinancialDetailsV1ComponentState, SfhFinancialDetailsFormV1 } from './state';
import { ResetComponentStateAction, SetFormDataAction } from './actions';

const SfhFinancialDetailsFormName = 'SfhFinancialDetailsFormV1';

export const state: ISevereFinancialHardshipFinancialDetailsV1ComponentState = {
  form: createFormGroupState(SfhFinancialDetailsFormName, new SfhFinancialDetailsFormV1()),
};

export const customValueGreaterThanEqualToValidator = (value?: number) => {
  const errors = {};
  if (value === null || value < 0) {
    errors['customError'] = "Value must be greater than or equal to $0."
  }
  return errors;
}

export const customValueGreaterThanValidator = (value?: number) => {
  const errors = {};
  if (value === null || value < 0 || value === 0) {
    errors['customError'] = "Value must be greater than $0."
  }
  return errors;
}

export const validateForm = updateGroup<SfhFinancialDetailsFormV1>({
  // asset validations
  assetsBankBalance: validate(customValueGreaterThanEqualToValidator),
  assetsOwnHome: validate(customValueGreaterThanEqualToValidator),
  assetsInvestmentProperties: validate(customValueGreaterThanEqualToValidator),
  assetsSharesInvestments: validate(customValueGreaterThanEqualToValidator),
  assetsVehicles: validate(customValueGreaterThanEqualToValidator),
  assetsHomeContents: validate(customValueGreaterThanEqualToValidator),
  assetsOtherAssets: validate(customValueGreaterThanEqualToValidator),
  assetsOtherAssetsDetails: (state, parentState) => {
    if (parentState.value.assetsOtherAssets > 0) {
      state = validate(state, required)
    }
    else {
      state = setErrors(state, {})
    }
    return state;
  },

  // liability validations
  liabilitiesHomeLoanRepayments: validate(customValueGreaterThanEqualToValidator),
  liabilitiesInvestmentLoanRepayments: validate(customValueGreaterThanEqualToValidator),
  liabilitiesPersonalLoanRepayments: validate(customValueGreaterThanEqualToValidator),
  liabilitiesCreditCardRepayments: validate(customValueGreaterThanEqualToValidator),
  liabilitiesVehicleRepayments: validate(customValueGreaterThanEqualToValidator),
  liabilitiesOtherDebts: validate(customValueGreaterThanEqualToValidator),
  liabilitiesOtherDebtsDetails: (state, parentState) => {
    if (parentState.value.liabilitiesOtherDebts > 0) {
      state = validate(state, required)
    }
    else {
      state = setErrors(state, {})
    }
    return state;
  },

  // income
  ownWeeklyIncome: validate(customValueGreaterThanEqualToValidator),
  partnersWeeklyIncome: validate(customValueGreaterThanEqualToValidator),
  dependantsWeeklyIncome: validate(customValueGreaterThanEqualToValidator),
  weeklyIncomeSupportPayments: validate(customValueGreaterThanValidator),
  rentalWeeklyIncome: validate(customValueGreaterThanEqualToValidator),
  investmentInterestWeeklyIncome: validate(customValueGreaterThanEqualToValidator),
  otherWeeklyIncome: validate(customValueGreaterThanEqualToValidator),
  otherWeeklyIncomeDetails: (state, parentState) => {
    if (parentState.value.otherWeeklyIncome > 0) {
      state = validate(state, required)
    }
    else {
      state = setErrors(state, {})
    }
    return state;
  },

  //expenses
  weeklyRentMortgageExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyCreditCardExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyCouncilRateExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyGroceriesExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyMedicalExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyVehicleExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyElectricityExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyGasExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyWaterExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyPhoneExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyInternetExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyInsuranceExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyEducationExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyOtherExpenses: validate(customValueGreaterThanEqualToValidator),
  weeklyOtherExpensesDetails: (state, parentState) => {
    if (parentState.value.weeklyOtherExpenses > 0) {
      state = validate(state, required)
    }
    else {
      state = setErrors(state, {})
    }
    return state;
  },

  attemptedRenegotiateLoansDetails: (state, parentState) => {
    if (parentState.value.attemptedRenegotiateLoans) {
      state = validate(state, required)
    }
    else {
      state = setErrors(state, {})
    }
    return state;
  }
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      form: createFormGroupState(SfhFinancialDetailsFormName, new SfhFinancialDetailsFormV1()),
    }
  }),
  on(SetFormDataAction, (state, { financialDetails }) => {
    return {
      ...state,
      form: setValue(Object.assign(new SfhFinancialDetailsFormV1(),
        {
          ...financialDetails
        }))(state.form)
    }
  }),
);

const editSfhFinancialDetailsV1ReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForm
)



export function severeFinancialHardshipFinancialDetailsV1ComponentReducer(state: any | undefined, action: Action) {
  return editSfhFinancialDetailsV1ReducerFormState(state, action);
}
