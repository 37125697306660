<div id="personal-details-container">
  <div class="page-header">
    <app-toolbar-options icon="person">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Profile' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex">
      <div class="w-50 " [ngClass]="{'pr-3': screenWidth>992}">
        <app-component-wrapper icon="warning" header="Attention" headerclass="danger">
          <div class="description" slot="start">
            <div [innerHTML]="'profileView_Attention'| message | async"></div>
          </div>
        </app-component-wrapper>
        <app-component-wrapper icon="insert_drive_file" header="Name Change & Date of Birth">
          <div class="description" slot="start">
            <div [innerHTML]="'profileView_NameChangeAndDob'| message | async"></div>
          </div>
        </app-component-wrapper>
        <app-component-wrapper icon="groups" header="Beneficiaries" *featuretoggle="personalDetailsBeneficiaryFeatureToggle">
          <div class="description" slot="start">
            <div [innerHTML]="'profileView_Beneficiaries'| message | async"></div>
          </div>
        </app-component-wrapper>
      </div>
      <div class="w-50">
        <app-component-wrapper icon="assignment_ind" header="Member Details">
          <div class="d-flex flex-column" slot="start">
            <app-member-view [hideTfn]="false"></app-member-view>
          </div>
          <div class="d-flex flex-row components-actions" slot="actions">
            <app-button *featuretoggle="updatePersonalDetailsFeatureToggle" class=" mr-1 ml-1" title="Update" icon="{{'BTN.personal-details.update.icon' | translate}}"
              (onClick)="onMemberEditClick()">
            </app-button>
          </div>
        </app-component-wrapper>
      </div>
    </div>

  </div>
</div>
