import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { HistoricalDataService } from 'src/app/services/historical-data.service';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccessYourSuperStartComponentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private service: AccessYourSuperService) {
  }

  getApplicationList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getApplicationList(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  cancelApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CancelApplicationAction),
    switchMap((action: any) => this.service.cancelApplication(action.accountId, action.applicationId).pipe(
      map((data: any) => {
        return ActionsList.SetApplicationToCancelledAction()
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ))

  continueApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestContinueApplicationAction),
    switchMap((action: any) => this.service.continueApplication(action.accountId, action.applicationId, action.draftApplication).pipe(
      map((data: any) => {
        if (action.draftApplication) {
          return ActionsList.ContinueApplicationAction({ model: data.data })
        }
        else {
          return ActionsList.UpdateSubmittedApplicationAction({ model: data.data })
        }
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ))
}
