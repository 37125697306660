import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { AsyncPipe } from '@angular/common';
import { InsuranceDetailsModel } from 'src/app/model/insurance-details.model';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';

@Component({
  selector: 'app-no-insurance',
  templateUrl: './no-insurance.component.html',
  styleUrls: ['./no-insurance.component.scss']
})
export class NoInsuranceComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() fund = '';
  @Input() data?:InsuranceDetailsModel = undefined;


  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  constructor(public appstore: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}


