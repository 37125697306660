import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperPensionCommutationsComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperPensionCommutationsState = createSelector(memberPortalState, (state) => state.accessYourSuperPensionCommutationsComponentState);

export const accessYourSuperPensionCommutations_Model = createSelector(
    getAccessYourSuperPensionCommutationsState,
    (state: IAccessYourSuperPensionCommutationsComponentState) => state.model
);

export const accessYourSuperPensionCommutations_Form = createSelector(
    getAccessYourSuperPensionCommutationsState,
    (state: IAccessYourSuperPensionCommutationsComponentState) => state.form
)

export const accessYourSuperPensionCommutations_FullAmountRadioOptions = createSelector(
    getAccessYourSuperPensionCommutationsState,
    (state: IAccessYourSuperPensionCommutationsComponentState) => state.fullAmountRadioOptions
)

export const accessYourSuperPensionCommutations_TaxOptions = createSelector(
    getAccessYourSuperPensionCommutationsState,
    (state: IAccessYourSuperPensionCommutationsComponentState) => state.taxOptions
)

export const accessYourSuperPensionCommutations_CurrentTotalPercent = createSelector(
    getAccessYourSuperPensionCommutationsState,
    (state: IAccessYourSuperPensionCommutationsComponentState) => state.form.value.investmentOptionDrawdown.reduce((a, b) => a + b.percentage, 0)
);

export const accessYourSuperPensionCommutations_SavedFormData = createSelector(
    getAccessYourSuperPensionCommutationsState,
    (state: IAccessYourSuperPensionCommutationsComponentState) => state.savedFormData
)