import { createReducer, Action, on } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { INoticeOfIntentTaxDeductionContainerState, NoticeOfIntentTaxDeductionContainerDislaimerForm } from './state';
import { ResetComponentStateAction, ResponseAction, SetCurrentStepAction, SetFormDataAction } from './actions';
import { requiredTrue } from 'ngrx-forms/validation';

export const state: INoticeOfIntentTaxDeductionContainerState = {
  currentStep: 1,
  applications: [],
  formComponentData: null,
  form: createFormGroupState('NoticeOfIntentContainerDisclaimerForm', new NoticeOfIntentTaxDeductionContainerDislaimerForm())
};

export const validateNoticeOfIntentTaxDeductionContainerForm = updateGroup<NoticeOfIntentTaxDeductionContainerDislaimerForm>({
  disclaimer: validate(requiredTrue),
})


const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      applications: payload,
    };
  }),
  on(SetCurrentStepAction, (state, { currentStep }) => {    
    return {
      ...state,
      currentStep,
    }
  }),
  on(SetFormDataAction, (state, { formData }) => {
    return {
      ...state,
      formComponentData: formData,
      currentStep: formData.hasError ? -1 : 2,
    }
  }),
  on(ResetComponentStateAction, (state) => {
    return {
      currentStep: 1,
      applications: [],
      formComponentData: null,
      form: createFormGroupState('NoticeOfIntentContainerDisclaimerForm', new NoticeOfIntentTaxDeductionContainerDislaimerForm()),
    }
  })
);


const editNoticeOfIntentTaxDeductionContainerReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateNoticeOfIntentTaxDeductionContainerForm
)

export function noticeOfIntentTaxDeductionContainerReducer(state: any | undefined, action: Action) {
  return editNoticeOfIntentTaxDeductionContainerReducerFormState(state, action);
}