import { FrequencyEnum } from "../enums/frequency.enum";
import { PensionPaymentScheduleModel } from "../model/pension-payment-schedule.model";

export class PensionHelper {
    constructor() {

    }

    getStartOfFinancialYear = () => {
        const today = new Date();
        const year = today.getFullYear();

        const startOfFiscalYear = today.getMonth() >= 6  // july
            ? new Date(year, 6, 1)
            : new Date(year - 1, 6, 1);

        return startOfFiscalYear;
    }

    getEndOfFinancialYear = () => {
        const today = new Date();
        const year = today.getFullYear();

        const endOfFiscalYear = today.getMonth() >= 6 // july
            ? new Date(year + 1, 5, 30)
            : new Date(year, 5, 30);

        return endOfFiscalYear;
    }

    monthsBetween = (endDate: Date, nextPaymentDate: Date) => {
        // Create Date objects for the start and end dates
        const start = new Date(); // todays date
        const end = new Date(endDate);

        // Adjust the end date to be at the end of the day for correct comparisons
        end.setHours(23, 59, 59, 999);

        // Ensure start date is earlier than end date
        if (start > end) {
            throw new Error('Start date must be before end date.');
        }

        let count = 0;

        // Iterate through the months until we exceed the end date
        while (nextPaymentDate <= end) {
            // Check if the payment date of the current month is within the range
            if (nextPaymentDate >= start && nextPaymentDate <= end) {
                count++;
            }
            // Move to the 15th of the next month
            nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
        }

        return count;
    }

    paymentIntervalsRemaining = (freqId: number, nextPaymentDate: Date) => {
        var currentDate = new Date();
        const endOfFy = this.getEndOfFinancialYear();

        // Get the current year and month
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth(); // Note: Months are 0-based (0 = January, 11 = December)

        // Create a Date object for the 10th of the current month
        const tenthOfMonth = new Date(year, month, 10);
        let nxtPaymentDate = new Date(year, month, 15);

        if (currentDate >= tenthOfMonth) {
            nxtPaymentDate = new Date(nxtPaymentDate.setMonth(nxtPaymentDate.getMonth() + 1))
        }

        var paymentDateCopy = new Date(nxtPaymentDate);
        var intervalsRemaining = 0;

        switch (freqId) {
            case FrequencyEnum.Annually: {
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), nxtPaymentDate.getDate()) <= new Date(endOfFy.getFullYear(), endOfFy.getMonth(), nxtPaymentDate.getDate()) ? 1 : 0;
            }
            case FrequencyEnum.HalfYearly: {
                const halfYearlyIntervals = [
                    new Date(nxtPaymentDate),
                    new Date(paymentDateCopy.setMonth(paymentDateCopy.getMonth() + 6))
                ];

                for (const interval of halfYearlyIntervals) {
                    if (interval <= endOfFy) {
                        intervalsRemaining++;
                    }
                }

                return intervalsRemaining;
            }
            case FrequencyEnum.Quarterly: {
                const quarterlyIntervals = [
                    new Date(nxtPaymentDate),
                    new Date(paymentDateCopy.setMonth(paymentDateCopy.getMonth() + 3)),
                    new Date(paymentDateCopy.setMonth(paymentDateCopy.getMonth() + 3)),
                    new Date(paymentDateCopy.setMonth(paymentDateCopy.getMonth() + 3))
                ];

                for (const interval of quarterlyIntervals) {
                    if (interval <= endOfFy) {
                        intervalsRemaining++;
                    }
                }

                return intervalsRemaining;
            }
            case FrequencyEnum.Monthly: {
                return this.monthsBetween(endOfFy, nxtPaymentDate);
            }
        }
    }

    calculateFreqAmount = (freqId: number, scheduleData: PensionPaymentScheduleModel, isMax: boolean): number => {
        var remainingAmount = (isMax ? scheduleData.annualMaximum - scheduleData.paymentAmountAlreadyMade : scheduleData.annualMinimum - scheduleData.paymentAmountAlreadyMade);
        var paymentsRemaining = this.paymentIntervalsRemaining(freqId, scheduleData.pensionNextPaymentDate);

        // if the remaining amount is less than or equal to zero, or payments remaining equals 0, the calculated amount is 0
        if (remainingAmount <= 0 || paymentsRemaining === 0) return 0;

        var amount = remainingAmount / paymentsRemaining;

        return isMax ? Math.floor(amount * 100) / 100 : Math.ceil(amount * 100) / 100;
    }
}