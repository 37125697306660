import { MemberPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { INoticeOfIntentTaxDeductionContainerState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const noticeOfIntentTaxDeduction = createSelector(memberPortalState, (state) => state.noticeOfIntentTaxDeductionContainerComponentState);

export const noticeOfIntentTaxDeduction_CurrentStep = createSelector(
  noticeOfIntentTaxDeduction,
(state: INoticeOfIntentTaxDeductionContainerState) => state.currentStep
);

export const noticeOfIntentTaxDeduction_Applications = createSelector(
  noticeOfIntentTaxDeduction,
(state: INoticeOfIntentTaxDeductionContainerState) => state.applications
);

export const noticeOfIntentTaxDeduction_FormComponentData = createSelector(
  noticeOfIntentTaxDeduction, 
  (state:INoticeOfIntentTaxDeductionContainerState) => state.formComponentData
)

export const noticeOfIntentTaxDeduction_Form = createSelector(
  noticeOfIntentTaxDeduction, 
  (state:INoticeOfIntentTaxDeductionContainerState) => state.form
)

export const noticeOfIntentTaxDeductionForm_ViewTitle = createSelector(
  noticeOfIntentTaxDeduction,
  (state: INoticeOfIntentTaxDeductionContainerState) => {
    switch (state.currentStep){
      case -1:
        return "Eligibility to claim a deduction";
      case 1:
        return "Your tax deduction requests";
      case 2:
        return "Eligibility to claim a deduction";
      case 3: 
        return "Notice of intent to claim or vary a tax deduction application";
      default:
        return "Your tax deduction requests";
    }
  }
);