<div id="dashboard-container">
    <div class="page-header">
      <app-toolbar-options icon="verified_user">
        <div class="d-flex flex-column" slot="start">
          <div class="page-title">{{'Access Your Super' | textTransform}}</div>

        </div>
      </app-toolbar-options>
    </div>
    <div class="page-content" appanimatecss>

      <app-component-wrapper >
        <div class="d-flex flex-column" slot="start">
            <app-access-your-super-update-submitted></app-access-your-super-update-submitted>
        </div>
      </app-component-wrapper>
    </div>
  </div>
