import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../../store/shared.reducers";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { IAccessYourSuperTerminalMedicalConditionComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperTerminalMedicalConditionState = createSelector(memberPortalState, (state) => state.accessYourSuperTerminalMedicalConditionComponentState);

export const accessYourSuperTerminalMedicalCondition_Model = createSelector(
    getAccessYourSuperTerminalMedicalConditionState,
    (state: IAccessYourSuperTerminalMedicalConditionComponentState) => state.model
);

export const accessYourSuperTerminalMedicalCondition_Form = createSelector(
    getAccessYourSuperTerminalMedicalConditionState,
    (state: IAccessYourSuperTerminalMedicalConditionComponentState) => state.form
)

export const accessYourSuperTerminalMedicalCondition_ReleaseConditionOptions = createSelector(
    getAccessYourSuperTerminalMedicalConditionState,
    (state: IAccessYourSuperTerminalMedicalConditionComponentState) => state.releaseConditionOptions
)

export const accessYourSuperTerminalMedicalCondition_FullAmountRadioOptions = createSelector(
    getAccessYourSuperTerminalMedicalConditionState,
    (state: IAccessYourSuperTerminalMedicalConditionComponentState) => state.fullAmountRadioOptions
)

export const accessYourSuperTerminalMedicalCondition_CurrentTotalPercent = createSelector(
    getAccessYourSuperTerminalMedicalConditionState,
    (state: IAccessYourSuperTerminalMedicalConditionComponentState) => state.form.value.investmentOptionDrawdown.reduce((a, b) => a + b.percentage, 0)
);

export const accessYourSuperTerminalMedicalCondition_SavedFormData = createSelector(
    getAccessYourSuperTerminalMedicalConditionState,
    (state: IAccessYourSuperTerminalMedicalConditionComponentState) => state.savedFormData
)