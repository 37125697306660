<div id="historical-graph-view-container">
  <div class="total-amount " matTooltip="Info about the action">
    <h2>{{(model$ | async).totalAmount | currency}}
    </h2>
    <h5>Balance as at {{(model$ | async).asAt | date:'dd/MM/yyyy'}}</h5>
  </div>

  <div>
    <div *ngIf="('historicalGraph_BalanceDetails' | message | async)"
      class="alert alert-info d-flex align-items-center balance-details">
      <mat-icon class="mr-3">help_outline</mat-icon>

      {{('historicalGraph_BalanceDetails' | message | async)}}

    </div>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-light" (click)="updateOptions( '1m' )"
        [class.active]="(selectedFilter$ | async)=='1m'">1M</button>
      <button type="button" class="btn btn-light" (click)="updateOptions( '6m' )"
        [class.active]="(selectedFilter$ | async)=='6m'">6M</button>
      <button type="button" class="btn btn-light" (click)="updateOptions( '1y' )"
        [class.active]="(selectedFilter$ | async)=='1y'">1Y</button>
      <button type="button" class="btn btn-light" (click)="updateOptions( 'all' )"
        [class.active]="(selectedFilter$ | async)=='all'">All</button>
    </div>

    <div class=" alert alert-info d-flex align-items-center" *ngIf="'historicalChart_Description'|message|async">
      <mat-icon class="mr-2">warning</mat-icon>
      {{'historicalChart_Description'|message|async}}
    </div>

    <apx-chart class="apx-chart" #chart [series]="chartOptions.series" [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels"
      [grid]="chartOptions.grid" [stroke]="chartOptions.stroke" [title]="chartOptions.title"
      [colors]="chartOptions.colors" [markers]="chartOptions.markers" [tooltip]="chartOptions.tooltip"></apx-chart>
    <div class="d-flex flex-fill flex-column">
      <mat-icon class="help-icon d-flex align-self-end"
        matTooltip="Select the area on the graph below by dragging the dotted line to zoom into the period you would like to view in the chart above.">
        help_outline</mat-icon>
      <div id="chart-line2">
        <apx-chart #chart2 [series]="chartOptions2.series" [chart]="chartOptions2.chart" [xaxis]="chartOptions2.xaxis"
          [colors]="chartOptions2.colors" [fill]="chartOptions2.fill" [yaxis]="chartOptions2.yaxis"></apx-chart>
      </div>
    </div>


  </div>
  <div class="d-flex mt-3 mb-1 links">

    <app-button class="{{'BTN.historical-chart-view.viewStatement.class'|translate}}"
      *featuretoggle="viewOtherStatementsFeatureToggle" icon="east"
      color="{{'BTN.historical-chart-view.viewStatement.color'|translate}}" routerLink="/member-statements"
      title="View Statements"></app-button>
    <app-button *featuretoggle="viewTransactionsFeatureToggle" icon="east" routerLink="/transaction-listing"
      title="View all transactions"></app-button>
    <app-button *featuretoggle="lodgeCirtClaimFeatureToggle" icon="attach_money" routerLink="/cirt/lodge-claim"
      title="Make a CIRT Claim"></app-button>
    <app-button *featuretoggle="jetcoClaimFeatureToggle" icon="attach_money" routerLink="/cirt/jetco-lodge-claim"
      title="Make a JETCO Claim"></app-button>


    <!-- <a class="btn btn-primary flex-fill d-grid mr-1" routerLink="/transaction-listing">View all transactions</a>
    <a class="btn btn-light flex-fill ml-1" routerLink="/member-statements">View Statements</a> -->
  </div>



</div>
