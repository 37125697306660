import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { EMPTY } from 'rxjs';

@Injectable()
export class MainLayoutEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    //private service: MembersService
  ) {
  }

  // searchRequest$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.MainSearchRequestAction),
  //   switchMap((action: any) => this.service.get(action.search, action.page).pipe(
  //     map((data: any) => {
  //       return ActionsList.MainSearchResponseAction({ payload: data.members });
  //     }),
  //     catchError(err => {
  //       return EMPTY;
  //     })
  //   )),
  // ));

}
