import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { accessYourSuperStart_GetSelectedAccount } from '../access-your-super-start/selectors';
import { combineLatest } from 'rxjs';
import { accessYourSuperForm_ApplicationId, accessYourSuperForm_ApplicationModel, accessYourSuperForm_ApplicationTypes, accessYourSuperForm_CurrentStep, accessYourSuperForm_CurrentStepUpdated } from './selector';
import {
  GetApplicationTypesRequestAction, ResetComponentStateAction, SaveApplicationDataAction, SaveBankDetailsAction, SaveDocumentModelAction, SaveLastEmployerDetailsAction, SetCurrentStepAction,
  UpdateCurrentStepAction
} from './actions';
import { Helper } from '@ifaa-components/ui-components';
import { AccessYourSuperBankDetailsSubmissionModel, AccessYourSuperDocumentOverviewModel, AccessYourSuperEmployerDetailsSubmissionModel } from 'src/app/model/access-your-super-form.models';
// import { getMemberView_Model } from '../member-view/selectors';
import { AccessYourSuperStepper } from '../access-your-super-progress-stepper/access-your-super-progress-stepper.component';
import { AccessYourSuperHelper } from 'src/app/helper/accessyourSuperHelper';
import { TranslateService } from '@ngx-translate/core';
import { commonState_MemberData } from 'src/app/store/common/common.selectors';

@Component({
  selector: 'app-access-your-super-form',
  templateUrl: './access-your-super-form.component.html',
  styleUrls: ['./access-your-super-form.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class AccessYourSuperFormComponent extends ComponentBase implements OnInit, OnDestroy {
  accountDropdown$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  applicationAccount$ = this.store.pipe(select(accessYourSuperStart_GetSelectedAccount));
  currentStep$ = this.store.pipe(select(accessYourSuperForm_CurrentStep));
  currentStepUpdated$ = this.store.pipe(select(accessYourSuperForm_CurrentStepUpdated));
  applicationModel$ = this.store.pipe(select(accessYourSuperForm_ApplicationModel));
  applicationId$ = this.store.pipe(select(accessYourSuperForm_ApplicationId));
  memberViewModel$ = this.store.pipe(select(commonState_MemberData))
  applicationTypes$ = this.store.pipe(select(accessYourSuperForm_ApplicationTypes));

  nextStep: number = null;
  previousStep: number = null;

  showStepper: boolean = true;
  canGoBack: boolean = false;
  stepperData: any[] = [];
  accessYourSuperHelper = new AccessYourSuperHelper();
  validationErrorList: string[] = [];
  @ViewChild(AccessYourSuperStepper) private myStepper: AccessYourSuperStepper;


  tfnTooltipText: string = "It isn’t compulsory to provide your TFN, but if its not provided additional tax may be deducted from your superannuation benefit and the taxable component of any cash payment will be taxed at the highest marginal rate plus applicable levies if you are under age 60.";

  constructor(
    public store: Store<AppState>,
    public translate: TranslateService,

    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = combineLatest([this.accountDropdown$, this.currentStep$])
      .pipe(
        map(([accountDropdown, currentStep]) => ({ accountDropdown, currentStep })))
      .subscribe(x => {
        if (x.currentStep === 0 && x.accountDropdown && x.accountDropdown.accountTypeId) {
          this.dispatch(this.store, GetApplicationTypesRequestAction({ accountType: x.accountDropdown.accountTypeId, accountId: x.accountDropdown.accountId }))
        }
      })

    this.sub = this.applicationModel$.pipe(distinctUntilChanged((x, y) => {
      return x?.conditionData === y?.conditionData;
    })).subscribe(x => {
      if (x) {
        // Create stepper data payload
        x.conditionData.requiredSections?.forEach(step => {
          switch (step) {
            case 3: {
              this.stepperData.push({
                title: 'Available components',
                completed: x.conditionData.currentStep > step ? true : false,
                stepIndex: 3
              })
              break;
            }
            case 4: {
              this.stepperData.push({
                title: 'Employment and final contribution',
                completed: x.conditionData.currentStep > step ? true : false,
                stepIndex: 4
              })
              break;
            }
            case 5: {
              this.stepperData.push({
                title: this.accessYourSuperHelper.getCustomSectionTitle(x.conditionData.applicationTypeId),
                completed: x.conditionData.currentStep > step ? true : false,
                stepIndex: 5
              })
              break;
            }
            case 6: {
              this.stepperData.push({
                title: 'Payment instructions',
                completed: x.conditionData.currentStep > step ? true : false,
                stepIndex: 6
              })
              break;
            }
            case 7: {
              this.stepperData.push({
                title: 'Documents',
                completed: x.conditionData.currentStep > step ? true : false,
                stepIndex: 7
              })
              break;
            }
            case 8: {
              this.stepperData.push({
                title: 'Review your application',
                completed: x.conditionData.currentStep > step ? true : false,
                stepIndex: 8
              })
              break;
            }
            default: {
              break;
            }
          }
        })
      }
    })

    this.sub = this.memberViewModel$.subscribe(async x => {
      if (x) {
        this.validationErrorList = [];
        if (!x.residentialAddress || !x.residentialAddress.fullAddress)
          this.validationErrorList.push("Please provide your Residential Address by clicking on the 'Update Details' button");
        var count = 0;
        var fieldsMissing = [];
        if (!x.firstName) {
          count++;
          fieldsMissing.push('First name');
        }
        if (!x.lastName) {
          count++;
          fieldsMissing.push('Last name');
        }
        if (!x.dateOfBirth) {
          count++;
          fieldsMissing.push('Date of birth');
        }
        if (count > 0) {
          this.sub = await this.translate.get('PLEASE_CONTACT_US_MESSAGE').subscribe(x => {
            var msg = fieldsMissing.join(', ');
            msg += count > 1 ? ' are required. ' : ' is required. ';
            msg += x.toString();
            this.validationErrorList.push(msg);

          });
        }
      }
    })

    // if member changes their account while completing the form, redirect back to landing page
    this.sub = combineLatest([this.accountDropdown$, this.applicationAccount$])
      .pipe(

        map(([accountDropdown, applicationAccountId]) => ({ accountDropdown, applicationAccountId })))
      .subscribe(x => {
        if (x.accountDropdown && x.applicationAccountId) {
          if (x.accountDropdown.accountId !== x.applicationAccountId) {
            this.router.navigate(['/access-your-super']);
          }
        }
        // stop users from navigating directly to the form
        if (!x?.applicationAccountId) {
          this.router.navigate(['/access-your-super']);
        }
      }
      );

    // Gets the next step when the current step value changes and we have the application model
    // local nextStep property will keep track of the next step value used to update state and application in backend
    this.sub = this.currentStep$.subscribe(async x => {
      var helper = new Helper();
      var model = await helper.getValue(this.applicationModel$);

      if (x && model && model.conditionData?.requiredSections) {

        var currentStepIndex = model.conditionData.requiredSections.indexOf(x);

        this.myStepper?.setSelectedIndex(currentStepIndex);
        if (currentStepIndex === 0) {
          this.canGoBack = false;
        }
        else {
          this.canGoBack = true;
        }

        this.previousStep = model.conditionData.requiredSections[currentStepIndex - 1];
        this.nextStep = model.conditionData.requiredSections[currentStepIndex + 1]
      }
    })

    // Subscription to handle updating the currentStep after the UpdateCurrentStep api request is successful
    // No value is returned via the api request so we use the local nextStep property to keep track
    this.sub = this.currentStepUpdated$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.nextStep }))
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.dispatch(this.store, ResetComponentStateAction());
  }

  updatePersonalDetails() {
    this.router.navigate(['/personal-edit'])
  }

  // function used when component doesn't need to send api request just to update the currentStep value of the application
  goNextStep(nextStep: number) {
    this.dispatch(this.store, SetCurrentStepAction({ nextStep: nextStep }))
  }

  // function used to update application next step value in backend
  async sendNextStepRequest() {
    var helper = new Helper();
    var account = await helper.getValue(this.accountDropdown$);
    var applicationId = await helper.getValue(this.applicationId$);

    this.dispatch(this.store, UpdateCurrentStepAction({ accountId: account?.accountId, currentStep: this.nextStep, applicationId: applicationId }))
  }

  exitForm() {
    this.router.navigate(['/access-your-super']);
  }

  onApplicationCreated(payload: any) {
    this.dispatch(this.store, SaveApplicationDataAction({ applicationId: payload.id, model: payload.model }))
  }

  async openAtoPage() {
    var helper = new Helper();
    var model = await helper.getValue(this.applicationModel$);
    window.open(model.conditionData.redirectToAtoLink, "_blank");
  }

  onLastEmployerDetailsSaved(payload: AccessYourSuperEmployerDetailsSubmissionModel) {
    this.dispatch(this.store, SaveLastEmployerDetailsAction({ lastEmployerName: payload.lastEmployerName, stillWithEmployer: payload.stillWithEmployer, finishDate: payload.finishDate, waitingOnFinalContribution: payload.waitingOnFinalContribution }))
  }

  onBankDetailsSaved(payload: AccessYourSuperBankDetailsSubmissionModel) {
    this.dispatch(this.store, SaveBankDetailsAction({ bankName: payload.bankName, accountName: payload.accountName, accountNumber: payload.accountNumber, bsb: payload.bsb }))
  }

  onDocumentsSaved(payload: AccessYourSuperDocumentOverviewModel[]) {
    this.dispatch(this.store, SaveDocumentModelAction({ payload: payload }))
  }

  onStepperSelectionChange(titles: any[], event: any) {
    this.dispatch(this.store, SetCurrentStepAction({ nextStep: titles[event.selectedIndex].stepIndex }))
  }

  onSubmissionSuccesful(payload: boolean) {
    if (payload) {
      this.showStepper = false;
    }
  }

  async documentsInvalid(isValid: boolean) {
    if (!isValid) {
      var helper = new Helper();
      var model = await helper.getValue(this.applicationModel$);
      var documentStep = model.conditionData.requiredSections.indexOf(7);
      this.myStepper.resetCompletion(documentStep);
    }
  }
}
