<div id="jetco-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Jetco</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper class="historical-graph-component" icon="" header="">
      <div class="d-flex flex-column" slot="start">
        <div class="header ">
          <h3 class="mt-2 mb-0">What is JETCO?</h3>
        </div>
        <p>
          The Joint Electrical Training Council (JETCO) is CIRTs associated training fund. JETCO offers eligible
          employees, who complete training related to the electrical contracting industry, subsidies to assist with in
          the costs of completing these courses.
        </p>
        <div class="header">
          <h3 class="mt-2 mb-0">Eligibility Rules</h3>
        </div>
        <p>Course fees associated with training will be reimbursed where the employee completing the training program
          meets the following criteria: </p>
        <ul>
          <li>
            Employees with 6 consecutive monthly contributions into the JETCO training fund are eligible for a training
            subsidy of up to <b>$500.00</b> on approved courses.
          </li>
          <li>
            Once an employee receives 12 consecutive monthly contributions into the JETCO training fund, they are
            eligible for increased training subsidy as follows;
            <ul>
              <li>
                Where the employer contributes <b>$5.00 per week</b>  into the training fund, the employee is eligible
                for a training subsidy of up to <b>$1,200.00</b>.
              </li>
              <li>
                Where the employer contributes <b>$10.00 per week</b>  into the training fund, the employee is eligible
                for a training subsidy of up to <b>$1,800.00</b>.
              </li>
              <li>
                Where the employer contributes <b>$15.00 per week</b>  into the training fund, the employee is eligible
                for a training subsidy of up to  <b>$2,200.00</b>.
              </li>
            </ul>



          </li>
        </ul>



        <b>Please note that:</b>
        <p>
          An employee will remain eligible for a JETCO subsidy on approved courses commenced up to 6 months after
          payments into the JETCO training fund have ceased e.g. if your last contribution is for June 2017, training
          must commence before the end of December 2017. Training limits apply per financial year and are based on the
          date training commenced. Eligibility limit is determined as at the date training commenced. Employees must
          fully complete the training subsidy application form and supply all relevant documentation to support their
          claim.
           </p>

        <div class="header">
          <h3 class="mt-2 mb-0">Approved Training</h3>
        </div>
        <p>
          To be eligible for a JETCO training subsidy, the course completed must be an approved course. Training courses
          that are generally considered to be acceptable would be any course relating to the electrical contracting
          industry. For more information on approved courses, see the <a
            href="https://www.cirt.com.au/index.php/jetco/" target="_blank">Approved Training</a> page.
           </p>
        <div class="header">
          <h3 class="mt-2 mb-0">Claiming a Subsidy</h3>
        </div>
        <p>
          Complete the following steps to be able to make a JETCO claim. Select a training course that you wish to
          study. Check your course is listed as an approved course or contact JETCO for approval and to confirm your
          eligibility. Enroll in and complete your training. Forward a completed Training Subsidy Application form, a
          copy of your completion certificate (or similar) a copy for your invoice, proof of payment, certified ID and a
          copy of the top section of your bank statement (containing your full name, account number and BSB) to JETCO.
          Your reimbursement will be made by direct deposit into your bank account.

        </p>
        <b>Please note that:</b>
         JETCO is only able to provide a training subsidy upon successful completion of your training program.
        <p></p>

      </div>
    </app-component-wrapper>





  </div>
</div>
