import { AppFileModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";

export interface IAccessYourSuperDocumentsComponentState {
    form: FormGroupState<AccessYourSuperDocumentsForm>;
    uploadedDocument:UploadedDocumentModel;
    deletedDocument:DeletedDocumentModel;
    fileRequestFailed:{
        controlId:string;
        file:AppFileModel;
    };
    documentsCompleted:Date;
    replacedDocument:ReplacedDocumentModel;
}

export class UploadedDocumentModel{
    uploadedDocumentId:number;
    fileName:string;
    documentTypeId:number;
    documentIndex:number;
    controlId:any;
}

export class DeletedDocumentModel{
    uploadedDocumentId:number;
    overviewIndex:number;
    uploadIndex:number;
    controlId:any;
}

export class ReplacedDocumentModel{
    payload:AppFileModel;
    fileName:string;
    documentTypeId:number;
    documentIndex:number;
    controlId:any;
}

export class AccessYourSuperDocumentDetails {
    files: AppFileModel[] = [];
    documentTypeId:number;
    requestAuthority:boolean;
    referenceRequired:boolean;
    referenceValue:string;
    isDocumentRequired:boolean;
    previouslyProvided:boolean;
}

export class AccessYourSuperDocumentsForm {
    greenIdVerified: boolean = false;
    supportingDocuments: AccessYourSuperDocumentDetails[] = [];
}