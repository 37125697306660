import { KeyValueModel } from '@ifaa-components/ui-components';
import { createAction, props } from '@ngrx/store';
import { PensionPaymentScheduleModel, PensionPaymentScheduleSubmissionModel } from 'src/app/model/pension-payment-schedule.model';

export const ResetFormAction = createAction('[Pension Change Payment Schedule] reset form');

export const GetPaymentScheduleRequestAction = createAction('[Pension Change Payment Schedule] get schedule data', props<{accountId:number, isPension:boolean}>())
export const GetPaymentScheduleResponseAction = createAction('[Pension Change Payment Schedule] set schedule data', props<{scheduleData: PensionPaymentScheduleModel}>())

export const SetFilteredPaymentTypesAction = createAction('[Pension Change Payment Schedule] set filtered payment types', props<{paymentTypes: KeyValueModel[]}>())
export const SetFilteredFrequenciesAction = createAction('[Pension Change Payment Schedule] set filtered frequencies', props<{frequencies: KeyValueModel[]}>())

export const SubmitPaymentScheduleRequestAction = createAction('[Pension Change Payment Schedule] save schedule data request', props<{accountId:number, submissionData:PensionPaymentScheduleSubmissionModel}>());
export const SubmitPaymentScheduleResponseAction = createAction('[Pension Change Payment Schedule] save schedule data response');
export const SubmitPaymentScheduleErrorResponseAction = createAction('[Pension Change Payment Schedule] error submitting schedule data response')