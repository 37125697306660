import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent extends ComponentBase implements OnInit, OnDestroy {

  displayPopupError = null;
  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.displayPopupError = localStorage.getItem('login-error-popup-block');
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
