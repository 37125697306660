import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";


@Component({
    selector: 'app-defined-benefit-account-summary',
    templateUrl: './defined-benefit-account-summary.component.html',
    styleUrls: ['./defined-benefit-account-summary.component.scss']
})

export class DefinedBenefitAccountSummaryComponent extends ComponentBase implements OnInit, OnDestroy {
    
    constructor(public store: Store<AppState>) {
      super();
    }

    ngOnDestroy(): void {
        super.ngOnDestroyBase();
    }

    ngOnInit(): void {
        super.ngOnInitBase();
    }
}