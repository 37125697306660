<div id="take-me-with-you-container">
  <div class="page-header">
    <app-toolbar-options icon="supervisor_account">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title" [innerHtml]="'VIEWS.TakeMeWithYou.pageTitle' | translate | textTransform">
        </div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex">
      <div class="flex-fill">
        <app-component-wrapper icon="article" header="Compliance Letter" headerclass="">
          <div class="d-flex flex-column" slot="start">
            <div class="description">Please click <a target="_blank"
                href="{{'VIEWS.TakeMeWithYou.complianceLetterLink' | translate}}">here</a> to open the Letter of
              Compliance.
            </div>
          </div>
        </app-component-wrapper>
      </div>
      <div class="flex-fill pl-3" *ngIf="(selected$ | async)?.accountTypeName =='Accumulation' && ('VIEWS.TakeMeWithYou.displayChoiceOfFundForm' | translate)" >
        <app-component-wrapper icon="list_alt" header="Choice of Fund - Form" headerclass="" *ngIf="!('VIEWS.TakeMeWithYou.choiceOfFundLink' | translate)">

          <div class="d-flex flex-column" slot="start">
            <div class="description">
              Please click <a class="document-link" (click)="download()">here</a> to download the Choice of Fund Form.
            </div>

          </div>
        </app-component-wrapper>
        <app-component-wrapper icon="list_alt" header="Choice of Fund - Form" headerclass="" *ngIf="'VIEWS.TakeMeWithYou.choiceOfFundLink' | translate">

          <div class="d-flex flex-column" slot="start">
            <div class="description">
              Please click <a target="_blank"
                href="{{'VIEWS.TakeMeWithYou.choiceOfFundLink' | translate}}">here</a> to download the Choice of Fund Form.
            </div>

          </div>
        </app-component-wrapper>


      </div>
    </div>
    <div class="d-flex" *ngIf="choiceOfFundLetter">
      <app-component-wrapper class="flex-fill" icon="list_alt" header="Choice of Fund - Email" headerclass="">

        <div class="d-flex flex-column" slot="start">
          <p class="user-select-all description" [innerHtml]="choiceOfFundLetter">
          </p>

        </div>
        <div class="d-flex flex-column" slot="actions">
          <!-- (onClick)="onSubmitClick(form.value)"  -->
          <app-button class="copy-text w-fit-content" title="Copy text" icon="content_copy"
           (onClick)="copyText(choiceOfFundLetter)" >
        </app-button>

        </div>
      </app-component-wrapper>
    </div>
  </div>

</div>
