import { consolidateSuper_List } from './selectors';
import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { RequestAction } from './actions';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { FeatureToggleCheckService, FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';
import { OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-consolidate-super',
  templateUrl: './consolidate-super.component.html',
  styleUrls: ['./consolidate-super.component.scss']
})
export class ConsolidateSuperComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(consolidateSuper_List));
  rollInFeatureToggle = FeatureToggleName.member.account.rollIn.view;
  toggleHelper = new FeatureToggleHelper();

  constructor(public store: Store<MemberPortalSharedState>,
    public appStore: Store<AppState>,
    public toggleCheck: FeatureToggleCheckService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.checkAccountFeatureToggle(this.rollInFeatureToggle, (enabled, account) => {
      if (enabled) {
        this.dispatch(this.store, RequestAction({ accountId: account.accountId }));
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onStartClick() {
    this.router.navigate(['rollin']);
  }
}
