<app-component-wrapper class="d-flex  flex-fill" compclass="flex-fill h-100" id="member-view-overview">
  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="member-view-overview-container">
      <div class="d-flex flex-column" *ngIf="data$ | async; let data">
        <div class="header">
          <h3>{{'VIEWS.MemberViewOverview.greetings' | translate}}</h3>
          <div style="display: contents;">
            <div class="d-flex flex-row">
              <div *ngIf="data?.preferredName?.length > 0 && 'VIEWS.MemberViewOverview.showChosenName' | translate"
                class="member-name" title="{{data.preferredName}}">
                {{data.preferredName}}
              </div>
              <div *ngIf="!data?.preferredName && 'VIEWS.MemberViewOverview.showChosenName' | translate"
                class="member-name" title="{{data.firstName}}">
                {{data.firstName}}
              </div>
              <div *ngIf="!('VIEWS.MemberViewOverview.showChosenName' | translate)" class="member-name"
                title="{{data.firstName}} {{data.lastName}}">
                {{data.firstName}} {{data.lastName}}
              </div>
              <span class="post-greeting" *ngIf="'VIEWS.MemberViewOverview.post-greetings' | translate">
                {{'VIEWS.MemberViewOverview.post-greetings' | translate}}
              </span>
            </div>
          </div>


          <div class="float-right animate__animated " *ngIf="screenWidth>=992"
            [ngClass]="{'animate__bounde': (tenantConfig$|async)?.overviewWelcomeBackJump}">
            <img [src]="(tenantConfig$|async)?.overviewWelcomeBackImage">
          </div>
        </div>
        <div class="content">
          <div class="d-flex flex-wrap flex-fill {{screenWidth<=992?'flex-column':''}}">
            <app-readonly *ngIf="'VIEWS.MemberViewOverview.displayMemberNumber' | translate" title="Member number"
              [value]="data.memberNumber" class="flex-fill p-1 custom"></app-readonly>
            <app-readonly *ngIf="'VIEWS.MemberViewOverview.displayAccountNumber' | translate" title="Account number"
              [value]="(selected$|async)?.accountNumber" class="flex-fill p-1 custom"></app-readonly>
            <app-readonly title="Mobile" [value]="data.mobile"
              class="flex-fill p-1 custom mobile-wrapper"></app-readonly>
            <app-readonly title="Email Address" [value]="data.email"
              class="flex-fill p-1 custom email-wrapper"></app-readonly>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="d-flex flex-row page-content-actions" slot="actions">
    <app-button *featuretoggle="viewProfileDetailsFeatureToggle"
      icon="{{'BTN.member-view-overview-view.profile.icon' | translate}}" class="" title="View profile"
      (onClick)="personalDetails()"></app-button>
    <ng-container *ngIf="screenWidth>992">
      <app-button *featuretoggle="changePasswordFeatureToggle" class=" mr-1 ml-1" title="Change Password"
        icon="{{'BTN.member-view-overview-view.changepassword.icon' | translate}}"
        color="{{'BTN.member-view-overview-view.changepassword.color' | translate}}" (onClick)="changePassword()">
      </app-button>
    </ng-container>
  </div>
</app-component-wrapper>
