import { createAction, props } from "@ngrx/store";
import { AccessYourSuperKiwiSaverDetailSectionModel, AccessYourSuperKiwiSaverDetailSubmissionModel, } from "src/app/model/access-your-super-custom-sections.model";

export const ResetComponentStateAction = createAction('[Access your super kiwi saver component] reset state');
export const RequestAction = createAction('[Access your super kiwi saver component] get data', props<{ accountId: number, applicationId:number }>());
export const ResponseAction = createAction('[Access your super kiwi saver component] set data', props<{ payload: AccessYourSuperKiwiSaverDetailSectionModel }>());


export const SaveFormDataAction = createAction('[Access your super kiwi saver component] save form data', props<{accountId:number, payload:AccessYourSuperKiwiSaverDetailSubmissionModel}>());
export const SavedFormDataAction = createAction('[Access your super kiwi saver component] saved form data');


export const SetKiwiSaverStepAction = createAction('[Access your super kiwi saver component] set kiwi saver step', props<{step:number}>())
