import { SetMenuAction } from './../../../../store/common/common.actions';
import { mainLayout_Expanded } from './selectors';
import { MenuModel } from './../../../../model/menu.model';
import { AppState } from 'src/app/store/app.states';
import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from 'src/app/views/component-base';
import { commomState_IsLoading, commomState_LoggedinInfo, commomState_MenuList, commomState_SystemConfig_FeatureToggle, commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { SystemConfigurationRequestAction } from 'src/app/store/common/common.actions';
import { MainExpandedRequestAction } from './actions';
import { CIRTState } from '../../store/cirt.states';
import { MatDrawer } from '@angular/material/sidenav';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { Title } from '@angular/platform-browser';
import { memberViewOverview_Model } from 'src/app/modules/shared/components/member-view-overview/selectors';
// import { RequestAction } from 'src/app/modules/shared/components/member-view/actions';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { combineLatest } from 'rxjs';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { AccountFeatureToggle } from 'src/app/model/account-feature-toggles.model';
import { FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';
import { FeatureToggleValue } from 'src/app/model/feature-toggle-value.model';

@Component({
  selector: 'login-master',
  templateUrl: './main.master.html',
  styleUrls: ['./main.master.scss']
})
export class MainMasterComponent extends ComponentBase {

  expanded: MenuModel[] = [];
  featuretoggle$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));
  expanded$ = this.cirtstore.pipe(select(mainLayout_Expanded));
  menuList$ = this.store.pipe(select(commomState_MenuList));
  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  isLoading$ = this.store.pipe(select(commomState_IsLoading));
  isQuestionnarieVisible$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  user$ = this.store.pipe(select(memberViewOverview_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  accountFeatureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));

  accountFeatureToggles: AccountFeatureToggle[] = null;
  featureToggleValues: any = null;
  featureToggleHelper = new FeatureToggleHelper();

  menuMode = "side";
  menuOpened = true;
  showMenuIconTop = true;
  menu = [
    { id: '1', name: 'Overview', link: '/dashboard', icon: 'house', children: [] } as MenuModel,
    {
      id: '2', name: 'Account', icon: 'account_box', children: [
        { id: '5-1', name: 'Update your details', link: '/personal-details', icon: 'create', children: [], featureFlag: FeatureToggleName.member.personalDetails.view } as MenuModel,
        { id: '5-4', name: 'Change your password', link: '/change-password', icon: 'security', children: [], featureFlag: FeatureToggleName.member.changePassword.view } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '11', name: 'Transaction History', link: '/transaction-listing', icon: 'receipt_long', children: [], featureFlag: FeatureToggleName.member.account.transactions.view
    } as MenuModel,
    {
      id: '14', name: 'Employment History', link: '/cirt/employment-history', icon: 'history', children: [], featureFlag: FeatureToggleName.member.account.employerBalances.view
    } as MenuModel,
    {
      id: '12', name: 'Beneficiaries', link: '/beneficiaries', icon: 'supervisor_account', children: [], featureFlag: FeatureToggleName.member.account.beneficiaries.view
    } as MenuModel,
    {
      id: '15', name: 'Lodge CIRT Claim', link: '/cirt/lodge-claim', icon: 'post_add', children: [], featureFlag: FeatureToggleName.member.account.lodgeCirtClaim.view
    } as MenuModel,
    {
      id: '13', name: 'Jetco', icon: 'auto_stories', link: '/cirt/jetco', children: [
        { id: '13-1', name: 'Approved Training', link: 'https://www.cirt.com.au/index.php/jetco/', target: '_blank', icon: 'text_snippet', children: [], featureFlag: FeatureToggleName.member.account.JetcoTraining.view } as MenuModel,
        { id: '13-2', name: 'Lodge a Claim', link: '/cirt/jetco-lodge-claim', icon: 'article', children: [], featureFlag: FeatureToggleName.member.account.lodgeCirtClaim.view } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '7', name: 'Documents', icon: 'folder', children: [
        { id: '7-1', name: 'View Statements', link: '/member-statements', icon: 'text_snippet', children: [], featureFlag: FeatureToggleName.member.account.viewStatements.view } as MenuModel,
        { id: '7-2', name: 'Other Documents', link: '/other-documents', icon: 'article', children: [], featureFlag: FeatureToggleName.member.account.otherDocuments.view } as MenuModel,
      ]
    } as MenuModel,

    {
      id: '10', name: 'Member Services', icon: 'portrait', link: 'https://www.cirt.com.au', target: '_blank', children: []
    } as MenuModel,


  ]

  @ViewChild("drawer") drawer: MatDrawer;

  constructor(public store: Store<AppState>,
    public cirtstore: Store<CIRTState>,
    public dialog: MatDialog,
    private titleService: Title,
    private router: Router,
    private msalInstance: MsalService) {
    super();
    this.titleService.setTitle("CIRT Super – Member Portal");
  }

  ngOnInit() {
    super.ngOnInitBase();
    // this.store.dispatch(RequestAction());

    this.featureToggleValues = {
      available: FeatureToggleValue.available,
      hidden: FeatureToggleValue.hidden,
      disabled: FeatureToggleValue.disabled
    };

    this.screenWidthChange(this.store, (width: number) => {
      this.menuMode = "side";
      this.menuOpened = true;

      if (width < 1300) {
        this.menuMode = "over";
        this.menuOpened = false;
      }

    });
    this.sub = this.expanded$.subscribe(x => {
      this.expanded = x;
    });

    // Filter menu items based on insurance questionnaire and feature toggles, and then push to redux state
    combineLatest([this.selectedAccount$, this.accountFeatureToggles$, this.isQuestionnarieVisible$])
      .subscribe((([selectedAccount, featureToggles, isVisible]) => {
        if (selectedAccount && featureToggles && (isVisible === true || isVisible === false)) {

          //Filter questionnaire stuff first
          var newMenu = [...this.menu];
          if (!isVisible) {
            for (let i = 0; i < newMenu.length; i++) {
              newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-2') };
            }
          }
          else {
            for (let i = 0; i < newMenu.length; i++) {
              newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-1') };
            }
          }

          // Filter feature toggles
          var returnMenu = [];
          for (let i = 0; i < newMenu.length; i++) {
            // If the menu item has children we should check that at least 1 child item will be available/disabled
            // If there are 0 child items available/disabled, we dont show the top level menu item
            if (newMenu[i].children.length > 0) {
              var topLevelMenuItem = { ...newMenu[i], children: newMenu[i].children?.filter(c => this.featureToggleHelper.checkToggle(c.featureFlag, featureToggles, selectedAccount)) };

              if (topLevelMenuItem.children.length > 0) {
                returnMenu.push(topLevelMenuItem);
              }
            }
            else {
              // If there are 0 children, we just check the feature toggle on the top level item and add it if its not hidden (e.g. AYS)
              if (this.featureToggleHelper.checkToggle(newMenu[i].featureFlag, featureToggles, selectedAccount)) {
                returnMenu.push(newMenu[i]);
              }
            }
          }

          this.setMenu(returnMenu);
        }
      }));
  }

  openedChange() {
    if (this.drawer) {
      // setTimeout(() => {
        this.menuOpened = this.drawer.opened;
      // }, 1);
    }
  }
  onSwipeToggle(event) {
    this.menuOpened = !this.menuOpened;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onItemSelectedClick(item: MenuModel) {
    this.store.dispatch(MainExpandedRequestAction({ menuItem: item }))

    if (item.link) {
      if (item.link.startsWith('http')) {
        window.open(item.link, "_blank");
      }
      else {
        this.router.navigate([item.link]);
      }
    }
  }

  isMenuExpanded(item: MenuModel) {
    return this.expanded.filter(x => x.id == item.id).length > 0;
  }

  onSignoutClick(): void {
    this.msalInstance.logoutRedirect(
      {      postLogoutRedirectUri: environment.b2cLogoutUrl  }
      );
    // window.localStorage.removeItem('token_name');
    // this.router.navigate(['login']);
  }


  setMenu(menu: MenuModel[]) {

    this.store.dispatch(SetMenuAction({ data: menu }))

  }

}
