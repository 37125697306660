import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue, updateArray
} from 'ngrx-forms';
import { CurrentInvestmentModel, IInvestmentEditState, InvestmentItemModel, InvestmentModel } from './state';
import {
  ResetAction, SubmitResponseAction, GoToStepAction,
  ResponseCurrentDataAction, ResponseOptionsDataAction, SetStepsAction, SetIsPensionAction, SetSubmitText, SetIsSimpleSwitch
} from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'investmentEditForm';
var helper = new Helper();
export const state: IInvestmentEditState = {
  form: createFormGroupState(formName, new CurrentInvestmentModel()),
  lastModifiedTime: null,
  currentStep: 0,
  steps: [],
  currentInfo: null,
  originalCurrentInfo: null,
  options: null,
  isPension: false,
  futureModelList: [],
  submitText : '',
  failedPerformanceMessage:  null,
  isSimpleSwitch:false
};

export const validateEditMemberForm = updateGroup<CurrentInvestmentModel>({
  //  firstName: validate(required),
  // current: updateArray(updateGroup<InvestmentItemModel>({
  //   percentage: validate(helper.zeroAllowedAndgreaterThanOrEqualTo(5)),
  // })),
  // future: updateArray(updateGroup<InvestmentItemModel>({
  //   percentage: validate(helper.zeroAllowedAndgreaterThanOrEqualTo(5)),
  // }))
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetAction, (state) => {
    return {
      ...state,
      form: createFormGroupState(formName, new CurrentInvestmentModel()),
      lastModifiedTime: null,
      submitText:'',
      currentStep: 0
    };
  }),
  on(SetStepsAction, (state, { steps }) => {
    var form = state.form;
    // if (!state.currentInfo.hasPendingCurrentOptions) {
    //   var val = helper.clone(form.value);
    //   val.future = state.futureModelList;
    //   form = setValue(val)(state.form);
    // }
    return {
      ...state,
      form: form,
      steps: steps
    };
  }),
  on(GoToStepAction, (state, { step }) => {
    var form = state.form;

    if (JSON.stringify(state.steps) != JSON.stringify(state.steps)) {
      form = setValue(Object.assign(new CurrentInvestmentModel(), state.options))(state.form);
    }
      if (state.isPension) {
        // selected both
        if (state.steps.indexOf(1) > 0 && state.steps.indexOf(2) > 0) {
          if (state.currentStep == 1) {
            var val = helper.clone(form.value);

            val.future = [...val.current];

            val.future = val.future.filter(x => x.percentage > 0);
            val.future.forEach(element => {
              element.percentage = 0
            });
            form = setValue({
              ...form.value,
              future: val.future
            })(state.form);
          }
        }
        else {
          if (!state.currentInfo.hasPendingCurrentOptions && state.currentStep == 0) {
            var val = helper.clone(form.value);
            var current = helper.clone(state.currentInfo.current);
            current.forEach(element => {
              element.percentage = 0;
            });
            val.future = current;//.futureModelList;
            form = setValue(val)(state.form);
          }

        }
      }
      else {
        var val = helper.clone(form.value);
        if (state.futureModelList.length != val.future.length) {
          val.future = state.futureModelList;
          form = setValue(val)(state.form);
        }
      }


    return {
      ...state,
      form: form,
      currentStep: step
    };
  }),
  on(SetIsPensionAction, (state, { payload }) => {
    return {
      ...state,
      isPension: payload
    };
  }),
  on(ResponseCurrentDataAction, (state, { payload }) => {
    return {
      ...state,
      currentInfo: payload,
      originalCurrentInfo : payload
    };
  }),
  on(ResponseOptionsDataAction, (state, { payload }) => {
    var futureModelList = payload.future;
    var failedPerformanceMessage = payload.failedPerformanceMessage;

    return {
      ...state,
      form: setValue(Object.assign(new CurrentInvestmentModel(), payload))(state.form),
      futureModelList: futureModelList,
      failedPerformanceMessage:failedPerformanceMessage,

      options: payload
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
  on(SetSubmitText, (state, { submitText }) => {
    return {
      ...state,
      submitText: submitText
    };
  }),
  on(SetIsSimpleSwitch, (state) => {
    return {
      ...state,
      isSimpleSwitch:true
    }
  })
);

const editInvestmentReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditMemberForm,
);

export function investmentEditReducer(state: any | undefined, action: Action) {
  return editInvestmentReducerFormState(state, action);
}
