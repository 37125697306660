import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainMasterComponent } from "./layout/main/main.master";
import { VerveAcademyViewComponent } from "./views/verve-academy/verve-academy-view.component";
import { AccountFeatureToggleGuardService } from "src/app/route-guard/account-feature-toggle-guard.service";
import { FeatureToggleName } from "src/app/model/feature-toggle-name.model";
import { MsalGuard } from "@azure/msal-angular";

const verveRoutes: Routes = [
  // { path: "", component: ShipListComponent },
  {
    path: 'academy',
    component: MainMasterComponent,
    canActivate: [MsalGuard, AccountFeatureToggleGuardService],
    data: { flag: [FeatureToggleName.member.knowledgeAcademy.view] },
    children: [
      {
        outlet: 'master',
        path: '',
        component: VerveAcademyViewComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(verveRoutes)],
  exports: [RouterModule]
})
export class VerveRoutingModule { }
