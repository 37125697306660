import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey } from "../../store/shared.reducers";
import { MemberPortalSharedState } from "../../store/shared.states";
import { IAccessYourSuperBankDetailsComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperBankDetailState = createSelector(memberPortalState, (state) => state.accessYourSuperBankDetailComponentState);

export const accessYourSuperBankDetails_Form = createSelector(
    getAccessYourSuperBankDetailState,
    (state: IAccessYourSuperBankDetailsComponentState) => state.form
)

export const accessYourSuperBankDetails_BankDetailsSaved = createSelector(
    getAccessYourSuperBankDetailState,
    (state:IAccessYourSuperBankDetailsComponentState) => state.bankDetailsSaved
)