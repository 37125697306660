import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessYourSuperService } from 'src/app/services/access-your-super.service';
import * as ActionsList from './actions';
import { EMPTY } from 'rxjs';

@Injectable()
export class AccessYourSuperBankDetailsComponentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: AccessYourSuperService) {
  }

  searchBankName$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SearchBankNameAction),
    switchMap((action: any) => this.service.searchBankName(action.bsb).pipe(
      map((data: any) => {
        return ActionsList.SetBankNameAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  saveBankDetails$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SaveBankDetailsAction),
    switchMap((action: any) => this.service.saveBankDetails(action.accountId, action.applicationId, action.payload).pipe(
      map((data: any) => {
        return ActionsList.SetBankDetailsSavedAction()
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ))
}
