<div id="personal-details-container">
  <div class="page-header">
    <app-toolbar-options icon="savings">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Pension Details' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex">
      <div class="w-100">
        <app-component-wrapper icon="savings" header="Pension Details">
          <div class="d-flex flex-column" slot="start">
            <app-member-pension-view></app-member-pension-view>
          </div>

        </app-component-wrapper>
      </div>
    </div>

  </div>
</div>
