
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction, SetFullAmountRadioOptionsAction, SetTaxOptionsAction, SetSfhStepAction, SetDependendantFormDataAction, SetFormVersionAction, SetFormThreeValidAction } from './actions';
import { SfhBenefitPaymentDetailsForm, SfhDependantsForm, IAccessYourSuperSevereFinancialHardshipComponentState} from './state';

const SfhBenefitPaymentDetailsFormName = 'SfhBenefitPaymentDetailsForm';
const SfhDependantsFormName = 'SfhDependantsForm';

export const state: IAccessYourSuperSevereFinancialHardshipComponentState = {
  model: null,
  formOne: createFormGroupState(SfhBenefitPaymentDetailsFormName, new SfhBenefitPaymentDetailsForm()),
  formTwo: createFormGroupState(SfhDependantsFormName, new SfhDependantsForm()),
  formThreeValid:false,
  taxOptions: null,
  fullAmountRadioOptions: null,
  savedFormData: null,
  sfhStep: 0,
  formVersion: 1, 
};

export const validateFormOne = updateGroup<SfhBenefitPaymentDetailsForm>({
  paymentType: validate(required),
  sfhSummary: validate(required),
  partialAmountSpecified: (state, parentState) => {
    if (parentState.value.paymentType === 1) {
      const checkPartialAmount = () => {
        const errors = {};
        var totalBalance = parentState.value.investmentOptionDrawdown.reduce((a, b) => a + b.amount, 0);
        totalBalance = Math.round(totalBalance * 100) / 100;

        if (parentState.value.memberReachedPreservationAge) {
          if (totalBalance < 1000) {
            if (totalBalance !== parentState.value.partialAmountSpecified)
              errors["customError"] = `You must specify ${totalBalance} as your balance is less than 1000`;
          }
          else if (totalBalance >= 1000 && 1000 > parentState.value.partialAmountSpecified) {
            errors["customError"] = 'Value must be greater than 1000';
          }
          else if (totalBalance >= 1000 && totalBalance < parentState.value.partialAmountSpecified) {
            errors["customError"] = `Value must be less than or equal ${totalBalance}`
          }
        }
        else {
          if (totalBalance < 1000) {
            if (totalBalance !== parentState.value.partialAmountSpecified)
              errors["customError"] = `You must specify ${totalBalance} as your balance is less than 1000`;
          }
          else if (totalBalance >= 1000 && 1000 > parentState.value.partialAmountSpecified) {
            errors["customError"] = 'Value must be greater than 1000';
          }
          else if (totalBalance >= 1000 && 10000 < parentState.value.partialAmountSpecified) {
            errors["customError"] = `Value must be less than or equal 10000`
          }
        }

        return errors;
      }
      state = validate(state, required, checkPartialAmount)
    }
    return state;
  },
  receivingQualifyingSupport: (state, parentState) => {
    const validateReceivingQualifyingSupport = () => {
      const errors = {};
      if (!parentState.value.receivingQualifyingSupport) {
        errors["customError"] = parentState.value.receivingQualifyingSupportErrorMsg;
      }
      return errors;
    }
    state = validate(state, required, validateReceivingQualifyingSupport)
    return state;
  }
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      model: null,
      formOne: createFormGroupState(SfhBenefitPaymentDetailsFormName, new SfhBenefitPaymentDetailsForm()),
      formTwo: createFormGroupState(SfhDependantsFormName, new SfhDependantsForm()),
      savedFormData: null,
      sfhStep:0,
      formVersion:1
    }
  }),
  on(SetTaxOptionsAction, (state, { payload }) => {
    return {
      ...state,
      taxOptions: payload
    }
  }),
  on(SetFullAmountRadioOptionsAction, (state, { payload }) => {
    return {
      ...state,
      fullAmountRadioOptions: payload
    }
  }),
  on(SavedFormDataAction, (state, { }) => {
    return {
      ...state,
      savedFormData: new Date()
    }
  }),
  on(SetSfhStepAction, (state, { step }) => {
    return {
      ...state,
      sfhStep: step,
      savedFormData: null
    }
  }),
  on(SetDependendantFormDataAction, (state, { dependants }) => {
    return {
      ...state,
      formTwo: setValue(Object.assign(new SfhDependantsForm(),
        {
          dependants: [...dependants]
        }
      ))(state.formTwo)
    }
  }),
  on(SetFormVersionAction, (state, {formVersion}) => {
    return {
      ...state,
      formVersion:formVersion
    }
  }),
  on(SetFormThreeValidAction, (state, {isValid}) => {
    return {
      ...state,
      formThreeValid:isValid
    }
  })
);

const editSevereFinancialHardshipReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.formOne,
  validateFormOne
)


export function accessYourSuperSevereFinancialHardshipComponentReducer(state: any | undefined, action: Action) {
  return editSevereFinancialHardshipReducerFormState(state, action);
}
